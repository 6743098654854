import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Call = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M12 22.1923V20.6923H18.6923C18.7821 20.6923 18.8558 20.6634 18.9135 20.6058C18.9712 20.5481 19 20.4743 19 20.3845V19.5H15.4615V12.423H19V11C19 9.06667 18.3167 7.41667 16.95 6.05C15.5833 4.68333 13.9333 4 12 4C10.0667 4 8.41667 4.68333 7.05 6.05C5.68333 7.41667 5 9.06667 5 11V12.423H8.5385V19.5H5.30775C4.80258 19.5 4.375 19.325 4.025 18.975C3.675 18.625 3.5 18.1974 3.5 17.6923V11C3.5 9.83083 3.72308 8.73083 4.16925 7.7C4.61542 6.66917 5.22442 5.76792 5.99625 4.99625C6.76792 4.22442 7.66917 3.61542 8.7 3.16925C9.73083 2.72308 10.8308 2.5 12 2.5C13.1692 2.5 14.2692 2.72308 15.3 3.16925C16.3308 3.61542 17.2321 4.22442 18.0038 4.99625C18.7756 5.76792 19.3846 6.66917 19.8307 7.7C20.2769 8.73083 20.5 9.83083 20.5 11V20.3845C20.5 20.8897 20.325 21.3173 19.975 21.6673C19.625 22.0173 19.1974 22.1923 18.6923 22.1923H12ZM5.30775 18H7.0385V13.923H5V17.6923C5 17.7821 5.02883 17.8558 5.0865 17.9135C5.14417 17.9712 5.21792 18 5.30775 18ZM16.9615 18H19V13.923H16.9615V18ZM5.30775 13.923H5H7.0385H5.30775Z", fill: "currentColor" }) }));
};
const CallIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Call, ...props });
};
export default CallIcon;
