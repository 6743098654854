import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get } from 'lodash';
import FxTextArea from './FxTextArea';
export default (passedProps) => {
    const { field, form, input, name, label, isRequired, helptext, colClass, disabled, style, placeholder, numberOfRow, showLabelonTop, inputClassNames = '', ...props } = passedProps;
    const error = get(form.errors, field.name, '');
    const isTouched = get(form.touched, field.name, '');
    const className = `form-group row ${isTouched && error ? 'has-danger' : ''}`;
    let { labelClass } = passedProps;
    labelClass = `${isTouched && error ? 'text-danger' : labelClass ? labelClass : ''}`;
    return (_jsxs("div", { className: `${className} ${showLabelonTop ? `flex-col d-flex mt-3` : ''}`, children: [label && (_jsx("div", { className: `col-md-12 ${colClass}`, children: _jsxs("label", { htmlFor: name, className: labelClass, children: [label, " ", isRequired ? _jsx("abbr", { className: "text-danger", children: "*" }) : ''] }) })), _jsxs("div", { className: `${!showLabelonTop ? 'col-md-9' : 'col-md-12'} ${colClass}`, children: [_jsx(FxTextArea, { style: { width: '100%', ...style }, className: `${inputClassNames}`, rows: `${numberOfRow ? numberOfRow : '2'}`, placeholder: placeholder, disabled: disabled, "data-testid": field.name, ...field }), isTouched && error ? (_jsx("span", { className: "help-block text-danger", children: error })) : (helptext && _jsx("span", { className: "help-block", children: helptext }))] })] }));
};
