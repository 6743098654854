import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Shipments = () => {
    return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M5 7.90375V18.6923C5 18.7821 5.02883 18.8558 5.0865 18.9135C5.14417 18.9712 5.21792 19 5.30775 19H18.6923C18.7821 19 18.8558 18.9712 18.9135 18.9135C18.9712 18.8558 19 18.7821 19 18.6923V7.90375H5ZM5.38475 20.5C4.88592 20.5 4.44708 20.3106 4.06825 19.9318C3.68942 19.5529 3.5 19.1141 3.5 18.6152V7.00575C3.5 6.79175 3.53433 6.58758 3.603 6.39325C3.6715 6.19908 3.77433 6.01992 3.9115 5.85575L5.3155 4.15775C5.4795 3.94358 5.68458 3.78042 5.93075 3.66825C6.17692 3.55608 6.43717 3.5 6.7115 3.5H17.2693C17.5436 3.5 17.8054 3.55608 18.0548 3.66825C18.3041 3.78042 18.5108 3.94358 18.675 4.15775L20.0885 5.875C20.2257 6.03917 20.3285 6.21992 20.397 6.41725C20.4657 6.61475 20.5 6.82058 20.5 7.03475V18.6152C20.5 19.1141 20.3106 19.5529 19.9318 19.9318C19.5529 20.3106 19.1141 20.5 18.6152 20.5H5.38475ZM5.3905 6.404H18.6L17.5095 5.10575C17.4775 5.07375 17.4407 5.04808 17.399 5.02875C17.3573 5.00958 17.3141 5 17.2693 5H6.72125C6.67642 5 6.63308 5.00958 6.59125 5.02875C6.54958 5.04808 6.51275 5.07375 6.48075 5.10575L5.3905 6.404Z", fill: "currentColor" }), _jsx("path", { d: "M15.6538 13.9423L12.0001 10.2885L8.34631 13.9423L9.40006 14.996L11.2501 13.146V17.596H12.7501V13.146L14.6001 14.996L15.6538 13.9423Z", fill: "currentColor" })] }));
};
const ShipmentsIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Shipments, ...props });
};
export default ShipmentsIcon;
