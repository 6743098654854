export const COLOR_PRIMARY = '#0078D4';
export const COLOR_PRIMARY_HOVER = '#008BF5';
export const COLOR_SUCCESS = '#107C10';
export const COLOR_ERROR = '#D60000';
export const COLOR_WARNING = '#ffc107';
export const COLOR_BG_DISABLED = '#EDEBE9';
export const COLOR_PENDING = '#f9ad11';
export const COLOR_BLOCKED = '#605e5c';
export const COLOR_RED = COLOR_ERROR;
export const COLOR_GREEN = COLOR_SUCCESS;
export const COLOR_YELLOW = COLOR_WARNING;
export const COLOR_WHITE = '#fff';
export const BG_COLOR_PAGE = '#f6f8fa';
export const BG_LIGHT_BLUE = '#EFF6FC';
export const COLOR_GRAPH_RED = '#ff9999';
export const COLOR_GRAPH_GREEN = '#76c09d';
export const COLOR_GRAPH_YELLOW = '#feda6e';
export const COLOR_GRAPH_AQUA = '#95e5ff';
export const LIGHT_COLORS = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#FF00FF',
    '#00FFFF',
    '#FFA500',
    '#800080',
    '#008000',
    '#800000',
    '#008080',
    '#808000',
    '#FFC0CB',
    '#808080',
    '#FFD700',
    '#00FF7F',
    '#FF4500',
    '#FF8C00',
    '#9932CC',
    '#00CED1',
    '#8A2BE2',
    '#4B0082',
    '#FF1493',
    '#2E8B57',
    '#9400D3',
    '#FF6347',
    '#A0522D',
    '#4682B4',
    '#556B2F',
    '#8B4513',
    '#B22222',
    '#D2691E',
    '#5F9EA0',
    '#20B2AA',
    '#8B008B',
    '#BDB76B',
    '#00FA9A',
    '#FF69B4',
    '#1E90FF',
    '#F0E68C',
    '#9370DB',
    '#87CEEB',
    '#32CD32',
    '#E9967A',
    '#8FBC8F',
    '#483D8B',
    '#FA8072',
    '#6A5ACD',
    '#00FF00',
    '#FFA07A',
];
export const COLOR_BLOCKED_LIGHT = '#dadada';
export const COLOR_PENDING_LIGHT = '#fff7e7';
export const COLOR_SUCCESS_LIGHT = '#e7f2e7';
export const COLOR_ERROR_LIGHT = '#fbe6e6';
export const COLOR_GRAY_LIGHT = '#f4f4f4';
export const COLOR_ACTIVE_ITEM = '#F3F2F1';
export const COLOR_PRIMARY_LIGHT = '#E6F2FB';
export const BORDER_COLOR = '#f0f0f0';
export const COLOR_REPAIRED_TRIP_POLYLINE = 'rgba(0,90,158,0.7)';
// dark theme color for text and background
export const COLOR_DARK = '#141414';
export const COLOR_BG_CONTAINER_DARK = '#141414';
export const COLOR_BG_LAYOUT_DARK = '#000000';
export const COLOR_TEXT_DARK = 'rgba(255, 255, 255, 0.85)';
export const COLOR_HOVER_DARK = '#1f1f1f';
export const COLOR_BORDER_DARK = '#424242';
export const COLOR_BORDER_SECONDARY = '#303030';
export const COLOR_SPLIT_DARK = 'rgb(59 58 58 / 99%)';
export const COLOR_BG_CONTAINER_DISABLED = 'rgba(0,0,0,0.04)';
export const COLOR_TEXT_DISABLED = '#999';
export const COLOR_BG_ELEVATED = '#1f1f1f';
// light theme color for text and background
export const COLOR_BG_CONTAINER_LIGHT = '#ffffff';
export const COLOR_BG_LAYOUT_LIGHT = '#f5f5f5';
export const COLOR_TEXT_LIGHT = 'rgba(0, 0, 0, 0.88)';
