import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ConfigProvider } from 'antd';
import { useState } from 'react';
import { FxCloseOutlined, FxExportOutlined } from 'sharedV2/FxIcons';
import { FxButton, FxStatistic, FxToolTip } from 'sharedV2';
import styled from 'styled-components';
import { Typography } from 'antd';
import { get } from 'lodash';
const Hover = styled.div `
    display: flex;
    position: relative;
    border-radius: 3px;
    width: 100%;
    padding: 0.1rem 0.1rem 0.1rem 0.3rem;
    transition: 0.4s;
    cursor: ${(props) => (!props.pointer ? 'default' : props.hover ? 'pointer' : 'default')};
    background: ${(props) => (props.active ? 'rgba(0, 0, 0, 0.1)' : 'none')};
    border-left: 4px solid ${(props) => props.color};
    justify-content: space-between;

    &:hover {
        background: ${(props) => (props.hover ? 'rgba(0, 0, 0, 0.15)' : 'none')};
    }
`;
const RemoveFilter = styled(FxButton) `
    position: absolute;
    right: 0rem;
    top: 0rem;
`;
const Title = styled(Typography.Text) `
    display: inline-block;
    font-size: 12px;
    width: ${(props) => (props.tooltip ? '3.8rem' : '100%')};
    color: #605e5c;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
const FxColorSummary = (props) => {
    const [open, setOpen] = useState(false);
    const { color, hover = true, pointer = true, showRemoveFilter = true, active, onClick, onMouseEnter, onMouseLeave, tooltip = false, item, startDate, endDate, vehicleId, showExportButton, ...rest } = props;
    return (_jsx(FxToolTip, { title: props.title, open: tooltip && open, children: _jsxs(Hover, { active: active, hover: hover, pointer: pointer, onClick: onClick, color: color, onMouseEnter: tooltip ? () => setOpen(true) : onMouseEnter, onMouseLeave: tooltip ? () => setOpen(false) : onMouseLeave, children: [_jsxs(ConfigProvider, { theme: {
                        components: {
                            Statistic: {
                                contentFontSize: 14,
                                titleFontSize: 12,
                            },
                        },
                    }, children: [_jsx(FxStatistic, { ...rest, title: _jsx(Title, { style: { maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' }, tooltip: tooltip, children: props.title }), value: props.value }), pointer && showExportButton && (_jsx(FxExportOutlined, { onClick: (e) => {
                                e.stopPropagation();
                                const type = get(item, 'name');
                                let url = `/dashboard/alarms/analytics#startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}&alarmType=${type}`;
                                if (vehicleId) {
                                    url += `&vehicleId=${vehicleId}`;
                                }
                                window.open(url);
                            } }))] }), active && showRemoveFilter && (_jsx(RemoveFilter, { size: "small", type: "text", icon: _jsx(FxCloseOutlined, {}), onClick: (e) => {
                        e.stopPropagation();
                        if (onClick)
                            onClick();
                    } }))] }) }));
};
export default FxColorSummary;
