import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mappedBranches } from '../../../utils/mapping_utils';
import { FxReactSelect as Select } from '../../../../shared';
import { isTimeRangeAllowedForCN } from '../../../../utils/consignmentsUtils';
import { debounce, get } from 'lodash';
import CalendarComponent from '../../../utils/calendar_component';
import { hideYearsInFreightBillCNListing } from 'components/dashboard/freightBills/forms/freight_bill_account_utils';
const space = 'mb-3';
const ConsignmentMappingFilterComponent = (props) => {
    const { startDate, endDate, billingPartyId, consignmentOfficeId, consignmentNumber, handleFilterChange, customers, formikProps, branches, } = props;
    const setTextFilter = debounce((key, term) => {
        handleFilterChange(key, term);
    }, 1000);
    const handleOnTextFilterChange = (event) => {
        setTextFilter(event.target.dataset.datafield, event.target.value);
    };
    const paymentNature = get(formikProps, 'values.paymentNature');
    const isPR = paymentNature === 'PAYMENT_RECEIPT' || paymentNature === 'JOB_BILL_RECEIPT';
    const hideYearsInCNListing = hideYearsInFreightBillCNListing();
    return (_jsx("div", { children: _jsxs("div", { className: "row", children: [!isPR && (_jsxs(_Fragment, { children: [_jsx("div", { className: "col-md-4", children: _jsx(Select, { className: `${space}`, simpleValue: true, placeholder: "CN Office", value: consignmentOfficeId, options: mappedBranches(branches), onChange: (value) => {
                                    handleFilterChange('consignmentOfficeId', value);
                                } }) }), _jsx("div", { className: "col-md-4", children: _jsx("div", { className: `${space}`, children: _jsx("input", { type: "text", name: "consignmentNumber", placeholder: `Consignment Number`, className: "form-control rounded Select-control grey-placeholder", onChange: handleOnTextFilterChange, "data-datafield": 'consignmentNumber' }) }) })] })), isPR && (_jsx("div", { className: "col-md-4", children: _jsx("div", { className: `${space}`, children: _jsx("input", { type: "text", name: "billNumber", placeholder: `Freight Bill No.`, className: "form-control rounded Select-control grey-placeholder", onChange: handleOnTextFilterChange, "data-datafield": 'billNumber' }) }) })), _jsx("div", { className: "col-md-4", children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                            handleFilterChange('timeFilter', [startDate, endDate]);
                        }, startDate: startDate, endDate: endDate, isDateRangeAllowed: isTimeRangeAllowedForCN, hideYears: !isPR ? hideYearsInCNListing : true }) })] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ConsignmentMappingFilterComponent);
