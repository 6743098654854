import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Issues = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M7 13.5V20C7 20.2125 6.92809 20.3906 6.78427 20.5343C6.64047 20.6781 6.46227 20.75 6.24967 20.75C6.03709 20.75 5.85901 20.6781 5.71542 20.5343C5.57182 20.3906 5.50002 20.2125 5.50002 20V5.40385C5.50002 5.14777 5.58665 4.93311 5.7599 4.75988C5.93313 4.58663 6.14779 4.5 6.40387 4.5H12.848C13.0614 4.5 13.252 4.56699 13.4196 4.70098C13.5873 4.83494 13.6929 5.00898 13.7365 5.22308L13.9961 6.5H18.5961C18.8522 6.5 19.0669 6.58626 19.2401 6.75878C19.4133 6.93128 19.5 7.14503 19.5 7.40003V14.6001C19.5 14.8551 19.4133 15.0688 19.2401 15.2413C19.0669 15.4137 18.8522 15.5 18.5961 15.5H14.1519C13.9386 15.5 13.748 15.433 13.5804 15.299C13.4127 15.165 13.3071 14.991 13.2635 14.7769L13.0039 13.5H7ZM14.65 14H18V7.99998H13.4981C13.2847 7.99998 13.0942 7.93299 12.9265 7.79903C12.7588 7.66504 12.6532 7.491 12.6096 7.2769L12.35 5.99998H7V12H13.5019C13.7153 12 13.9058 12.067 14.0735 12.2009C14.2412 12.3349 14.3468 12.509 14.3904 12.7231L14.65 14Z", fill: "currentColor" }) }));
};
const IssuesIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Issues, ...props });
};
export default IssuesIcon;
