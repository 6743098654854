import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Inventory = () => {
    return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M4 19H6.5V11H17.5V19H20V8.35001L12 5.15001L4 8.35001V19ZM2.5 20.5V7.33651L12 3.53851L21.5 7.33651V20.5H16V12.5H8V20.5H2.5ZM9.1155 20.5V19.7308H14.8845V20.5H9.1155Z", fill: "currentColor" }), _jsx("path", { d: "M9.11548 15.7308V16.5H14.8845V15.7308H9.11548Z", fill: "currentColor" }), _jsx("path", { d: "M9.11548 17.7308V18.5H14.8845V17.7308H9.11548Z", fill: "currentColor" }), _jsx("path", { d: "M9.11548 13.7308V14.5H14.8845V13.7308H9.11548Z", fill: "currentColor" })] }));
};
const InventoryIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Inventory, ...props });
};
export default InventoryIcon;
