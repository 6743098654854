import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Finance = () => {
    return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", "clip-rule": "evenodd", d: "M3.25 6.25V16.75H20.75V6.25H3.25ZM3 5C2.44772 5 2 5.44772 2 6V17C2 17.5523 2.44772 18 3 18H21C21.5523 18 22 17.5523 22 17V6C22 5.44772 21.5523 5 21 5H3Z", fill: "currentColor" }), _jsx("path", { d: "M14.8125 9.33594L14.5227 10.3246H9L9.28977 9.33594H14.8125ZM12.2216 16.0007L9.10227 12.1825L9.08523 11.4666H10.6364C11.0398 11.4666 11.3835 11.4098 11.6676 11.2962C11.9517 11.1797 12.169 11.0064 12.3196 10.7763C12.4702 10.5433 12.5455 10.2507 12.5455 9.89844C12.5455 9.37855 12.3892 8.97088 12.0767 8.67543C11.7642 8.37713 11.2841 8.22798 10.6364 8.22798H9L9.28977 7.27344H10.6364C11.3295 7.27344 11.8977 7.38849 12.3409 7.61861C12.7869 7.84588 13.1165 8.15838 13.3295 8.55611C13.5455 8.95099 13.6534 9.39844 13.6534 9.89844C13.6534 10.3501 13.554 10.7663 13.3551 11.147C13.1591 11.5249 12.8438 11.8288 12.4091 12.0589C11.9773 12.2891 11.4062 12.4041 10.696 12.4041H10.6705L13.5511 15.9325V16.0007H12.2216ZM14.8125 7.27344L14.5227 8.26207L10.1761 8.22798L10.4659 7.27344H14.8125Z", fill: "currentColor" })] }));
};
const FinanceIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Finance, ...props });
};
export default FinanceIcon;
