import { jsx as _jsx } from "react/jsx-runtime";
import RouteErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditProjectComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditProject" */ 'components/dashboard/finances/projects/add_edit_projects_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const ViewCustomerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-viewCustomer" */ 'components/dashboard/finances/customers/view_customer_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditCustomerComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditCustomer" */ 'components/dashboard/finances/customers/add_edit_customer_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const AddEditCustomerDetentionRateChartComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-addEditDetentionRateChart" */ 'components/dashboard/finances/customers/customer_detention_rate_chart/AddEditCustomerDetentionRateChartComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const CustomersComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-settings-customers" */ 'components/dashboard/finances/customers/customers_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouteErrorBoundary, {}) };
};
const customersRoutes = [
    {
        index: true,
        lazy: CustomersComponent,
    },
    {
        path: 'add',
        lazy: AddEditCustomerComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditCustomerComponent,
    },
    {
        path: 'view/:id',
        lazy: ViewCustomerComponent,
    },
    {
        path: 'project/add/:id',
        lazy: AddEditProjectComponent,
    },
    {
        path: 'project/edit/:id',
        lazy: AddEditProjectComponent,
    },
    {
        path: 'detention-rate-chart/add/:id',
        lazy: AddEditCustomerDetentionRateChartComponent,
    },
    {
        path: 'detention-rate-chart/edit/:id',
        lazy: AddEditCustomerDetentionRateChartComponent,
    },
];
export default customersRoutes;
