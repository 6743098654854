// Google Identity Services
export function addGoogleGSIScript(callback) {
    if (typeof window.google?.accounts !== 'undefined') {
        callback(true);
    }
    else {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://accounts.google.com/gsi/client');
        s.onload = () => {
            callback(false);
        };
        s.onerror = (error) => {
            console.log('error', error);
        };
        document.body.appendChild(s);
    }
}
// load turf.js
export function addTurfScript(callback) {
    if (typeof window.turf !== 'undefined') {
        callback(true);
    }
    else {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@turf/turf@7/turf.min.js');
        s.onload = () => {
            callback(false);
        };
        document.body.appendChild(s);
    }
}
// load d3-delaunay
export function addD3DelaunayScript(callback) {
    if (typeof window.d3?.Delaunay !== 'undefined') {
        callback(true);
    }
    else {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://cdn.jsdelivr.net/npm/d3-delaunay@6');
        s.onload = () => {
            callback(false);
        };
        document.body.appendChild(s);
    }
}
export function addGeoJsonScript(callback) {
    if (typeof window.toGeoJSON !== 'undefined') {
        callback(true);
    }
    else {
        const s = document.createElement('script');
        s.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@mapbox/togeojson@0.16.2/togeojson.min.js');
        s.onload = () => {
            callback(false);
        };
        s.onerror = (error) => {
            console.log('error', error);
        };
        document.body.appendChild(s);
    }
}
