import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Accounts = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 -960 960 960", width: "24px", fill: "currentColor", children: _jsx("path", { d: "M503.85-494.31q25.53-27.77 37.77-63.77 12.23-36 12.23-74.23 0-38.23-12.23-74.23-12.24-36-37.77-63.77 52.69 6.08 87.5 45.5 34.8 39.43 34.8 92.5 0 53.08-34.8 92.5-34.81 39.42-87.5 45.5Zm210 306.62v-93.85q0-32.71-13.31-62.24t-37.77-50.68q46 15.31 84.69 41.31t38.69 71.61v93.85h-72.3ZM786.15-450v-80h-80v-60h80v-80h60v80h80v60h-80v80h-60Zm-452.3-42.31q-57.75 0-98.88-41.12-41.12-41.13-41.12-98.88 0-57.75 41.12-98.87 41.13-41.13 98.88-41.13 57.75 0 98.87 41.13 41.13 41.12 41.13 98.87 0 57.75-41.13 98.88-41.12 41.12-98.87 41.12Zm-300 304.62v-88.93q0-29.38 15.96-54.42 15.96-25.04 42.65-38.5 59.31-29.07 119.66-43.61 60.34-14.54 121.73-14.54 61.38 0 121.73 14.54 60.34 14.54 119.65 43.61 26.69 13.46 42.65 38.5 15.97 25.04 15.97 54.42v88.93h-600Zm300-364.62q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm-240 304.62h480v-28.93q0-12.15-7.04-22.5-7.04-10.34-19.12-16.88-51.69-25.46-105.41-38.58-53.73-13.11-108.43-13.11-54.71 0-108.43 13.11Q171.69-341.46 120-316q-12.08 6.54-19.11 16.88-7.04 10.35-7.04 22.5v28.93Zm240-384.62Zm0 384.62Z" }) }));
};
const AccountsIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Accounts, ...props });
};
export default AccountsIcon;
