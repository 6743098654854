import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Fuel = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 -960 960 960", width: "24px", fill: "currentColor", children: _jsx("path", { d: "M180-140v-607.69Q180-778 201-799q21-21 51.31-21h215.38Q498-820 519-799q21 21 21 51.31v260h38.46q29.83 0 51.07 21.24 21.24 21.24 21.24 51.06v181.54q0 19.31 13.42 32.73 13.42 13.43 32.73 13.43 19.31 0 32.73-13.43 13.43-13.42 13.43-32.73v-280.3q-9 5.38-19 7.46-10 2.07-21 2.07-36.83 0-62.27-25.43-25.43-25.43-25.43-62.26 0-29.69 16.93-52.88 16.92-23.19 44.77-31.12l-90.16-90.15L620.46-800l142.61 140.16q13.47 13.46 20.58 31.19 7.12 17.73 7.12 36.34v358.46q0 39.42-27.2 66.63Q736.38-140 697-140q-39.39 0-66.66-27.22-27.26-27.21-27.26-66.63v-193.84q0-5.39-3.47-8.85-3.46-3.46-8.84-3.46H540v300H180Zm60-410h240v-197.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H252.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46V-550Zm463.08-2.31q17 0 28.5-11.5t11.5-28.5q0-17-11.5-28.5t-28.5-11.5q-17 0-28.5 11.5t-11.5 28.5q0 17 11.5 28.5t28.5 11.5ZM240-200h240v-290H240v290Zm240 0H240h240Z" }) }));
};
const FuelIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Fuel, ...props });
};
export default FuelIcon;
