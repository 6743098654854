import { jsx as _jsx } from "react/jsx-runtime";
import { find, map } from 'lodash';
import { getSearchByButtonByLabel } from 'utils/realtime_utils';
import FxSegmented from 'sharedV2/FxSegmented';
const FxToggleButton = (props) => {
    const { activeButton, onButtonClick, buttons } = props;
    return (_jsx(FxSegmented, { style: { height: '32px' }, value: activeButton.key, options: map(buttons, (button) => {
            return { label: getSearchByButtonByLabel(button.name), value: button.key };
        }), onChange: (v) => onButtonClick(find(buttons, { key: v })) }));
};
export default FxToggleButton;
