import { jsx as _jsx } from "react/jsx-runtime";
import { merge } from 'lodash';
import { isShellThemeOpenToAccount } from 'utils/account_utils';
import shellTheme from './shellAccount';
import defaultTheme from './default';
import { theme, App, ConfigProvider } from 'antd';
import { getCurrentThemeMode } from './utils';
import { useEffect, useState } from 'react';
import { storeEventEmitter } from '../middlewares/storeEventMiddleware';
import { SET_CURRENT_THEME } from 'actions/dashboard/local_action';
export { ConfigProvider, App } from 'antd';
export const useApp = App.useApp;
export const { useToken } = theme;
export { themeMode } from './utils';
export function getCssBaseline(options) {
    const isShellTheme = isShellThemeOpenToAccount();
    let BaselineComponent = defaultTheme.getCssBaseline(options);
    if (isShellTheme) {
        BaselineComponent = shellTheme.CssBaseline;
    }
    return BaselineComponent;
}
export function getTheme(options) {
    let themeToMerge = {};
    const isShellTheme = isShellThemeOpenToAccount();
    if (isShellTheme) {
        themeToMerge = shellTheme.theme;
    }
    const themeMode = {
        algorithm: theme.defaultAlgorithm,
    };
    if (options?.currentThemeMode === 'dark') {
        themeMode.algorithm = theme.darkAlgorithm;
    }
    return merge({ ...defaultTheme.theme }, themeToMerge, themeMode);
}
export function withToken(Component) {
    return (props) => {
        const { token } = useToken();
        return _jsx(Component, { ...props, token: token });
    };
}
export const withThemedComponent = (WrappedComponent) => {
    const ThemedComponent = (props) => {
        const [currentThemeMode, setCurrentThemeMode] = useState(getCurrentThemeMode());
        const theme = getTheme({ currentThemeMode });
        useEffect(() => {
            let unlisten = null;
            if (storeEventEmitter) {
                unlisten = storeEventEmitter.listen((emitAction, emitValue) => {
                    if (emitAction === SET_CURRENT_THEME) {
                        setCurrentThemeMode(emitValue);
                    }
                });
            }
            return () => {
                if (unlisten) {
                    unlisten();
                }
            };
        }, []);
        return (_jsx(ConfigProvider, { theme: theme, children: _jsx(WrappedComponent, { ...props }) }));
    };
    return ThemedComponent;
};
