import { getAccountId } from './account_utils';
import { VEHICLE_STATUS } from './vehicle_utils';
import { filter, find, get, has, includes, indexOf, map } from 'lodash';

export const COMMENT_SHOWON = {
    ENGINE_ON: 'ENGINE_ON',
    PARKED: 'PARKED',
    UNREACHABLE: 'UNREACHABLE',
    REMOVED: 'REMOVED',
    ACCOUNT: 'ACCOUNT',
};

export const COMMENT_TYPE = {
    JOB: 'JOB',
    TRIP: 'TRIP',
    VEHICLE_STATUS: 'VEHICLE_STATUS',
    ACCOUNT: 'ACCOUNT',
    VEHICLE: 'VEHICLE',
    ISSUE: 'ISSUE',
    JOB_ANALYTICS: 'JOB_ANALYTICS',
};

export const COMMENT_SUBTYPE = {
    MISUSE: 'MISUSE',
    DELAY: 'DELAY',
    DEVICE: 'DEVICE',
    OTHER: 'OTHER',
    LOADING: 'LOADING',
    RUNNING: 'RUNNING',
    LOADED: 'LOADED',
    EMPTY: 'EMPTY',
    ACCOUNT_OPERATIONS: 'ACCOUNT_OPERATIONS',
    JOB_CANCELLATION: 'JOB_CANCELLATION',
    HOME_STOP: 'HOME_STOP',
    BREAKDOWN: 'BREAKDOWN',
    WITHOUT_DRIVER: 'WITHOUT_DRIVER',
};

export const COMMENT_OPTIONS = [
    {
        title: 'WAITING FOR PLAN',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.EMPTY,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        showAll: true,
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'LOADING PLAN',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.EMPTY,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        showAll: true,
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'LOADING',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.LOADING,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'SENT TO LOADING',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.LOADING,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
        showAll: true,
    },
    {
        title: 'UNLOADING',
        commentableType: COMMENT_TYPE.JOB,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'LOADED',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.LOADED,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'EMPTY',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.EMPTY,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        showAll: true,
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'LOADING DELAY',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'UNLOADING DELAY',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'TRAFFIC',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'IN TRANSIT',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.RUNNING,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DRIVER REST STOP',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DHABA/RESTAURANT',
        commentableType: COMMENT_TYPE.JOB,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.PARKED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DRIVER NOT AVAILABLE',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'VEHICLE BREAKDOWN',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'IN TRANSIT DELAY',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'VEHICLE ACCIDENT',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DELAY,
        showOn: [COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'VEHICLE STOLEN',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.MISUSE,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'VEHICLE SOLD',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.MISUSE,
        showOn: [COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'VEHICLE BATTERY REMOVED',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DEVICE REMOVED',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DEVICE FAULT',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'STANDBY',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'WORKSHOP',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DEVICE WITH CLIENT',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DEVICE RECEVIED',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'DEVICE LOST',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'No Demand',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Client will callback',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Call Back',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Phone Switch Off',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Phone Busy',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Phone No Response',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Phone not Pick',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Wrong Number',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Vehicle Available for tomorrow',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Call back after few days',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'No Vehicles Available',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Completed',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Sales Issue',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Slow Moving',
        commentableType: COMMENT_TYPE.ACCOUNT,
        subType: COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        showOn: [COMMENT_SHOWON.ACCOUNT],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'MAINTENANCE SERVICE',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.DEVICE,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },

    {
        title: 'Home Stop',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.HOME_STOP,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },

    {
        title: 'Breakdown',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.BREAKDOWN,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },

    {
        title: 'Without Driver',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.WITHOUT_DRIVER,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => !includes([14469, 11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Empty Start',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Empty End',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Loading Complete',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'On Road',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Enroute Repair',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'No Entry',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'RTO Issue',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Reach Destination',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Unloading Complete',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },

    {
        title: 'Sent for load',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'No entry',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Without Driver',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Driver at Home',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Under Maintenance',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Wait for Load',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([14469], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Standing at the loading point',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Standing at the unloading point',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Empty but Non Unloaded due to Network issue',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Jaamed In-transit',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Repair & maintenance',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Stop for Refreshment',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.PARKED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Long Breakdown',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Shift change',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'Local Disturbance',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: '',
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED],
        enabled: () => includes([11553], get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0)),
    },
    {
        title: 'OTHER',
        commentableType: COMMENT_TYPE.VEHICLE_STATUS,
        subType: COMMENT_SUBTYPE.OTHER,
        showOn: [COMMENT_SHOWON.ENGINE_ON, COMMENT_SHOWON.PARKED, COMMENT_SHOWON.UNREACHABLE, COMMENT_SHOWON.REMOVED],
        enabled: () => true,
    },
];

export function mappedStopCommentOptions(stopComments) {
    return map(stopComments, (o) => {
        return {
            value: o,
            label: o,
        };
    });
}

export function getShowOnOption(currentStatus) {
    let showOnOption = COMMENT_SHOWON.PARKED;
    if (currentStatus) {
        switch (currentStatus) {
            case VEHICLE_STATUS.RUNNING:
            case VEHICLE_STATUS.IDLE:
                showOnOption = COMMENT_SHOWON.ENGINE_ON;
                break;
            case VEHICLE_STATUS.PARKED:
            case VEHICLE_STATUS.NO_POWER:
                showOnOption = COMMENT_SHOWON.PARKED;
                break;
            case VEHICLE_STATUS.PARKED_10_DAYS:
            case VEHICLE_STATUS.DISCONNECTED:
            case VEHICLE_STATUS.UNREACHABLE:
            case VEHICLE_STATUS.UNREACHABLE_3_DAYS:
            case VEHICLE_STATUS.UNREACHABLE_4_DAYS:
            case VEHICLE_STATUS.UNREACHABLE_5_DAYS:
            case VEHICLE_STATUS.UNREACHABLE_6_DAYS:
            case VEHICLE_STATUS.API_UNREACHABLE:
            case VEHICLE_STATUS.WIRING_DEFECT:
            case VEHICLE_STATUS.WIRING_DEFECT_PARKED:
            case VEHICLE_STATUS.GPS_DEFECT:
            case VEHICLE_STATUS.GPS_0:
            case VEHICLE_STATUS.DISCONNECTED_3_DAYS:
            case VEHICLE_STATUS.DISCONNECTED_4_DAYS:
            case VEHICLE_STATUS.DISCONNECTED_5_DAYS:
            case VEHICLE_STATUS.DISCONNECTED_6_DAYS:
            case VEHICLE_STATUS.IMMOBILISED:
            case VEHICLE_STATUS.BATTERY_DISCHARGED:
                showOnOption = COMMENT_SHOWON.UNREACHABLE;
            case VEHICLE_STATUS.REMOVED:
                showOnOption = COMMENT_SHOWON.REMOVED;
                break;
        }
    }
    return showOnOption;
}

export function mappedCommentOptions(showOn, selectedComment, onlyNotOnJob = false) {
    const selectedTitle = get(selectedComment, 'title');
    const options = filter(COMMENT_OPTIONS, (option) => {
        const isEnabled = typeof option.enabled === 'function' ? option.enabled() : true;
        return (
            isEnabled &&
            (includes(option?.showOn, showOn) || (selectedTitle && option?.title === selectedTitle)) &&
            (onlyNotOnJob ? option?.showAll || option?.commentableType !== COMMENT_TYPE.JOB : true)
        );
    });
    return map(options, (o) => ({
        value: o.title,
        label: o.title,
    }));
}

export function getCommentText(comment, defaultValue = '') {
    if (!comment) {
        return defaultValue;
    }
    if (comment.title === comment.comment) {
        return comment.title;
    } else if (comment.title === find(COMMENT_OPTIONS, { title: 'OTHER' })?.title) {
        return comment.comment;
    } else {
        return `${comment.title} ${comment.comment ? `- ${comment.comment}` : ''}`;
    }
}

export function getAccountOperationsCommentOptionsList() {
    const commentOperations = {};
    map(
        filter(COMMENT_OPTIONS, {
            'commentableType': COMMENT_TYPE.ACCOUNT,
            'subType': COMMENT_SUBTYPE.ACCOUNT_OPERATIONS,
        }),
        (option) => {
            commentOperations[option.title] = option.title;
        }
    );
    return commentOperations;
}

export function getCommentedBy(comment, userListMiniAsMap = {}, userKey = 'lastModifiedBy') {
    const user = userListMiniAsMap[comment[userKey]];
    return `${get(user, '[1]', '')} ${get(user, '[2]', '')}`;
}
