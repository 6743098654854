import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Control = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 -960 960 960", width: "24px", fill: "currentColor", children: _jsx("path", { d: "M206.92-170v-282.31h-80v-60h220v60h-80V-170h-60Zm0-437.69V-790h60v182.31h-60Zm163.08 0v-60h80V-790h60v122.31h80v60H370ZM450-170v-342.31h60V-170h-60Zm243.08 0v-122.31h-80v-60h220v60h-80V-170h-60Zm0-277.69V-790h60v342.31h-60Z" }) }));
};
const ControlIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Control, ...props });
};
export default ControlIcon;
