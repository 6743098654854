import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Admin = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 -960 960 960", width: "24px", fill: "currentColor", children: _jsx("path", { d: "M680-280q25 0 42.5-17.5T740-340q0-25-17.5-42.5T680-400q-25 0-42.5 17.5T620-340q0 25 17.5 42.5T680-280Zm0 120q31.38 0 57.19-14.31t42.19-38.31Q757-226 732-233q-25-7-52-7t-52 7q-25 7-47.38 20.38 16.38 24 42.19 38.31Q648.62-160 680-160Zm-200 59.23q-129.77-35.39-214.88-152.77Q180-370.92 180-516v-230.15l300-112.31 300 112.31v226.61q-14-5.69-29.39-10.27-15.38-4.57-30.61-7.19v-167.38L480-794l-240 89.62V-516q0 53.15 15 103.81 15 50.65 41.35 94.69 26.34 44.04 62.96 79.08 36.61 35.04 79.46 55.96l1.16-.39q7.92 22 20.53 42.16 12.62 20.15 28.69 36.61-2.53.77-4.57 1.66-2.04.88-4.58 1.65Zm200 .77q-74.92 0-127.46-52.54Q500-205.08 500-280q0-74.92 52.54-127.46Q605.08-460 680-460q74.92 0 127.46 52.54Q860-354.92 860-280q0 74.92-52.54 127.46Q754.92-100 680-100ZM480-488.23Z" }) }));
};
const AdminIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Admin, ...props });
};
export default AdminIcon;
