/**
 * Created by udbhav on 11/07/17.
 */

import axios from 'axios';
import {
    FREQUENCY,
    parseQueryParams,
    ROOT_API,
    ROOT_API_ALFRED,
    ROOT_API_ERP,
    ROOT_API_URL,
    ROOT_API_URL_V2,
    ROOT_NODE_API_URL,
    ROOT_REPORTING_NODE_API_URL,
} from '../../constant';
import { get, join, omit, set } from 'lodash';
import { REPORT_FORMAT } from '../../utils/report_utils';
import { queryString } from '../../utils/string_utils';
import { getMomentTime } from '../../utils/date_utils';
import { fetchJobUtilisationQueryForAccount, preferredTimeStampFormat } from '../../utils/account_utils';
import { isVehicleAnalyticsVisible } from '../../components/dashboard/roles/permission_utils';
import { VEHICLE_IMMOBILISE_COMMANDS } from 'utils/vehicle_utils';
import { useJobUtilisationForRunningVehicle } from 'utils/permissionUtils/fmsPermissions/vehicles';

export const FETCH_VEHICLE_MAKE = 'fetch_vehicle_make';
export const FETCH_VEHICLE_MODEL = 'fetch_vehicle_model';
export const SIM_TRACKING_REPORT = 'sim_tracking_report';
export const FETCH_VEHICLE_TYPES = 'fetch_vehicle_types';
export const FETCH_VEHICLE_STATUS = 'fetch_vehicle_status';
export const FETCH_VEHICLE_LIST = 'fetch_vehicle_list';
export const FETCH_VEHICLE_LIST_V2 = 'fetch_vehicle_list_v2';
export const FETCH_VEHICLE_LIST_MINI = 'fetch_vehicle_list_mini';
export const UPDATE_VEHICLE_ENABLE_DISABLE = 'UPDATE_VEHICLE_ENABLE_DISABLE';
export const FETCH_VEHICLE_ID = 'fetch_vehicle_id';
export const ADD_NEW_VEHICLE = 'add_new_vehicle';
export const UPDATE_VEHICLE_ASSIGNMENT = 'update_vehicle_assignment';
export const FETCH_ACCOUNT_VEHICLE_ANALYTICS = 'fetch_account_vehicle_analytics';
export const FETCH_ACCOUNT_VEHICLE_ODOMETER_READING = 'fetch_account_vehicle_odometer_reading';
export const FETCH_VEHICLE_ANALYTICS = 'fetch_vehicle_analytics';
export const FETCH_MULTI_VEHICLE_ANALYTICS = 'fetch_multi_vehicle_analytics';
export const FETCH_MULTI_VEHICLE_ODO_ANALYTICS = 'fetch_multi_vehicle_odo_analytics';
export const FETCH_VEHICLE_ODO = 'fetch_vehicle_odo';
export const FETCH_VEHICLE_CHALLAN = 'fetch_vehicle_challan';
export const FETCH_VEHICLE_CHALLAN_DATA = 'fetch_vehicle_challan_data';
export const FETCH_NIGHT_DRIVING_VEHICLE_ANALYTICS = 'fetch_night_driving_vehicle_analytics';
export const DOWNLOAD_VEHICLE_PERFORMANCE_REPORT = 'download_vehicle_performance_report';
export const DOWNLOAD_VEHICLE_TAG_REPORT = 'download_vehicle_tag_report';
export const DOWNLOAD_VEHICLE_DETAILS_REPORT = 'download_vehicle_details_report';
export const DOWNLOAD_ALL_VEHICLE_HISTORY_REPORT = 'donwload_all_vehicle_history_report';
export const DOWNLOAD_ADVANCE_FUEL_REPORT = 'download_advance_fuel_report';
export const FETCH_VEHICLE_TAG_DATA = 'fetch_vehicle_tag_data';
export const FETCH_VEHICLE_GRAPHS = 'fetch_vehicle_graphs';
export const FETCH_ACCOUNT_VEHICLE_REPORT = 'fetch_account_vehicle_report';
export const DELETE_VEHICLE = 'delete_vehicle';
export const DETACH_DEVICE = 'detach_device';
export const REQUEST_IMMOBILISE_OTP = 'request_immobilise_otp';
export const REQUEST_IMMOBILISE_BULK_OTP = 'request_immobilise_bulk_otp';
export const SEND_IMMOBILISE_COMMAND = 'send_immobilise_command';
export const SEND_IMMOBILISE_BULK_COMMAND = 'send_immobilise_bulk_command';
export const VEHICLE_VARIOUS_COUNT = 'vehicle_various_count';
export const SET_IMMOBILISE_STATUS = 'set_immobilise_status';
export const FETCH_VEHICLE_SPECS_UNIQUE_VALUES = 'FETCH_VEHICLE_SPECS_UNIQUE_VALUES';
export const ADD_VEHICLE_SPECS_UNIQUE_VALUE = 'ADD_VEHICLE_SPECS_UNIQUE_VALUE';
export const FETCH_UPTIME_DETAILS = 'FETCH_UPTIME_DETAILS';
export const FETCH_NOT_ON_JOB_VEHICLES = 'fetch_not_on_job_vehicles';
export const FETCH_ALL_CACHED_VEHICLES = 'fetch_all_cached_vehicles';
export const FETCH_MASTER_VEHICLE = 'FETCH_MASTER_VEHICLE';
export const FETCH_ALL_CACHED_VEHICLES_SUMMARY = 'fetch_all_cached_vehicles_summary';
export const DOWNLOAD_ALL_VEHICLES_REPORT = 'download_all_vehicles_report';
export const DOWNLOAD_VEHICLE_STATUS_REPORT = 'download_vehicle_status_report';
export const DOWNLOAD_REALTIME_VEHICLES_REPORT_NODE = 'download_realtime_vehicles_report_node';
export const FORCE_UPDATE_ALL_VEHICLES_DATA = 'force_update_all_vehicles_data';
export const FETCH_SIM_TRACKING_HISTORY = 'fetch_sim_tracking_history';
export const FETCH_SIM_PING_ERRORS = 'fetch_sim_ping_errors';
export const FETCH_DEVICE_DETAILS_HISTORY = 'fetch_device_details_history';
export const FETCH_VEHICLE_SIM_REPORT_FENESTA = 'fetch_vehicle_sim_report_fenesta';
export const REMOVE_DRIVER_ASSIGNMENT = 'remove_driver_assignment';
export const GET_DRIVER_MAPPING = 'get_drivver_mapping';
export const PATCH_FUEL_CALIBRATION = 'patch_fuel_calibration';
export const GET_FUEL_CALIBRATION = 'get_fuel_calibration';
export const GET_MANUAL_FUEL_DATA = 'manual_fuel_data';
export const VEHICLE_TYRE_INSPECTION = 'get_vehicle_tyre_inspection';
export const FETCH_VEHICLE_DUTY_IMAGES = 'fetch_vehicle_duty_images';
export const UPDATE_VEHICLE_DUTY_IMAGES = 'update_vehicle_duty_images';
export const FETCH_VEHICLE_SUMMARY_BY_GROUP = 'fetch_vehicle_summary_by_group';
export const FETCH_VEHICLE_SUMMARY_BY_ID = 'fetch_vehicle_summary_by_id';
export const FETCH_VEHICLE_COST_BY_ID = 'fetch_vehicle_cost_by_id';
export const FETCH_VEHICLE_COST_SUMMARY__BY_ID = 'fetch_vehicle_cost_by_id';
export const FETCH_TRIP_STRATIFICATION_ALL = 'fetch_trip_stratification_all';
export const MULTI_VEHICLE_DAY_WISE_REPORT = 'multi_vehicle_day_wise_report';
export const SINGLE_VEHICLE_DAY_WISE_REPORT = 'single_vehicle_day_wise_report';
export const FETCH_VEHICLE_HISTORY_LOCATION_BULK = 'fetch_vehicle_history_location_bulk';
export const UPTIME_REPORT_TRIGGER_FOR_MAIL = 'uptime_report_trigger_for_mail';
export const UPDATE_BULK_VEHICLE_TAGS = 'update_bulk_vehicle_tags';
export const DOWNLOAD_NIGHT_DRIVING_REPORT = 'download_night_driving_report';
export const DONWLOAD_UP_TIME_REPORT = 'download_uptime_report';
export const DONWLOAD_UP_TIME_DAY_WISE_REPORT = 'download_uptime_day_wise_report';
export const FETCH_CHILD_VEHICLE = 'fetch_child_vehicle';
export const DOWNLOAD_TSM_DAY_WISE_REPORT = 'DOWNLOAD_TSM_DAY_WISE_REPORT';
export const FETCH_DATA_FROM_MODULES_FOR_VEHICLE = 'FETCH_DATA_FROM_MODULES_FOR_VEHICLE';
export const GET_LAST_METER_ENTRY = 'GET_LAST_METER_ENTRY';
export const UPDATE_ODOMETER = 'UPDATE_ODOMETER';
export const DOWNLOAD_ALL_VEHICLE_SUMMARY_REPORT = 'DOWNLOAD_ALL_VEHICLE_SUMMARY_REPORT';
export const DOWNLOAD_ALL_VEHICLE_UPDATE_REPORT = 'DOWNLOAD_ALL_VEHICLE_UPDATE_REPORT';
export const GET_VEHICLE_ATTENDANCE = 'GET_VEHICLE_ATTENDANCE';
export const MARK_ATTENDANCE_BY_VEHICLE_ID = 'MARK_ATTENDANCE_BY_VEHICLE_ID';
export const RUN_ATTENDANCE_CRON = 'RUN_ATTENDANCE_CRON';
export const UPDATE_ULIP_DATA = 'UPDATE_ULIP_DATA';
export const FETCH_VEHICLE_MODEL_LIST = 'fetch_vehicle_model_list';
export const FETCH_IMAGES = 'FETCH_IMAGES';
export const VEHICLE_DEVICE_REPORT = 'VEHICLE_DEVICE_REPORT';
export const DOWNLOAD_HOURLY_TEMPERATURE_REPORT = 'DOWNLOAD_HOURLY_TEMPERATURE_REPORT';
export const FETCH_TRO_DATA = 'FETCH_TRO_DATA';
export const FETCH_TRO_AUTOCOMPLETE = 'FETCH_TRO_AUTOCOMPLETE';
export const FETCH_VEHICLE_SUMMARY_V2 = 'FETCH_VEHICLE_SUMMARY_V2';
export const FETCH_VEHICLE_LISTING_V2 = 'FETCH_VEHICLE_LISTING_V2';
export const FETCH_VEHICLE_QUICK_DETAILS = 'FETCH_VEHICLE_QUICK_DETAILS';
export const DOWNLOAD_GEAR_PREDICTION_REPORT = 'DOWNLOAD_GEAR_PREDICTION_REPORT';
export const ALL_VEHICLES_LOCATION_REPORT = 'ALL_VEHICLES_LOCATION_REPORT';
export const POST_ATTENDANCE_BY_VEHICLE_ID = 'POST_ATTENDANCE_BY_VEHICLE_ID';
export const UPDATE_VEHICLE_CHALLAN = 'UPDATE_VEHICLE_CHALLAN';
export const FETCH_TEMPERATURE_COMPLIANCE_ANALYTICS = 'FETCH_TEMPERATURE_COMPLIANCE_ANALYTICS';
export const MONTHLY_RUNNING_REPORT = 'MONTHLY_RUNNING_REPORT';
export const FETCH_ULIP_VEHICLE_DATA = 'FETCH_ULIP_VEHICLE_DATA';
export const FETCH_VEHICLE_SECONDARY_TANK_CLOSING_BALANCE = 'FETCH_VEHICLE_SECONDARY_TANK_CLOSING_BALANCE';
export const VEHICLE_MAINTAINANCE_LOG_REPORT = 'VEHICLE_MAINTAINANCE_LOG_REPORT';
export const FETCH_TRO_LISTING = 'FETCH_TRO_LISTING';
export const FETCH_TRO_NUMBER = 'FETCH_TRO_NUMBER';
export const PUSH_VEHICLE_DATA = 'PUSH_VEHICLE_DATA';
export const UPDATE_VEHICLE_TRANSPORTER = 'UPDATE_VEHICLE_TRANSPORTER';
export const UPDATE_VEHICLE_CUSTOM_FIELDS = 'UPDATE_VEHICLE_CUSTOM_FIELDS';

const CancelToken = axios.CancelToken;

export function fetchVehicleMakers(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_makers/`, config);

    return {
        type: FETCH_VEHICLE_MAKE,
        promise: request,
    };
}

export function fetchVehicleModelsList(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_models/`, config);

    return {
        type: FETCH_VEHICLE_MODEL_LIST,
        promise: request,
    };
}

export function fetchVehicleModels(vehicleMakerId, accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicle_models/vehicle_maker_id/${vehicleMakerId}`, config);

    return {
        type: FETCH_VEHICLE_MODEL,
        promise: request,
    };
}

export function triggerSimTrackingReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams(options),
    };

    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/simTracking`, config);

    return {
        type: SIM_TRACKING_REPORT,
        promise: request,
    };
}

export function getLastMeterEntry(accesstoken, vehicleId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({ vehicleId }),
    };
    const request = axios.get(`${ROOT_API_URL}meter_entries/last-entry`, config);

    return {
        type: GET_LAST_METER_ENTRY,
        promise: request,
    };
}

export function updateOdometer(accesstoken, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };

    const request = axios.post(`${ROOT_API_URL}meter_entries/`, data, config);

    return {
        type: UPDATE_ODOMETER,
        promise: request,
    };
}

export function fetchVehicleTypes(accesstoken, accountId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_types/`, config);

    return {
        type: FETCH_VEHICLE_TYPES,
        promise: request,
    };
}

export function fetchVehicleStatus(accesstoken, accountId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicle_statuses/`, config);

    return {
        type: FETCH_VEHICLE_STATUS,
        promise: request,
    };
}

export function fetchVehicleList(accesstoken, groupId = null, page = 1, size = 2000, checkUserGroups = true) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_vehicles_action_fetchVehicleList';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const checkUserGroupsQuery = !checkUserGroups ? `&checkUserGroups=false` : '';
    const request = axios.get(
        `${ROOT_API_URL}vehicles/?page=${page - 1}&size=${size}${groupQuery}${checkUserGroupsQuery}`,
        config
    );

    return {
        type: FETCH_VEHICLE_LIST,
        promise: request,
    };
}

export function fetchVehicleListV2(
    accesstoken,
    groupId = null,
    page = 1,
    size = 40000,
    checkUserGroups = true,
    deviceType,
    vehicleId,
    allVehicles = false,
    showRemove = true,
    showHealthPage = false,
    currentStatus = null,
    tpMappingId = null,
    showCurrentStatus = false,
    moreFilters = {}
) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_vehicles_action_fetchVehicleListV2';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const {
        matchAllTags,
        tagIds,
        vehicleMakerId,
        deviceSerialNumber,
        vehicleModelId,
        vehicleOwnership,
        deviceCategory,
        checkUserTagAndGroup,
        sort,
        transporterId,
        vehicleIds,
        gearDataPresent,
        currentTransporter,
        customFieldId,
        customFieldValue,
        vehicleStatusId,
    } = moreFilters || {};
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            page: page - 1,
            size,
            groupId,
            checkUserGroups,
            deviceType,
            vehicleId,
            allVehicles,
            showRemove,
            showHealthPage,
            currentStatus,
            tpMappingId,
            showCurrentStatus,
            matchAllTags,
            tagIds,
            vehicleMakerId,
            deviceSerialNumber,
            vehicleModelId,
            vehicleOwnership,
            deviceCategory,
            checkUserTagAndGroup,
            sort,
            transporterId,
            vehicleIds,
            gearDataPresent,
            currentTransporter,
            customFieldId,
            customFieldValue,
            vehicleStatusId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/search`.replace('v1', 'v2'), config);

    return {
        type: FETCH_VEHICLE_LIST_V2,
        promise: request,
    };
}

export function triggerSingleVehicleDayWiseReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerSingleVehicleDayWiseReport`, data, config);

    return {
        type: SINGLE_VEHICLE_DAY_WISE_REPORT,
        promise: request,
    };
}

export function triggerMultiVehicleDayWiseReport(accesstoken, options) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const data = parseQueryParams(options);

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerMultiVehicleDayWiseReport`, data, config);

    return {
        type: MULTI_VEHICLE_DAY_WISE_REPORT,
        promise: request,
    };
}

export function fetchTripStratificationAll(accesstoken, from, to, vehicleId, type = 'SPEED') {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            type,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}trips/stratification`.replace('v1', 'v2'), config);

    return {
        type: FETCH_TRIP_STRATIFICATION_ALL,
        promise: request,
    };
}

/**
 *
 * @param {*} accesstoken
 * @param {*} [showRemove]
 * @param {*} [groupId]
 * @param {*} [excludeInactive]
 * @param {*} [accountId]
 * @param {*} [allVehicles]
 * @param {*} [searchedText]
 * @returns
 */

/**
 *
 * @param {*} accesstoken
 * @param {*} [showRemove]
 * @param {*} [groupId]
 * @param {*} [excludeInactive]
 * @param {*} [accountId]
 * @param {*} [allVehicles]
 * @param {*} [searchedText]
 * @returns {*}
 */
export function fetchVehicleListMini(
    accesstoken,
    showRemove = false,
    groupId = null,
    excludeInactive = false,
    accountId = null,
    allVehicles = undefined,
    searchedText,
    isVideoTalematics = null,
    isFuelSensor = null,
    moreFilters = {}
) {
    const { transporterIds } = moreFilters || {};
    const page = 1,
        size = 40000;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            accountId,
            allVehicles,
            transporterIds,
        }),
    };
    const groupQuery = groupId && groupId != -1 && groupId != window.DEFAULT_GROUP_ID ? `&groupId=${groupId}` : '';
    const showRemoveQuery = showRemove ? `&showRemove=${true}` : '';
    const excludeInactiveQuery = excludeInactive ? `&excludeInactive=${true}` : '';
    const searchQuery = searchedText ? `&searchKey=${searchedText}` : '';
    const isCameraAttached = isVideoTalematics ? `&assetType=${isVideoTalematics}` : '';
    const fuelSensorQuery = isFuelSensor ? `&fuelSensor=${1}` : '';
    const request = axios.get(
        `${ROOT_API_URL}vehicles/autocomplete/?page=${
            page - 1
        }&size=${size}${groupQuery}${showRemoveQuery}${excludeInactiveQuery}${searchQuery}${isCameraAttached}${fuelSensorQuery}`.replace(
            '/v1',
            '/v2'
        ),
        config
    );

    return {
        type: FETCH_VEHICLE_LIST_MINI,
        promise: request,
    };
}

export function fetchVehicleListRemote(
    accesstoken,
    searchKey,
    showRemove = false,
    groupId = null,
    page = 1,
    size = 40000,
    searchById
) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            groupId,
            page: page - 1,
            size,
            showRemove,
            searchKey,
            searchById,
        }),
    };
    //api-staging.fleetx.io/api/v2/vehicles/autocomplete/?page=0&size=40000&showRemove=true&searchKey=4436
    return axios.get(`${ROOT_API_URL}vehicles/autocomplete/`.replace('/v1', '/v2'), config);
}

export function fetchVehiclesOdo(accesstoken, startTimestamp, endTimestamp, type = 'MONTHS') {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(
        `${ROOT_API_URL}analytics/vehicles/odo?from=${startTimestamp}&to=${endTimestamp}&type=${type}`,
        config
    );

    return {
        type: FETCH_VEHICLE_ODO,
        promise: request,
    };
}

export function fetchVehiclesChallan(vId, params = {}) {
    const config = { headers: {}, params: parseQueryParams({ vehicleId: vId, ...params }) };
    const request = axios.get(`${ROOT_API_URL}challan`, config);

    return {
        type: FETCH_VEHICLE_CHALLAN,
        promise: request,
    };
}

export function fetchVehicleChallanData(vId) {
    const config = { headers: {}, params: parseQueryParams({ vehicleId: vId }) };
    const request = axios.post(`${ROOT_API_URL}vehicles/challan`, {}, config);
    return {
        type: FETCH_VEHICLE_CHALLAN_DATA,
        promise: request,
    };
}

export function updateVehicleEnableDisable(accesstoken, data, toggle) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };

    const request = axios.post(`${ROOT_API_URL}analytics/vehicles/disable/${toggle}`, data, config);

    return {
        type: UPDATE_VEHICLE_ENABLE_DISABLE,
        promise: request,
    };
}

export function fetchVehicleListMiniOld(accesstoken, showRemove = false, groupId = null) {
    const page = 1,
        size = 40000;
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const showRemoveQuery = showRemove ? `&showRemove=${true}` : '';
    const request = axios.get(
        `${ROOT_API_URL}vehicles/autocomplete/?page=${page - 1}&size=${size}${groupQuery}${showRemoveQuery}`,
        config
    );

    return {
        type: FETCH_VEHICLE_LIST_MINI,
        promise: request,
    };
}

export function fetchVehicleById(vehicleId, accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicles/${vehicleId}`, config);

    return {
        type: FETCH_VEHICLE_ID,
        promise: request,
    };
}

export function addVehicle(data, isEdit, accesstoken, avoidGroupCondition) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    let request;
    data = omit(data, 'vehicleDriver');
    if (!avoidGroupCondition && get(data, 'group.id', 0) == 0) {
        set(data, 'group', null);
        //data = omit(data, "group");
    }
    if (isEdit && data.id) {
        request = axios.patch(`${ROOT_API_URL}vehicles/${data.id}`, data, config);
    } else {
        request = axios.post(`${ROOT_API_URL}vehicles/`, data, config);
    }

    return {
        type: ADD_NEW_VEHICLE,
        promise: request,
    };
}

export function deleteVehicle(id, accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
    };
    const request = axios.delete(`${ROOT_API_URL}vehicles/${id}`, config);
    return {
        type: DELETE_VEHICLE,
        promise: request,
    };
}

export function detachDevice(vehicleId, accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.post(`${ROOT_API_URL}devices/detach-device?vehicleId=${vehicleId}`, {}, config);

    return {
        type: DETACH_DEVICE,
        promise: request,
    };
}

export function removeDriverFromVehicle(vehicleId, accesstoken, endDate) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: {
            vehicleId,
            endDate,
        },
    };
    const request = axios.put(`${ROOT_API_URL}vehicle_assignments/close/`, null, config);

    return {
        type: REMOVE_DRIVER_ASSIGNMENT,
        promise: request,
    };
}

export function removeSecondaryDriverFromVehicle(vehicleId, accesstoken, endDate) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: {
            vehicleId,
            endDate,
        },
    };
    const request = axios.put(`${ROOT_API_URL}vehicle_assignments/secondary/close/`, null, config);

    return {
        type: REMOVE_DRIVER_ASSIGNMENT,
        promise: request,
    };
}

export function getVehicleDriverMapping(vehicleId, startTimestamp, endTimestamp, page = 1, size = 1000, accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(
        `${ROOT_API_URL}vehicle_assignments/search?page=${
            page - 1
        }&size=${size}&vehicleId=${vehicleId}&from=${getMomentTime(startTimestamp).valueOf()}&to=${getMomentTime(
            endTimestamp
        ).valueOf()}`,
        config
    );

    return {
        type: GET_DRIVER_MAPPING,
        promise: request,
    };
}

export function getFuelCalibration(accesstoken, vId) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(`${ROOT_API_URL}fuel-calibration/sentinel/threshold/${vId}`, config);

    return {
        type: GET_FUEL_CALIBRATION,
        promise: request,
    };
}

export function getManualFuelData(startTimestamp, endTimestamp, accesstoken) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(
        `${ROOT_API_URL}alarms/vehicle-fuel-report?from=${startTimestamp}&to=${endTimestamp}`,
        config
    );

    return {
        type: GET_MANUAL_FUEL_DATA,
        promise: request,
    };
}

export function fetchUpTimeReportReport(
    // uptimeReportVehicles,
    accesstoken,
    // groupList,
    reportFormat,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    filterConfig,
    progressUpdateCallback,
    vehicleId
) {
    let format = {};
    if (reportFormat === 'DOWNLOAD') {
        // format = {  responseType: 'blob' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            // uptimeReportVehicles,
            accesstoken,
            // groupList,
            reportFormat,
            licensePlate,
            groupName,
            fromDate: fromDate.valueOf(),
            toDate: toDate.valueOf(),
            filterConfig,
            progressUpdateCallback,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getUpTimeReport`, config);

    return {
        type: DONWLOAD_UP_TIME_REPORT,
        promise: request,
    };
}

export function patchFuelCalibration(vId, fillThreshold, theftThreshold) {
    const request = axios.patch(
        `${ROOT_API_URL}fuel-calibration/sentinel/threshold/${vId}?fillThreshold=${fillThreshold}&theftThreshold=${theftThreshold}`
    );

    return {
        type: PATCH_FUEL_CALIBRATION,
        promise: request,
    };
}

//accesstoken, reportFormat, licensePlate, groupName, fromDate, toDate, filterConfig, vehicleId, groupId;

export function fetchUpTimeDayWiseReportReport(
    accesstoken,
    reportFormat,
    licensePlate,
    groupName,
    fromDate,
    toDate,
    filterConfig,
    vehicleId,
    groupId,
    moreData
) {
    const data = {
        reportFormat,
        licensePlate,
        groupName,
        fromDate: fromDate.valueOf(),
        toDate: toDate.valueOf(),
        filterConfig,
        vehicleId,
        groupId,
        ...moreData,
    };
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/getUpTimeDayWiseReport`, data, config);

    return {
        type: DONWLOAD_UP_TIME_DAY_WISE_REPORT,
        promise: request,
    };
}

export function getVehicleTyreInspection(accesstoken, vehicleId, from, to) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.get(
        `${ROOT_API_ERP}tyre/theft/vehicle/inspection/history/${vehicleId}?from=${from}&to=${to}`,
        config
    );

    return {
        type: VEHICLE_TYRE_INSPECTION,
        promise: request,
    };
}

export function addDriverToVehicle(vehicleId, driverId, accesstoken, startedAt) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const data = {
        vehicleId: vehicleId,
        userId: driverId,
        startedAt: startedAt,
    };
    const request = axios.post(`${ROOT_API_URL}vehicle_assignments/`, data, config);

    return {
        type: UPDATE_VEHICLE_ASSIGNMENT,
        promise: request,
    };
}

export function addSecondaryDriverToVehicle(vehicleId, driverId, accesstoken, startedAt) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const data = {
        vehicleId: vehicleId,
        userId: driverId,
        startedAt: startedAt,
    };
    const request = axios.post(`${ROOT_API_URL}vehicle_assignments/secondary`, data, config);

    return {
        type: UPDATE_VEHICLE_ASSIGNMENT,
        promise: request,
    };
}

export function triggerUptimeReportFormail(accesstoken, data) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/trigger`, data, config);

    return {
        type: UPTIME_REPORT_TRIGGER_FOR_MAIL,
        promise: request,
    };
}

export function fetchVehicleHistoryLocationBulk(accesstoken, data, params) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        },
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_URL}vehicles/history/location/bulk`, data, config);

    return {
        type: FETCH_VEHICLE_HISTORY_LOCATION_BULK,
        promise: request,
    };
}

export function fetchAccountVehicleAnalytics(
    accesstoken,
    startTimestamp,
    endTimestamp,
    groupId,
    split,
    fetchUptime = false,
    cancelFlag = false,
    includeExternalMachineDurationValue,
    vehicleIds = ''
) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_vehicles_action_fetchAccountVehicleAnalytics';
    if (window[cancelCallbackKey] && !cancelFlag) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            vehicleIds,
        }),
    };
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const splitQuery = split ? `&split=${split}` : '';
    const uptimeQuery = fetchUptime ? `&fetchUptime=${fetchUptime}` : '';
    const includeExternalMachineDuration = includeExternalMachineDurationValue
        ? `&includeExternalMachineDuration=${includeExternalMachineDurationValue}`
        : '';
    const fetchJobUtilisationQuery =
        fetchJobUtilisationQueryForAccount() || useJobUtilisationForRunningVehicle()
            ? `&fetchJobUtilisation=${true}`
            : '';
    const request = axios.get(
        `${ROOT_API_URL}analytics/account/vehicle/?from=${startTimestamp}&to=${endTimestamp}${groupQuery}${splitQuery}${includeExternalMachineDuration}${uptimeQuery}${fetchJobUtilisationQuery}`,
        config
    );
    return {
        type: FETCH_ACCOUNT_VEHICLE_ANALYTICS,
        promise: request,
    };
}

export function fetchOdometerReading(accesstoken, startTimestamp, endTimestamp, vehicle) {
    //Make this unique for each function like 'CANCEL_CALLBACK_FUNCTION_FOR_<filename>_<functionname>';
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_vehicles_action_fetchAccountVehicleOdometerReading';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const vehicleQuery = vehicle ? `&vehicleId=${vehicle}` : '';
    const request = axios.get(
        `${ROOT_API_URL}meter_entries/search?from=${startTimestamp}&to=${endTimestamp}&size=${2000}${vehicleQuery}`,
        config
    );
    return {
        type: FETCH_ACCOUNT_VEHICLE_ODOMETER_READING,
        promise: request,
    };
}

export function fetchVehicleAnalytics(
    accesstoken,
    vehicleId,
    startTimestamp,
    endTimestamp,
    split,
    fetchUptime = false,
    moreOptions = {}
) {
    const { fetchInstantFuel } = moreOptions || {};
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_vehicles_action_fetchVehicleAnalytics';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            fetchInstantFuel,
        }),
    };
    const splitQuery = split ? `&split=${split}` : '';
    const uptimeQuery = fetchUptime ? `&fetchUptime=${fetchUptime}` : '';
    const fetchJobUtilisationQuery = useJobUtilisationForRunningVehicle() ? `&fetchJobUtilisation=${true}` : '';
    const request = axios.get(
        `${ROOT_API_URL}analytics/vehicle/${vehicleId}?from=${startTimestamp}&to=${endTimestamp}${splitQuery}${uptimeQuery}${fetchJobUtilisationQuery}`,
        config
    );

    return {
        type: FETCH_VEHICLE_ANALYTICS,
        promise: request,
    };
}

export function fetchMultiVehicleAnalytics(
    accesstoken,
    startTimestamp,
    endTimestamp,
    groupId,
    vehicleIds,
    type = 'DAYS'
) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const vehicleIdQuery = vehicleIds && vehicleIds != -1 ? `&vehicleIds=${vehicleIds}` : '';
    const request = axios.get(
        `${ROOT_API_URL}analytics/vehicle/multi/?from=${startTimestamp}&to=${endTimestamp}&type=${type}${groupQuery}${vehicleIdQuery}`,
        config
    );

    return {
        type: FETCH_MULTI_VEHICLE_ANALYTICS,
        promise: request,
    };
}

export function fetchMultiVehicleOdoAnalytics(
    accesstoken,
    startTimestamp,
    endTimestamp,
    groupId,
    vehicleId,
    type = 'DAYS'
) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const vehicleIdQuery = vehicleId && vehicleId != -1 ? `&vehicleId=${vehicleId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}analytics/vehicle/multi/odo?from=${startTimestamp}&to=${endTimestamp}&type=${type}${groupQuery}${vehicleIdQuery}`,
        config
    );

    return {
        type: FETCH_MULTI_VEHICLE_ODO_ANALYTICS,
        promise: request,
    };
}

export function fetchTemperatureComplianceAnalytics(params) {
    let config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}analytics/temperature-compliance-report`, config);

    return {
        type: FETCH_TEMPERATURE_COMPLIANCE_ANALYTICS,
        promise: request,
    };
}

export function fetchNightDrivingAnalytics(accesstoken, startTimestamp, endTimestamp, vehicleId, type = 'DAYS') {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const vehicleIdQuery = vehicleId && vehicleId != -1 ? `&vehicleId=${vehicleId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}analytics/vehicle/night/?from=${startTimestamp}&to=${endTimestamp}&type=${type}${vehicleIdQuery}`,
        config
    );

    return {
        type: FETCH_NIGHT_DRIVING_VEHICLE_ANALYTICS,
        promise: request,
    };
}

export function fetchVehiclePerformanceReport(accesstoken, startDate, endDate, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            startDate,
            endDate,
            vehicleId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}reports/vehicle/shivani/download`, config);

    return {
        type: DOWNLOAD_VEHICLE_PERFORMANCE_REPORT,
        promise: request,
    };
}

export function downloadTSMDayWiseReport(accesstoken, from, to, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            vehicleId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}reports/tsm-day-wise`, config);

    return {
        type: DOWNLOAD_TSM_DAY_WISE_REPORT,
        promise: request,
    };
}

export function fetchAdvanceFuelReport(accesstoken, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/omnicomm/report`, config);

    return {
        type: DOWNLOAD_ADVANCE_FUEL_REPORT,
        promise: request,
    };
}

export function fetchAllVehicleHistoryReport(accesstoken, filterConfig, deviceCategoryFilter, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId: get(filterConfig, 'vehicleTagSelectedVehicleId', ''),
            tagId: get(filterConfig, 'selectedTags.value', ''),
            // deviceType: 'tpapi',
            from,
            to,
            // deviceType: join(deviceCategoryFilter, ','),
        }),
    };

    const request = axios.get(`${ROOT_API_URL}devices/audit/bulk`, config);

    return {
        type: DOWNLOAD_ALL_VEHICLE_HISTORY_REPORT,
        promise: request,
    };
}

export function fetchVehicleTagHistoryReport(accesstoken, filterConfig, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId: get(filterConfig, 'vehicleTagSelectedVehicleId', ''),
            tagId: get(filterConfig, 'selectedTags.value', ''),
            from,
            to,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}tags/vehicle/history`, config);

    return {
        type: DOWNLOAD_VEHICLE_TAG_REPORT,
        promise: request,
    };
}

export function fetchVehicleDetailsReport(accesstoken, filterConfig, from, to) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            ...filterConfig,
            from,
            to,
        }),
    };

    const request = axios.get(`${ROOT_API_URL_V2}vehicles/vehicle-details-report`, config);

    return {
        type: DOWNLOAD_VEHICLE_DETAILS_REPORT,
        promise: request,
    };
}

export function fetchVehicleTagHistoryData(accesstoken, vehicleId, tag, from, to, page = 1, size = 20) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId: vehicleId,
            tagId: tag,
            from,
            to,
            page: page - 1,
            size,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}tags/vehicle/history`, config);

    return {
        type: FETCH_VEHICLE_TAG_DATA,
        promise: request,
    };
}

export function fetchChildVehicles(accesstoken, vehicleId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId: vehicleId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}vehicles/children`, config);

    return {
        type: FETCH_CHILD_VEHICLE,
        promise: request,
    };
}

export function addChildVehicle(accesstoken, vehicleId, parentId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId: vehicleId,
            parentId: parentId,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}vehicles/children`, config);

    return {
        type: FETCH_CHILD_VEHICLE,
        promise: request,
    };
}

export function fetchVehicleGraphs(accesstoken, vehicleId, startTimestamp, endTimestamp, frequency = FREQUENCY.DAYS) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const { scope, role } = window.FLEETX_LOGGED_IN_DATA || {};
    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};
    if (!isVehicleAnalyticsVisible(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_VEHICLE_GRAPHS,
            promise: Promise.resolve({}),
        };
    }
    const request = axios.get(
        `${ROOT_API_URL}analytics/vehicle/${vehicleId}?from=${startTimestamp}&to=${endTimestamp}&type=${frequency}`,
        config
    );

    return {
        type: FETCH_VEHICLE_GRAPHS,
        promise: request,
    };
}

export function fetchAccountVehiclesReport(
    accesstoken,
    startTimestamp,
    endTimestamp,
    vehicleId,
    groupId,
    reportFormat
) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` }, responseType: 'arraybuffer' };
    const format = reportFormat ? `&format=${reportFormat}` : '&format=' + REPORT_FORMAT.PDF;
    const groupQuery = groupId && groupId != -1 ? `&groupId=${groupId}` : '';
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const request = axios.get(
        `${ROOT_API_URL}analytics/account/vehicle/report?from=${startTimestamp}&to=${endTimestamp}${vehicleQuery}${groupQuery}${format}`,
        config
    );

    return {
        type: FETCH_ACCOUNT_VEHICLE_REPORT,
        promise: request,
    };
}

export function requestImmobiliseOtp(accesstoken, vehicleId) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicles/immobilise/otp?vehicleId=${vehicleId}`, config);

    return {
        type: REQUEST_IMMOBILISE_OTP,
        promise: request,
    };
}

export function requestImmobiliseBulkOtp(accesstoken, vehicleIds) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            vehicleIds,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/immobilise/bulk/otp`, config);

    return {
        type: REQUEST_IMMOBILISE_BULK_OTP,
        promise: request,
    };
}

export function sendImmobiliseCommand(accesstoken, otp, toImmobilise) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    const command = toImmobilise ? 'CUT' : 'RESTORE';
    const data = {
        otp,
        command,
    };
    const request = axios.post(`${ROOT_API_URL}vehicles/immobilise`, queryString.stringify(data), config);

    return {
        type: SEND_IMMOBILISE_COMMAND,
        promise: request,
    };
}

export function sendImmobiliseBulkCommand(accesstoken, otp, command = VEHICLE_IMMOBILISE_COMMANDS.RESTORE) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    const data = {
        otp,
        command,
    };
    const request = axios.post(`${ROOT_API_URL}vehicles/immobilise/bulk`, queryString.stringify(data), config);

    return {
        type: SEND_IMMOBILISE_BULK_COMMAND,
        promise: request,
    };
}

export function setImmobiliseStatus(accesstoken, deviceId, toImmobilise) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const command = toImmobilise ? 'CUT' : 'RESTORE';
    const request = axios.get(
        `${ROOT_API_URL}analytics/webhook/vehicle/status?deviceId=${deviceId}&command=${command}&manual=true`,
        config
    );

    return {
        type: SET_IMMOBILISE_STATUS,
        promise: request,
    };
}

export function vehicleVariousCount(accesstoken, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicles/${id}/counts`, config);

    return {
        type: VEHICLE_VARIOUS_COUNT,
        promise: request,
    };
}

export function fetchVehicleSpecsUniqueValues(accesstoken, vehicleSpecsFieldType, input, id) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(
        `${ROOT_API_URL}vehicle_specs_unique_values/?vehicleSpecsFieldType=${vehicleSpecsFieldType}&value=${input}&page=0&size=20`,
        config
    );
    return {
        type: VEHICLE_VARIOUS_COUNT,
        promise: request,
    };
}

export function createVehicleSpecUniqueValue(accesstoken, data) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}vehicle_specs_unique_values/`, data, config);

    return {
        type: ADD_VEHICLE_SPECS_UNIQUE_VALUE,
        promise: request,
    };
}

export function fetchUptimeDetails(accesstoken, vehicleId, from, to) {
    // const runningThreshold = 20 * 1000 // 20 seconds
    // const parkedThreshold = 20 * 60 * 1000 // 20 minutes
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_vehicles_action_uptimeDetails';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(
        `${ROOT_API_URL}analytics/uptime?from=${from.valueOf()}&to=${to.valueOf()}&vehicleId=${vehicleId}`,
        config
    );

    return {
        type: FETCH_UPTIME_DETAILS,
        promise: request,
    };
}

export function fetchNotOnJobVehicles(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vehicles/not-on-job`, config);

    return {
        type: FETCH_NOT_ON_JOB_VEHICLES,
        promise: request,
    };
}

export function fetchAllCachedVehicles(
    accesstoken,
    page = 1,
    limit = 20,
    vehicleId = null,
    vehicleNumber = null,
    vehicleName = null,
    currentStatus = null,
    groupId = null,
    driverName = null,
    deviceType = null,
    deviceId = null,
    accountId = null,
    accountName = null,
    accountEmail = null,
    vehicleMake = null,
    vehicleModel = null,
    vehicleYear = null,
    deviceSupplier = null,
    issuedTo = null,
    sim = null,
    serialNumber = null,
    whiteLabel,
    whiteLabelAccountIds
) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            currentStatus,
            groupId,
            vehicleNumber,
            vehicleName,
            driverName,
            deviceType,
            deviceId,
            accountName,
            accountEmail,
            accountId,
            vehicleMake,
            vehicleModel,
            vehicleYear,
            vehicleId,
            deviceSupplier,
            issuedTo,
            sim,
            serialNumber,
            page,
            limit,
            whiteLabel,
            whiteLabelAccountIds,
        }),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/vehicles/fetchAllVehicles`, config);

    return {
        type: FETCH_ALL_CACHED_VEHICLES,
        promise: request,
    };
}

export function fetchAllCachedVehiclesSummary(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/vehicles/fetchAllVehiclesSummary`, config);

    return {
        type: FETCH_ALL_CACHED_VEHICLES_SUMMARY,
        promise: request,
    };
}

/**
 *
 * @param {string} accesstoken
 * @param {*} vehicleId
 * @param {*} accountId
 * @returns {{promise: Promise<AxiosResponse<any>>, type: string}}
 */
export function fetchMasterVehicle(accesstoken, vehicleId, accountId = null) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/vehicles/fetchMasterVehicle`, config);

    return {
        type: FETCH_MASTER_VEHICLE,
        promise: request,
    };
}

export function fetchAllVehiclesReportExcel(
    accesstoken,
    vehicleId = null,
    vehicleNumber = null,
    vehicleName = null,
    currentStatus = null,
    groupId = null,
    driverName = null,
    deviceType = null,
    deviceId = null,
    accountId = null,
    accountName = null,
    accountEmail = null,
    vehicleMake = null,
    vehicleModel = null,
    vehicleYear = null,
    deviceSupplier = null,
    issuedTo = null,
    sim = null,
    serialNumber = null,
    sendToEmail = null,
    reportRequestMode = 'DOWNLOAD'
) {
    const preferredDateFormat = preferredTimeStampFormat();
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'arraybuffer',
        params: parseQueryParams({
            currentStatus,
            groupId,
            vehicleNumber,
            vehicleName,
            driverName,
            deviceType,
            deviceId,
            accountName,
            accountEmail,
            accountId,
            vehicleMake,
            vehicleModel,
            vehicleYear,
            vehicleId,
            deviceSupplier,
            issuedTo,
            sim,
            serialNumber,
            sendToEmail,
            reportRequestMode,
            preferredDateFormat,
        }),
    };
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getAllVehiclesReport`, config);

    return {
        type: DOWNLOAD_ALL_VEHICLES_REPORT,
        promise: request,
    };
}

export function fetchNodeRealtimeVehicleReport(
    accesstoken,
    reportFormat,
    groupName,
    filterConfig,
    vehiclesOnJob,
    progressUpdateCallback,
    latestComments,
    addressBook,
    tags,
    addressBookAsMap,
    type,
    startDate,
    endDate,
    sendToEmail = null,
    reportRequestMode = 'DOWNLOAD',
    reportType = 'REALTIME_RMC'
) {
    let format;
    if (reportFormat === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const preferredDateFormat = preferredTimeStampFormat();
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
        params: parseQueryParams({
            accesstoken,
            reportFormat,
            groupName,
            filterConfig,
            vehiclesOnJob,
            progressUpdateCallback,
            type,
            sendToEmail,
            reportRequestMode,
            reportType,
            startDate,
            endDate,
            preferredDateFormat,
        }),
    };
    //const request = axios.get(`https://www.fleetx.io/napp/reports/getAllVehiclesReport`, config);
    const request = axios.get(`${ROOT_REPORTING_NODE_API_URL}reports/getRealtimeVehiclesReport`, config);

    return {
        type: DOWNLOAD_REALTIME_VEHICLES_REPORT_NODE,
        promise: request,
    };
}

export function forceUpdateAllVehiclesData(accesstoken) {
    const config = { headers: { Authorization: `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API}gg/prepare/vehicles`, config);

    return {
        type: FORCE_UPDATE_ALL_VEHICLES_DATA,
        promise: request,
    };
}

export function fetchSimTrackingHistory(accesstoken, vehicleId, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}devices/sim/history/${vehicleId}`, config);

    return {
        type: FETCH_SIM_TRACKING_HISTORY,
        promise: request,
    };
}

export function fetchSimPingHistory(accesstoken, { vehicleId, deviceId, from, to }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
            deviceId,
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}exception/`, config);

    return {
        type: FETCH_SIM_PING_ERRORS,
        promise: request,
    };
}

export function fetchDeviceDetailsHistory(accesstoken, vehicleId, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from: getMomentTime(from).valueOf(),
            to: getMomentTime(to).valueOf(),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}devices/audit/history/${vehicleId}`, config);

    return {
        type: FETCH_DEVICE_DETAILS_HISTORY,
        promise: request,
    };
}

export function fetchVehicleSimReportFenesta(accesstoken, from, to, vehicleId, onDownloadProgress) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            from,
            to,
            vehicleId,
        }),
        onDownloadProgress: onDownloadProgress,
    };
    const request = axios.get(`${ROOT_API_URL}tp/sim/report/fenesta`, config);

    return {
        type: FETCH_VEHICLE_SIM_REPORT_FENESTA,
        promise: request,
    };
}

export function fetchVehicleStatusReport(accesstoken, from, to, vehicleId, groupId, reportRequestMode = 'DOWNLOAD') {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const data = parseQueryParams({ from, to, vehicleId, groupId, reportRequestMode });
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerVehicleStatusReport`, data, config);

    return {
        type: DOWNLOAD_VEHICLE_STATUS_REPORT,
        promise: request,
    };
}

export function fetchVehicleDutyImages(accesstoken, vehicleId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}technician/duty/tracking`, config);

    return {
        type: FETCH_VEHICLE_DUTY_IMAGES,
        promise: request,
    };
}

export function updateVehicleDutyImages(dutyId, images) {
    const data = {
        activityImages: images,
    };

    const request = axios.patch(`${ROOT_API_URL}technician/duty/tracking/${dutyId}/images`, data);

    return {
        type: UPDATE_VEHICLE_DUTY_IMAGES,
        promise: request,
    };
}

export function fetchVehicleSummary(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/api-summary`.replace('v1', 'v2'), config);

    return {
        type: FETCH_VEHICLE_SUMMARY_BY_GROUP,
        promise: request,
    };
}

export function fetchVehicleSimHistoryLogs(accesstoken, from, to) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const data = parseQueryParams({ from, to });
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/triggerVehicleSimHistoryLogs`, data, config);

    return {
        type: DOWNLOAD_VEHICLE_STATUS_REPORT,
        promise: request,
    };
}

export function updateBulkVehicleTags(accesstoken, tagIds, vehicleIds) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            tagIds: join(tagIds, ','),
            vehicleIds: join(vehicleIds, ','),
        }),
    };
    const request = axios.post(`${ROOT_API}v1/vehicles/bulk-tags`, null, config);

    return {
        type: UPDATE_BULK_VEHICLE_TAGS,
        promise: request,
    };
}

export function updateVehiclesChallan(data) {
    const request = axios.put(`${ROOT_API_URL}challan`, data);
    return {
        type: UPDATE_VEHICLE_CHALLAN,
        promise: request,
    };
}

export function fetchVehicleNightDrivingReport(accesstoken, reportRequestType, data) {
    let format = {};
    if (reportRequestType === 'DOWNLOAD') {
        format = { responseType: 'arraybuffer' };
    }
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        ...format,
    };

    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/getNightDrivingReport`, data, config);

    return {
        type: DOWNLOAD_NIGHT_DRIVING_REPORT,
        promise: request,
    };
}

export function fetchDataFromModulesForVehicle(accesstoken, vehicleId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };

    const request = axios.get(`${ROOT_API_URL_V2}vehicles/${vehicleId}`, config);

    return {
        type: FETCH_DATA_FROM_MODULES_FOR_VEHICLE,
        promise: request,
    };
}

export function fetchVehicleSummaryById(accesstoken, id, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/cost/${id}/summary`, config);

    return {
        type: FETCH_VEHICLE_SUMMARY_BY_ID,
        promise: request,
    };
}

export function fetchVehicleCostById(accesstoken, id, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/cost/${id}`, config);

    return {
        type: FETCH_VEHICLE_COST_BY_ID,
        promise: request,
    };
}

export function fetchVehicleCostSummaryById(accesstoken, id, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/cost/${id}`, config);

    return {
        type: FETCH_VEHICLE_COST_SUMMARY__BY_ID,
        promise: request,
    };
}

export function triggerAllVehicleSummeryReport(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const request = axios.post(
        `${ROOT_REPORTING_NODE_API_URL}reports/allVehicleSummaryReport`,
        parseQueryParams(data),
        config
    );

    return {
        type: DOWNLOAD_ALL_VEHICLE_SUMMARY_REPORT,
        promise: request,
    };
}

export function triggerGearPredictionReport(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const request = axios.post(
        `${ROOT_REPORTING_NODE_API_URL}reports/vehicles/gearPredictionReport`,
        parseQueryParams(data),
        config
    );

    return {
        type: DOWNLOAD_GEAR_PREDICTION_REPORT,
        promise: request,
    };
}

export function triggerAllVehiclesLocationReport(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };

    const request = axios.post(
        `${ROOT_REPORTING_NODE_API_URL}reports/vehicles/allVehiclesLocationReport`,
        parseQueryParams(data),
        config
    );

    return {
        type: ALL_VEHICLES_LOCATION_REPORT,
        promise: request,
    };
}

export function getVehicleUpdateReport(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/vehicle-report`, config);

    return {
        type: DOWNLOAD_ALL_VEHICLE_UPDATE_REPORT,
        promise: request,
    };
}

export function getVehicleAttendance(accesstoken, params) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_ALFRED}/vehicle-attendance`, config);

    return {
        type: GET_VEHICLE_ATTENDANCE,
        promise: request,
    };
}

export function markAttendanceByVehicleId(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_ALFRED}/vehicle-attendance`, data, config);

    return {
        type: MARK_ATTENDANCE_BY_VEHICLE_ID,
        promise: request,
    };
}

export function runAttendanceCron(accesstoken) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ALFRED}/vehicle-attendance/mark`, '', config);

    return {
        type: RUN_ATTENDANCE_CRON,
        promise: request,
    };
}

export function updateUlipData(accesstoken, { vehicleId }) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            vehicleId,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}vehicles/ulip-data`, vehicleId, config);

    return {
        type: UPDATE_ULIP_DATA,
        promise: request,
    };
}

export function fetchImages(url) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.get(`${url}`, config);

    return {
        type: FETCH_IMAGES,
        promise: request,
    };
}

export function triggerVehicleDeviceReport(data) {
    const config = {
        responseType: 'blob',
    };
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/vehicleDeviceReport`, data, config);
    return {
        type: VEHICLE_DEVICE_REPORT,
        promise: request,
    };
}

export function downloadMonthlyRunningReport(payload) {
    const config = {};
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/monthlyRunningReport`, payload, config);
    return {
        type: MONTHLY_RUNNING_REPORT,
        promise: request,
    };
}

export function postAttendanceByVehicleId(data) {
    const request = axios.post(`${ROOT_API_ALFRED}/vehicle-attendance`, data);
    return {
        type: POST_ATTENDANCE_BY_VEHICLE_ID,
        promise: request,
    };
}

export function triggerHourlyTemperatureReport(data) {
    const request = axios.post(`${ROOT_REPORTING_NODE_API_URL}reports/hourWiseTemperatureReport`, data, {});
    return {
        type: DOWNLOAD_HOURLY_TEMPERATURE_REPORT,
        promise: request,
    };
}

// FETCH driver info and more for vehicle
export function fetchTROData({ accountId, licenseNumber }) {
    const config = {
        params: parseQueryParams({ accountId, licenseNumber }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/tro-data`, config);

    return {
        type: FETCH_TRO_DATA,
        promise: request,
    };
}

export function fetchTROVehicleAutoComplete({ accountId }) {
    const config = {
        params: parseQueryParams({ accountId }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/tro-autocomplete`, config);

    return {
        type: FETCH_TRO_AUTOCOMPLETE,
        promise: request,
    };
}

export function fetchVehicleSummaryV2(params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_FETCH_VEHICLE_SUMMARY_V2';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }

    const config = {
        params: parseQueryParams(params),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}vehicles/summary`, config);

    return {
        type: FETCH_VEHICLE_SUMMARY_V2,
        promise: request,
    };
}

export function fetchVehicleListingV2(params) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_FETCH_VEHICLE_LISTING_V2';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        params: parseQueryParams(params),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}vehicles/listing`, config);

    return {
        type: FETCH_VEHICLE_LISTING_V2,
        promise: request,
    };
}

export function fetchVehicleQuickDetailsById({ vehicleId, from, to }) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_FETCH_VEHICLE_QUICKDETAILS';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        params: parseQueryParams({ vehicleId, from, to }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}vehicles/quick-details`, config);

    return {
        type: FETCH_VEHICLE_QUICK_DETAILS,
        promise: request,
    };
}

export function fetchVehicleCalenderDetailsById({ vehicleId, to, from, showRemove, allVehicles, checkUserGroups }) {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_FETCH_VEHICLE_CALENDER';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {
        params: parseQueryParams({ vehicleId, from, to, showRemove, allVehicles, checkUserGroups }),
        cancelToken: new axios.CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
    };
    const request = axios.get(`${ROOT_API_URL_V2}vehicles/calender-view`, config);

    return {
        type: FETCH_VEHICLE_QUICK_DETAILS,
        promise: request,
    };
}

export function fetchULIPVehicleData({ licensePlate }) {
    const config = {
        params: parseQueryParams({ licensePlate }),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/ulip-vehicle`, config);

    return {
        type: FETCH_ULIP_VEHICLE_DATA,
        promise: request,
    };
}

export function triggerVehicleMaintainanceLogReport(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}reports/vmlr`, config);
    return {
        type: VEHICLE_MAINTAINANCE_LOG_REPORT,
        promise: request,
    };
}

export function fetchVehicleSecondaryTankClosingBalance(params) {
    const config = { headers: {}, params: parseQueryParams(params) };
    const request = axios.get(`${ROOT_API_URL}vehicles/trip-sheet`, config);
    return {
        type: FETCH_VEHICLE_SECONDARY_TANK_CLOSING_BALANCE,
        promise: request,
    };
}

export function triggerPushToSap(params) {
    const config = { params: parseQueryParams(params) };
    const request = axios.get(`${ROOT_API_URL}tp/truckMaster`, config);
    return {
        type: PUSH_VEHICLE_DATA,
        promise: request,
    };
}

export const fetchTRONumber = (data) => {
    let url = `${ROOT_NODE_API_URL}napp/tro/generate`;
    const request = axios.post(url, data);

    return {
        type: FETCH_TRO_NUMBER,
        promise: request,
    };
};

export function updateVehicleTransporter(data) {
    const config = {
        params: parseQueryParams(data),
    };
    const request = axios.put(`${ROOT_API_ALFRED}/transporter/vehicle-transporter-mapping`, {}, config);
    return {
        type: UPDATE_VEHICLE_TRANSPORTER,
        promise: request,
    };
}
export function fetchTroListing(params = {}) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/tro-listing`, config);

    return {
        type: FETCH_TRO_LISTING,
        promise: request,
    };
}

export function updateVehicleCustomField(accesstoken, customFields) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({}),
    };
    const request = axios.patch(`${ROOT_API_URL_V2}vehicles/custom-fields`, customFields, config);

    return {
        type: UPDATE_VEHICLE_CUSTOM_FIELDS,
        promise: request,
    };
}
