import axios from 'axios';
import { ROOT_API_ALFRED, ROOT_API_URL, parseQueryParams } from '../../constant';

export const CREATE_VENDOR = 'create_vendor';
export const FETCH_VENDORS = 'fetch_vendors';
export const FETCH_VENDOR = 'fetch_vendor';
export const UPDATE_VENDOR = 'update_vendor';
export const DELETE_VENDOR = 'delete_vendor';
export const ADD_DETAILS = 'add_details';
export const FETCH_VENDOR_SAMPLE_REPORT = 'FETCH_VENDOR_SAMPLE_REPORT';

export function createVendor(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}vendors/`, values, config);
    return {
        type: CREATE_VENDOR,
        promise: request,
    };
}

export function updateVendor(accesstoken, values, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.patch(`${ROOT_API_URL}vendors/${id}`, values, config);

    return {
        type: UPDATE_VENDOR,
        promise: request,
    };
}

export function fetchVendors(accesstoken, data = {}) {
    const { size, page = 1, name = null, fuelTypeId } = data;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            size: size || 4000,
            page: page - 1 || 0,
            name: name,
            fuelTypeId,
        }),
    };
    //need to add in pagination in the future
    const request = axios.get(`${ROOT_API_URL}vendors/`, config);

    return {
        type: FETCH_VENDORS,
        promise: request,
    };
}

export function fetchVendor(id, accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}vendors/${id}`, config);

    return {
        type: FETCH_VENDOR,
        promise: request,
    };
}

export function deleteVendor(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}vendors/${id}`, config);
    return {
        type: DELETE_VENDOR,
        promise: request,
    };
}

export function triggerAddDetailsReportDownload(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_ALFRED}/indent/billNumber/report?accountId=${accountId}`, config);
    return {
        type: ADD_DETAILS,
        promise: request,
    };
}

export function getVendorSampleReport() {
    const config = {
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_URL}vendors/report`, config);

    return {
        type: FETCH_VENDOR_SAMPLE_REPORT,
        promise: request,
    };
}
