import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Overview = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M19.675 20.3558L20.2981 19.7327L18.4423 17.8769V15.1154H17.5577V18.2385L19.675 20.3558ZM5.30772 20.5C4.80901 20.5 4.38305 20.3233 4.02985 19.9701C3.67663 19.6169 3.50002 19.191 3.50002 18.6923V5.3077C3.50002 4.80898 3.67663 4.38302 4.02985 4.02982C4.38305 3.67661 4.80901 3.5 5.30772 3.5H18.6923C19.191 3.5 19.617 3.67661 19.9701 4.02982C20.3234 4.38302 20.5 4.80898 20.5 5.3077V11.6327C20.2538 11.5275 20.0073 11.4384 19.7605 11.3654C19.5138 11.2923 19.2603 11.234 19 11.1904V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H5.30772C5.23079 4.99998 5.16026 5.03203 5.09615 5.09613C5.03205 5.16024 5 5.23077 5 5.3077V18.6923C5 18.7692 5.03205 18.8397 5.09615 18.9038C5.16026 18.9679 5.23079 19 5.30772 19H11.1654C11.2026 19.2769 11.2577 19.5387 11.3308 19.7855C11.4038 20.0323 11.4929 20.2705 11.5981 20.5H5.30772ZM5 18V19V4.99998V11.1904V11.1154V18ZM7.25002 16.6346H11.2673C11.3109 16.3743 11.3756 16.1208 11.4615 15.874C11.5474 15.6272 11.641 15.3808 11.7423 15.1346H7.25002V16.6346ZM7.25002 12.75H13.5904C14.0212 12.3846 14.4808 12.0785 14.9692 11.8317C15.4577 11.5849 15.9807 11.4096 16.5384 11.3058V11.25H7.25002V12.75ZM7.25002 8.86535H16.75V7.36538H7.25002V8.86535ZM18 22.5576C16.7513 22.5576 15.6891 22.1198 14.8135 21.2442C13.9378 20.3685 13.5 19.3064 13.5 18.0577C13.5 16.809 13.9378 15.7468 14.8135 14.8712C15.6891 13.9955 16.7513 13.5577 18 13.5577C19.2487 13.5577 20.3109 13.9955 21.1865 14.8712C22.0622 15.7468 22.5 16.809 22.5 18.0577C22.5 19.3064 22.0622 20.3685 21.1865 21.2442C20.3109 22.1198 19.2487 22.5576 18 22.5576Z", fill: "currentColor" }) }));
};
const OverviewIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Overview, ...props });
};
export default OverviewIcon;
