import axios, { CancelToken } from 'axios';
import { parseQueryParams, ROOT_API_URL } from '../../../constant';

export const ADD_TRIP_SHEET = 'add_trip_sheet';
export const UPDATE_TRIP_SHEET = 'update_trip_sheet';
export const DELETE_TRIP_SHEET = 'delete_trip_sheet';
export const FETCH_TRIP_SHEET = 'fetch_trip_sheet';
export const FETCH_TRIP_SHEET_PDF = 'fetch_trip_sheet_pdf';
export const FETCH_TRIP_SHEET_WITH_SUMMARY = 'fetch_trip_sheet_with_summary';
export const UPDATE_TRIP_SHEET_STATUS = 'UPDATE_TRIP_SHEET_STATUS';
export const DOWNLOAD_TRIP_SHEET_REPORT = 'DOWNLOAD_TRIP_SHEET_REPORT';

export function addTripSheet(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}finance/tripsheet`, values, config);
    return {
        type: ADD_TRIP_SHEET,
        promise: request,
    };
}

export function updateTripSheet(accesstoken, id, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}finance/tripsheet/${id}`, values, config);
    return {
        type: UPDATE_TRIP_SHEET,
        promise: request,
    };
}

export function fetchTripSheet(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}finance/tripsheet/${id}`, config);
    return {
        type: FETCH_TRIP_SHEET,
        promise: request,
    };
}

export function fetchTripSheetPDF(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` }, responseType: 'arraybuffer' };
    const request = axios.get(`${ROOT_API_URL}finance/tripsheet/${id}/pdf`, config);
    return {
        type: FETCH_TRIP_SHEET_PDF,
        promise: request,
    };
}

export function fetchTripSheetWithSummary(accesstoken, from, to, driver, vehicleId, extraFilters = {}, status = null) {
    const { stationaryNumber = '' } = extraFilters;
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const driverQuery = driver ? `&driverId=${driver}` : '';
    const toQuery = to ? `&to=${to}` : '';
    const vehicleQuery = vehicleId ? `&vehicleId=${vehicleId}` : '';
    const stationaryNumberQuery = stationaryNumber ? `&stationaryNumber=${stationaryNumber}` : '';
    const statusQuery = status ? `&status=${status}` : '';
    const request = axios.get(
        `${ROOT_API_URL}finance/tripsheet/summary/?from=${from}${toQuery}${driverQuery}${vehicleQuery}${stationaryNumberQuery}${statusQuery}`,
        config
    );
    return {
        type: FETCH_TRIP_SHEET_WITH_SUMMARY,
        promise: request,
    };
}

export function updateTripSheetStatus(accesstoken, id, status, moreOptions = {}) {
    const { userPaymentIds } = moreOptions;

    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            status,
            userPaymentIds,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL}finance/tripsheet/status/${id}`, null, config);
    return {
        type: UPDATE_TRIP_SHEET_STATUS,
        promise: request,
    };
}

export function deleteTripSheet(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}finance/tripsheet/${id}`, config);
    return {
        type: DELETE_TRIP_SHEET,
        promise: request,
    };
}

export function downloadTripSheetReport(accesstoken, from, to, extraFilters = {}) {
    const { stationaryNumber = '', driverId = null, vehicleId = null, status = null } = extraFilters;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            from,
            to,
            stationaryNumber,
            driverId,
            vehicleId,
            status,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}reports/tripsheet`, config);
    return {
        type: DOWNLOAD_TRIP_SHEET_REPORT,
        promise: request,
    };
}
