import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_API_URL_V2 } from '../../../constant';
export const CREATE_EPOD_FORM = 'CREATE_EPOD_FORM';
export const FETCH_EPODS_BY_JOB_ID = 'FETCH_EPODS_BY_JOB_ID';
export const FETCH_EPOD_FORM = 'FETCH_EPOD_FORM';
export const UPDATE_EPOD_FORM = 'UPDATE_EPOD_FORM';
export const SEND_EPOD_OTP = 'SEND_EPOD_OTP';
export const VERIFY_EPOD_OTP = 'VERIFY_EPOD_OTP';
export const FETCH_EPOD_LIST = 'FETCH_EPOD_LIST';
export const BULK_EPOD_ACCEPT = 'BULK_EPOD_ACCEPT';
export function createEPODForm(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/epod-form`, data, config);
    return {
        type: CREATE_EPOD_FORM,
        promise: request,
    };
}
export function updateEPODForm(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.patch(`${ROOT_API_URL}dispatch/epod-form`, data, config);
    return {
        type: UPDATE_EPOD_FORM,
        promise: request,
    };
}
export function fetchEPODByJobId(accesstoken, jobId) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            jobId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/epod-form/listing`, config);
    return {
        type: FETCH_EPODS_BY_JOB_ID,
        promise: request,
    };
}
export function fetchEPODForm(accesstoken, id) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}dispatch/epod-form/`, config);
    return {
        type: FETCH_EPOD_FORM,
        promise: request,
    };
}
export function sendEPODOTP(params, payload) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.post(`${ROOT_API_URL}dispatch/send-otp`, payload, config);
    return {
        type: SEND_EPOD_OTP,
        promise: request,
    };
}
export function verifyEPODOTP(payload) {
    const request = axios.post(`${ROOT_API_URL}dispatch/verify-otp`, payload);
    return {
        type: VERIFY_EPOD_OTP,
        promise: request,
    };
}
export function fetchEPODData(params) {
    const { from, to, page, size, customerId, isCustomer, isTransporter, transporterStatus, shipperTransporterStatus, shipperCustomerStatus, status, transporterId, billNumber, ptl, customerStatus, tagIds, customerIds, jobAnalyticsId, } = params || {};
    const config = {
        params: parseQueryParams({
            from,
            to,
            page,
            size,
            customerId,
            transporterStatus,
            shipperTransporterStatus,
            shipperCustomerStatus,
            customerStatus,
            status,
            transporterId,
            billNumber,
            ptl,
            tagIds,
            customerIds,
            jobAnalyticsId,
        }),
    };
    // dispatch/epods/shipper
    // dispatch/epods/customer
    // dispatch/epods/transporter
    let url = `${ROOT_API_URL}dispatch/epods/`;
    if (isCustomer) {
        url += 'customer';
    }
    else if (isTransporter) {
        url += 'transporter';
    }
    else {
        url += 'shipper';
    }
    const request = axios.get(url.replace('v1', 'v2'), config);
    return {
        type: FETCH_EPOD_LIST,
        promise: request,
    };
}
export function bulkEPODAccept(params = {}, data) {
    const { type } = params;
    const config = {
        params: parseQueryParams({
            type,
        }),
    };
    const request = axios.put(`${ROOT_API_URL}dispatch/bulk/epod/accept`, data, config);
    return {
        type: BULK_EPOD_ACCEPT,
        promise: request,
    };
}
export function fetchPendingEPODs(params) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_API_URL_V2}dispatch/pending-epod`, config);
    return {
        type: FETCH_EPOD_LIST,
        promise: request,
    };
}
