import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Reminder = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M11.9999 21.5C10.8204 21.5 9.71534 21.2769 8.68468 20.8307C7.65384 20.3846 6.75509 19.7782 5.98843 19.0115C5.22176 18.2448 4.61534 17.3461 4.16918 16.3152C3.72301 15.2846 3.49993 14.1795 3.49993 13C3.49993 11.8205 3.72301 10.7154 4.16918 9.68474C4.61534 8.65391 5.22176 7.75516 5.98843 6.98849C6.75509 6.22182 7.65384 5.61541 8.68468 5.16924C9.71534 4.72307 10.8204 4.49999 11.9999 4.49999C13.1794 4.49999 14.2845 4.72307 15.3152 5.16924C16.346 5.61541 17.2448 6.22182 18.0114 6.98849C18.7781 7.75516 19.3845 8.65391 19.8307 9.68474C20.2768 10.7154 20.4999 11.8205 20.4999 13C20.4999 14.1795 20.2768 15.2846 19.8307 16.3152C19.3845 17.3461 18.7781 18.2448 18.0114 19.0115C17.2448 19.7782 16.346 20.3846 15.3152 20.8307C14.2845 21.2769 13.1794 21.5 11.9999 21.5ZM14.9729 17.027L16.0269 15.973L12.7499 12.6962V7.99999H11.2499V13.3037L14.9729 17.027ZM5.75368 2.88849L6.80768 3.94224L2.94218 7.80774L1.88843 6.75374L5.75368 2.88849ZM18.2462 2.88849L22.1114 6.75374L21.0577 7.80774L17.1922 3.94224L18.2462 2.88849ZM11.9999 20C13.9436 20 15.5962 19.3192 16.9577 17.9577C18.3192 16.5962 18.9999 14.9437 18.9999 13C18.9999 11.0563 18.3192 9.40374 16.9577 8.04224C15.5962 6.68074 13.9436 5.99999 11.9999 5.99999C10.0563 5.99999 8.40368 6.68074 7.04218 8.04224C5.68068 9.40374 4.99993 11.0563 4.99993 13C4.99993 14.9437 5.68068 16.5962 7.04218 17.9577C8.40368 19.3192 10.0563 20 11.9999 20Z", fill: "currentColor" }) }));
};
const ReminderIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Reminder, ...props });
};
export default ReminderIcon;
