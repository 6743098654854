import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Vehicle = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M12 21.4808C9.83717 20.8909 8.0465 19.6178 6.628 17.6615C5.20933 15.7052 4.5 13.518 4.5 11.1V5.34626L12 2.53851L19.5 5.34626V11.1C19.5 13.518 18.7907 15.7052 17.372 17.6615C15.9535 19.6178 14.1628 20.8909 12 21.4808ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.37501L12 4.13451L6 6.37501V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9ZM10.0192 15.827H13.9807C14.2369 15.827 14.4516 15.7403 14.6247 15.567C14.7979 15.3938 14.8845 15.1792 14.8845 14.923V11.9615C14.8845 11.7055 14.7979 11.4908 14.6247 11.3175C14.4516 11.1443 14.2369 11.0578 13.9807 11.0578H13.8943V10.0578C13.8943 9.53343 13.7113 9.08826 13.3453 8.72226C12.9793 8.3561 12.534 8.17301 12.0095 8.17301C11.4852 8.17301 11.04 8.3561 10.674 8.72226C10.308 9.08826 10.125 9.53343 10.125 10.0578V11.0578H10.0192C9.76308 11.0578 9.54842 11.1443 9.37525 11.3175C9.20208 11.4908 9.1155 11.7055 9.1155 11.9615V14.923C9.1155 15.1792 9.20208 15.3938 9.37525 15.567C9.54842 15.7403 9.76308 15.827 10.0192 15.827ZM11.0095 11.0578V10.0578C11.0095 9.77443 11.1053 9.53693 11.297 9.34526C11.4887 9.1536 11.7262 9.05776 12.0095 9.05776C12.2928 9.05776 12.5303 9.1536 12.722 9.34526C12.9137 9.53693 13.0095 9.77443 13.0095 10.0578V11.0578H11.0095Z", fill: "currentColor" }) }));
};
const VehicleIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Vehicle, ...props });
};
export default VehicleIcon;
