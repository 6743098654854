import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useMemo } from 'react';
import { find, get, includes, toLower } from 'lodash';
import { Field } from 'redux-form';
import { mappedAddressBookWithIdMapping, mappedConsignee, mappedConsignors } from '../../utils/form_utils';
import { Button } from 'reactstrap';
import { renderAddressBookOption } from '../../utils/address_book_utils';
import SelectInput from '../../components/utils/SelectInput';
import CommoditiesFormComponentOld from './CommoditiesFormComponentOld';
import { FxSingleDatePickerRedux } from '..';
import { getEndDate } from '../../utils/date_utils';
const RoutePointItemComponentOld = (props) => {
    const { index, customers, isExistingRouteSelected, addressBook, isEdit, commodityList, routePoint, selectLocationFromGoogleMap, baseKey, remove, commodityName = 'commodities', } = props;
    const isStartLocation = index === 0;
    const filterCountRef = React.useRef(0);
    const addressOptions = useMemo(() => {
        return mappedAddressBookWithIdMapping(addressBook);
    }, [addressBook]);
    const customerOptions = useMemo(() => {
        return {
            consignor: mappedConsignors(customers) || [],
            consignee: mappedConsignee(customers) || [],
        };
    }, [customers]);
    const customFilterOption = (option, filter, options) => {
        if (filterCountRef.current < 10) {
            if (includes(toLower(get(option, 'label', '')), toLower(filter)) ||
                includes(toLower(get(option, 'value', '')), toLower(filter))) {
                filterCountRef.current = filterCountRef.current + 1;
                return true;
            }
        }
        else {
            return false;
        }
    };
    const resetFilterCount = () => {
        filterCountRef.current = 0;
    };
    return (_jsx("div", { className: "my-4 card", children: _jsxs("div", { className: "card-body", children: [_jsxs("h5", { children: [isStartLocation ? 'Loading' : 'Unloading', " Point ", isStartLocation ? '' : `#${index}`, !isExistingRouteSelected && (_jsx(Button, { className: "ml-2", color: "link", onClick: () => remove(index), children: "Remove" }))] }), _jsxs("div", { className: "row", children: [_jsxs("div", { className: "col-md-6", children: [_jsx(Field, { disabled: isExistingRouteSelected, name: `${baseKey}.routePoint.addressBookId`, 
                                    // name={`${baseKey}.routePoint.selectId`}
                                    placeholder: "Route Point", label: "Select Route Point", isRequired: true, options: addressOptions, component: SelectInput, optionRenderer: renderAddressBookOption, onKeyDown: resetFilterCount, filterOption: customFilterOption, onSelected: (id) => props.onRoutePointSelect(find(addressBook, { id: +id }), index) }), !isExistingRouteSelected && !isEdit && (_jsx("div", { children: _jsx("button", { disabled: isExistingRouteSelected, type: "button", onClick: () => selectLocationFromGoogleMap(index), className: "btn btn-link pl-0", children: "Select location using google map" }) }))] }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: `${baseKey}.customer.id`, placeholder: isStartLocation ? 'Consignor' : 'Consignee', options: isStartLocation ? customerOptions.consignor : customerOptions.consignee, component: SelectInput, isAntdSelect: true, label: isStartLocation ? 'Consignor' : 'Consignee' }) }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: `${baseKey}.scheduleArrival`, label: 'Scheduled Arrival', component: FxSingleDatePickerRedux, timePicker: true, minDate: getEndDate() }) }), _jsx("div", { className: "col-md-6", children: _jsx(Field, { name: `${baseKey}.scheduleDeparture`, label: 'Scheduled Departure', component: FxSingleDatePickerRedux, timePicker: true, minDate: getEndDate() }) })] }), _jsx(CommoditiesFormComponentOld, { name: `${baseKey}.${commodityName}`, commodityList: commodityList, commodities: routePoint[commodityName] })] }) }));
};
export default RoutePointItemComponentOld;
