import { mappedObjectToOptions } from 'components/utils/mapping_utils';
import { forOwn, round } from 'lodash';

export const EPOD_STATUS = {
    OPEN: 'OPEN',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
};
export const EPOD_NEGOTIATION_STATUS = {
    OPEN: 'OPEN',
    NEGOTIATION: 'NEGOTIATION',
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
};

export const EPOD_OWNERSHIP = {
    FLEET_MANAGER: 'FLEET_MANAGER',
    DRIVER: 'DRIVER',
};

export const EPOD_DELIVERY_STATUS = {
    SUCCESSFUL: 'SUCCESSFUL',
    DAMAGED_MATERIAL: 'DAMAGED_MATERIAL',
    MISSING_MATERIAL: 'MISSING_MATERIAL',
};

export const EPOD_DELIVERY_STATUS_LABEL = {
    SUCCESSFUL: 'Successful',
    DAMAGED_MATERIAL: 'Damaged Material',
    MISSING_MATERIAL: 'Missing Material',
};

export const chargeTypeOptions = [
    {
        label: 'Unloading Charge',
        value: 'Unloading Charge',
    },
    {
        label: 'Loading Charge',
        value: 'Loading Charge',
    },
    {
        label: 'Damage Penalty',
        value: 'Damage Penalty',
    },
    {
        label: 'Missing Penalty',
        value: 'Missing Penalty',
    },
];

export function getColorClassForEpodStatus(status) {
    switch (status) {
        case EPOD_STATUS.OPEN:
            return 'text-muted';
        case EPOD_STATUS.ACCEPTED:
            return 'text-success';
        case EPOD_STATUS.REJECTED:
            return 'text-danger';
        default:
            return '';
    }
}
export function getColorClassForEpodNegotiationStatus(status) {
    switch (status) {
        case EPOD_NEGOTIATION_STATUS.OPEN:
            return '#4dbd74';
        case EPOD_NEGOTIATION_STATUS.NEGOTIATION:
            return '#f2c94c';
        case EPOD_NEGOTIATION_STATUS.PENDING:
            return '#f2994a';
        case EPOD_NEGOTIATION_STATUS.ACCEPTED:
            return '#4dbd74';
        default:
            return '';
    }
}

export function getColorClassForDeviationCount(deviation) {
    if (deviation >= 10 && deviation <= 20) {
        return '#f2994a';
    } else if (deviation > 20) {
        return '#FF0000';
    } else return '#4dbd74';
}

export function mappedEpodStatus() {
    const options = [];
    forOwn(EPOD_NEGOTIATION_STATUS, (value, key) => {
        options.push({
            label: key,
            value,
        });
    });
    return options;
}

export function epodDeliveryStatusOptions() {
    return mappedObjectToOptions(EPOD_DELIVERY_STATUS, EPOD_DELIVERY_STATUS_LABEL);
}
export const getMisplacedCount = ({ dispatchQuantity = 0, unloadedCount = 0, damagedCount = 0 }) => {
    const misplacedCount = dispatchQuantity - unloadedCount - damagedCount;
    return round(misplacedCount, 2);
};

export const getIncentiveAndPenalty = ({ unloadedCount = 0, dispatchQuantity, tolerance, itemRate }) => {
    if (!dispatchQuantity || +tolerance < 0 || +itemRate < 0) {
        return {
            penalty: 0,
            incentive: 0,
        };
    }
    const percentage = 100.0 - (unloadedCount / dispatchQuantity) * 100;
    const val = ((tolerance - percentage) * itemRate * dispatchQuantity) / 100;
    return {
        incentive: val > 0 ? round(val, 2) : 0,
        penalty: val < 0 ? round(val, 2) : 0,
    };
};

export const EPOD_TABS = {
    OVERVIEW: 'EPOD',
    PENDING_EPOD: 'PENDING_EPOD',
};
