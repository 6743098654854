import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filter, forEach, get, isArray, map } from 'lodash';
import axios from 'axios';
import { getImageKitLink, image, parseQueryParams, ROOT_API_BASE, ROOT_API_URL } from '../constant';
import { Alert, Button, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { getEllipsisedText } from '../utils/string_utils';
import { validateFileBeforeUpload } from '../utils/fileUploadutils';
import { createSafeMarkup } from '../utils/dom_utils';
import { useTranslation } from 'react-i18next';
import { fetchTemplateByFormType } from 'actions/dashboard/form_templates_action';
import { isViewERPConfigurationAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { FxReactSelect as Select } from '../shared';
import { Link } from 'react-router-dom';
import { FxButton, FxDivider, FxDropdown, FxModal } from 'sharedV2/index';
import { FxDownloadIcon } from 'sharedV2/FxIcons';
import { FxDropzone } from 'sharedV2';
const ModalBodyWrapper = styled.div `
    .modal-body {
        height: 400px;
        max-height: 400px;
        overflow-y: scroll;
    }
`;
const DropZoneContainer = styled.div `
    .dropzone {
        border-radius: 6.4px;
        border: solid 2.3px #666666;
        border-image: ${(props) => `url(${props.image}) 2 round`};
        height: ${(props) => (props.height ? props.height : '')};
    }
`;
const UploadIcon = styled.img `
    height: 44px;
`;
const ListWrapper = styled.ul `
    font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
    padding-left: 0px;

    li {
        list-style: none;
        font-weight: 500;
        color: ${(props) => (props.color ? props.color : '#43434a')};
        line-height: 1.6;

        span {
            word-break: break-all;
        }
    }
`;
const ProgressBar = styled.div `
    progress {
        width: 100%;
        height: 7px;
        border-radius: 15px;
        background: none;
    }

    progress::-webkit-progress-bar {
        height: 7px;
        background-color: #ccc;
        border-radius: 15px;
        background-color: #eaeaea;
    }

    progress::-webkit-progress-value {
        display: inline-block;
        height: 7px;
        float: left;
        background: #48b0e2;
        border-radius: 15px;
    }
`;
const SuccessFulUploadedContainer = styled.div `
    height: 370px;
`;
const AlertWrapper = styled.div `
    .alert {
        height: 52px;
    }
`;
const Icon = styled.i `
    color: ${(props) => (props.color ? props.color : '')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
`;
const P = styled.p `
    font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
    text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
    color: ${(props) => (props.color ? props.color : '')};
    cursor: ${(props) => (props.cursor ? props.cursor : '')};
`;
const Span = styled.span `
    font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '')};
    color: ${(props) => (props.color ? props.color : '')};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
`;
const FxUploadModal = (props) => {
    const { extraSampleFiles } = props;
    const [modalState, setModalState] = useState('');
    const [progress, setProgress] = useState(0);
    const [acceptedFile, setAcceptedFile] = useState(null);
    const [isOpenViewErrors, setIsOpenViewErrors] = useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const [templateUrl, setTemplateUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    useEffect(() => {
        const { accesstoken, module, isOpen } = props;
        if (!isOpen || !module || templateUrl) {
            setIsLoading(false);
            return;
        }
        if (isViewERPConfigurationAllowedForUser(get(props, 'login.scope'))) {
            props.fetchTemplateByFormType(accesstoken, 'CUSTOM_SAMPLE_FILE').then((res) => {
                if (!res.error) {
                    const data = filter(get(res, 'payload.data', []), (item) => {
                        return get(item, 'data.module', '') === module;
                    });
                    if (get(data, '[0].data.url', null) !== null) {
                        setTemplateUrl(getImageKitLink(get(data, '[0].data.url', null)));
                    }
                    else if (isArray(get(data, '[0].data.urls'))) {
                        setTemplateUrl(get(data, '[0].data.urls', []));
                    }
                    else {
                        setTemplateUrl(null);
                    }
                }
                setIsLoading(false);
            });
        }
    }, [props.isOpen, props.module, templateUrl]);
    let source;
    const uploadReportFileIcon = '/public/img/upload_modal/upload_report_file_icon.png';
    const dropZoneBorderImage = '/public/img/upload_modal/dropdown-border.svg';
    const toggle = () => {
        const { isOpen, onToggle } = props;
        reset();
        onToggle?.(isOpen ? isOpen : false);
    };
    const reset = () => {
        if (source) {
            source = source.cancel('');
        }
        setModalState('');
        setProgress(0);
        setAcceptedFile(null);
        setIsOpenViewErrors(false);
        setErrorsList([]);
    };
    const onFileUploadSuccess = (response) => {
        const { onSubmit } = props;
        if (source) {
            source = source.cancel('');
        }
        setModalState('success');
        setAcceptedFile(null);
        if (!props.modalAsPage) {
            setTimeout(() => {
                !props.shouldRedirect && reset();
                !props.shouldRedirect && onSubmit?.(response);
            }, 1000);
        }
    };
    const onFileUploadError = (error) => {
        setModalState('error');
        setErrorsList(get(error, 'response.data.errors', []));
        if (!error.response?.data?.errors?.length && error.response?.data?.error_description) {
            setErrorsList([{ description: error.response.data.error_description || '' }]);
        }
    };
    const onDrop = (acceptedFiles) => {
        if (source) {
            source = source.cancel('');
        }
        const acceptedFile = acceptedFiles[0];
        const fileValidationObj = validateFileBeforeUpload(acceptedFile);
        if (get(fileValidationObj, 'error')) {
            alert(get(fileValidationObj, 'errorMsg'));
            return;
        }
        const CancelToken = axios.CancelToken;
        source = CancelToken.source();
        const config = {
            onUploadProgress: (progressEvent) => setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
            cancelToken: source.token,
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${props.accesstoken}` },
            params: parseQueryParams({
                ...props.requestParams,
            }),
        };
        setAcceptedFile(acceptedFile);
        const data = new FormData();
        data.append('file', acceptedFile);
        // attach any extra fields to body
        forEach(props.extraBodyFields, (value, key) => {
            data.append(key, value);
        });
        const requestMethod = props.requestMethod === 'put' ? axios.put : props.requestMethod === 'patch' ? axios.patch : axios.post;
        let requestUrl = '';
        if (props.enpoint?.startsWith('http')) {
            requestUrl = props.enpoint;
        }
        else {
            requestUrl = `${props.basePath ? `${ROOT_API_BASE}${props.basePath}` : ROOT_API_URL}${props.enpoint}`;
        }
        const request = requestMethod(requestUrl, data, config)
            .then((response) => {
            if (response) {
                onFileUploadSuccess(response);
            }
        })
            .catch((err) => {
            onFileUploadError(err);
        });
    };
    const beforeUpload = (file) => {
        onDrop([file]);
        return false;
    };
    const renderBody = () => {
        const uploadProps = {
            beforeUpload,
            multiple: false,
            accept: `text/csv,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ${props.acceptFiles ? props.acceptFiles : ''}`,
            maxCount: 1,
            showUploadList: false,
        };
        return (_jsxs(_Fragment, { children: [isOpenViewErrors ? (_jsxs("div", { className: "", children: [_jsx("div", { className: "d-flex align-items-baseline", children: _jsxs(Button, { color: "primary", onClick: () => setIsOpenViewErrors(false), children: [_jsx(Icon, { className: "fa fa-arrow-left mr-1", fontWeight: "100" }), "Back"] }) }), _jsx(P, { fontSize: "0.825rem", className: "mt-3 mb-1 font-weight-500", color: "#536c79", children: "How to Upload" }), _jsx(ListWrapper, { fontSize: "0.79rem", color: "#536c79", children: map(props.uploadInstruction, (instruction, index) => {
                                return (_jsxs("li", { className: "d-flex", children: [_jsx("div", { children: `${index + 1}. ` }), _jsx("span", { dangerouslySetInnerHTML: createSafeMarkup(instruction) })] }, index));
                            }) }), _jsxs(P, { className: "mb-0 text-danger", fontSize: "1.4rem", children: [_jsx(Icon, { className: "fa fa-exclamation-triangle ml-1 mr-2" }), " Error"] }), _jsxs(ListWrapper, { className: "text-danger", color: "#f86c6b", children: [map(errorsList, (error, index) => {
                                    return (_jsxs("li", { className: "d-flex", children: [_jsx("div", { children: _jsx(Span, { style: { whiteSpace: 'nowrap' }, marginLeft: "12px", children: `${index + 1}. ` }) }), _jsx(Span, { marginLeft: "14px", children: error.description })] }, index));
                                }), (!errorsList || errorsList.length === 0) && (_jsx("div", { className: "font-weight-bold text-danger mt-1", children: "Error details not available please fix the issues and upload again" }))] })] })) : (_jsxs(_Fragment, { children: [modalState === 'error' && !props.shouldRedirect && (_jsx(AlertWrapper, { children: _jsxs(Alert, { color: "danger", children: ["Oops! something went wrong", _jsx(Button, { className: "font-weight-bold float-right py-1 px-2", color: "danger", onClick: () => setIsOpenViewErrors(true), children: "View details" })] }) })), (modalState === '' || (modalState === 'error' && !props.shouldRedirect)) && (_jsxs("div", { className: "mt-2", children: [props.selectForUploadTitle && (_jsxs("div", { className: "mb-3", children: [_jsx("div", { className: "h6 mb-1", children: props.selectForUploadTitle }), _jsx(Select, { simpleValue: true, value: props.uploadSelectedType, onChange: props.onUploadTypeSelected, placeholder: "Select Type", options: props.typeSelectOptions ? props.typeSelectOptions : [], clearable: false })] })), _jsx(DropZoneContainer, { height: modalState === 'error' ? '150px' : '200px', image: dropZoneBorderImage, children: modalState === 'error' ? (_jsxs("div", { className: "dropzone text-center w-100", children: [_jsx(P, { className: "mt-1", fontSize: "1.125rem", children: "Fix issues in file and upload again" }), _jsxs(Button, { className: "font-weight-bold mt-1", onClick: reset, children: ["Upload again", _jsx(Icon, { className: "fa fa-repeat ml-1", color: "black", fontWeight: "100" })] })] })) : (_jsx(FxDropzone, { ...uploadProps, children: _jsxs("div", { className: "text-center", children: [_jsx(UploadIcon, { className: "mt-2", src: image(uploadReportFileIcon), alt: "upload file" }), _jsxs("div", { className: "mt-3", children: [_jsx(P, { className: "m-0", fontSize: "1rem", fontWeight: "bold", lineHeight: "1.53", textAlign: "center", color: "#48b0e2", cursor: "pointer", children: props.dropZoneHeading
                                                                ? props.dropZoneHeading
                                                                : 'Drag and drop excel/csv file here' }), _jsx(P, { fontSize: "1rem", lineHeight: "1.53", textAlign: "center", color: "#43434a", className: "m-0", children: "or" }), _jsx(Button, { fontSize: "1rem", fontWeight: "bold", color: "primary", cursor: "pointer", className: "m-0", onClick: (e) => e.preventDefault(), children: "Upload file" })] })] }) })) })] })), acceptedFile && !props.shouldRedirect && (_jsxs("div", { className: "mt-4", children: [_jsx(Span, { id: "uploadedFileName", className: "mb-0", fontSize: "0.825rem", fontWeight: "bold", lineHeight: "1.57", color: "#43434a", children: getEllipsisedText(acceptedFile.name, 37) }), acceptedFile.name.length >= 37 && !props.shouldRedirect && (_jsx(UncontrolledTooltip, { target: "uploadedFileName", autohide: false, children: acceptedFile.name })), _jsx(_Fragment, { children: modalState === 'error' && !props.shouldRedirect ? (_jsx("span", { className: "ml-3 text-danger float-right", children: "Upload failed" })) : (!props.shouldRedirect && (_jsxs(Span, { className: "ml-3  float-right", fontSize: "0.825rem", fontWeight: "bold", lineHeight: "1.57", color: "#48b0e2", children: [_jsx("i", { className: "fa fa-spinner fa-pulse mr-2" }), progress, "%"] }))) }), modalState !== 'error' && !props.shouldRedirect && (_jsx(ProgressBar, { children: _jsx("progress", { max: "100", value: progress }) }))] })), modalState === '' && !acceptedFile && (_jsxs("div", { className: "mt-4", children: [_jsx("p", { className: "font-lg mb-1 font-weight-500", children: "How to Upload" }), _jsx(ListWrapper, { children: map(props.uploadInstruction, (instruction, index) => {
                                        return (_jsxs("li", { className: "d-flex", children: [_jsx("div", { children: `${index + 1}. ` }), _jsx("span", { dangerouslySetInnerHTML: createSafeMarkup(instruction) })] }, index));
                                    }) })] })), props.modalAsPage &&
                            (modalState === '' || (!isOpenViewErrors && modalState === 'error')) &&
                            !props.hideDownloadSampleFile && (_jsx(Button, { className: "mr-auto font-weight-bold mt-3", href: props.sampleDownloadFileLocation, color: "secondary", download: true, children: "Download sample file" }))] })), modalState === 'success' && !props.shouldRedirect && (_jsxs(SuccessFulUploadedContainer, { className: "d-flex flex-column justify-content-center align-items-center", children: [_jsx(Icon, { className: "fa fa-check-circle fa-5x", color: "#4dbd74" }), _jsx(P, { fontSize: "1.125rem", color: "#1e3254", className: "mt-3 mb-0", children: "Your file has been uploaded successfully" }), _jsx(Button, { className: "font-weight-bold mt-3", onClick: reset, children: "Upload again" })] })), props.shouldRedirect && (modalState == 'success' || modalState == 'error') && (_jsxs("div", { className: "text-center", style: { marginTop: '8rem' }, children: [_jsx(P, { children: props.redirectionDetails?.text }), _jsxs(P, { children: ["You can see the status of the upload", ' ', _jsx(Link, { target: '_blank', to: props.redirectionDetails?.link, children: "here." })] }), _jsx(P, { children: "Note: If the uploaded file is empty, its status wont be available. " })] }))] }));
    };
    const items = map(templateUrl || [], (item) => {
        return {
            key: item.name,
            label: (_jsxs("a", { href: getImageKitLink(item.url), type: 'link', target: "_blank", children: [item.name, ' '] })),
        };
    });
    return (_jsx("div", { children: props.modalAsPage ? (renderBody()) : (_jsxs(FxModal, { footer: null, open: props.isOpen, onCancel: toggle, title: props.title, children: [renderBody(), !props.hideModalFooter && (modalState === '' || (!isOpenViewErrors && modalState === 'error')) && (_jsxs("div", { children: [_jsx(FxDivider, {}), !props.showActionChildren &&
                            (props.isUpdateVehicle ? (_jsx(FxButton, { onClick: props.onDownloadVehicleReport, disabled: props.isReportDownloading, children: props.isReportDownloading
                                    ? `${t('downloading')}...`
                                    : t('downloadVehicleFile') })) : (_jsx(_Fragment, { children: isArray(templateUrl) ? (_jsx(FxDropdown, { trigger: ['click'], menu: { items: items }, children: _jsx(FxButton, { type: "primary", icon: _jsx(FxDownloadIcon, {}), children: "Download" }) })) : (_jsxs(_Fragment, { children: [_jsx(FxButton, { href: templateUrl ? templateUrl : props.sampleDownloadFileLocation, ...(templateUrl && { target: '_blank' }), download: true, type: 'link', children: t('downloadSampleFile') }), map(extraSampleFiles, (file, index) => {
                                            return (_jsx(FxButton, { href: file.url, ...(file.url && { target: '_blank' }), download: true, type: 'link', children: file.name }, index));
                                        })] })) }))), props.showActionChildren && props.actionChildren] }))] })) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchTemplateByFormType,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: state.login.data.access_token,
        login: get(state, 'login.data'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FxUploadModal);
