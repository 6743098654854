import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, forEach, get, keyBy, map, merge, omit, pickBy, sortBy, toArray, values as valuesLodash, } from 'lodash';
import { FxCol, FxRow, FxTable } from 'sharedV2';
import CalendarComponent from 'components/utils/calendar_component';
import DeductionSection from './BillSettlementDeductionComponent';
import { getCommonColumns, getConsignmentColumns } from './utils';
const BillSettlementFreightBillComponent = (props) => {
    const { formikProps, bills, loading, dateRange, onDateRangeChange, ledgers } = props;
    const { values, setFieldValue } = formikProps;
    const [freightBillData, setFreightBillData] = useState([]);
    const [expandedBillRowKeys, setExpandedBillRowKeys] = useState([]);
    const [expandedConsignmentRowKeys, setExpandedConsignmentRowKeys] = useState({});
    useEffect(() => {
        const { values } = formikProps;
        // Get debit notes
        const debitNotes = pickBy(get(values, 'paymentMappingRequests', {}), (mapping) => get(mapping, 'settlementType') === 'FREIGHT_INVOICE');
        const debitData = toArray(debitNotes);
        // Highlight: Get list of selected bill IDs
        const selectedBillIds = new Set(debitData.map((note) => note.billId));
        // Create maps
        const billsMap = keyBy(bills, 'id');
        const debitMap = keyBy(map(debitData, (note) => ({
            ...omit(note, 'billId'),
            id: note.billId,
        })), 'id');
        // Merge maps
        const mergedMap = merge({}, debitMap, billsMap);
        // Step 1: Convert mergedMap into an array of values
        const valuesArray = valuesLodash(mergedMap);
        // Step 2: Map over each item to adjust 'billAmount' and 'paymentAmount'
        const mappedItems = map(valuesArray, (item) => item.billAmount ? omit({ ...item, totalFreight: item.billAmount }, 'billAmount') : item);
        // Step 3: Sort the items, placing selected items at the top
        const finalData = sortBy(mappedItems, (item) => (selectedBillIds.has(item.id) ? 0 : 1));
        forEach(finalData, (item) => {
            // Convert existing settlementEntityRequests object to array
            if (item.settlementEntityRequests && !Array.isArray(item.settlementEntityRequests)) {
                item.settlementEntityRequests = Object.values(item.settlementEntityRequests);
            }
            // Convert consignmentBillDetailsList to settlementEntityRequests
            if (item.consignmentBillDetailsList) {
                const convertedEntities = map(item.consignmentBillDetailsList, (consignment) => {
                    return {
                        settlementEntityId: consignment.id,
                        total: consignment.total,
                        balanceAmount: consignment.balancePaymentAmount,
                        deductionMappings: [],
                        remarks: '',
                        receivedAdvance: consignment.receivedAdvanceAmount.amount,
                        receivedPayment: consignment.receivedPaymentAmount,
                        consignmentEntityDetailsDto: consignment.consignmentEntityDetailsDto,
                    };
                });
                if (!item.settlementEntityRequests) {
                    item.settlementEntityRequests = [];
                }
                item.settlementEntityRequests.push(...convertedEntities);
                delete item.consignmentBillDetailsList;
            }
            // Ensure unique settlementEntityId objects in settlementEntityRequests
            if (item.settlementEntityRequests) {
                const uniqueEntities = new Map();
                forEach(item.settlementEntityRequests, (entity) => {
                    if (!uniqueEntities.has(entity.settlementEntityId) ||
                        (entity.consignmentEntityDetailsDto &&
                            !uniqueEntities.get(entity.settlementEntityId).consignmentEntityDetailsDto)) {
                        uniqueEntities.set(entity.settlementEntityId, entity);
                    }
                });
                item.settlementEntityRequests = Array.from(uniqueEntities.values());
            }
        });
        setFreightBillData(finalData);
    }, [bills]);
    // Automatically set expanded rows for preselected rows
    useEffect(() => {
        const consignmentRowKeys = {};
        const preselectedBillIds = map(get(values, 'paymentMappingRequests', []), (r) => r.billId);
        setExpandedBillRowKeys(preselectedBillIds);
        preselectedBillIds.forEach((freightBillId) => {
            const consignmentMapping = get(values, `paymentMappingRequests.${freightBillId}.settlementEntityRequests`, {});
            consignmentRowKeys[freightBillId] = Object.keys(consignmentMapping).map((key) => parseInt(key, 10));
        });
        setExpandedConsignmentRowKeys(consignmentRowKeys);
    }, [values]);
    const handleFreightBillSelect = (selectedRowKeys, selectedRows) => {
        const currentMappings = cloneDeep(get(values, 'paymentMappingRequests', {}));
        // Create a map of selected bill IDs for quick lookup
        const selectedBillIds = new Set(selectedRowKeys);
        // Preserve existing mappings for other sections
        const otherMappings = pickBy(currentMappings, (mapping) => mapping.settlementType !== 'FREIGHT_INVOICE');
        // 1. Keep existing freight bill mappings that are still selected
        const existingFreightMappings = pickBy(currentMappings, (mapping, key) => mapping.settlementType === 'FREIGHT_INVOICE' && selectedBillIds.has(key));
        // 2. Add new freight bill entries for newly selected rows
        const newMappings = selectedRows
            .filter((row) => !existingFreightMappings[row.id])
            .reduce((acc, row) => {
            acc[row.id] = {
                billId: row.id,
                settlementType: 'FREIGHT_INVOICE',
                settlementEntityRequests: get(currentMappings, [row.id, 'settlementEntityRequests'], {}),
                tdsAmount: row.tdsAmount || get(currentMappings, [row.id, 'tdsAmount'], null),
            };
            return acc;
        }, {});
        // 3. Merge all mappings
        const updatedMappings = {
            ...otherMappings,
            ...existingFreightMappings,
            ...newMappings, // New freight entries
        };
        setFieldValue('paymentMappingRequests', updatedMappings);
        setExpandedBillRowKeys(selectedRowKeys);
    };
    // Handle consignment selection
    const handleConsignmentSelect = (freightBillId, selectedRowKeys, selectedRows) => {
        const currentMapping = get(values, `paymentMappingRequests.${freightBillId}`);
        if (!currentMapping)
            return;
        const currentSettlements = { ...get(currentMapping, 'settlementEntityRequests', {}) };
        Object.keys(currentSettlements).forEach((settlementId) => {
            if (!selectedRows.find((row) => row.id === settlementId)) {
                delete currentSettlements[settlementId];
            }
        });
        selectedRows.forEach((row) => {
            if (!currentSettlements[row.id]) {
                currentSettlements[row.id] = {
                    settlementEntityId: row.id,
                    deductionMappings: row.deductionMappings,
                    balanceAmount: row.balanceAmount,
                    remarks: row.remarks,
                };
            }
        });
        setFieldValue(`paymentMappingRequests.${freightBillId}.settlementEntityRequests`, currentSettlements);
        // Automatically expand consignment rows for this bill
        setExpandedConsignmentRowKeys((prevState) => ({
            ...prevState,
            [freightBillId]: selectedRowKeys,
        }));
    };
    const consignmentColumns = getConsignmentColumns('FREIGHT_BILL', formikProps);
    const columns = getCommonColumns('FREIGHT_BILL', formikProps);
    const expandedConsignmentRowRender = useCallback((record) => {
        const consignmentId = get(record, 'id');
        const freightBillId = get(record, 'freightBillId');
        if (!consignmentId || !freightBillId)
            return null;
        return (_jsx(DeductionSection, { consignmentId: consignmentId, freightBillId: freightBillId, ledgers: ledgers, formikProps: formikProps }));
    }, [ledgers, formikProps]);
    const expandedRowRender = useCallback((record) => {
        // Process settlementEntityRequests into table data
        const settlementEntities = map(get(record, 'settlementEntityRequests', []), (item) => {
            return {
                ...item,
                freightBillId: record.id,
                // Add missing fields needed for table rendering
                id: item.settlementEntityId,
                balancePaymentAmount: item.balanceAmount,
                receivedAdvanceAmount: item.receivedAdvance,
                receivedPaymentAmount: item.receivedPayment,
                consignmentEntityDetailsDto: item.consignmentEntityDetailsDto,
            };
        });
        const freightBillId = record.id;
        const currentMapping = get(values, `paymentMappingRequests.${freightBillId}`, {});
        // Get selected settlement entity IDs
        const selectedKeys = Object.keys(get(currentMapping, 'settlementEntityRequests', {})).map((key) => parseInt(key, 10));
        return (_jsx(FxTable, { columns: consignmentColumns, dataSource: settlementEntities, pagination: false, rowKey: (record) => record.settlementEntityId, size: "small", expandable: {
                expandedRowKeys: expandedConsignmentRowKeys[freightBillId] || [],
                onExpand: (expanded, consignmentRecord) => {
                    setExpandedConsignmentRowKeys((prevState) => ({
                        ...prevState,
                        [freightBillId]: expanded
                            ? [...(prevState[freightBillId] || []), consignmentRecord.settlementEntityId]
                            : (prevState[freightBillId] || []).filter((key) => key !== consignmentRecord.settlementEntityId),
                    }));
                },
                expandedRowRender: expandedConsignmentRowRender,
            }, rowSelection: {
                type: 'checkbox',
                selectedRowKeys: selectedKeys,
                onChange: (selectedRowKeys, selectedRows) => handleConsignmentSelect(freightBillId, selectedRowKeys, selectedRows),
                getCheckboxProps: (record) => {
                    return {
                        disabled: record.balanceAmount === 0, // Use correct balance field
                    };
                },
            } }));
    }, [consignmentColumns, expandedConsignmentRowRender, expandedConsignmentRowKeys, values]);
    return (_jsxs(_Fragment, { children: [_jsx(FxRow, { className: "mb-4", children: _jsx(FxCol, { span: 24, children: _jsx(CalendarComponent, { startDate: dateRange.startDate, endDate: dateRange.endDate, onDateSelected: onDateRangeChange }) }) }), _jsx(FxTable, { columns: columns, dataSource: freightBillData, loading: loading, rowKey: "id", pagination: false, scroll: { x: true }, size: "small", expandable: {
                    expandedRowKeys: expandedBillRowKeys,
                    onExpand: (expanded, record) => {
                        const keys = expanded
                            ? [...expandedBillRowKeys, record.id]
                            : expandedBillRowKeys.filter((key) => key !== record.id);
                        setExpandedBillRowKeys(keys);
                    },
                    expandedRowRender,
                }, rowSelection: {
                    type: 'checkbox',
                    selectedRowKeys: map(get(values, 'paymentMappingRequests', []), (r) => {
                        return r.billId;
                    }),
                    onChange: handleFreightBillSelect,
                    getCheckboxProps: (record) => {
                        return {
                            disabled: record.totalFreight ===
                                (+record.settledAmount >= 0 ? record.settledAmount : record.voucher.settledAmount), // Disable if billAmount equals settledAmount
                        };
                    },
                } })] }));
};
const mapStateToProps = (state) => ({
    ledgers: get(state, 'consignments.ledgers', []),
});
export default connect(mapStateToProps)(BillSettlementFreightBillComponent);
