import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Vendor = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4.21149 5.75V4.25H19.7885V5.75H4.21149ZM4.24999 19.75V13.75H3.13474V12.25L4.21149 7.25H19.7885L20.8652 12.25V13.75H19.75V19.75H18.25V13.75H13.75V19.75H4.24999ZM5.74999 18.25H12.25V13.75H5.74999V18.25ZM4.66524 12.25H19.3347L18.5712 8.75H5.42874L4.66524 12.25Z", fill: "currentColor" }) }));
};
const VendorIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Vendor, ...props });
};
export default VendorIcon;
