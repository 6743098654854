import axios from 'axios';
import { parseQueryParams, ROOT_NODE_API_URL } from 'constant';
export const FETCH_DEVICE_COMMANDS = 'FETCH_DEVICE_COMMANDS';
export const DELETE_DEVICE_COMMAND = 'DELETE_DEVICE_COMMAND';
export const FETCH_DEVICE_COMMAND_BY_ID = 'FETCH_DEVICE_COMMAND_BY_ID';
export const CREATE_DEVICE_COMMAND = 'CREATE_DEVICE_COMMAND';
export const UPDATE_DEVICE_COMMAND = 'UPDATE_DEVICE_COMMAND';
export function fetchDeviceCommands(params = {}) {
    const config = {
        params: parseQueryParams(params),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/deviceCommands/commands`, config);
    return {
        type: FETCH_DEVICE_COMMANDS,
        promise: request,
    };
}
export function createDeviceCommand(data) {
    const request = axios.post(`${ROOT_NODE_API_URL}napp/deviceCommands/command`, data);
    return {
        type: CREATE_DEVICE_COMMAND,
        promise: request,
    };
}
export function deleteDeviceCommand(id) {
    const request = axios.delete(`${ROOT_NODE_API_URL}napp/deviceCommands/command/${id}`);
    return {
        type: DELETE_DEVICE_COMMAND,
        promise: request,
    };
}
export function fetchDeviceCommandById(id) {
    const request = axios.get(`${ROOT_NODE_API_URL}napp/deviceCommands/command/${id}`);
    return {
        type: FETCH_DEVICE_COMMAND_BY_ID,
        promise: request,
    };
}
export function updateDeviceCommand(id, data) {
    const request = axios.put(`${ROOT_NODE_API_URL}napp/deviceCommands/command/${id}`, data);
    return {
        type: UPDATE_DEVICE_COMMAND,
        promise: request,
    };
}
