import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Dashcam = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4.54812 19.5C4.04299 19.5 3.61542 19.325 3.26542 18.975C2.91544 18.625 2.74045 18.1974 2.74045 17.6923V6.3077C2.74045 5.80257 2.91544 5.375 3.26542 5.025C3.61542 4.675 4.04299 4.5 4.54812 4.5H15.9327C16.4378 4.5 16.8654 4.675 17.2154 5.025C17.5654 5.375 17.7404 5.80257 17.7404 6.3077V10.8846L21.2596 7.36543V16.6345L17.7404 13.1153V17.6923C17.7404 18.1974 17.5654 18.625 17.2154 18.975C16.8654 19.325 16.4378 19.5 15.9327 19.5H4.54812ZM4.54812 18H15.9327C16.0224 18 16.0962 17.9711 16.1538 17.9134C16.2115 17.8557 16.2404 17.782 16.2404 17.6923V6.3077C16.2404 6.21795 16.2115 6.14423 16.1538 6.08652C16.0962 6.02882 16.0224 5.99998 15.9327 5.99998H4.54812C4.45837 5.99998 4.38465 6.02882 4.32695 6.08652C4.26925 6.14423 4.2404 6.21795 4.2404 6.3077V17.6923C4.2404 17.782 4.26925 17.8557 4.32695 17.9134C4.38465 17.9711 4.45837 18 4.54812 18Z", fill: "currentColor" }) }));
};
const DashcamIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Dashcam, ...props });
};
export default DashcamIcon;
