/**
 * Created by udbhav on 10/07/17.
 */

import { applyMiddleware, compose, createStore } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist';
import { asyncLocalStorage as localStorage } from 'redux-persist/storages';
import reducers from './reducers';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import thunk from 'redux-thunk';
import eventMiddleware from './middlewares/storeEventMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Creating a store with middle ware
//const createStoreWithMiddleware = applyMiddleware(thunk, reduxPackMiddleware)(createStore);
//const appStore = createStoreWithMiddleware(reducers);

// Adding compose enhancer to create a store withREDUX_DEVTOOLS_EXTENSION
// const appStore = createStore(
//     reducers,
//     /* preloadedState, */
//     composeEnhancers(applyMiddleware(thunk, reduxPackMiddleware), autoRehydrate()));

const config = { storage: localStorage, whitelist: ['login', 'local', 'admin'] };

export default function configureStore() {
    // use desired middlewares
    const middlewares = [thunk, reduxPackMiddleware, eventMiddleware];

    return new Promise((resolve, reject) => {
        try {
            const store = createStore(
                reducers,
                undefined,
                composeEnhancers(autoRehydrate(), applyMiddleware(...middlewares))
            );

            persistStore(store, config, () => resolve(store));
        } catch (e) {
            reject(e);
        }
    });
}
