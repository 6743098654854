import axios from 'axios';
import { ROOT_API_URL, parseQueryParams } from 'constant';
import { isViewTagsSummaryAllowedForUser } from 'components/dashboard/roles/permission_utils';
import { getMomentTime } from 'utils/date_utils';
import { isArray, join } from 'lodash';

export const CREATE_TAG = 'create_tag';
export const CREATE_BULK_TAG = 'create_bulk_tag';
export const UPDATE_TAG = 'update_tag';
export const FETCH_TAGS = 'fetch_tags';
export const FETCH_MULTIPLE_TAGS = 'fetch_multiple_tags';
export const FETCH_ACCOUNT_TAGS = 'fetch_account_tags';
export const FETCH_VENDOR_LIST = 'fetch_vendor_list';
export const DELETE_TAG = 'delete_tag';
export const ADD_TAG_TO_VEHICLE = 'add_tag_to_vehicle';
export const DELETE_TAG_FROM_VEHICLE = 'delete_tag_from_vehicle';
export const ADD_TAG_TO_ADDRESS_BOOK = 'add_tag_to_address_book';
export const DELETE_TAG_TO_ADDRESS_BOOK = 'add_tag_from_address_book';
export const VEHICLE_TAG_REPORT = 'VEHICLE_TAG_REPORT';
export const FETCH_TAGS_REPORT = 'FETCH_TAGS_REPORT';

export function createTag(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}tags`, data, config);
    return {
        type: CREATE_TAG,
        promise: request,
    };
}

export function createBulkTag(accesstoken, data) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}tags/bulk`, data, config);
    return {
        type: CREATE_BULK_TAG,
        promise: request,
    };
}

export function updateTag(accesstoken, data, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}tags/${id}`, data, config);
    return {
        type: UPDATE_TAG,
        promise: request,
    };
}

export function fetchTags(accesstoken, type = null, parentId = null, accountId = null) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewTagsSummaryAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_TAGS,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            type,
            parentId,
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}tags`, config);

    return {
        type: FETCH_TAGS,
        promise: request,
    };
}

export function fetchTagsByAccountId(accesstoken, accountId) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewTagsSummaryAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_TAGS,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            accountId,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}tags`, config);

    return {
        type: FETCH_ACCOUNT_TAGS,
        promise: request,
    };
}

export function fetchMultipleTags(accesstoken, ids) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    if (!isViewTagsSummaryAllowedForUser(scope, window.FLEETX_LOGGED_IN_USER)) {
        return {
            type: FETCH_TAGS,
            promise: Promise.resolve({}),
        };
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            ids,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}tags`, config);

    return {
        type: FETCH_MULTIPLE_TAGS,
        promise: request,
    };
}

export function deleteTag(accesstoken, id) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}tags/${id}`, config);
    return {
        type: DELETE_TAG,
        promise: request,
    };
}

export function addTagToVehicle(accesstoken, vehicleId, tagId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id: vehicleId,
            tagIds: tagId,
        }),
    };

    const request = axios.post(`${ROOT_API_URL}vehicles/tags`, {}, config);
    return {
        type: ADD_TAG_TO_VEHICLE,
        promise: request,
    };
}

export function deleteTagFromVehicle(accesstoken, vehicleId, tagId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id: vehicleId,
            tagIds: tagId,
        }),
    };

    const request = axios.delete(`${ROOT_API_URL}vehicles/tags`, config);
    return {
        type: DELETE_TAG_FROM_VEHICLE,
        promise: request,
    };
}

export function addTagToAddressBook(accesstoken, addressId, tagId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id: addressId,
            tagIds: tagId,
        }),
    };

    const request = axios.post(`${ROOT_API_URL}address_book/tags`, {}, config);
    return {
        type: ADD_TAG_TO_ADDRESS_BOOK,
        promise: request,
    };
}

export function deleteTagFromAddressBook(accesstoken, addressId, tagId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id: addressId,
            tagIds: tagId,
        }),
    };

    const request = axios.delete(`${ROOT_API_URL}address_book/tags`, config);
    return {
        type: DELETE_TAG_TO_ADDRESS_BOOK,
        promise: request,
    };
}

export function fetchVendorList(accesstoken, params) {
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: {
            type: params.type,
        },
    };
    const request = axios.get(`${ROOT_API_URL}vendors/type`, config);

    return {
        type: FETCH_VENDOR_LIST,
        promise: request,
    };
}

export function downloadVehicleTagReport(accesstoken, params, onDownloadProgress) {
    const { startDate, endDate, tagIds } = params || {};
    const config = {
        headers: {
            'Authorization': `Bearer ${accesstoken}`,
        },
        params: {
            from: getMomentTime(startDate).valueOf(),
            to: getMomentTime(endDate).valueOf(),
            tagIds: isArray(tagIds) ? join(tagIds, ',') : tagIds,
        },
        responseType: 'blob',
        onDownloadProgress,
    };
    const request = axios.get(`${ROOT_API_URL}vehicles/vehicle-tag/report`.replace('v1', 'v2'), config);

    return {
        type: VEHICLE_TAG_REPORT,
        promise: request,
    };
}

export function getTagReport() {
    const config = {
        responseType: 'blob',
    };
    const request = axios.get(`${ROOT_API_URL}tags/report`, config);

    return {
        type: FETCH_TAGS_REPORT,
        promise: request,
    };
}
