import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, includes, map, set, filter, find, dropRight, forEach, remove, unset } from 'lodash';
import { Field, FieldArray, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { fetchStores } from '../../../actions/dashboard/client_stores_actions';
import { getPageTitle } from '../../../../white_label_constants';
import { FxSelectField, FxSelectSingleDate, FxTextFieldStackedLabel } from '../../../shared';
import { Button, Col, Row } from 'reactstrap';
import { mappedClientStores, mappedOptionsFromArray, mappedTyreConfigurations } from '../../utils/mapping_utils';
import { fetchLedgers } from '../../../actions/dashboard/consignments/ledgersActions';
import { mappedTags } from '../../../utils/tag_utils';
import { fetchTags } from '../../../actions/dashboard/tags_action';
import { DATE_FORMAT_LOCAL_DATE_TIME, DATE_FORMAT_TIMESTAMP, getEndDate, getFormattedTimeStringForAPI, getMomentTime, } from '../../../utils/date_utils';
import { handleError } from '../../../constant';
import { issueRemoveTyre, tyreFilter, fetchTyreById } from '../../../actions/dashboard/tyres/v2/tyre_actions';
import { fetchVehicleById, fetchVehicleListMini } from '../../../actions/dashboard/vehicles_action';
import { mappedVehiclesMini } from '../../../utils/form_utils';
import VehicleTyreConfig from './vehicle_tyre_config';
import { fetchAttachedTyresToVehicle, fetchTyreAccountConfiguration } from '../../../actions/dashboard/tyres_action';
import BillNumberComponent from '../bill_number/bill_number_component';
import { isCreateIssuanceAllowedForUser, isCreateRemovalAllowedForUser, isManageTags } from '../roles/permission_utils';
import { withRoute } from '../customHocs/withRoute';
import { fetchWorkOrder } from 'actions/dashboard/parts_inventory/work_order_action';
const mappedTyres = (tyres) => {
    return map(tyres, (tyre) => {
        return {
            label: get(tyre, 'tyreNo'),
            value: get(tyre, 'id'),
        };
    });
};
const IssueRemoveTyreComponent = (props) => {
    const { accesstoken, ledgers, vehicleListMini, tyreFlow, workOrderList } = props;
    const bookType = tyreFlow === 'ISSUANCE' ? 'TYRE_ISSUANCE' : 'TYRE_REMOVAL';
    const filteredLedgers = filter(ledgers, (ledger) => get(ledger, 'group.groupName') === 'Direct Expenses');
    const canIssue = tyreFlow == 'ISSUANCE' && isCreateIssuanceAllowedForUser(get(props.login, 'scope'));
    const canRemove = tyreFlow == 'REMOVAL' && isCreateRemovalAllowedForUser(get(props.login, 'scope'));
    const [initialValues, setInitialValues] = useState({
        nature: tyreFlow == 'ISSUANCE' ? 'Tyre Issue' : 'Tyre Remove',
        branch: null,
        stationaryId: null,
        storeId: null,
        stationaryNumber: null,
        date: null,
        ledgerId: null,
        tyreRequestList: [],
        bookType,
    });
    const [stores, setStores] = useState([]);
    const [tyres, setTyres] = useState([]);
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [tyreConfigurationList, setTyreConfigurationList] = useState([]);
    const mappedVehicles = useMemo(() => {
        return mappedVehiclesMini(vehicleListMini);
    }, [vehicleListMini]);
    const storeOptions = useMemo(() => {
        return mappedClientStores(stores);
    }, [stores]);
    const tyreConfigurationListOptions = useMemo(() => {
        return mappedTyreConfigurations(tyreConfigurationList);
    }, [tyreConfigurationList]);
    const options = {
        workOrderOptions: useMemo(() => mappedOptionsFromArray(workOrderList, 'number'), [workOrderList]),
    };
    useEffect(() => {
        props.fetchStores(accesstoken, 1000).then((res) => {
            if (!res.error) {
                setStores(get(res, 'payload.data.content'));
            }
        });
        props.fetchLedgers(accesstoken);
        if (isManageTags(get(props.login, 'scope'))) {
            props.fetchTags(props.accesstoken, 'TYRE');
        }
        props.fetchVehicleListMini(accesstoken, true);
        if (tyreFlow === 'REMOVAL') {
            fetchTyres(null);
        }
        fetchTyreAccountConfiguration();
    }, []);
    const addEditSchema = useMemo(() => {
        let odometerReading = tyreFlow === 'ISSUANCE' ? 'attachOdometerReading' : 'detachOdometerReading';
        return Yup.object().shape({
            branch: Yup.string().required('Brand Required').nullable(),
            stationaryId: Yup.string().required('Book Name Required').nullable(),
            storeId: Yup.string().required('Store Required').nullable(),
            date: Yup.string().required(' Date Required').nullable(),
            stationaryNumber: Yup.string().required('Stationary Number Required').nullable(),
            ledgerId: Yup.string().required('Ledger Required').nullable(),
            tyreRequestList: Yup.array().of(Yup.object().shape({
                id: Yup.string().required('Tyre is Required.').nullable(),
                vehicleId: Yup.number().required('Vehicle is Required.').nullable(),
                [odometerReading]: Yup.number().required('Odometer is Required.').nullable(),
                eventTime: Yup.string().required('Date Required').nullable(),
                currentAmount: Yup.number().required('Current Amount is Required.').nullable(),
                positionId: Yup.string().required('Required.').nullable(),
                psi: Yup.number().required('PSI Amount is Required.').nullable(),
            })),
        });
    }, []);
    const fetchTyres = (store) => {
        const tyreAttched = tyreFlow === 'ISSUANCE' ? false : true;
        props.tyreFilter(accesstoken, null, store, null, tyreAttched, true).then((res) => {
            if (!res.error) {
                const filteredTyres = filter(get(res, 'payload.data'), (tyre) => includes(['NEW', 'REMOULD', 'CLAIMED'], get(tyre, 'lifeCycleState')));
                setTyres(filteredTyres);
            }
        });
    };
    const onSubmit = (values, { setSubmitting }) => {
        const data = JSON.parse(JSON.stringify(values));
        if (tyreFlow === 'ISSUANCE') {
            set(data, 'nature', 'TYRE_ISSUANCE');
        }
        else {
            set(data, 'nature', 'TYRE_REMOVAL');
        }
        if (get(data, 'date')) {
            set(data, 'date', getFormattedTimeStringForAPI(get(values, 'date'), DATE_FORMAT_LOCAL_DATE_TIME));
        }
        remove(get(data, 'tyreRequestList'), (tyre) => !get(tyre, 'vehicleId') || !get(tyre, 'id'));
        forEach(get(values, 'tyreRequestList'), (tyre, index) => {
            const { vehicleId, id, eventTime, tags } = tyre;
            if (id && vehicleId) {
                const tyreDetailsOfVehicle = get(vehicleDetails, `[${vehicleId}].tyreDetails`);
                const { tyreNo, positionId } = find(tyreDetailsOfVehicle, { id }) || {};
                set(get(data, 'tyreRequestList'), `[${index}].positionId`, positionId);
                set(get(data, 'tyreRequestList'), `[${index}].tyreNo`, tyreNo);
                if (eventTime) {
                    set(get(data, 'tyreRequestList'), `[${index}].eventTime`, getFormattedTimeStringForAPI(get(values, `tyreRequestList[${index}].eventTime`), DATE_FORMAT_LOCAL_DATE_TIME));
                }
            }
            if (tags && tags.length > 0) {
                let tagsList = map(tags, (tag) => {
                    return {
                        'id': tag,
                    };
                });
                set(get(data, 'tyreRequestList'), `[${index}].tags`, tagsList);
            }
        });
        if (data.branch) {
            set(data, 'branchId', data.branch);
            unset(data, 'branch');
        }
        if (canIssue || canRemove) {
            props
                .issueRemoveTyre(accesstoken, data)
                .then((res) => {
                setSubmitting(false);
                if (!res.error) {
                    props.navigate('/dashboard/tyres');
                }
                else {
                    setSubmitting(false);
                    throw new Error(handleError(res.payload.response));
                }
            })
                .catch((e) => {
                setSubmitting(false);
                alert(handleError(e));
            });
        }
    };
    const setNoOfRows = (tyreConfigObj) => {
        if (tyreConfigObj.code) {
            let rows = 0;
            let noOfStepney = 0;
            const rowsString = dropRight(tyreConfigObj.code.split(/_/)).join();
            map([...rowsString], (c) => {
                if (c === ',') {
                }
                else {
                    rows++;
                }
            });
            map(tyreConfigObj.code, (c) => {
                if (c === 'S') {
                    noOfStepney++;
                }
            });
            return {
                tyreConfigObj,
                showTyreConfig: true,
                noOfRows: rows + 1,
                rowsString: rowsString,
                noOfStepney,
                selectedConfiguration: { label: tyreConfigObj.name, value: tyreConfigObj.id },
            };
        }
    };
    const fetchVehicleById = async (vehicleId) => {
        let vehicleDetailsTemp = JSON.parse(JSON.stringify(get(vehicleDetails, vehicleId, {})));
        try {
            const resp = await props.fetchVehicleById(vehicleId, accesstoken);
            const tyreConfigObj = get(resp, 'payload.data.tyreConfiguration', '-');
            if (tyreConfigObj !== '-') {
                const config = setNoOfRows(tyreConfigObj);
                vehicleDetailsTemp = { ...config, fetchedByVehicleId: true };
            }
            else {
                set(vehicleDetailsTemp, `configfetchedByVehicleId`, false);
                set(vehicleDetailsTemp, `tyreConfigObj`, '');
                set(vehicleDetailsTemp, `showTyreConfig`, false);
            }
        }
        catch (e) { }
        return vehicleDetailsTemp;
    };
    const fetchAttachedTyresToVehicle = async (vehicleId) => {
        const vehicleDetailsTemp = JSON.parse(JSON.stringify(get(vehicleDetails, vehicleId, {})));
        set(vehicleDetailsTemp, `fetchingAttachedTyres`, true);
        set(vehicleDetailsTemp, `showTyreConfig`, true);
        try {
            const { payload: { data: tyreDetails }, } = await props.fetchAttachedTyresToVehicle(vehicleId, accesstoken);
            if (get(tyreDetails, 'tyreDetails', '')) {
                const existingTyrePositions = get(tyreDetails, 'tyreDetails', '').map((tyre) => tyre.positionId);
                set(vehicleDetailsTemp, `tyreDetails`, get(tyreDetails, 'tyreDetails', ''));
                set(vehicleDetailsTemp, `existingTyrePositions`, existingTyrePositions);
                set(vehicleDetailsTemp, `fetchingAttachedTyres`, false);
            }
            else {
                set(vehicleDetailsTemp, `showTyreConfig`, false);
            }
        }
        catch (error) {
            alert('Something went wrong while fetching attached tyres. Please try again later');
        }
        return vehicleDetailsTemp;
    };
    const handleVehicleSelelection = (vehicleId, setFieldValue, removeTyreId, index) => {
        if (!removeTyreId && tyreFlow === 'REMOVAL') {
            setFieldValue(`tyreRequestList[${index}].id`, '');
        }
        if (!vehicleId)
            return;
        if (vehicleDetails[vehicleId]) {
            if (removeTyreId) {
                const selectedVehicle = vehicleDetails[vehicleId];
                const selectedVehicleTyreDetails = get(selectedVehicle, 'tyreDetails');
                const selectedTyrePosition = find(selectedVehicleTyreDetails, (tyre) => {
                    return get(tyre, 'id') === removeTyreId;
                });
                const wheelPosition = get(selectedTyrePosition, 'positionId', '');
                setFieldValue(`tyreRequestList[${index}].vehicleId`, vehicleId);
                setFieldValue(`tyreRequestList[${index}].positionId`, wheelPosition);
            }
            return;
        }
        setVehicleDetails((prev) => {
            return {
                ...prev,
                [vehicleId]: {
                    ...prev[vehicleId],
                    fetchingAttachedTyres: true,
                },
            };
        });
        Promise.allSettled([fetchVehicleById(vehicleId), fetchAttachedTyresToVehicle(vehicleId)]).then((results) => {
            if (removeTyreId) {
                setFieldValue(`tyreRequestList[${index}].vehicleId`, vehicleId);
                const selectedVehicle = vehicleDetails[vehicleId];
                const selectedVehicleTyreDetails = get(selectedVehicle, 'tyreDetails');
                const selectedTyrePosition = find(selectedVehicleTyreDetails, (tyre) => {
                    return get(tyre, 'id') === removeTyreId;
                });
                const wheelPosition = get(selectedTyrePosition, 'positionId', '');
                setFieldValue(`tyreRequestList[${index}].vehicleId`, vehicleId);
                setFieldValue(`tyreRequestList[${index}].positionId`, wheelPosition);
            }
            setVehicleDetails((prev) => {
                return {
                    ...prev,
                    [vehicleId]: { ...results[0].value, ...results[1].value, fetchingAttachedTyres: false },
                };
            });
        });
    };
    const fetchVehicleInfoFromWorkOrder = async ({ vehicleId }) => {
        Promise.allSettled([fetchVehicleById(vehicleId), fetchAttachedTyresToVehicle(vehicleId)]).then((results) => {
            setVehicleDetails((prev) => {
                return {
                    ...prev,
                    [vehicleId]: { ...results[0].value, ...results[1].value, fetchingAttachedTyres: false },
                };
            });
        });
    };
    const handleTyreChange = async (vehicleId, tyre, setFieldValue, index) => {
        if (get(tyre, 'id') && index != null) {
            props.fetchTyreById(accesstoken, get(tyre, 'id')).then((res) => {
                if (!res.error) {
                    let tagList = get(res, 'payload.data.tyreDetails[0].tags', []);
                    tagList = map(tagList, (tag) => {
                        return get(tag, 'id');
                    });
                    setFieldValue(`tyreRequestList[${index}].tags`, tagList);
                }
            });
        }
        if (tyreFlow == 'ISSUANCE' && tyre && index != null) {
            setFieldValue(`tyreRequestList[${index}].psi`, get(tyre, 'psi'));
            setFieldValue(`tyreRequestList[${index}].currentAmount`, get(tyre, 'currentAmount'));
            setFieldValue(`tyreRequestList[${index}].tyreDepth`, get(tyre, 'treadDepth'));
        }
        if (tyreFlow === 'REMOVAL' && tyre) {
            const tyreId = get(tyre, 'id');
            const vehicleId = get(tyre, 'vehicleId');
            handleVehicleSelelection(vehicleId, setFieldValue, tyreId, index);
            return;
        }
        if (!vehicleId)
            return;
        const currentVehicleDetails = vehicleDetails[vehicleId];
        const tempCurrentVehicleDetails = JSON.parse(JSON.stringify(currentVehicleDetails));
        const tyreToRemove = filter(get(tempCurrentVehicleDetails, 'tyreDetails'), (tyre) => get(tyre, 'addedFromUI') == index);
        remove(get(tempCurrentVehicleDetails, 'tyreDetails'), (tyre) => get(tyre, 'addedFromUI') == index);
        remove(get(tempCurrentVehicleDetails, 'existingTyrePositions'), (position) => position === get(tyreToRemove, '[0].positionId'));
        setVehicleDetails((prev) => {
            return {
                ...prev,
                [vehicleId]: tempCurrentVehicleDetails,
            };
        });
    };
    const fetchTyreAccountConfiguration = async () => {
        try {
            const { payload: { data: resp }, } = await props.fetchTyreAccountConfiguration(props.accesstoken);
            setTyreConfigurationList(get(resp, 'configurations'));
        }
        catch (error) {
            handleError(error);
        }
    };
    const getWorkOrders = (issueDate) => {
        props.fetchWorkOrder(props.accesstoken, {
            fromDate: getMomentTime(issueDate).subtract(1, 'months').format(DATE_FORMAT_TIMESTAMP),
            toDate: getMomentTime(issueDate).add(1, 'months').format(DATE_FORMAT_TIMESTAMP),
            size: 500,
        });
    };
    const onWorkOrderChange = ({ vehicleId, formikProps }) => {
        const { setFieldValue, values } = formikProps;
        const { tyreRequestList } = values;
        forEach(tyreRequestList, (tyre, index) => {
            setFieldValue(`tyreRequestList[${index}].vehicleId`, vehicleId);
            setFieldValue(`tyreRequestList[${index}].id`, null);
        });
        if (vehicleId) {
            fetchVehicleInfoFromWorkOrder({ vehicleId });
        }
    };
    return (_jsx("div", { className: "card-group mt-4 mb-0", children: _jsx("div", { className: "card p-1", children: _jsxs("div", { className: "card-block", children: [_jsx(Helmet, { title: getPageTitle(`${props.tyreFlow === 'ISSUANCE' ? 'Issue' : 'Remove'} Tyre`) }), _jsxs("h4", { children: [props.tyreFlow === 'ISSUANCE' ? 'Issue' : 'Remove', " Tyre"] }), _jsx("hr", {}), _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validate: (value) => {
                            try {
                                validateYupSchema(value, addEditSchema, true, value);
                            }
                            catch (err) {
                                return yupToFormErrors(err); //for rendering validation errors
                            }
                            return {};
                        }, enableReinitialize: true, children: (formikProps) => {
                            const { values, setFieldValue, errors, isSubmitting } = formikProps;
                            const { tyreRequestList, workOrderId } = values;
                            const isAnyTyreAdded = !!(tyreRequestList && tyreRequestList.length);
                            return (_jsx(Form, { children: _jsxs("div", { className: "form-group", children: [_jsx(Row, { children: _jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "nature", type: "text", component: FxTextFieldStackedLabel, label: "Nature", disabled: true }) }) }), _jsx(BillNumberComponent, { formikProps: formikProps, isEdit: false, isReadyToFetchBillNumber: true }), _jsxs(Row, { children: [_jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "date", timePicker: true, label: tyreFlow == 'ISSUANCE' ? 'Issue Date' : 'Removal Date', component: FxSelectSingleDate, maxDate: getEndDate(), isRequired: true, 
                                                        // valueFormatter={(value) =>
                                                        //     getMomentTime(value).format(DATE_FORMAT_TIMESTAMP)
                                                        // }
                                                        handleOnDateSelected: (val) => {
                                                            getWorkOrders(val);
                                                        } }) }), _jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "storeId", options: storeOptions, component: FxSelectField, label: "Store", onSelected: (value) => {
                                                            if (tyreFlow === 'ISSUANCE') {
                                                                fetchTyres(value);
                                                            }
                                                        }, isRequired: true }) }), _jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "ledgerId", options: mappedOptionsFromArray(filteredLedgers, 'ledgerName'), component: FxSelectField, label: "Expense A/C", isRequired: true }) }), _jsx(Col, { md: 6, xs: 12, children: _jsx(Field, { name: "workOrderId", options: options.workOrderOptions, component: FxSelectField, label: "Work Orders", onChange: (val) => {
                                                            const currentWorkOrder = find(workOrderList, { id: val });
                                                            onWorkOrderChange({
                                                                vehicleId: get(currentWorkOrder, 'vehicleId'),
                                                                formikProps,
                                                            });
                                                        } }) })] }), _jsx(FieldArray, { name: "tyreRequestList", children: ({ push, remove }) => {
                                                return (_jsxs(_Fragment, { children: [isAnyTyreAdded &&
                                                            map(tyreRequestList, (tyre, index) => {
                                                                const currentvehiclId = tyreRequestList?.[index]?.vehicleId;
                                                                const selectedVehicle = vehicleDetails[currentvehiclId];
                                                                const selectedVehicleTyreDetails = get(selectedVehicle, 'tyreDetails');
                                                                const selectedTyrePosition = find(selectedVehicleTyreDetails, (tyre) => {
                                                                    return (get(tyre, 'id') ===
                                                                        get(tyreRequestList, `[${index}].id`));
                                                                });
                                                                const wheelPosition = get(selectedTyrePosition, 'positionId', '');
                                                                const alreadySelectedTyres = [];
                                                                forEach(tyreRequestList, (item) => {
                                                                    if (item.id) {
                                                                        alreadySelectedTyres.push(item.id);
                                                                    }
                                                                });
                                                                let tyresListToRender = [];
                                                                if (tyreFlow === 'ISSUANCE' || !selectedVehicle) {
                                                                    tyresListToRender = filter(tyres, (tyre) => {
                                                                        return !includes(alreadySelectedTyres, get(tyre, 'id'));
                                                                    });
                                                                }
                                                                else {
                                                                    tyresListToRender = filter(get(selectedVehicle, 'tyreDetails'), (tyre) => {
                                                                        return !includes(alreadySelectedTyres, get(tyre, 'id'));
                                                                    });
                                                                }
                                                                return (_jsx("div", { className: "card", children: _jsxs("div", { className: "card-body", children: [_jsxs("div", { className: "row align-items-center", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].vehicleId`, options: mappedVehicles, onSelected: (value) => {
                                                                                                handleVehicleSelelection(value, setFieldValue, null, index);
                                                                                            }, component: FxSelectField, label: "Select Vehicle", isRequired: true, disabled: !!workOrderId }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].id`, options: mappedTyres(tyresListToRender), component: FxSelectField, onSelected: (value) => {
                                                                                                const currentTyre = find(tyresListToRender, { id: value });
                                                                                                handleTyreChange(tyreRequestList[index]
                                                                                                    .vehicleId, currentTyre, setFieldValue, index);
                                                                                            }, label: "Select Tyre", isRequired: true }) }), _jsx("div", { className: "col-md-2 ml-4", children: _jsx("i", { title: "Remove", className: "fa fa-minus-circle cursor-pointer  text-primary", style: { fontSize: '22px' }, onClick: () => {
                                                                                                remove(index);
                                                                                            } }) })] }), tyreRequestList[index]?.vehicleId && (_jsx(VehicleTyreConfig, { tyreListIndex: index, vehicleDetails: vehicleDetails, vehicleId: tyreRequestList[index]
                                                                                    ?.vehicleId, selectedTyreId: get(values, `tyreRequestList[${index}].id`), tyres: tyres, setVehicleDetails: setVehicleDetails, tyreFlow: tyreFlow, setFieldValue: setFieldValue, alreadySelectedTyres: alreadySelectedTyres, onAddTyreCB: (val) => setFieldValue(`tyreRequestList[${index}].positionId`, val), tyreConfigurationListOptions: tyreConfigurationListOptions, tyreConfigurationList: tyreConfigurationList })), _jsxs("div", { className: "row align-items-center", children: [_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: tyreFlow === 'ISSUANCE'
                                                                                                ? `tyreRequestList[${index}].attachOdometerReading`
                                                                                                : `tyreRequestList[${index}].detachOdometerReading`, label: tyreFlow === 'ISSUANCE'
                                                                                                ? 'Start Odometer'
                                                                                                : 'Remove Odometer', component: FxTextFieldStackedLabel, type: "number", isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].eventTime`, timePicker: true, label: tyreFlow === 'ISSUANCE'
                                                                                                ? 'Attach Date'
                                                                                                : 'Detach Date', component: FxSelectSingleDate, maxDate: getEndDate(), isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].tyreDepth`, label: "Tyre Depth", component: FxTextFieldStackedLabel, type: "number" }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].currentAmount`, label: "Current Amount", component: FxTextFieldStackedLabel, type: "number", isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].psi`, label: "PSI", component: FxTextFieldStackedLabel, type: "number", isRequired: true }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].remarks`, label: "Remarks", component: FxTextFieldStackedLabel, type: "text" }) }), _jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].positionId`, label: "Wheel Position", value: wheelPosition, component: FxTextFieldStackedLabel, isRequired: true, type: "number", disabled: true }) }), isManageTags(get(props.login, 'scope')) && (_jsx("div", { className: "col-md-4", children: _jsx(Field, { name: `tyreRequestList[${index}].tags`, label: "Tags", options: mappedTags(props.tags), outerDivStyle: 'p-1', component: FxSelectField, multi: true }) }))] })] }) }, `tyreRequestList-${index}`));
                                                            }), _jsx("div", { className: "row justify-content-center", children: _jsxs(Button, { type: "button", color: "secondary", onClick: () => {
                                                                    let odometerReading = tyreFlow === 'ISSUANCE'
                                                                        ? 'attachOdometerReading'
                                                                        : 'detachOdometerReading';
                                                                    const currentWorkOrder = find(workOrderList, {
                                                                        id: workOrderId,
                                                                    });
                                                                    const index = get(tyreRequestList, 'length');
                                                                    push({
                                                                        id: null,
                                                                        [odometerReading]: '',
                                                                        eventTime: '',
                                                                        currentAmount: '',
                                                                        positionId: '',
                                                                        psi: '',
                                                                        vehicleId: get(currentWorkOrder, 'vehicleId', null),
                                                                    });
                                                                    if (get(currentWorkOrder, 'vehicleId')) {
                                                                        handleVehicleSelelection(get(currentWorkOrder, 'vehicleId'), setFieldValue, null, index);
                                                                    }
                                                                }, children: [tyreFlow === 'ISSUANCE' &&
                                                                        (isAnyTyreAdded ? 'Add More Tyre' : 'Add  Tyre'), tyreFlow == 'REMOVAL' &&
                                                                        (isAnyTyreAdded
                                                                            ? 'Remove More Tyres'
                                                                            : 'Remove Tyres')] }) })] }));
                                            } }), _jsx(Button, { disabled: isSubmitting || !(canIssue || canRemove), type: "submit", className: " float-right btn btn-primary mr-2", children: "Submit" })] }) }));
                        } })] }) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStores,
        fetchLedgers,
        fetchVehicleListMini,
        fetchVehicleById,
        fetchAttachedTyresToVehicle,
        tyreFilter,
        issueRemoveTyre,
        fetchTyreAccountConfiguration,
        fetchTags,
        fetchTyreById,
        fetchWorkOrder,
    }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        login: state.login.data,
        ledgers: state.consignments.ledgers,
        vehicleListMini: state.vehicles.vehicleListMini,
        tags: state.tags.tags,
        workOrderList: state.partsInventory.workOrderList,
    };
}
export default withRoute(connect(mapStateToProps, mapDispatchToProps)(IssueRemoveTyreComponent));
