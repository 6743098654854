import { jsx as _jsx } from "react/jsx-runtime";
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
const AddEditAddressComponentWrapper = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-dispatch-addressbook-add-edit" */ 'components/dashboard/route_dispatch/addressbook/AddressBookComponentWrapper'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddressBookComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-route-dispatch-addressbook" */ 'components/dashboard/route_dispatch/addressbook/address_book_component'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const routeAddressDispatchRoutes = [
    {
        index: true,
        lazy: AddressBookComponent,
    },
    {
        path: 'add',
        lazy: AddEditAddressComponentWrapper,
    },
    {
        path: 'edit/:id',
        lazy: AddEditAddressComponentWrapper,
    },
];
export default routeAddressDispatchRoutes;
