import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Alarms = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M3.42457 20.5004C3.25507 20.5004 3.10285 20.4589 2.9679 20.3761C2.83295 20.2932 2.72799 20.184 2.65302 20.0484C2.57504 19.9138 2.53215 19.768 2.52435 19.6109C2.51655 19.4539 2.55892 19.2984 2.65147 19.1443L11.2122 4.35652C11.3048 4.20249 11.4206 4.08861 11.5597 4.0149C11.6988 3.94116 11.8453 3.9043 11.9991 3.9043C12.153 3.9043 12.2994 3.94116 12.4385 4.0149C12.5776 4.08861 12.6935 4.20249 12.786 4.35652L21.3468 19.1443C21.4393 19.2984 21.4817 19.4539 21.4739 19.6109C21.4661 19.768 21.4232 19.9138 21.3452 20.0484C21.2703 20.184 21.1653 20.2932 21.0303 20.3761C20.8954 20.4589 20.7432 20.5004 20.5737 20.5004H3.42457ZM4.44912 19.0004H19.5491L11.9991 6.0004L4.44912 19.0004ZM11.9991 17.8081C12.228 17.8081 12.4198 17.7307 12.5746 17.5759C12.7294 17.4211 12.8068 17.2292 12.8068 17.0004C12.8068 16.7715 12.7294 16.5797 12.5746 16.4249C12.4198 16.2701 12.228 16.1927 11.9991 16.1927C11.7703 16.1927 11.5784 16.2701 11.4236 16.4249C11.2688 16.5797 11.1914 16.7715 11.1914 17.0004C11.1914 17.2292 11.2688 17.4211 11.4236 17.5759C11.5784 17.7307 11.7703 17.8081 11.9991 17.8081ZM11.9994 15.1927C12.212 15.1927 12.3901 15.1208 12.5337 14.9771C12.6773 14.8333 12.7491 14.6552 12.7491 14.4427V10.9427C12.7491 10.7302 12.6772 10.5521 12.5334 10.4083C12.3896 10.2646 12.2114 10.1927 11.9988 10.1927C11.7862 10.1927 11.6081 10.2646 11.4645 10.4083C11.3209 10.5521 11.2491 10.7302 11.2491 10.9427V14.4427C11.2491 14.6552 11.321 14.8333 11.4648 14.9771C11.6087 15.1208 11.7869 15.1927 11.9994 15.1927Z", fill: "currentColor" }) }));
};
const AlarmsIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Alarms, ...props });
};
export default AlarmsIcon;
