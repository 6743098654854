import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Metabase = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 -960 960 960", width: "24px", fill: "currentColor", children: _jsx("path", { d: "M246.15-246.15h210.77v-150.77H246.15v150.77Zm0-196.93h210.77v-270.77H246.15v270.77Zm256.93 196.93h210.77v-270.77H503.08v270.77Zm0-316.93h210.77v-150.77H503.08v150.77ZM212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v83.08h70.77v59.99H820V-510h70.77v60H820v94.62h70.77v59.99H820v83.08Q820-182 799-161q-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-535.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM200-760v560-560Z" }) }));
};
const MetabaseIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Metabase, ...props });
};
export default MetabaseIcon;
