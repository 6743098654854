import { getScopesArr } from './permission_utils';
import { getFlagValueFromAccountConfig } from 'utils/account_utils';
import { isWithinScope } from 'utils/permissionUtils/utils';
export function isViewProcurementIndentsAllowedForUser(scopes) {
    return isWithinScope('VIEW_PROCUREMENT_INDENT', getScopesArr(scopes));
}
export function isCreateProcurementIndentsAllowedForUser(scopes) {
    return isWithinScope('CREATE_PROCUREMENT_INDENT', getScopesArr(scopes));
}
export function isUpdateProcurementIndentsAllowedForUser(scopes) {
    return isWithinScope('UPDATE_PROCUREMENT_INDENT', getScopesArr(scopes));
}
export function isDeleteProcurementIndentsAllowedForUser(scopes) {
    return isWithinScope('DELETE_PROCUREMENT_INDENT', getScopesArr(scopes));
}
export function isViewProcurementChartsAllowedForUser(scopes) {
    return isWithinScope('VIEW_PROCUREMENT_CHART', getScopesArr(scopes));
}
export function isCreateProcurementChartsAllowedForUser(scopes) {
    return isWithinScope('CREATE_PROCUREMENT_CHART', getScopesArr(scopes));
}
export function isUpdateProcurementChartsAllowedForUser(scopes) {
    return isWithinScope('UPDATE_PROCUREMENT_CHART', getScopesArr(scopes));
}
export function isDeleteProcurementChartsAllowedForUser(scopes) {
    return isWithinScope('DELETE_PROCUREMENT_CHART', getScopesArr(scopes));
}
export function isViewProcurementQuotationsAllowedForUser(scopes) {
    return isWithinScope('VIEW_QUOTATION', getScopesArr(scopes));
}
export function isCreateProcurementQuotationsAllowedForUser(scopes) {
    return isWithinScope('CREATE_QUOTATION', getScopesArr(scopes));
}
export function isUpdateProcurementQuotationsAllowedForUser(scopes) {
    return isWithinScope('UPDATE_QUOTATION', getScopesArr(scopes));
}
export function isDeleteProcurementQuotationsAllowedForUser(scopes) {
    return isWithinScope('DELETE_QUOTATION', getScopesArr(scopes));
}
export function isViewProcurementPOAllowedForUser(scopes) {
    return isWithinScope('VIEW_PROCUREMENT_PURCHASE_ORDER', getScopesArr(scopes));
}
export function isCreateProcurementPOAllowedForUser(scopes) {
    return isWithinScope('CREATE_PROCUREMENT_PURCHASE_ORDER', getScopesArr(scopes));
}
export function isUpdateProcurementPOAllowedForUser(scopes) {
    return isWithinScope('UPDATE_PROCUREMENT_PURCHASE_ORDER', getScopesArr(scopes));
}
export function isDeleteProcurementPOAllowedForUser(scopes) {
    return isWithinScope('DELETE_PROCUREMENT_PURCHASE_ORDER', getScopesArr(scopes));
}
export function showConsignmentBasedLorryArrival() {
    return getFlagValueFromAccountConfig('consignmentBasedLorryArrival');
}
export function showMonthWiseSalary() {
    return getFlagValueFromAccountConfig('isMonthWiseSalaryEnabled');
}
export function isEmiManagementVisible(scopes) {
    return isWithinScope('VIEW_VEHICLE_EMI', getScopesArr(scopes));
}
export function isAddEmiManagementVisible(scopes) {
    return isWithinScope('CREATE_VEHICLE_EMI', getScopesArr(scopes));
}
export function isUpdateEmiManagementVisible(scopes) {
    return isWithinScope('UPDATE_VEHICLE_EMI', getScopesArr(scopes));
}
export function isDeleteEmiManagementVisible(scopes) {
    return isWithinScope('DELETE_VEHICLE_EMI', getScopesArr(scopes));
}
export function isOneQuantityInCnMaterial() {
    return getFlagValueFromAccountConfig('oneQuantityInCnMaterial');
}
