import { handle } from 'redux-pack';
import { CREATE_MATERIAL, UPDATE_MATERIAL, DELETE_MATERIAL, FETCH_MATERIALS, FETCH_MATERIAL, } from 'actions/dashboard/rateCharts/materialsActions';
import { reduce } from 'lodash';
const initialState = {
    isLoading: false,
    error: null,
    materialList: [],
    isMaterialListLoading: false,
    isMaterialLoading: false,
    material: {},
    materialsMap: {},
};
export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_MATERIAL:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case UPDATE_MATERIAL:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case DELETE_MATERIAL:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
            });
        case FETCH_MATERIALS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isMaterialListLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isMaterialListLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({
                    ...prevState,
                    materialList: payload.data,
                    materialsMap: reduce(payload.data, (acc, v) => {
                        acc[v.id] = v;
                        return acc;
                    }, {}),
                }),
            });
        case FETCH_MATERIAL:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    isMaterialLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false, isMaterialLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, material: payload.data }),
            });
        default:
            return state;
    }
}
