import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { FxButton, FxCol, FxRow, FxSelectField, FxSelectSingleDateV2, FxTextAreaStackedLabelV2, FxTextFieldStackedLabelV2, } from 'sharedV2/index';
import BillNumberComponentV2 from 'components/dashboard/bill_number/bill_number_component_v2';
import { PAYMENT_NATURES } from './account_form_utils';
import LedgerDetailsComponentV2 from 'components/dashboard/ledger_details/ledger_details_component_v2';
import { mappedBillingPartyBranches } from 'components/utils/mapping_utils';
import { ScrollToFieldError } from 'utils/form_errors';
const OnAccountFormFields = (props) => {
    const { accesstoken, options, formikProps, editId, navigate, onDelete, accountConfigData, customers } = props;
    const { t } = useTranslation();
    const { values, isSubmitting, setFieldValue } = formikProps;
    const { paymentNature, creditAccountId } = values;
    const [branchesForCreditAccount, setBranchesForCreditAccount] = React.useState([]);
    const isCreditBranchLedger = find(accountConfigData, { propertyType: 'CLIENT_BRANCH_LEDGER' });
    const showCreditAccountBranch = isCreditBranchLedger && paymentNature === PAYMENT_NATURES.ON_ACCOUNT_RECEIPT;
    const handlePaymentNatureChange = (value) => {
        formikProps.setFieldValue('bookType', value);
    };
    React.useEffect(() => {
        if (showCreditAccountBranch) {
            const selectedCreditAccount = find(customers, { id: creditAccountId });
            const branches = get(selectedCreditAccount, 'branchDtos', []);
            setBranchesForCreditAccount(branches);
        }
    }, [creditAccountId, customers]);
    const branchOptions = React.useMemo(() => {
        return mappedBillingPartyBranches(branchesForCreditAccount);
    }, [branchesForCreditAccount]);
    const ledgerDetailsFields = [
        {
            name: 'debitLedger.ledgerId',
            disabled: !!editId,
            label: paymentNature === PAYMENT_NATURES.ON_ACCOUNT_RECEIPT ? 'Debit Ledger' : 'Credit Ledger',
            shouldShowAmountField: true,
            colSize: 8,
            isRequired: true,
            amountFieldInfo: {
                label: 'Amount',
                name: 'debitLedger.amount',
                disabled: true,
                type: 'number',
                isAmountFieldRequired: true,
            },
        },
    ];
    return (_jsxs(Form, { children: [_jsx(ScrollToFieldError, {}), _jsxs(FxRow, { gutter: 16, children: [_jsx(FxCol, { span: 8, children: _jsx(Field, { name: `paymentNature`, component: FxSelectField, options: options.paymentNautreOptions, label: "Payment Nature", isRequired: true, clearable: true, disabled: !!editId, onChange: handlePaymentNatureChange }) }), _jsx(BillNumberComponentV2, { formikProps: formikProps, isEdit: !!editId, isReadyToFetchBillNumber: true, fieldDisabled: { 'branch': !!editId, 'bookName': !!editId, 'stationaryNumber': !!editId }, fieldLabel: { 'stationaryNumber': 'Voucher No.' }, onStationaryNumberPopulate: (stationaryNumber) => {
                            formikProps.setFieldValue('adviceNumber', stationaryNumber);
                        }, removeDivWrapper: true }), _jsx(FxCol, { span: 8, children: _jsx(Field, { name: `adviceNumber`, component: FxTextFieldStackedLabelV2, label: "Advice Number" }) }), _jsx(FxCol, { span: 8, children: _jsx(Field, { name: `voucherDate`, timePicker: true, component: FxSelectSingleDateV2, label: "Voucher Date", isRequired: true, disabled: !!editId }) }), _jsx(FxCol, { span: 8, children: _jsx(Field, { name: `adviceAmount`, component: FxTextFieldStackedLabelV2, type: "number", label: "Advice Amount", onChange: (val) => {
                                setFieldValue('debitLedger.amount', val);
                            }, isRequired: true }) }), _jsx(FxCol, { span: 8, children: _jsx(Field, { name: `narration`, component: FxTextAreaStackedLabelV2, label: "Narration" }) }), _jsx(FxCol, { span: 8, children: _jsx(Field, { name: `creditAccountId`, component: FxSelectField, options: paymentNature === PAYMENT_NATURES.ON_ACCOUNT_PAYMENT
                                ? options.transporters
                                : options.customers, label: paymentNature === PAYMENT_NATURES.ON_ACCOUNT_PAYMENT ? 'Debit Account' : 'Credit Account', clearable: true, isRequired: true }) }), showCreditAccountBranch && (_jsx(FxCol, { span: 8, children: _jsx(Field, { name: `creditAccountBranchId`, component: FxSelectField, options: branchOptions, label: 'Credit Account Branch' }) })), paymentNature === PAYMENT_NATURES.ON_ACCOUNT_PAYMENT && (_jsx(FxCol, { span: 8, children: _jsx(Field, { name: `onAccountVehicleId`, component: FxSelectField, options: options.vehicles, label: 'Vehicle', clearable: true }) }))] }), _jsx(LedgerDetailsComponentV2, { ledgerDetailsFields: ledgerDetailsFields, isNewRow: true }), _jsxs("div", { className: "d-flex justify-content-end col-gap-1", children: [!!editId && (_jsx(FxButton, { size: "large", onClick: () => {
                            onDelete(editId);
                        }, danger: true, type: "primary", children: t('delete') })), _jsx(FxButton, { size: "large", onClick: () => {
                            navigate(-1);
                        }, children: t('cancel') }), _jsx(FxButton, { loading: isSubmitting, size: "large", type: "primary", htmlType: "submit", children: t('submit') })] })] }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(OnAccountFormFields);
