import { get, includes } from 'lodash';
import { FLEETX_ACCOUNTS, getFlagValueFromAccountConfig } from 'utils/account_utils';
export function showCustomFieldsForAccountInAllVehicleSummaryReport() {
    return includes([FLEETX_ACCOUNTS.GRASIM_INBOUND], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null));
}
export function showConstructionMachineryData() {
    return getFlagValueFromAccountConfig('showConstructionMachineryDetails');
}
export function isShowNewTimeOnSitePageForAccount() {
    return (getFlagValueFromAccountConfig('showNewTimeOnSitePage') ||
        get(window.FLEETX_LOGGED_IN_USER, 'accountId', null) > 6000);
}
export function showStoppagesCountBreakdownInVehicleSummaryReport() {
    return getFlagValueFromAccountConfig('showStoppagesCountBreakdownInVehicleSummaryReport');
}
export function showRemovedVehiclesByDefaultOnVehicleListPage() {
    return !includes([7092], get(window.FLEETX_LOGGED_IN_USER, 'accountId', null));
}
export function showAllVehiclesLocationReportForAccount() {
    return getFlagValueFromAccountConfig('allVehiclesLocationReport');
}
export const isDrumSensorReportVisible = (accountId) => {
    return includes([14008, FLEETX_ACCOUNTS.RMC], accountId);
};
export const excludeVehicleStatusValue = () => {
    return getFlagValueFromAccountConfig('excludeVehicleStatus');
};
export const showVehicleV2page = () => {
    return getFlagValueFromAccountConfig('showVehicleV2page');
};
export const showMarketVehicleAutofillButton = () => {
    return getFlagValueFromAccountConfig('showMarketVehicleAutofillButton');
};
