import { SET_CURRENT_THEME } from 'actions/dashboard/local_action';
import { createChangeEmitter } from 'change-emitter';
import { includes } from 'lodash';
export const storeEventEmitter = createChangeEmitter();
const eventMiddleware = (storeAPI) => (next) => (action) => {
    const result = next(action); // Process the action
    if (includes([SET_CURRENT_THEME], action.type)) {
        //  If you want to listen for changes in the Redux store in a separate React tree (outside the main React render tree like google map controls)
        storeEventEmitter.emit(action.type, action.payload);
    }
    return result;
};
export default eventMiddleware;
