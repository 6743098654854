// tslint:disable:variable-name
import axios from 'axios';
import { parseQueryParams, ROOT_NODE_API_URL, ROOT_API_URL, REPORT_CONFIG } from 'constant';
import storage from '../../utils/storage_utils';
import { get } from 'lodash';
export const FETCH_ACCOUNT_CONFIG = 'FETCH_ACCOUNT_CONFIG';
export const FETCH_USER_PERSONALISATION = 'FETCH_USER_PERSONALISATION';
export const SAVE_ACCOUNT_CONFIG = 'SAVE_ACCOUNT_CONFIG';
export const SAVE_USER_PERSONALISATION = 'SAVE_USER_PERSONALISATION';
export const FETCH_TABLE_CONFIG = 'FETCH_TABLE_CONFIG';
export const SAVE_TABLE_CONFIG = 'SAVE_TABLE_CONFIG';
export const CHECK_APP_FLEETX_IO_GRANT = 'check_app_fleetx_io_grant';
export const FETCH_ACCOUNT_CONFIG_LIST = 'FETCH_ACCOUNT_CONFIG_LIST';
export const FETCH_ACCOUNT_CONFIG_PROPERTY = 'FETCH_ACCOUNT_CONFIG_PROPERTY';
export const SAVE_ACCOUNT_CONFIG_PROPERTY = 'SAVE_ACCOUNT_CONFIG_PROPERTY';
export const DELETE_ACCOUNT_CONFIG_PROPERTY = 'DELETE_ACCOUNT_CONFIG_PROPERTY';
export const UPDATE_ACCOUNT_CONFIG_PROPERTY = 'UPDATE_ACCOUNT_CONFIG_PROPERTY';
export const CREATE_ACCOUNT_CONFIG_PROPERTY = 'CREATE_ACCOUNT_CONFIG_PROPERTY';
export const FETCH_APP_CONFIG = 'FETCH_APP_CONFIG';
export const SET_APP_CONFIG = 'FETCH_APP_CONFIG';
export const FETCH_REPORT_CONFIG = 'FETCH_REPORT_CONFIG';
export const SAVE_REPORT_CONFIG = 'SAVE_REPORT_CONFIG';
export const FETCH_PROPERTY_NAME_LIST = 'FETCH_PROPERTY_NAME_LIST';
export const FETCH_ACCOUNT_CONFIG_AUDIT_HISTORY = 'FETCH_ACCOUNT_CONFIG_AUDIT_HISTORY';
export const FETCH_ACCOUNT_CONFIG_SHARE = 'FETCH_ACCOUNT_CONFIG_SHARE';
export function checkAppFirewallGrant() {
    const request = axios.get(`https://app.fleetx.io/napp/config/check-app-grant`);
    return {
        type: CHECK_APP_FLEETX_IO_GRANT,
        promise: request,
    };
}
export function fetchAccountConfig(accountId) {
    const config = {
        params: parseQueryParams({ accountId }),
    };
    let request = axios.get(`${ROOT_NODE_API_URL}napp/config/fetchAccountConfig`, config);
    return {
        type: FETCH_ACCOUNT_CONFIG,
        promise: request,
    };
}
export function fetchAccountConfigShare(accountId) {
    const config = {
        headers: { 'Authorization': `Bearer dG9rZW5CeVNhdGlzaEt1bWFyOmZsZWV0eC5pbw==` },
        params: parseQueryParams({ accountId }),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/config/fetchAccountConfig/share`, config);
    return {
        type: FETCH_ACCOUNT_CONFIG_SHARE,
        promise: request,
    };
}
export function fetchAccountConfigAuditHistory(accountId) {
    const config = {
        params: parseQueryParams({ accountId }),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/config/fetchAccountConfig/audit`, config);
    return {
        type: FETCH_ACCOUNT_CONFIG_AUDIT_HISTORY,
        promise: request,
    };
}
export function fetchUserPersonalisation(accesstoken, userId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ userId }),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/config/fetchUserPersonalisation`, config);
    return {
        type: FETCH_USER_PERSONALISATION,
        promise: request,
    };
}
export function saveAccountConfig(accountId, account_config, widget_config) {
    const config = {};
    const switchedUserInfo = {
        email: get(window.SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalEmailId'),
    };
    const body = { accountId, account_config, widget_config, switchedUserInfo };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/config/saveAccountConfig`, body, config);
    return {
        type: SAVE_ACCOUNT_CONFIG,
        promise: request,
    };
}
// tslint:disable-next-line:variable-name
export function saveUserPersonalisation(accesstoken, userId, accountId, user_personalisation) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const body = { userId, accountId, user_personalisation };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/config/saveUserPersonalisation`, body, config);
    return {
        type: SAVE_USER_PERSONALISATION,
        promise: request,
    };
}
export function fetchTableConfig(accesstoken, accountId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ accountId }),
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/config/fetchTableConfig`, config);
    return {
        type: FETCH_TABLE_CONFIG,
        promise: request,
    };
}
export function saveTableConfig(accesstoken, accountId, table_config) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const body = { accountId, table_config };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/config/saveTableConfig`, body, config);
    return {
        type: SAVE_TABLE_CONFIG,
        promise: request,
    };
}
export function fetchAccountConfigList(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}accounts/config/`, config);
    return {
        type: FETCH_ACCOUNT_CONFIG_LIST,
        promise: request,
    };
}
export function fetchPropertyNameList(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}accounts/config/property-type`, config);
    return {
        type: FETCH_PROPERTY_NAME_LIST,
        promise: request,
    };
}
export function fetchAccountConfigProperty(accesstoken, id) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_API_URL}accounts/config/property/${id}`, config);
    return {
        type: FETCH_ACCOUNT_CONFIG_PROPERTY,
        promise: request,
    };
}
export function saveAccountConfigProperty(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}accounts/config/property/bulk`, data, config);
    return {
        type: SAVE_ACCOUNT_CONFIG_PROPERTY,
        promise: request,
    };
}
export function deleteAccountConfigProperty(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    // following is a work around as Axios does not use data from config when making DELETE request in v0.20
    const request = axios.request({
        ...config,
        method: 'delete',
        url: `${ROOT_API_URL}accounts/config/property`,
        data,
    });
    return {
        type: DELETE_ACCOUNT_CONFIG_PROPERTY,
        promise: request,
    };
}
export function updateAccountConfigProperty(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.put(`${ROOT_API_URL}accounts/config/property`, data, config);
    return {
        type: UPDATE_ACCOUNT_CONFIG_PROPERTY,
        promise: request,
    };
}
export function createAccountConfigProperty(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}accounts/config/property`, data, config);
    return {
        type: CREATE_ACCOUNT_CONFIG_PROPERTY,
        promise: request,
    };
}
export function fetchAppConfig(accesstoken) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.get(`${ROOT_NODE_API_URL}napp/config/fetchAppConfig`, config);
    return {
        type: FETCH_APP_CONFIG,
        promise: request,
    };
}
export function setAppConfig(accesstoken, data) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_NODE_API_URL}napp/config/setAppConfig`, data, config);
    return {
        type: SET_APP_CONFIG,
        promise: request,
    };
}
export function saveReportConfig(accesstoken, userId, report_config) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
    };
    const body = { userId, report_config };
    storage.setItem(REPORT_CONFIG, JSON.stringify(report_config));
    let request = axios.post(`${ROOT_NODE_API_URL}napp/config/saveReportConfig`, body, config);
    return {
        type: SAVE_REPORT_CONFIG,
        promise: request,
    };
}
export function fetchReportConfig(accesstoken, userId) {
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        params: parseQueryParams({ userId }),
    };
    let request = axios.get(`${ROOT_NODE_API_URL}napp/config/fetchReportConfig`, config);
    return {
        type: FETCH_REPORT_CONFIG,
        promise: request,
    };
}
