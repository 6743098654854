import axios from 'axios';
import { ROOT_API_URL, parseQueryParams } from '../../../constant';
import { isViewCustomerAllowedForUser } from '../../../components/dashboard/roles/permission_utils';
import { get } from 'lodash';
import { isRoleEPODCustomer } from 'utils/permissionUtils/erpPermissions';

export const CREATE_CUSTOMER = 'create_customer';
export const UPDATE_CUSTOMER = 'update_customer';
export const DELETE_CUSTOMER = 'delete_customer';
export const FETCH_CUSTOMERS = 'fetch_customers';
export const FETCH_CUSTOMER_SUMMARY = 'fetch_customer_summary';
export const FETCH_CUSTOMER_PARTY = 'fetch_customer_party';
export const UPDATE_CUSTOMER_PARTY = 'update_customer_party';
export const ARCHIVE_UNARCHIVE_CUSTOMER = 'archive_unarchive_customer';
export const FETCH_CUSTOMER_REPORT = 'fetch_customer_report';
export const FETCH_CUSTOMER_AUTOCOMPLETE = 'fetch_customer_autocomplete';

const CancelToken = axios.CancelToken;
export function createCustomer(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.post(`${ROOT_API_URL}customers/`, values, config);
    return {
        type: CREATE_CUSTOMER,
        promise: request,
    };
}

export function updateCustomer(accesstoken, values) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}customers/`, values, config);

    return {
        type: UPDATE_CUSTOMER,
        promise: request,
    };
}

export function deleteCustomer(accesstoken, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.delete(`${ROOT_API_URL}customers/${customerId}`, config);

    return {
        type: DELETE_CUSTOMER,
        promise: request,
    };
}

export function fetchCustomers(accesstoken, accountId, enabled, fetchAll = false, moreParms = {}) {
    const { scope } = window.FLEETX_LOGGED_IN_DATA || {};
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetchCustomers';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    if (!isViewCustomerAllowedForUser(scope)) {
        return {
            type: FETCH_CUSTOMERS,
            promise: Promise.resolve({}),
        };
    }
    const { userId, ids } = moreParms;
    const config = {
        headers: { 'Authorization': `Bearer ${accesstoken}` },
        cancelToken: new CancelToken(function executor(c) {
            window[cancelCallbackKey] = c;
        }),
        params: parseQueryParams({
            accountId,
            enabled,
            fetchAll,
            userCustomerId: isRoleEPODCustomer() ? get(window.FLEETX_LOGGED_IN_USER, 'id', null) : null,
            ids,
        }),
    };
    const size = 50000;
    const request = axios.get(`${ROOT_API_URL}customers/?size=${size}`, config);

    return {
        type: FETCH_CUSTOMERS,
        promise: request,
    };
}

export function fetchCustomerAutocomplete() {
    const cancelCallbackKey = 'CANCEL_CALLBACK_FUNCTION_FOR_fetchCustomers_autocomplete';
    if (window[cancelCallbackKey]) {
        window[cancelCallbackKey]();
    }
    const config = {};
    const request = axios.get(`${ROOT_API_URL}customers/autocomplete`, config);

    return {
        type: FETCH_CUSTOMER_AUTOCOMPLETE,
        promise: request,
    };
}

export function fetchCustomerSummary(accesstoken, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}customers/${customerId}`, config);

    return {
        type: FETCH_CUSTOMER_SUMMARY,
        promise: request,
    };
}
export function fetchCustomerParty(accesstoken, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}customers/party/${customerId}`, config);

    return {
        type: FETCH_CUSTOMER_PARTY,
        promise: request,
    };
}

export function updateCustomerParty(accesstoken, values, customerId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.put(`${ROOT_API_URL}customers/party/`, values, config);

    return {
        type: UPDATE_CUSTOMER_PARTY,
        promise: request,
    };
}

export function archiveUnarchiveCustomer(accesstoken, id, enabled) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            id,
            enabled,
        }),
    };
    const request = axios.patch(`${ROOT_API_URL}customers/archive-unarchive/`, null, config);

    return {
        type: ARCHIVE_UNARCHIVE_CUSTOMER,
        promise: request,
    };
}
export function triggerCustomerReport(accesstoken, params) {
    const { enabled } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        responseType: 'blob',
        params: parseQueryParams({
            enabled,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}reports/customer`, config);

    return {
        type: FETCH_CUSTOMER_REPORT,
        promise: request,
    };
}
