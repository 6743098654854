import { jsx as _jsx } from "react/jsx-runtime";
import componentLoader, { NO_OF_RETRIES } from 'components/utils/component_loader_utils';
import RouterErrorBoundary from 'components/utils/RouterErrorBoundary';
const DeviceCommandsComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-device-commands" */ 'components/dashboard/internal_tools/deviceCommands/DeviceCommandsComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const AddEditDeviceCommandComponent = async () => {
    const Module = await componentLoader(() => import(
    /* webpackChunkName: "app-dashboard-admin-device-commands-add-edit" */ 'components/dashboard/internal_tools/deviceCommands/AddEditDeviceCommandComponent'), NO_OF_RETRIES);
    return { Component: Module.default, errorElement: _jsx(RouterErrorBoundary, {}) };
};
const deviceCommandsRoutes = [
    {
        index: true,
        lazy: DeviceCommandsComponent,
    },
    {
        path: 'add',
        lazy: AddEditDeviceCommandComponent,
    },
    {
        path: 'edit/:id',
        lazy: AddEditDeviceCommandComponent,
    },
];
export default deviceCommandsRoutes;
