import { filter, includes, unset } from 'lodash';
import { ADDRESS_TYPES } from '../utils/consignmentsUtils';
import { mappedAddressBookWithIdMapping } from './form_utils';
export const CHALAN_TYPES = [
    {
        value: 'PICKUP',
        label: 'Pick Up',
    },
    {
        value: 'DISPATCH',
        label: 'Dispatch',
    },
    {
        value: 'DELIVERY',
        label: 'Delivery',
    },
];
const CHALLAN_TYPE_DISABLED_FIELDS = {
    'PICKUP': ['deliveryType', 'ewayBill', 'remarkLog'],
    'DELIVERY': ['deliveryType', 'ewayBill', 'remarkLog'],
};
export const isChallanFieldDisabled = (fieldName, challanType, showForPickUp = false) => {
    if (showForPickUp) {
        unset(CHALLAN_TYPE_DISABLED_FIELDS, 'PICKUP');
    }
    return includes(CHALLAN_TYPE_DISABLED_FIELDS[challanType], fieldName);
};
export const prepareConsignmentFetchQuery = ({ challanType, challanOfficeId, deliveryType }) => {
    switch (challanType) {
        case 'PICKUP':
            return {
                pickupType: ADDRESS_TYPES.DOOR,
                // nature: CONSIGNMENT_NATURES.PTL,
                fromBranchId: challanOfficeId,
                pickupChallanCompleted: false,
            };
        case 'DISPATCH':
            const data = {
                toBranchIdExcept: challanOfficeId,
                deliveryType: ADDRESS_TYPES.DOOR,
                withBalanceMaterialsAtBranch: challanOfficeId,
            };
            if (deliveryType === ADDRESS_TYPES.GODOWN) {
                delete data.deliveryType;
            }
            return data;
        case 'DELIVERY':
            return {
                // nature: CONSIGNMENT_NATURES.PTL,
                toBranchId: challanOfficeId,
                // deliveryType: ADDRESS_TYPES.GODOWN,
                withBalanceMaterialsAtBranch: challanOfficeId,
            };
        default:
            return {};
    }
};
export const getAddressBookOptionsBasedOnChallan = ({ challanType, addressBook, challanOfficeId }) => {
    const filteredAddressBooks = filter(addressBook, (address) => !!address.controllingBranchId);
    const addressBookOptions = mappedAddressBookWithIdMapping(filteredAddressBooks);
    let originInfo = {
        options: addressBookOptions,
    };
    let defaultInfo = {
        options: addressBookOptions,
    };
    let destinationInfo = {
        options: addressBookOptions,
    };
    switch (challanType) {
        case 'PICKUP':
            const filteredDestinationAddressBooks = filter(filteredAddressBooks, (address) => `${address.controllingBranchId}` === `${challanOfficeId}`);
            destinationInfo.options = mappedAddressBookWithIdMapping(filteredDestinationAddressBooks);
            break;
        case 'DISPATCH':
        case 'DELIVERY':
            const filteredOriginAddressBooks = filter(filteredAddressBooks, (address) => `${address.controllingBranchId}` === `${challanOfficeId}`);
            originInfo.options = mappedAddressBookWithIdMapping(filteredOriginAddressBooks);
            break;
    }
    return {
        originInfo,
        defaultInfo,
        destinationInfo,
    };
};
export const prepareRouteFetchQuery = ({ challanType, challanOfficeId }) => {
    switch (challanType) {
        case 'PICKUP':
            return {
                destinationBranchId: challanOfficeId,
            };
        case 'DISPATCH':
        case 'DELIVERY':
            return {
                originBranchId: challanOfficeId,
            };
        default:
            return {};
    }
};
