import { jsx as _jsx } from "react/jsx-runtime";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CalendarComponent from '../../utils/calendar_component';
import { isTimeRangeAllowedForCN } from 'utils/consignmentsUtils';
import { hideYearsInFreightBillCNListing } from '../freightBills/forms/freight_bill_account_utils';
const DeductionFilterComponent = (props) => {
    const { startDate, endDate, handleFilterChange, formikProps } = props;
    const hideYearsInCNListing = hideYearsInFreightBillCNListing();
    return (_jsx("div", { children: _jsx("div", { className: "row", children: _jsx("div", { className: "col-md-4", children: _jsx(CalendarComponent, { onDateSelected: (startDate, endDate) => {
                        handleFilterChange('timeFilterDeductions', [startDate, endDate]);
                    }, startDate: startDate, endDate: endDate, isDateRangeAllowed: isTimeRangeAllowedForCN, hideYears: hideYearsInCNListing }) }) }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(DeductionFilterComponent);
