import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Menu = () => {
    return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 -960 960 960", width: "24px", fill: "currentColor", children: _jsx("path", { d: "M140-254.62v-59.99h680v59.99H140ZM140-450v-60h680v60H140Zm0-195.39v-59.99h680v59.99H140Z" }) }));
};
const MenuIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Menu, ...props });
};
export default MenuIcon;
