import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { forEach, get, isEmpty, map, round, sumBy, values } from 'lodash';
import { getMomentTime } from 'utils/date_utils';
import { Input, InputNumber } from 'sharedV2/FxFormItems';
import { Link } from 'react-router-dom';
import { getCurrencyFromCountry } from 'utils/internationalization_utils';
const renderTdsAmountFieldForFreightBill = (record, formikProps) => {
    const { values } = formikProps;
    const billId = get(record, 'id');
    const disabled = !get(values, `paymentMappingRequests[${billId}]`);
    return (_jsx(InputNumber, { addonBefore: getCurrencyFromCountry(window.FLEETX_LOGGED_IN_USER), disabled: disabled, name: `paymentMappingRequests.${billId}.tdsAmount`, placeholder: "Enter TDS Amount", style: { width: '60%' } }));
};
const renderPaymentAmountFieldForFreightBill = (record, formikProps) => {
    const { values } = formikProps;
    const billId = get(record, 'id');
    const disabled = !get(values, `paymentMappingRequests[${billId}]`);
    return (_jsx(InputNumber, { addonBefore: getCurrencyFromCountry(window.FLEETX_LOGGED_IN_USER), disabled: disabled, name: `paymentMappingRequests.${billId}.totalAmount`, placeholder: "Enter Payment Amount", style: { width: '60%' } }));
};
export const getCommonColumns = (billType, formikProps) => {
    const billNumberConfig = {
        FREIGHT_BILL: {
            idPath: ['basicDetails', 'freightBillId'],
            amountPath: ['totalFreight'],
            editUrl: (id) => `/dashboard/bills/freightbill/edit/${id}`,
        },
        SUPPLEMENTARY_LOGISTICS_INVOICE: {
            idPath: ['basicDetails', 'supplementaryBillId'],
            amountPath: ['totalFreight'],
            editUrl: (id) => `/dashboard/bills/supplementarybill/edit/${id}`,
        },
        SUPPLEMENTARY_NON_LOGISTICS_INVOICE: {
            idPath: ['basicDetails', 'supplementaryBillId'],
            amountPath: ['totalFreight'],
            editUrl: (id) => `/dashboard/bills/supplementarybill/edit/${id}`,
        },
        DEBIT_NOTE: {
            idPath: ['id'],
            amountPath: ['paymentAmount'],
            editUrl: (id) => `/dashboard/bills/debitnote/edit/${id}`,
        },
    };
    const config = billNumberConfig[billType];
    return [
        {
            title: 'Bill Number',
            dataIndex: ['basicDetails', 'billNumber'],
            key: 'billNumber',
            render: (text, record) => {
                const id = get(record, config.idPath, get(record, 'id'));
                return (_jsx(_Fragment, { children: billType === 'FREIGHT_BILL' ||
                        billType === 'SUPPLEMENTARY_LOGISTICS_INVOICE' ||
                        billType === 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE' ? (_jsx("a", { target: "_blank", href: config.editUrl(id), children: text || get(record, 'billNumber') })) : (_jsx("div", { children: get(record, 'stationaryNumber') || get(record, 'billNumber') })) }));
            },
        },
        {
            title: 'Bill Amount',
            dataIndex: config.amountPath,
            key: 'amount',
            render: (amount) => `₹ ${round(amount, 2)}`,
        },
        {
            title: 'Billing Date',
            dataIndex: ['basicDetails', 'billingDate'],
            key: 'billingDate',
            render: (text, record) => {
                return (_jsx("div", { children: get(record, 'billingDate') ||
                        get(record, 'basicDetails.billingDate') ||
                        get(record, 'appliedDate') }));
            },
        },
        {
            title: 'Bill Settled Amount',
            dataIndex: ['voucher', 'settledAmount'],
            key: 'settledAmount',
            render: (text, record) => {
                return _jsx("div", { children: get(record, 'voucher.settledAmount', 0) || get(record, 'settledAmount', 0) });
            },
        },
        {
            title: 'TDS Amount',
            key: 'tdsAmount',
            render: (record) => renderTdsAmountFieldForFreightBill(record, formikProps),
            hidden: billType !== 'FREIGHT_BILL',
            width: '20%',
        },
        {
            title: 'Payment Amount',
            key: 'paymentAmount',
            render: (record) => renderPaymentAmountFieldForFreightBill(record, formikProps),
            hidden: billType === 'FREIGHT_BILL' || billType === 'SUPPLEMENTARY_LOGISTICS_INVOICE',
        },
    ];
};
const renderPaymentAmountField = (record, formikProps) => {
    const freightBillId = get(record, 'freightBillId');
    const consignmentId = get(record, 'id');
    const disabled = !get(formikProps.values, `paymentMappingRequests[${freightBillId}]`) ||
        !get(formikProps.values, `paymentMappingRequests[${freightBillId}].settlementEntityRequests[${consignmentId}]`);
    return (_jsx(InputNumber, { addonBefore: getCurrencyFromCountry(window.FLEETX_LOGGED_IN_USER), disabled: disabled, name: `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.balanceAmount`, placeholder: "Enter Amount" }));
};
const renderRemarksField = (record, formikProps) => {
    const freightBillId = get(record, 'freightBillId');
    const consignmentId = get(record, 'id');
    const disabled = !get(formikProps.values, `paymentMappingRequests[${freightBillId}]`) ||
        !get(formikProps.values, `paymentMappingRequests[${freightBillId}].settlementEntityRequests[${consignmentId}]`);
    return (_jsx(Input, { disabled: disabled, name: `paymentMappingRequests.${freightBillId}.settlementEntityRequests.${consignmentId}.remarks`, placeholder: "Enter Remarks" }));
};
export const getConsignmentColumns = (billType, formikProps) => [
    {
        title: 'CN Number',
        dataIndex: ['consignmentEntityDetailsDto', 'consignmentNumber'],
        key: 'consignmentNumber',
        render: (text, record) => {
            return (_jsx(Link, { target: "_blank", to: `/dashboard/consignments/edit/${get(record, 'consignmentEntityDetailsDto.id') || get(record, 'id')}/${get(record, 'consignmentEntityDetailsDto.consignmentStatus') || get(record, 'consignmentStatus')}`, children: text || get(record, 'consignmentNumber') }));
        },
    },
    {
        title: 'CN Date',
        dataIndex: ['basicDetails', 'consignmentDate'],
        key: 'consignmentDate',
        render: (date) => getMomentTime(date).format('DD/MM/YYYY'),
    },
    {
        title: 'Freight Amount',
        dataIndex: ['total'],
        key: 'total',
    },
    {
        title: 'Received Advance',
        dataIndex: ['receivedAdvanceAmount'],
        key: 'receivedAdvanceAmount',
    },
    {
        title: 'Received Payment',
        dataIndex: ['receivedPaymentAmount'],
        key: 'receivedPaymentAmount',
    },
    {
        title: 'Balance Amount',
        dataIndex: ['balancePaymentAmount'],
        key: 'balancePaymentAmount',
    },
    {
        title: 'Payment Amount',
        key: 'paymentAmount',
        width: '15%',
        render: (record) => renderPaymentAmountField(record, formikProps),
    },
    {
        title: 'Remark',
        key: 'remarks',
        width: '15%',
        render: (record) => renderRemarksField(record, formikProps),
    },
];
const getPaymentMappingRequests = (formData) => {
    const paymentMappingRequests = [];
    forEach(formData.paymentMappingRequests, (request) => {
        const settlementEntity = get(request, 'settlementEntityRequests', {});
        // Calculate totalBalanceAmount using balanceAmount if present (even 0), else settlementAmount
        const totalBalanceAmount = sumBy(values(settlementEntity), (entity) => {
            const balanceAmount = get(entity, 'balanceAmount');
            return balanceAmount !== undefined ? balanceAmount : get(entity, 'settlementAmount', 0);
        });
        // Calculate totalDeductionAmount from deductionMappings
        const totalDeductionAmount = sumBy(values(settlementEntity), (entity) => sumBy(get(entity, 'deductionMappings', []), (deduction) => get(deduction, 'deductionAmount', 0)));
        let totalAmount;
        if (get(request, 'settlementType') === 'SUPPLEMENTARY_NON_LOGISTICS_INVOICE' ||
            get(request, 'settlementType') === 'DEBIT_NOTE') {
            totalAmount = get(request, 'totalAmount', 0);
        }
        else {
            totalAmount = totalBalanceAmount + totalDeductionAmount;
        }
        const paymentMappingRequest = {
            billId: get(request, 'billId'),
            settlementType: get(request, 'settlementType'),
            totalAmount: totalAmount,
        };
        // Process settlementEntityRequests
        const settlementEntities = map(request.settlementEntityRequests, (entity) => {
            const mappedEntity = {
                settlementEntityId: get(entity, 'settlementEntityId'),
                remarks: get(entity, 'remarks'),
            };
            // Set settlementAmount based on balanceAmount or settlementAmount
            const balanceAmount = get(entity, 'balanceAmount');
            if (balanceAmount !== undefined) {
                mappedEntity.settlementAmount = balanceAmount;
            }
            else {
                mappedEntity.settlementAmount = get(entity, 'settlementAmount', 0);
            }
            // Include deductionMappings only if not empty
            const deductionMappings = get(entity, 'deductionMappings', []);
            if (!isEmpty(deductionMappings)) {
                mappedEntity.deductionMappings = map(deductionMappings, (deduction) => ({
                    ledgerId: get(deduction, 'ledgerId'),
                    deductionAmount: get(deduction, 'deductionAmount'),
                    remark: get(deduction, 'remark'),
                }));
            }
            return mappedEntity;
        });
        if (settlementEntities.length > 0) {
            paymentMappingRequest.settlementEntityRequests = settlementEntities;
        }
        // Include tdsAmount only if present
        const tdsAmount = get(request, 'tdsAmount');
        if (tdsAmount !== undefined && tdsAmount !== null && tdsAmount !== '') {
            paymentMappingRequest.tdsAmount = tdsAmount;
        }
        // Check computed totalAmount to include the request
        if (totalAmount > 0) {
            paymentMappingRequests.push(paymentMappingRequest);
        }
    });
    return paymentMappingRequests;
};
export const transformPayload = (formData) => {
    // Extract and convert amounts to string
    const indirectIncomeAmount = get(formData, 'ledgerDetails.indirectIncome.amount', '').toString();
    const tdsLedgerAmount = get(formData, 'ledgerDetails.tdsLedger.amount', '').toString();
    // Initialize ledgerDetails object to conditionally add properties
    const ledgerDetails = {};
    // Add indirectIncome to ledgerDetails if amount > 0
    if (parseFloat(indirectIncomeAmount) > 0) {
        ledgerDetails.indirectIncome = {
            ledgerId: get(formData, 'ledgerDetails.indirectIncome.ledgerId'),
            amount: indirectIncomeAmount,
        };
    }
    // Add tdsLedger to ledgerDetails if amount > 0
    if (parseFloat(tdsLedgerAmount) > 0) {
        ledgerDetails.tdsLedger = {
            ledgerId: get(formData, 'ledgerDetails.tdsLedger.ledgerId'),
            amount: tdsLedgerAmount,
        };
    }
    // Prepare transformed object
    const transformed = {
        mappingId: get(formData, 'mappingId'),
        mappingNature: get(formData, 'mappingNature'),
        ledgerDetails: Object.keys(ledgerDetails).length > 0 ? ledgerDetails : undefined,
        paymentMappingRequests: getPaymentMappingRequests(formData),
    };
    return transformed;
};
