import { jsxs as _jsxs } from "react/jsx-runtime";
import { get, groupBy, isEmpty, map, orderBy, round } from 'lodash';
import { TRANSACTION_TYPES_OBJECT } from '../constant';
export function getPreparedTransactionExpenseSummary(expenseTypeDTOS) {
    if (isEmpty(expenseTypeDTOS)) {
        return [];
    }
    const groupedByExpenseType = groupBy(expenseTypeDTOS, 'expenseName');
    return orderBy(map(groupedByExpenseType, (v) => {
        return {
            ...v[0],
            ...v[1],
        };
    }), 'expenseName');
}
export const TRANSACTION_SUMMARY_TAB = {
    SUMMARY: 'summary',
    EXPENSES: 'expenses',
};
export const TRANSACTIONS_TABS = {
    FASTAG: 'fastag',
    HAPPAY: 'happay',
    BPCL: 'bpcl',
    TRANSACTION: 'transaction',
};
export const isAccountIdGreaterThan10K = () => {
    return 10_000 < get(window.FLEETX_LOGGED_IN_USER, 'accountId', 0);
};
export const showTransactionType = (transactionType) => {
    const showSubLabels = isAccountIdGreaterThan10K();
    return (_jsxs("div", { children: [' ', transactionType === 'CR'
                ? TRANSACTION_TYPES_OBJECT.CR + (showSubLabels ? ' (Expenses)' : '')
                : TRANSACTION_TYPES_OBJECT.DR + (showSubLabels ? ' (Advance)' : '')] }));
};
export const getBudgetedFuel = (job) => {
    const jobKm = get(job, 'route.distance', 0);
    const jobMileage = get(job, 'jobBudget.jobMileage', 0);
    const fuel = jobMileage ? round(jobKm / jobMileage, 2) : 0;
    return { fuel, jobKm, jobMileage };
};
export const getBudgetedFuelBasedOnActualKm = (job) => {
    const actualKm = get(job, 'finalKm') ? get(job, 'finalKm', 0) : get(job, 'currentKm', 0);
    const jobMileage = get(job, 'jobBudget.jobMileage', 0);
    const fuelActualKm = jobMileage ? round(actualKm / jobMileage, 2) : 0;
    return { fuelActualKm, actualKm, jobMileage };
};
export const getModeOfPaymentOptions = () => {
    return [
        { value: 'Cash', label: 'Cash' },
        { value: 'Net Banking', label: 'Net Banking' },
        { value: 'Happay', label: 'Happay' },
        { value: 'IOCL', label: 'IOCL' },
        { value: 'UPI', label: 'UPI' },
        { value: 'BPCL', label: 'BPCL' },
        { value: 'HPCL', label: 'HPCL' },
        { value: 'Reliance', label: 'Reliance' },
        { value: 'Bank Transfer', label: 'Bank Transfer' },
        { value: 'FASTag Wallet', label: 'FASTag Wallet' },
        { value: 'JIO', label: 'JIO' },
        { value: 'Cheque', label: 'Cheque' },
    ];
};
