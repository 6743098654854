import styled from 'styled-components';
import {
    concat,
    filter,
    flatMap,
    forEach,
    get,
    has,
    includes,
    isEmpty,
    lowerCase,
    map,
    reduce,
    round,
    toSafeInteger,
    upperFirst,
} from 'lodash';
import React from 'react';
import { sortListAlphabetically } from 'utils/list_utils';
import FxTag from 'sharedV2/FxTag';
import { getCurrencyFromCountry } from './internationalization_utils';
import {
    getFlagValueFromAccountConfig,
    tagsForWhichAssignVehicleTimeLimitAdded,
    tagsInWhichArrivalDepartureUpdateAllowed,
    tagsInWhichConsignmentUpdateAllowed,
} from 'utils/account_utils';
import { isTransporter } from 'utils/permissionUtils/tmsPermissions';
import { getTransporterStatus } from 'utils/vendor_management/indentUtils';
import { getConsignmentIdsFromJobAnalyticsCommodities } from 'utils/job_utils';

export const StatusDiv = styled.div`
    padding: 5px 0px;
    font-weight: 800;
    color: ${(props) => (props.color ? props.color : '#2d2d2d')};
    width: 5.5rem;
`;

export const showStatus = (status, row) => {
    const type = get(row, 'type');
    const movementType = get(row, 'movementType');
    const statusDescription = get(row, 'statusDescription');
    switch (status) {
        case STATUS.DELIVERED:
        case STATUS.ARRIVED:
        case STATUS.COMPLETED:
        case STATUS.ASSIGNED:
            return (
                <>
                    <StatusDiv color="#00a70f">{upperFirst(lowerCase(status))}</StatusDiv>
                    {type && <div className="medium text-muted">{type}</div>}
                    {movementType && <div className="medium text-muted">{upperFirst(lowerCase(movementType))}</div>}
                </>
            );
        case STATUS.REJECTED:
        case STATUS.EXPIRED:
            return (
                <>
                    <StatusDiv color="#f86c6b">{upperFirst(lowerCase(status))}</StatusDiv>
                    {type && <div className="medium text-muted">{type}</div>}
                    {movementType && <div className="medium text-muted">{upperFirst(lowerCase(movementType))}</div>}
                </>
            );
        case STATUS.STARTED:
        case STATUS.ARRIVAL:
        case STATUS.DEPARTURE:
        case STATUS.ARRIVALDEST:
        case STATUS.END:
        case STATUS.CLOSED:
            return (
                <>
                    <StatusDiv color="#2d2d2d">
                        {upperFirst(lowerCase(statusDescription ? statusDescription : status))}
                    </StatusDiv>
                    {type && <div className="medium text-muted">{type}</div>}
                    {movementType && <div className="medium text-muted">{upperFirst(lowerCase(movementType))}</div>}
                </>
            );
        default:
            return (
                <>
                    <StatusDiv color="#2d2d2d">{upperFirst(lowerCase(status))}</StatusDiv>
                    {type && <div className="medium text-muted">{type}</div>}
                    {movementType && <div className="medium text-muted">{upperFirst(lowerCase(movementType))}</div>}
                </>
            );
    }
};

export const STATUS = {
    ACCEPTED: 'ACCEPTED',
    ARRIVED: 'ARRIVED',
    ASSIGNED: 'ASSIGNED',
    UNASSIGNED: 'UNASSIGNED',
    LRNOTASSIGNED: 'LRNOTASSIGNED',
    CHANGED: 'CHANGED',
    DELIVERED: 'DELIVERED',
    EXPIRED: 'EXPIRED',
    INDENTED: 'INDENTED',
    REJECTED: 'REJECTED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
    ARRIVAL: 'ARRIVAL',
    ARRIVALATPICKUP: 'ARRIVALATPICKUP',
    DEPARTURE: 'DEPARTURE',
    DEPARTUREATPICKUP: 'DEPARTUREATPICKUP',
    ARRIVALDEST: 'ARRIVALDEST',
    END: 'END',
    CLOSED: 'CLOSED',
    ONTIME: 'ONTIME',
    LATE: 'LATE',
    EARLY: 'EARLY',
    SCHEDULED: 'SCHEDULED',
    GEOFENCE_NOT_TRIGGERED: 'GEOFENCE_NOT_TRIGGERED',
    LATE_VEHICLE_ASSIGNMENT: 'LATEASSIGNMENT',
    CANCELLED: 'CANCELLED',
    CHANGE_REQUEST: 'CHANGE_REQUEST',
    CHANGE_ACCEPTED: 'CHANGE_ACCEPTED',
    CHANGE_REJECTED: 'CHANGE_REJECTED',
};

export const VENDOR_MAERSK_STATUS_LABEL = {
    ...STATUS,
    UNASSIGNED: 'VEHICLE NOT ASSIGNED',
    INDENTED: 'PENDING',
    STARTED: 'TRIP START',
    ARRIVAL: 'ARRIVAL AT FACTORY',
    ARRIVALATPICKUP: 'ARRIVAL AT PICKUP',
    DEPARTURE: 'DEPARTURE FROM FACTORY',
    DEPARTUREATPICKUP: 'DEPARTURE AT PICKUP',
    ARRIVALDEST: 'ARRIVAL AT DESTINATION',
    END: 'TRIP END',
    CLOSED: 'TRIP CLOSED',
    LRNOTASSIGNED: 'LR NOT ASSIGNED',
    GEOFENCE_NOT_TRIGGERED: 'GEOFENCE NOT TRIGGERED',
    EMPTY: 'EMPTY',
    SHUNT: 'SHUNT',
};
export const addDataVisible = (status, isTransporter = false) => {
    if (isTransporter) {
        return includes(statusForAddDataAllowedAtTransporterSideMaersk(), status);
    }
    return includes(statusForAddDataAllowedAtShipperSideMaersk(), status);
};

export const isGenerateLRVisible = (row) => {
    const status = isTransporter() && row.parallelIndenting ? getTransporterStatus(row) : row.status;
    return (
        status == STATUS.ASSIGNED ||
        status == STATUS.LRNOTASSIGNED ||
        (status !== STATUS.ACCEPTED &&
            status !== STATUS.REJECTED &&
            status !== STATUS.EXPIRED &&
            status !== STATUS.CANCELLED &&
            status !== STATUS.INDENTED)
    );
};
export const addDataVisibleOnlyInCaseLRNOTASSIGNED = (row, isTransporter = false) => {
    const status = isTransporter && row.parallelIndenting ? getTransporterStatus(row) : get(row, 'status');
    return (
        status === STATUS.LRNOTASSIGNED ||
        (get(window.FLEETX_LOGGED_IN_USER, 'accountId') == 13415 ? status === STATUS.END : false)
    );
};

const renderBillNumber = (billNo) => <div>{`${billNo.type} : ${billNo.value}\n`}</div>;

export const billNumberCell = (billNumbers, row = {}, consignmentMap) => {
    let obj = {};
    let _billNumbers = [];
    let indentAnalyticsBillNumbers = [];
    let jobAnalyticsVehicleWiseBillNumbers = [];
    let consignmentBillNumbers = [];
    const consignmentIds = getConsignmentIdsFromIndent(row);
    forEach(consignmentIds, (cId) => {
        const cn = consignmentMap[cId];
        consignmentBillNumbers = concat(consignmentBillNumbers, get(cn, 'billNumbers', []));
    });

    forEach(get(row, 'indentAnalytics', []), (el) => {
        indentAnalyticsBillNumbers = concat(indentAnalyticsBillNumbers, el.billNumbers);
    });
    forEach(row.vehicles || [], (el) => {
        forEach(get(el, 'job.jobAnalytics', []), (jobAnalytic) => {
            jobAnalyticsVehicleWiseBillNumbers = concat(jobAnalyticsVehicleWiseBillNumbers, jobAnalytic.billNumbers);
        });
    });
    let allBillNumbers = [
        ...billNumbers,
        ...indentAnalyticsBillNumbers,
        ...jobAnalyticsVehicleWiseBillNumbers,
        ...consignmentBillNumbers,
    ];
    forEach(allBillNumbers, (item) => {
        if (item.value && !obj[item.type + item.value]) {
            obj[item.type + item.value] = true;
            _billNumbers.push(item);
        }
    });

    _billNumbers = sortListAlphabetically(_billNumbers, 'label', 'type');
    const restBillNumbers = reduce(
        _billNumbers.slice(2),
        (str, billNo) => `${str}${billNo.type} : ${billNo.value}\n`,
        ''
    );
    const allBillNumberTooltip = reduce(_billNumbers, (str, billNo) => `${str}${billNo.type} : ${billNo.value}\n`, '');
    return (
        <div className="flex-text-wrapper" title={allBillNumberTooltip}>
            {_billNumbers[0] && renderBillNumber(_billNumbers[0])}
            {_billNumbers[1] && renderBillNumber(_billNumbers[1])}
            <div className="text-truncate" style={{ width: 300 }}>
                {' '}
                {restBillNumbers}
            </div>
        </div>
    );
};

const getConsignmentIdsFromIndent = (indent) => {
    const indentAnalytics = get(indent, 'indentAnalytics', []);
    const indentAnalyticsCommodity = flatMap(indentAnalytics, (el) => el.indentAnalyticsCommodity, []);
    return flatMap(indentAnalyticsCommodity, (commodity) =>
        get(commodity, 'consignmentMaterial.consignmentId', null)
    ).filter(Boolean);
};

const renderCustomFields = (cf) => <div>{`${cf.field.keyName} : ${cf.value}\n`}</div>;

export const showConsignmentDetails = (customFields, row = {}, consignmentMap) => {
    const consignmentIds = getConsignmentIdsFromIndent(row);
    let consignmentList = map(consignmentIds, (el) => {
        return get(consignmentMap, el);
    }).filter(Boolean);

    // let customFieldObj = {};
    // forEach(consignmentList, (cn) => {
    //     if (!isEmpty(cn.customFields)) {
    //         customFieldObj[cn.id] = cn.customFields;
    //     }
    // });

    let allCustomFields = flatMap(consignmentList, (cn) => get(cn, 'customFields', []));
    const allowedKeys = ['Incoterms', 'SalesOfficeDescription'];
    const filteredCustomFields = allCustomFields.filter((item) => includes(allowedKeys, item.field.keyName));

    // let obj = {};
    // let _customFields = [];
    // forEach(filteredCustomFields, (item) => {
    //     if (!obj[item.field.keyName + item.value]) {
    //         obj[item.field.keyName + item.value] = true;
    //         _customFields.push(item);
    //     }
    // });

    const restCustomFields = reduce(
        filteredCustomFields.slice(2),
        (str, cf) => `${str}${cf.field.keyName} : ${cf.value}\n`,
        ''
    );
    const allCustomFieldsTooltip = reduce(
        filteredCustomFields,
        (str, cf) => `${str}${cf.field.keyName} : ${cf.value}\n`,
        ''
    );

    return (
        <div className="flex-text-wrapper" title={allCustomFieldsTooltip}>
            {filteredCustomFields[0] && renderCustomFields(filteredCustomFields[0])}{' '}
            {filteredCustomFields[1] && renderCustomFields(filteredCustomFields[1])}
            <div className="text-truncate" style={{ width: 300 }}>
                {restCustomFields}
            </div>
        </div>
    );

    // let _customFields = [];
    // forEach(allCustomFields, (item) => {
    //     if (!obj[item.field.keyName + item.value]) {
    //         obj[item.field.keyName + item.value] = true;
    //         _customFields.push(item);
    //     }
    // });
    // const restCustomFields = reduce(
    //     _customFields.slice(2),
    //     (str, cf) => `${str}${cf.field.keyName} : ${cf.value}\n`,
    //     ''
    // );
    // const allCustomFieldsTooltip = reduce(_customFields, (str, cf) => `${str}${cf.field.keyName} : ${cf.value}\n`, '');
    // return (
    //     <div className="flex-text-wrapper" title={allCustomFieldsTooltip}>
    //         {_customFields[0] && renderCustomFields(_customFields[0])}
    //         {_customFields[1] && renderCustomFields(_customFields[1])}
    //         <div className="text-truncate" style={{ width: 300 }}>
    //             {' '}
    //             {restCustomFields}
    //         </div>
    //     </div>
    // );
};

export const consignmentStatusStages = [
    {
        id: 'OPEN',
        title: 'OPEN',
    },
    {
        id: 'ASSIGNED',
        title: 'Assigned',
    },
    {
        id: 'INDENTED',
        title: 'Indented',
    },
    {
        id: 'SCHEDULED',
        title: 'Scheduled',
    },
    {
        id: 'LOADING',
        title: 'Loading',
    },
    {
        id: 'LOADED',
        title: 'Loaded',
    },
    {
        id: 'IN_TRANSIT',
        title: 'In Transit',
    },
    {
        id: 'DELIVERED',
        title: 'Delivered',
    },
    {
        id: 'EPOD',
        title: 'EPOD',
    },
    {
        id: 'EPOD_UPLOADED',
        title: 'EPOD Uploaded',
    },
    {
        id: 'EPOD_ACCEPTED',
        title: 'EPOD Accepted',
    },
    {
        id: 'BILLED',
        title: 'Billed',
    },
];

export const getProjectedFtlPtlCost = (value, loggedInUser) => {
    return (
        <div className="d-flex">
            {has(value, 'ftlCost') && (
                <FxTag>
                    {'FTL Cost'} - {getCurrencyFromCountry(loggedInUser, true)} {round(get(value, 'ftlCost', '-'), 2)}
                </FxTag>
            )}
            {has(value, 'ptlCost') && (
                <FxTag>
                    {'PTL Cost'} - {getCurrencyFromCountry(loggedInUser, true)} {round(get(value, 'ptlCost', '-'), 2)}
                </FxTag>
            )}
        </div>
    );
};

export const isUpdateConsignmentAllowed = (job) => {
    const tagIds = get(job, 'tagIds', '').split(',');
    return (tagIds || []).some((tagId) => includes(tagsInWhichConsignmentUpdateAllowed() || [], toSafeInteger(tagId)));
};

export const isUpdateArrivalDepartureAllowed = (job) => {
    const tagIds = get(job, 'tagIds', '').split(',');
    return (tagIds || []).some((tagId) =>
        includes(tagsInWhichArrivalDepartureUpdateAllowed() || [], toSafeInteger(tagId))
    );
};

export const indentTagsForWhichAssignVehicleTimeLimitAdded = (indent) => {
    const tagIds = get(indent, 'tagIds', '').split(',');
    return (tagIds || []).some((tagId) =>
        includes(tagsForWhichAssignVehicleTimeLimitAdded() || [], toSafeInteger(tagId))
    );
};

export const indentTagsForWhichInAssignVehicleVehicleReminderChecksAdded = (indent) => {
    const tagIds = get(indent, 'tagIds', '').split(',');
    return (tagIds || []).some((tagId) =>
        includes(tagsForWhichInAssignVehicleVehicleReminderChecksAdded() || [], toSafeInteger(tagId))
    );
};

export const showVehicleEnable = (row) => {
    return (
        +get(row, 'approvedCount', 0) <=
        filter(get(row, 'vehicles', []), (vehicle) => vehicle?.job?.status !== 'CANCELLED').length
    );
};

export const showAssignVehicleBasedOnQuantity = (row) => {
    if (row.quantityBased) {
        return (
            +get(row, 'indentTransporters[0].allowedQuantity') >= +get(row, 'indentTransporters[0].allottedQuantity', 0)
        );
    }
    return false;
};

export const showVehicleAssignmentModal = (row) => {
    return get(row, 'quantityBased', false) ? showAssignVehicleBasedOnQuantity(row) : !showVehicleEnable(row);
};

export const showRequestChangeVehicle = (row) => {
    return includes([STATUS.STARTED, STATUS.LRNOTASSIGNED], row.status);
};

export function tagsForWhichInAssignVehicleVehicleReminderChecksAdded() {
    return getFlagValueFromAccountConfig('tagsForWhichInAssignVehicleVehicleReminderChecksAdded');
}

export function vehicleRemindersForWhichInAssignVehicleChecksAdded() {
    return getFlagValueFromAccountConfig('vehicleRemindersForWhichInAssignVehicleChecksAdded');
}

export const indentPlanningDescription = (key) => {
    switch (key) {
        case 'Sob_Month':
        case 'Sob_Week':
        case 'Sob_Day':
            return (
                "The allocation of indents will be based on each transporter's share of business. The allocated " +
                'and accepted indents by transporters will also be considered, aligning with the chosen frequency to' +
                ' maintain business shares as closely as possible to the contracted values. If any transporter exhausts' +
                ' its vehicle supply, the remaining indents will be allocated among the other transporters according ' +
                'to their respective shares of business. In case of clashes, the indents will be assigned to the ' +
                'transporter with the higher share.'
            );
        case 'Priority_Month':
        case 'Priority_Week':
        case 'Priority_Day':
            return (
                "The allocation of indents will be based on each transporter's share of business. The allocated and" +
                ' accepted indents by transporters will also be considered, aligning with the chosen frequency to' +
                ' maintain business shares as closely as possible to the contracted values. If any transporter ' +
                'exhausts its vehicle supply, the remaining indents will be allocated among the other transporters' +
                ' according to their respective shares of business. In case of clashes, the indents will be assigned' +
                ' to the transporter with the lower priority.'
            );
        case 'Level_Sob_Month':
        case 'Level_Sob_Week':
        case 'Level_Sob_Day':
            return (
                'The transporters at the lowest level will be picked and then the allocation of indents will be based on ' +
                "the transporter's share of business. The allocated and accepted indents by transporters will also be" +
                ' considered, aligning with the chosen frequency to maintain business shares as closely as possible to ' +
                'the contracted values. If any transporter exhausts its vehicle supply, the remaining indents will be' +
                ' allocated among the other transporters according to their respective shares of business. Once all' +
                ' the transporters at a level exhaust their vehicle supply, We will move to the next level.'
            );
        default:
            // Level
            return (
                'The indents will be allocated to the transporter with the lowest priority, transitioning to ' +
                'the next transporter once the current one exhausts its vehicle supply.'
            );
    }
};

export function taxCalculationBasedOnGSTn() {
    return getFlagValueFromAccountConfig('taxCalculationBasedOnGSTn');
}

export function isAllowOnlyPONumberJobsInFreightSettlement() {
    return getFlagValueFromAccountConfig('allowOnlyPONumberJobsInFreightSettlement');
}

export function statusForAddDataAllowedAtShipperSideMaersk() {
    return getFlagValueFromAccountConfig('statusForAddDataAllowedAtShipperSideMaersk');
}

export function statusForAddDataAllowedAtTransporterSideMaersk() {
    return getFlagValueFromAccountConfig('statusForAddDataAllowedAtTransporterSideMaersk');
}

export function xDayPendingForInvoiceCreate() {
    return getFlagValueFromAccountConfig('xDayPendingForInvoiceCreate');
}

export const CARGO_TYPES = [
    { value: 'Bag', label: 'Bag' },
    { value: 'Bulk', label: 'Bulk' },
];

export const CHANNEL_TYPES = [
    { value: 'Trade', label: 'Trade' },
    { value: 'Non-Trade', label: 'Non-Trade' },
    { value: 'KAM', label: 'KAM' },
];
