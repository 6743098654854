const batteryLifeCycleEventsMapping = {
    NEW: 'NEW',
    REMOULD: 'REMOULD',
    SENT_TO_REMOULD: 'SENT_TO_REMOULD',
    SENT_TO_CLAIM: 'SENT_TO_CLAIM',
    STOLEN: 'STOLEN',
    SCRAPPED: 'SCRAPPED',
    IN_TRANSIT_BETWEEN_STORES: 'IN_TRANSIT_BETWEEN_STORES',
    CLAIMED: 'CLAIMED',
};
export const allLifeCycleEvents = [
    'NEW',
    'REMOULD',
    'SENT_TO_REMOULD',
    'SENT_TO_CLAIM',
    'STOLEN',
    'SCRAPPED',
    'IN_TRANSIT_BETWEEN_STORES',
    'CLAIMED',
];
export function getFieldKeyByNature(nature) {
    const { STOLEN, SCRAPPED, NEW, SENT_TO_CLAIM, SENT_TO_REMOULD, REMOULD, IN_TRANSIT_BETWEEN_STORES, CLAIMED, } = batteryLifeCycleEventsMapping;
    const fieldsMapping = {
        // INWARD EVENTS
        'BATTERY_MRN': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            batteryFields: [
                'batteryNo',
                'mfgDate',
                'brandId',
                'modelId',
                'amount',
                'warrantyInDays',
                'warrantyInKm',
                'openingKm',
                'openingDurationInDays',
                'currentAmount',
                'capacity',
                'cellNumber',
                'remarks',
                'eventTime',
                'type',
                'discount',
                'batteryImages',
                'productionDate',
            ],
        },
        'CHASSIS_MRN': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            batteryFields: [
                'batteryNo',
                'chasisNo',
                'mfgDate',
                'brandId',
                'modelId',
                'amount',
                'warrantyInDays',
                'warrantyInKm',
                'openingKm',
                'openingDurationInDays',
                'currentAmount',
                'capacity',
                'cellNumber',
                'remarks',
                'eventTime',
                'type',
                'batteryImages',
                'productionDate',
            ],
        },
        'REFURBISHED_BATTERY': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            batteryFields: [
                'battery',
                'mfgDate',
                'brandId',
                'modelId',
                'extraAmount',
                'currentAmount',
                'warrantyInDays',
                'warrantyInKm',
                'openingKm',
                'openingDurationInDays',
                'currentAmount',
                'capacity',
                'cellNumber',
                'remarks',
                'eventTime',
                'discount',
                'batteryImages',
                'productionDate',
            ],
        },
        'BATTERY_AGAINST_CLAIM': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            batteryFields: [
                'battery',
                'claimedBatteryNo',
                'mfgDate',
                'brandId',
                'modelId',
                'extraAmount',
                'currentAmount',
                'warrantyInDays',
                'warrantyInKm',
                'openingKm',
                'openingDurationInDays',
                'currentAmount',
                'capacity',
                'cellNumber',
                'remarks',
                'eventTime',
                'discount',
                'batteryImages',
                'productionDate',
            ],
        },
        'BATTERY_STOCK_IN': {
            globalFields: ['mrnDate', 'inwardStoreId', 'outwardStoreId', 'stockOutStationaryNumber'],
            batteryFields: [
                'battery',
                'mfgDate',
                'brandId',
                'modelId',
                'currentAmount',
                'warrantyInDays',
                'warrantyInKm',
                'openingKm',
                'openingDurationInDays',
                'currentAmount',
                'capacity',
                'cellNumber',
                'remarks',
                'eventTime',
                'batteryImages',
                'productionDate',
            ],
        },
        'CLAIM_REJECTION': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            batteryFields: [
                'battery',
                'mfgDate',
                'brandId',
                'modelId',
                'amount',
                'warrantyInDays',
                'warrantyInKm',
                'openingKm',
                'openingDurationInDays',
                'currentAmount',
                'capacity',
                'cellNumber',
                'remarks',
                'eventTime',
                'batteryImages',
                'productionDate',
            ],
        },
        'REFURBISHED_REJECTION': {
            globalFields: ['mrnDate', 'storeId', 'purchaseDate', 'poNumber', 'poDate', 'billNumber', 'vendorId'],
            batteryFields: [
                'battery',
                'mfgDate',
                'brandId',
                'modelId',
                'amount',
                'warrantyInDays',
                'warrantyInKm',
                'openingKm',
                'openingDurationInDays',
                'currentAmount',
                'capacity',
                'cellNumber',
                'remarks',
                'eventTime',
                'batteryImages',
                'productionDate',
            ],
        },
        // OUTWARD EVENTS
        'SENT_TO_REMOULD': {
            globalFields: ['mrnDate', 'storeId', 'vendorId'],
            batteryFields: ['battery', 'brandId', 'modelId', 'currentAmount', 'eventTime', 'remarks'],
            stateToSkip: [STOLEN, SCRAPPED, IN_TRANSIT_BETWEEN_STORES, SENT_TO_CLAIM, SENT_TO_REMOULD],
        },
        'SENT_TO_CLAIM': {
            globalFields: ['mrnDate', 'storeId', 'vendorId'],
            batteryFields: ['battery', 'brandId', 'modelId', 'currentAmount', 'eventTime', 'remarks'],
            stateToSkip: [STOLEN, SCRAPPED, IN_TRANSIT_BETWEEN_STORES, SENT_TO_CLAIM, SENT_TO_REMOULD],
        },
        'BATTERY_THEFT': {
            globalFields: ['mrnDate', 'storeId', 'vendorId', 'driver', 'comment'],
            batteryFields: ['battery', 'brandId', 'modelId', 'currentAmount', 'recoveryAmount', 'eventTime', 'remarks'],
            stateToSkip: [STOLEN],
        },
        'BATTERY_SCRAPPING': {
            globalFields: ['mrnDate', 'storeId', 'buyer', 'comment'],
            batteryFields: ['battery', 'brandId', 'modelId', 'currentAmount', 'scrapAmount', 'eventTime', 'remarks'],
            stateToSkip: [SCRAPPED, STOLEN, SENT_TO_REMOULD, SENT_TO_CLAIM],
        },
        'BATTERY_STOCK_OUT': {
            globalFields: ['mrnDate', 'outwardStoreId', 'outwardDate', 'inwardStoreId', 'inwardDate', 'comment'],
            batteryFields: ['battery', 'brandId', 'modelId', 'currentAmount', 'recoveryAmount', 'eventTime', 'remarks'],
            stateToSkip: [STOLEN, IN_TRANSIT_BETWEEN_STORES, SENT_TO_CLAIM, SENT_TO_REMOULD],
        },
    };
    return fieldsMapping[nature];
}
