import axios from 'axios';
import { parseQueryParams, ROOT_API_URL, ROOT_API_ERP } from '../../../constant';
import { getFormattedTimeStringForAPI } from '../../../utils/date_utils';
export const ADD_PART_ENTRY_TO_STORE = 'add_part_entry_to_store';
export const TAKE_OUT_PART_ENTRY_FROM_STORE = 'take_out_part_entry_from_store';
export const FILTER_PART_ENTRIES_IN_STORES = 'filter_part_entries_in_store';
export const PART_ENTRY_IN_STORE_HISTORY = 'PART_ENTRY_IN_STORE_HISTORY';
export const FILTER_PART_ENTRIES_GROUPS_IN_STORES = 'FILTER_PART_ENTRIES_GROUPS_IN_STORES';
export function addPartEntryToStore(accesstoken, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_URL}part-entry-store`, data, config);
    return {
        type: ADD_PART_ENTRY_TO_STORE,
        promise: request,
    };
}
export function takeOutPartEntryFromStore(accesstoken, id, data) {
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
    };
    const request = axios.post(`${ROOT_API_ERP}part-entry-store/out/${id}`, data, config);
    return {
        type: TAKE_OUT_PART_ENTRY_FROM_STORE,
        promise: request,
    };
}
export function filterPartEntriesInStore(accesstoken, params = {}) {
    const { storeId, partId, partEntryId, combineDistinctParts } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            storeId,
            partId,
            partEntryId,
            combineDistinctParts,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry-store/filter`, config);
    return {
        type: FILTER_PART_ENTRIES_IN_STORES,
        promise: request,
    };
}
export function filterPartEntriesGroupInStore(params = {}) {
    const { storeId, partId, partEntryId, combineDistinctParts } = params;
    const config = {
        params: parseQueryParams({
            storeId,
            partId,
            partEntryId,
            combineDistinctParts,
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry-store/filter/group`, config);
    return {
        type: FILTER_PART_ENTRIES_GROUPS_IN_STORES,
        promise: request,
    };
}
export function fetchPartEntryInStoreHistory(accesstoken, params = {}) {
    const { fromDate, toDate, partId } = params;
    const config = {
        headers: { Authorization: `Bearer ${accesstoken}` },
        params: parseQueryParams({
            partId,
            fromDate: getFormattedTimeStringForAPI(fromDate),
            toDate: getFormattedTimeStringForAPI(toDate),
        }),
    };
    const request = axios.get(`${ROOT_API_URL}part-entry-store/audit-history`, config);
    return {
        type: PART_ENTRY_IN_STORE_HISTORY,
        promise: request,
    };
}
