import {
    get,
    forEach,
    filter,
    includes,
    remove,
    set,
    round,
    unset,
    omit,
    isInteger,
    find,
    toUpper,
    sumBy,
    groupBy,
    map,
    keyBy,
    uniqBy,
} from 'lodash';
import {
    getFreightCalculationPriority1,
    getFreightCalculationPriority2,
    getFreightCalculationPriority3,
    getFreightCalculationPriority4,
    isCNOrMaterialBasedEInvoicing,
    isInternationalAccount,
    multipleFreightBillForCN,
} from './account_utils';
import {
    DATE_FORMAT,
    DATE_FORMAT_TIMESTAMP_T,
    getFormattedTimeStringForAPI,
    getLastMonthRange,
    getLastQuarterRange,
    getLastYearRangeFromASpecificDate,
    getMomentTime,
} from './date_utils';
import {
    BILLING_NATURE,
    CONTRACT_RATE_TYPES,
    CONTRACT_RATE_TYPES_ADDITIONAL,
    CONTRACT_RATE_TYPES_ADDITIONAL_LABELS,
    RATE_TYPES,
} from './rateChartsUtils';
import { mappedOptionsFromArray } from 'components/utils/mapping_utils';
import { GST_NATURES, materilUnitMapping } from './consignmentsUtils';
import { handleCustomFieldsNull } from './custom_field_utils';
import { fetchBranches } from 'actions/dashboard/branch/actions';
import { showMaterialDescriptionInEInvoice } from 'components/dashboard/freightBills/forms/freight_bill_account_utils';
export const FREIGHT_PRIORITIES = [
    {
        label: 'Manual',
        value: 'MANUAL',
    },
    {
        label: 'Actual',
        value: 'ACTUAL',
    },
    {
        label: 'Scheduled',
        value: 'SCHEDULED',
    },
    {
        label: 'Odometer',
        value: 'ODOMETER',
    },
];
const calcFreightFromAdditionalRateRanges = ({ currentConf, jobs, job, km = 0 }) => {
    const additionalRateRanges = get(currentConf, '[0].additionalCharges', []);
    const totalJobKm = sumBy(jobs, (job) => getKmBasedOnPriority(job));
    const jobKm = getValueBasedOnRateType(job, CONTRACT_RATE_TYPES.KM);
    const jobHours = getValueBasedOnRateType(job, CONTRACT_RATE_TYPES.HOUR);
    const hillKm = get(job, 'route.hillKm', 0);
    const jobsGroupedJobByVehicle = groupBy(jobs, 'vehicleId');
    const noOfJobsForVehicle = get(jobsGroupedJobByVehicle, `${get(job, 'vehicleId', 0)}.length`, []);
    let freightAmount = 0;
    const jobsCount = get(jobs, 'length');
    const jobBillAdditionalCharges = [];
    forEach(additionalRateRanges, (range) => {
        const additionalRateType = get(range, 'additionalRateType');
        const threshold = get(range, 'threshold');
        const rate = get(range, 'rate');
        const rateTwo = get(range, 'rateTwo');
        let currFreight = 0;
        let quantity = 0;
        switch (additionalRateType) {
            case CONTRACT_RATE_TYPES_ADDITIONAL.EXTRA_KM_PER_BILLING_CYCLE:
                if (totalJobKm > threshold) {
                    quantity = (totalJobKm - threshold) / jobsCount;
                    currFreight = rate * quantity;
                    freightAmount += currFreight;
                }
                break;
            case CONTRACT_RATE_TYPES_ADDITIONAL.KM_PER_JOB:
                quantity = jobKm;
                currFreight = rate * quantity;
                freightAmount += currFreight;

                break;
            case CONTRACT_RATE_TYPES_ADDITIONAL.EXTRA_HOURS_PER_JOB:
                if (jobHours > threshold) {
                    quantity = jobHours - threshold;
                    currFreight = rate * quantity;
                    freightAmount += currFreight;
                }
                break;
            case CONTRACT_RATE_TYPES_ADDITIONAL.FIXED_PER_JOB_ADDITIONAL:
                quantity = 1;
                currFreight = rate;
                freightAmount += currFreight;
                break;
            case CONTRACT_RATE_TYPES_ADDITIONAL.FIXED_PER_BILLING_CYCLE_ADDITIONAL:
                quantity = 1 / jobsCount;
                currFreight = rate * quantity;
                freightAmount += currFreight;

                break;
            case CONTRACT_RATE_TYPES_ADDITIONAL.HILL_KM:
                quantity = hillKm;
                currFreight = rate * quantity;
                freightAmount += currFreight;
                break;
            case CONTRACT_RATE_TYPES_ADDITIONAL.SLAB_WISE_KM:
                const quotient = Math.floor(jobKm / threshold);
                const remainder = jobKm % threshold;
                if (rate && rateTwo) {
                    quantity = jobKm;
                    currFreight = quotient * threshold * rate + remainder * rateTwo;
                    freightAmount += currFreight;
                }
                break;
            case CONTRACT_RATE_TYPES_ADDITIONAL.EXTRA_KM_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE:
                quantity = km / noOfJobsForVehicle;
                currFreight = quantity * rate;
                freightAmount += currFreight;
                break;
        }
        set(range, 'additionalAmount', round(currFreight, 2));
        set(range, 'clientContractAdditionalChargeId', get(range, 'id'));
        set(range, 'additionalRateType', get(range, 'additionalRateType'));
        set(range, 'quantity', round(quantity, 2));
        const rangeCopy = Object.assign({}, range);
        unset(rangeCopy, 'id');

        jobBillAdditionalCharges.push(rangeCopy);
    });
    set(job, 'jobBillAdditionalCharges', jobBillAdditionalCharges);
    return freightAmount;
};
const calcFreightFromRateRanges = (clientContractRanges, max) => {
    let freightAmount = 0;
    const size = get(clientContractRanges, 'length', 0);
    for (let i = 0; i < size; i++) {
        const range = clientContractRanges[i];
        const minV = get(range, 'minValue', 0) - 1;

        if (get(range, 'maxValue', 0) >= max) {
            freightAmount += get(range, 'freightAmount') * (max - minV);
            break;
        } else {
            freightAmount += get(range, 'freightAmount') * (get(range, 'maxValue', 0) - minV);
        }
    }
    const lastRange = clientContractRanges[size - 1];

    if (max > get(lastRange, 'maxValue')) {
        // freightAmount +=  get(lastRange, 'freightAmount') * (max - get(lastRange, 'maxValue'));
        freightAmount = -1;
    }
    return freightAmount;
};
const getKmBasedForSinglePriority = (p, job) => {
    const jobAnalyticsSize = get(job, 'jobAnalytics.length');
    const scheduledKm = get(job, 'route.distance');
    const manualKm = get(
        job,
        `jobAnalytics[${jobAnalyticsSize - 1}].actualDistance`,
        get(job, 'closingOdo') - get(job, 'openingOdo')
    );
    const actualKm = get(job, 'currentKm');
    const odometerKm = get(job, 'closingOdo') - get(job, 'openingOdo');
    if (p == 'MANUAL' && manualKm >= 0) return manualKm;
    else if (p == 'ACTUAL' && actualKm >= 0) return actualKm;
    else if (p == 'SCHEDULED' && scheduledKm >= 0) return scheduledKm;
    else if (p == 'ODOMETER' && +odometerKm >= 0) return odometerKm;
    return -1;
};
export const getKmPriorities = () => {
    let p1 = getFreightCalculationPriority1();
    let p2 = getFreightCalculationPriority2();
    let p3 = getFreightCalculationPriority3();
    let p4 = getFreightCalculationPriority4();
    const defaultPriorities = ['MANUAL', 'ACTUAL', 'SCHEDULED', 'ODOMETER'];
    if (includes(defaultPriorities, p1)) remove(defaultPriorities, (el) => el == p1);
    if (includes(defaultPriorities, p2)) remove(defaultPriorities, (el) => el == p2);
    if (includes(defaultPriorities, p3)) remove(defaultPriorities, (el) => el == p3);
    if (includes(defaultPriorities, p4)) remove(defaultPriorities, (el) => el == p4);
    if (!p1 && get(defaultPriorities, 'length') > 0) {
        p1 = defaultPriorities[0];
        remove(defaultPriorities, (el) => el == p1);
    }
    if (!p2 && get(defaultPriorities, 'length') > 0) {
        p2 = defaultPriorities[0];
        remove(defaultPriorities, (el) => el == p2);
    }
    if (!p3 && get(defaultPriorities, 'length') > 0) {
        p3 = defaultPriorities[0];
        remove(defaultPriorities, (el) => el == p3);
    }
    if (!p4 && get(defaultPriorities, 'length') > 0) {
        p4 = defaultPriorities[0];
        remove(defaultPriorities, (el) => el == p4);
    }
    return { p1, p2, p3, p4 };
};
export const getKmBasedOnPriority = (job) => {
    const { p1, p2, p3, p4 } = getKmPriorities();
    let km1 = getKmBasedForSinglePriority(p1, job);
    let km2 = getKmBasedForSinglePriority(p2, job);
    let km3 = getKmBasedForSinglePriority(p3, job);
    let km4 = getKmBasedForSinglePriority(p4, job);
    if (km1 != -1) return km1;
    if (km2 != -1) return km2;
    if (km3 != -1) return km3;
    if (km4 != -1) return km4;
    return 0;
};
const getValueBasedOnRateType = (job, rateType) => {
    const jobDuration = getMomentTime(get(job, 'endDate')).diff(getMomentTime(get(job, 'startDate')));
    const jobDurationInHrs = jobDuration / (1000 * 60 * 60);
    const jobDurationInDays = jobDurationInHrs / 24;
    switch (rateType) {
        case CONTRACT_RATE_TYPES.HOUR:
            return jobDurationInHrs;
        case CONTRACT_RATE_TYPES.DAY:
            return jobDurationInDays;
        case CONTRACT_RATE_TYPES.KM:
            const km = getKmBasedOnPriority(job);
            set(job, 'kmRun', km);
            return km;
        default:
            return -1;
    }
};
export const calculateBasicFreight = (jobs, clientContractChart) => {
    const clientContractConfigurations = get(clientContractChart, 'clientContractConfigurations');
    const jobsCount = get(jobs, 'length');
    const jobsGroupedJobByVehicle = groupBy(jobs, 'vehicleId');

    forEach(jobs, (job) => {
        let freightAmt = 0;
        const basicCharge = {};
        const currentConf = filter(clientContractConfigurations, (conf) => {
            return get(conf, 'deliveryType') == get(job, 'movementType');
        });
        if (get(currentConf, 'length') > 0) {
            const basicRateRanges = get(currentConf, '[0].basicRateRanges', []);
            const basicRateType = get(currentConf, '[0].basicRateType');
            const basicRateTypeVal = getValueBasedOnRateType(job, basicRateType);
            const jobKm = getKmBasedOnPriority(job);
            const jobVehicleId = get(job, 'vehicleId');
            let additionalKm = {};
            basicCharge.rateType = basicRateType;
            if (basicRateType === CONTRACT_RATE_TYPES.STEP_RATE_PER_VEHICLE_PER_BILLING_CYCLE) {
                forEach(jobs, (j) => {
                    let valueJustLessThanJobKmIndex = -1;
                    const jobKm = getKmBasedOnPriority(j);
                    const jobVehicleId = get(j, 'vehicleId');
                    forEach(basicRateRanges, (range, index) => {
                        const max = get(range, 'maxValue', 0);
                        if (jobKm >= max) {
                            valueJustLessThanJobKmIndex = index;
                        }
                    });
                    if (valueJustLessThanJobKmIndex !== -1) {
                        const range = basicRateRanges[valueJustLessThanJobKmIndex];

                        if (!additionalKm[jobVehicleId]) {
                            additionalKm[jobVehicleId] = jobKm - get(range, 'maxValue', 0);
                        } else {
                            additionalKm[jobVehicleId] =
                                additionalKm[jobVehicleId] + (jobKm - get(range, 'maxValue', 0));
                        }
                    }
                });
            }
            if (basicRateType) {
                if (basicRateTypeVal == -1) {
                    if (basicRateType == CONTRACT_RATE_TYPES.FIXED_PER_JOB) {
                        freightAmt += get(currentConf, '[0].freightAmount', 0);
                        basicCharge.quantity = 1;
                        basicCharge.amount = get(currentConf, '[0].freightAmount', 0);
                    } else if (basicRateType == CONTRACT_RATE_TYPES.FIXED_PER_BILLING_CYCLE_PER_DISTINCT_VEHICLE) {
                        const newRate = get(currentConf, '[0].freightAmount', 0);
                        const noOfJobsForVehicle = get(
                            jobsGroupedJobByVehicle,
                            `${get(job, 'vehicleId', 0)}.length`,
                            []
                        );

                        freightAmt += newRate / noOfJobsForVehicle;
                        basicCharge.amount = newRate / noOfJobsForVehicle;
                    } else if (basicRateType == CONTRACT_RATE_TYPES.STEP_RATE_PER_VEHICLE_PER_BILLING_CYCLE) {
                        let valueJustLessThanJobKmIndex = -1;
                        forEach(basicRateRanges, (range, index) => {
                            const max = get(range, 'maxValue', 0);
                            if (jobKm >= max) {
                                valueJustLessThanJobKmIndex = index;
                            }
                        });
                        if (valueJustLessThanJobKmIndex !== -1) {
                            const range = basicRateRanges[valueJustLessThanJobKmIndex];
                            basicCharge.amount = get(range, 'freightAmount', 0);
                            freightAmt += get(range, 'freightAmount', 0);
                        }
                    } else {
                        basicCharge.amount = get(currentConf, '[0].freightAmount', 0) / jobsCount;
                        freightAmt += get(currentConf, '[0].freightAmount', 0) / jobsCount;
                    }
                } else {
                    const freight = calcFreightFromRateRanges(basicRateRanges, basicRateTypeVal);
                    if (freight == -1) {
                        freightAmt = -1;
                    } else {
                        basicCharge.amount = freight;
                        basicCharge.quantity = basicRateTypeVal;
                        freightAmt += freight;
                    }
                }
            }

            if (freightAmt != -1) {
                set(job, 'basicFreightBeforeGST', round(freightAmt, 2));
            } else {
                set(job, 'basicFreightBeforeGST', 0);
            }
            if (freightAmt != -1) {
                const freight = calcFreightFromAdditionalRateRanges({
                    currentConf,
                    jobs,
                    job,
                    km: additionalKm[jobVehicleId],
                });
                set(job, 'additionalFreightBeforeGST', freight);
                freightAmt += freight;
            }
        }
        set(job, 'freightBeforeGST', round(freightAmt, 2));
        set(job, 'basicCharge', basicCharge);
    });
    return {
        jobsGroupedJobByVehicleKeys: Object.keys(jobsGroupedJobByVehicle),
        jobsCount,
    };
};
export const isSameNatureForClientContractCharts = (clientContractChartIds = [], clientContractCharts) => {
    let isMultipleBillingNatureError = false;
    const currentClientContractChart = find(clientContractChartIds, { id: clientContractChartIds[0] });
    forEach(clientContractChartIds, (id) => {
        const currentCCC = find(clientContractChartIds, { id });
        if (get(currentCCC, 'billingNature') !== get(currentClientContractChart, 'billingNature')) {
            isMultipleBillingNatureError = true;
            return;
        }
    });
    return !isMultipleBillingNatureError;
};

export const setGstInJobs = ({ jobs, chargeHeadState, isIGST, gstNature }) => {
    const { CGST = 0, IGST = 0, SGST = 0 } = chargeHeadState?.gstRate || {};

    let newJobs = JSON.parse(JSON.stringify(jobs));

    forEach(newJobs, (item) => {
        let freightAmount = get(item, 'freightBeforeGST', 0) + get(item, `jobBillDetailsList.surchargeValue`, 0);
        let billingSummaryIGST = 0;
        let billingSummaryCGST = 0;
        let billingSummarySGST = 0;
        if (gstNature !== GST_NATURES.EXEMPTED) {
            if (isIGST) {
                billingSummaryIGST = freightAmount * (IGST / 100);
            } else {
                billingSummaryCGST = freightAmount * (CGST / 100);
                billingSummarySGST = freightAmount * (SGST / 100);
            }
        }

        set(item, `jobBillDetailsList.IGST`, billingSummaryIGST);
        set(item, `jobBillDetailsList.CGST`, billingSummaryCGST);
        set(item, `jobBillDetailsList.SGST`, billingSummarySGST);

        set(item, `jobBillDetailsList.gstTotal`, isIGST ? billingSummaryIGST : billingSummaryCGST + billingSummarySGST);
    });
    return newJobs;
};
export const getLedgerOptions = (ledgers) => {
    return mappedOptionsFromArray(ledgers, 'ledgerName');
};
export const getStartDateAndEndDateBasedOnBillingNature = (billingNature, billingDate) => {
    const lastMonthRange = getLastMonthRange(getMomentTime(billingDate));
    const lastYearRange = getLastYearRangeFromASpecificDate(getMomentTime(billingDate));
    const lastQuarterDateRange = getLastQuarterRange(getMomentTime(billingDate));
    switch (billingNature) {
        case BILLING_NATURE.MONTHLY:
            return { startDate: get(lastMonthRange, '[0]'), endDate: get(lastMonthRange, '[1]') };
        case BILLING_NATURE.QUARTERLY:
            return { startDate: get(lastQuarterDateRange, '[0]'), endDate: get(lastQuarterDateRange, '[1]') };
        case BILLING_NATURE.YEARLY:
            return { startDate: get(lastYearRange, '[0]'), endDate: get(lastYearRange, '[1]') };
        default:
            return { startDate: null, endDate: null };
    }
};
export const getLedgerDetailFields = (values, summaryData, otherParams = {}) => {
    const { basicDetails } = values;
    const { shouldCalcFreightFromJobs, summaryDataForCustomLineItems } = otherParams;
    return [
        {
            name: 'ledgerDetails.salesLedgerEntry.ledgerId',
            disabled: false,
            label: 'Sales ledger Entry',
            optionsFilter: getLedgerOptions,
            shouldShowField: true,
            isRequired: true,
        },
        {
            name: `ledgerDetails.discountLedgerEntry.ledgerId`,
            label: 'Discount Ledger Entry',
            optionsFilter: getLedgerOptions,
            shouldShowField: +get(summaryData, 'totalDiscountAmount') > 0 ? true : false,
            isRequired: true,
        },
        {
            name: 'ledgerDetails.sgstLedgerEntry.ledgerId',
            label: 'SGST Ledger Entry',
            optionsFilter: getLedgerOptions,
            shouldShowField: shouldCalcFreightFromJobs
                ? +get(summaryData, 'sgstTotalAmount') > 0
                : +get(summaryDataForCustomLineItems, 'sgstTotalAmount') > 0,
            isRequired: basicDetails.gstNature !== 'EXEMPTED',
        },
        {
            name: `ledgerDetails.cgstLedgerEntry.ledgerId`,
            label: 'CGST Ledger Entry',
            optionsFilter: getLedgerOptions,
            shouldShowField: shouldCalcFreightFromJobs
                ? +get(summaryData, 'cgstTotalAmount') > 0
                : +get(summaryDataForCustomLineItems, 'cgstTotalAmount') > 0,
            isRequired: basicDetails.gstNature !== 'EXEMPTED',
        },
        {
            name: `ledgerDetails.igstLedgerEntry.ledgerId`,
            label: 'IGST Ledger Entry',
            optionsFilter: getLedgerOptions,
            shouldShowField: shouldCalcFreightFromJobs
                ? +get(summaryData, 'igstTotalAmount') > 0
                : +get(summaryDataForCustomLineItems, 'igstTotalAmount') > 0,
            isRequired: basicDetails.gstNature !== 'EXEMPTED',
        },
        {
            name: `ledgerDetails.extraLedgerEntryOne.ledgerId`,
            label: 'Extra Ledger Entry I',
            optionsFilter: getLedgerOptions,
            shouldShowField: +get(summaryData, 'extraTotalChargesOne') > 0,
            isRequired: basicDetails.gstNature !== 'EXEMPTED',
        },
        {
            name: `ledgerDetails.extraLedgerEntryTwo.ledgerId`,
            label: 'Extra Ledger Entry II',
            optionsFilter: getLedgerOptions,
            shouldShowField: +get(summaryData, 'extraTotalChargesTwo') > 0,
            isRequired: basicDetails.gstNature !== 'EXEMPTED',
        },
        {
            name: `ledgerDetails.extraLedgerEntryThree.ledgerId`,
            label: 'Extra Ledger Entry III',
            optionsFilter: getLedgerOptions,
            shouldShowField: +get(summaryData, 'extraTotalChargesThree') > 0,
            isRequired: basicDetails.gstNature !== 'EXEMPTED',
        },
    ];
};

export const calculateSummaryForJobs = (jobs) => {
    let freightTotalAmount = 0;
    let totalDiscountAmount = 0;
    let igstTotalAmount = 0;
    let sgstTotalAmount = 0;
    let cgstTotalAmount = 0;
    let extraTotalChargesOne = 0;
    let extraTotalChargesTwo = 0;
    let extraTotalChargesThree = 0;
    let surchargeTotalValue = 0;

    forEach(jobs, (item) => {
        const totalDiscount = get(item, 'discountAmount', get(item, `totalDiscount`, 0));
        const freightBeforeGST = get(item, 'freightBeforeGST', 0);
        const igstAmount = get(item, 'jobBillDetailsList.IGST', 0);
        const sgstAmount = get(item, 'jobBillDetailsList.SGST', 0);
        const cgstAmount = get(item, 'jobBillDetailsList.CGST', 0);
        const extraChargesOne = get(item, 'jobBillDetailsList.extraChargesOne', 0);
        const extraChargesTwo = get(item, 'jobBillDetailsList.extraChargesTwo', 0);
        const extraChargesThree = get(item, 'jobBillDetailsList.extraChargesThree', 0);
        const surchargeValue = get(item, 'jobBillDetailsList.surchargeValue', 0);

        freightTotalAmount += freightBeforeGST;
        totalDiscountAmount += totalDiscount;
        igstTotalAmount += igstAmount;
        sgstTotalAmount += sgstAmount;
        cgstTotalAmount += cgstAmount;
        extraTotalChargesOne += extraChargesOne;
        extraTotalChargesTwo += extraChargesTwo;
        extraTotalChargesThree += extraChargesThree;
        surchargeTotalValue += surchargeValue;
    });
    return {
        freightTotalAmount: round(freightTotalAmount, 2),
        totalDiscountAmount: round(totalDiscountAmount, 2),
        igstTotalAmount: round(igstTotalAmount, 2),
        sgstTotalAmount: round(sgstTotalAmount, 2),
        cgstTotalAmount: round(cgstTotalAmount, 2),
        extraTotalChargesOne: round(extraTotalChargesOne, 2),
        extraTotalChargesTwo: round(extraTotalChargesTwo, 2),
        extraTotalChargesThree: round(extraTotalChargesThree, 2),
        surchargeTotalValue: round(surchargeTotalValue, 2),
    };
};

export const prepareDataForFreightBill = (
    values,
    { jobs, selectedJobs, summaryDataForCustomLineItems, shouldCalcFreightFromJobs }
) => {
    let data = { ...values };
    let isError = false;
    set(data, 'billMappingType', 'JOB_MAPPING');

    set(data, 'basicDetails.billingOfficeId', get(data, 'branch'));
    data = omit(data, 'branch');

    set(data, 'basicDetails.billNumber', get(data, 'stationaryNumber'));
    data = omit(data, 'stationaryNumber');

    set(data, 'basicDetails.billStationaryId', get(data, 'stationaryId'));
    data = omit(data, 'stationaryId');

    if (shouldCalcFreightFromJobs) {
        let jobDetailsList = [];
        let filteredJobs = filter(jobs, (item, index) => includes(selectedJobs, item.id));
        let freightTotalAmount = 0;
        let totalDiscountAmount = 0;
        let igstTotalAmount = 0;
        let sgstTotalAmount = 0;
        let cgstTotalAmount = 0;
        let extraTotalChargesOne = 0;
        let extraTotalChargesTwo = 0;
        let extraTotalChargesThree = 0;
        let totalSurchargeValue = 0;

        const entityToIterate = filteredJobs;
        const property = 'jobBillDetailsList';
        const gstNature = get(values, 'basicDetails.gstNature');

        forEach(entityToIterate, (item, index) => {
            const totalDiscount = get(
                item,
                'billingSummary.discountAmount',
                get(item, `billingSummary.totalDiscount`, 0)
            );
            const freightBeforeGST = get(item, 'freightBeforeGST', -1);
            if (freightBeforeGST == -1) {
                isError = true;
                return {
                    isError,
                    data,
                };
            }

            const igstAmount = +get(item, `${property}.IGST`, 0);
            const sgstAmount = +get(item, `${property}.SGST`, 0);
            const cgstAmount = +get(item, `${property}.CGST`, 0);
            const gstTotal = +get(item, `${property}.gstTotal`, 0);
            const otherChargesTotal = get(item, `${property}.otherCharges`, 0);
            const extraChargesOne = get(item, `${property}.extraChargesOne`, 0);
            const extraChargesTwo = get(item, `${property}.extraChargesTwo`, 0);
            const extraChargesThree = get(item, `${property}.extraChargesThree`, 0);
            const fuelSurcharge = get(item, `${property}.fuelSurcharge`, 0);
            const surchargeValue = get(item, `${property}.surchargeValue`, 0);
            const basicFreightBeforeGST = get(item, 'basicFreightBeforeGST', 0);
            const jobBillAdditionalCharges = get(item, 'jobBillAdditionalCharges', []);
            const tax = gstNature !== GST_NATURES.FCM ? 0 : igstAmount + sgstAmount + cgstAmount;

            freightTotalAmount += freightBeforeGST;
            totalDiscountAmount += totalDiscount;
            igstTotalAmount += igstAmount;
            sgstTotalAmount += sgstAmount;
            cgstTotalAmount += cgstAmount;
            extraTotalChargesOne += extraChargesOne;
            extraTotalChargesTwo += extraChargesTwo;
            extraTotalChargesThree += extraChargesThree;

            const idKey = `jobId`;
            const totalAmount = +(freightBeforeGST + tax + extraChargesOne + extraChargesTwo + extraChargesThree);
            let jobBillList = {
                [idKey]: get(item, 'id', ''),
                totalDiscount,
                freightBeforeGST,
                igstAmount: igstAmount % 1 != 0 ? igstAmount.toFixed(5) : igstAmount,
                sgstAmount: sgstAmount % 1 != 0 ? sgstAmount.toFixed(5) : sgstAmount,
                cgstAmount: cgstAmount % 1 != 0 ? cgstAmount.toFixed(5) : cgstAmount,
                gstTotal: gstTotal % 1 != 0 ? gstTotal.toFixed(5) : gstTotal,
                otherChargesTotal,
                total: totalAmount % 1 != 0 ? totalAmount.toFixed(5) : totalAmount,
                kmRun: get(item, 'kmRun'),
                basicFreightBeforeGST,
                jobBillAdditionalCharges,
            };

            if (extraChargesOne) {
                set(jobBillList, 'extraChargesOne', extraChargesOne);
            }
            if (extraChargesTwo) {
                set(jobBillList, 'extraChargesTwo', extraChargesTwo);
            }
            if (extraChargesThree) {
                set(jobBillList, 'extraChargesThree', extraChargesThree);
            }
            if (fuelSurcharge) {
                set(jobBillList, 'fuelSurcharge', fuelSurcharge);
            }
            if (surchargeValue) {
                set(jobBillList, 'surchargeValue', surchargeValue);
                totalSurchargeValue += surchargeValue;
            }

            set(jobBillList, 'freightBeforeGST', freightBeforeGST);
            jobDetailsList.push(jobBillList);
        });

        const igstTotalModified = igstTotalAmount % 1 != 0 ? igstTotalAmount.toFixed(5) : igstTotalAmount;
        const cgstTotalModified = cgstTotalAmount % 1 != 0 ? cgstTotalAmount.toFixed(5) : cgstTotalAmount;
        const sgstTotalModified = sgstTotalAmount % 1 != 0 ? sgstTotalAmount.toFixed(5) : sgstTotalAmount;
        set(data, 'basicDetails.igstAmount', igstTotalModified);
        set(data, 'basicDetails.sgstAmount', sgstTotalModified);
        set(data, 'basicDetails.cgstAmount', cgstTotalModified);
        set(data, 'basicDetails.discountAmount', totalDiscountAmount);
        set(data, 'basicDetails.freightAmount', freightTotalAmount);
        set(data, 'basicDetails.gstTotal', igstTotalAmount + sgstTotalAmount + cgstTotalAmount);
        set(data, 'basicDetails.extraOneAmount', extraTotalChargesOne);
        set(data, 'basicDetails.extraTwoAmount', extraTotalChargesTwo);
        set(data, 'basicDetails.extraThreeAmount', extraTotalChargesThree);

        if (data.ledgerDetails?.sgstLedgerEntry?.ledgerId && sgstTotalModified > 0) {
            set(data, 'ledgerDetails.sgstLedgerEntry.amount', sgstTotalModified);
        } else {
            data = omit(data, 'ledgerDetails.sgstLedgerEntry');
        }

        if (data.ledgerDetails?.igstLedgerEntry?.ledgerId && igstTotalModified > 0) {
            set(data, 'ledgerDetails.igstLedgerEntry.amount', igstTotalModified || 0);
        } else {
            data = omit(data, 'ledgerDetails.igstLedgerEntry');
        }

        if (data.ledgerDetails?.cgstLedgerEntry?.ledgerId && cgstTotalModified > 0) {
            set(data, 'ledgerDetails.cgstLedgerEntry.amount', cgstTotalModified || 0);
        } else {
            data = omit(data, 'ledgerDetails.cgstLedgerEntry');
        }

        if (+extraTotalChargesOne > 0) {
            set(data, 'ledgerDetails.extraLedgerEntryOne.amount', extraTotalChargesOne);
        } else {
            data = omit(data, 'ledgerDetails.extraLedgerEntryOne');
        }

        if (+extraTotalChargesTwo > 0) {
            set(data, 'ledgerDetails.extraLedgerEntryTwo.amount', extraTotalChargesTwo);
        } else {
            data = omit(data, 'ledgerDetails.extraLedgerEntryTwo');
        }

        if (+extraTotalChargesThree > 0) {
            set(data, 'ledgerDetails.extraLedgerEntryThree.amount', extraTotalChargesThree);
        } else {
            data = omit(data, 'ledgerDetails.extraLedgerEntryThree');
        }

        if (data.ledgerDetails?.salesLedgerEntry?.ledgerId) {
            set(
                data,
                'ledgerDetails.salesLedgerEntry.amount',
                freightTotalAmount + totalDiscountAmount + totalSurchargeValue
            );
        } else {
            data = omit(data, 'ledgerDetails.salesLedgerEntry');
        }

        if (data.ledgerDetails?.discountLedgerEntry?.ledgerId && totalDiscountAmount > 0) {
            set(data, 'ledgerDetails.discountLedgerEntry.amount', totalDiscountAmount);
        } else {
            data = omit(data, 'ledgerDetails.discountLedgerEntry');
        }
        set(data, 'jobBillDetailsList', jobDetailsList);

        unset(data, 'routeIds');
        unset(data, 'clientContractChartId');
        if (gstNature == GST_NATURES.EXEMPTED) {
            unset(data, 'basicDetails.chargeHeadId');
        }
        set(data, 'kmCalculationPrioirities', getKmPriorities());

        return {
            data,
            isError,
        };
    } else {
        const {
            amount,
            totalAmount,
            igstTotalAmount,
            sgstTotalAmount,
            cgstTotalAmount,
        } = summaryDataForCustomLineItems;
        const gstNature = get(values, 'basicDetails.gstNature');
        set(data, 'basicDetails.freightAmount', amount);
        set(data, 'basicDetails.gstTotal', igstTotalAmount + sgstTotalAmount + cgstTotalAmount);
        set(data, 'basicDetails.igstAmount', igstTotalAmount);
        set(data, 'basicDetails.sgstAmount', sgstTotalAmount);
        set(data, 'basicDetails.cgstAmount', cgstTotalAmount);
        let jobDetailsList = [];
        let jobName = '';
        forEach(jobs, (item) => {
            const totalDiscount = get(
                item,
                'billingSummary.discountAmount',
                get(item, `billingSummary.totalDiscount`, 0)
            );
            const freightBeforeGST = get(item, 'freightBeforeGST', -1);
            if (freightBeforeGST == -1) {
                isError = true;
                return {
                    isError,
                    data,
                };
            }
            if (getFreightCalculationPriority1() === 'ODOMETER') {
                const km = getKmBasedForSinglePriority('ODOMETER', item);

                if (km === -1) {
                    isError = true;
                    jobName += get(item, 'name') + ', ';
                }
            }
            const property = 'jobBillDetailsList';
            const igstAmount = +get(item, `${property}.IGST`, 0);
            const sgstAmount = +get(item, `${property}.SGST`, 0);
            const cgstAmount = +get(item, `${property}.CGST`, 0);
            const gstTotal = +get(item, `${property}.gstTotal`, 0);
            const otherChargesTotal = get(item, `${property}.otherCharges`, 0);

            const basicFreightBeforeGST = get(item, 'basicFreightBeforeGST', 0);
            const jobBillAdditionalCharges = get(item, 'jobBillAdditionalCharges', []);
            const tax = gstNature !== GST_NATURES.FCM ? 0 : igstAmount + sgstAmount + cgstAmount;

            const idKey = `jobId`;
            const totalAmount = +(freightBeforeGST + tax);
            let jobBillList = {
                [idKey]: get(item, 'id', ''),
                totalDiscount,
                freightBeforeGST,
                igstAmount: igstAmount % 1 != 0 ? igstAmount.toFixed(5) : igstAmount,
                sgstAmount: sgstAmount % 1 != 0 ? sgstAmount.toFixed(5) : sgstAmount,
                cgstAmount: cgstAmount % 1 != 0 ? cgstAmount.toFixed(5) : cgstAmount,
                gstTotal: gstTotal % 1 != 0 ? gstTotal.toFixed(5) : gstTotal,
                otherChargesTotal,
                total: totalAmount % 1 != 0 ? totalAmount.toFixed(5) : totalAmount,
                kmRun: get(item, 'kmRun'),
                basicFreightBeforeGST,
                jobBillAdditionalCharges,
            };
            set(jobBillList, 'freightBeforeGST', freightBeforeGST);
            jobDetailsList.push(jobBillList);
        });
        set(data, 'jobBillDetailsList', jobDetailsList);

        if (data.ledgerDetails?.salesLedgerEntry?.ledgerId) {
            set(data, 'ledgerDetails.salesLedgerEntry.amount', amount);
        } else {
            data = omit(data, 'ledgerDetails.salesLedgerEntry');
        }

        if (data.ledgerDetails?.sgstLedgerEntry?.ledgerId && sgstTotalAmount > 0) {
            set(data, 'ledgerDetails.sgstLedgerEntry.amount', sgstTotalAmount);
        } else {
            data = omit(data, 'ledgerDetails.sgstLedgerEntry');
        }

        if (data.ledgerDetails?.igstLedgerEntry?.ledgerId && igstTotalAmount > 0) {
            set(data, 'ledgerDetails.igstLedgerEntry.amount', igstTotalAmount || 0);
        } else {
            data = omit(data, 'ledgerDetails.igstLedgerEntry');
        }

        if (data.ledgerDetails?.cgstLedgerEntry?.ledgerId && cgstTotalAmount > 0) {
            set(data, 'ledgerDetails.cgstLedgerEntry.amount', cgstTotalAmount || 0);
        } else {
            data = omit(data, 'ledgerDetails.cgstLedgerEntry');
        }
        if (!data.ledgerDetails?.extraLedgerEntryOne?.ledgerId) {
            data = omit(data, 'ledgerDetails.extraLedgerEntryOne');
        }
        if (!data.ledgerDetails?.extraLedgerEntryTwo?.ledgerId) {
            data = omit(data, 'ledgerDetails.extraLedgerEntryTwo');
        }
        if (!data.ledgerDetails?.extraLedgerEntryThree?.ledgerId) {
            data = omit(data, 'ledgerDetails.extraLedgerEntryThree');
        }
        set(data, 'clientContractChartIds', get(data, 'clientContractChartId'));
        return {
            data,
            isError,
            jobName,
        };
    }
};

export const prepareDataForCNFreightBill = ({
    values,
    selectedConsignments,
    consignments,
    consignmentBillDetailsListInitial,
}) => {
    let data = JSON.parse(JSON.stringify(values));

    const gstNature = get(data, 'basicDetails.gstNature');
    set(data, 'basicDetails.billingOfficeId', get(data, 'branch'));
    data = omit(data, 'branch');

    set(data, 'basicDetails.billNumber', get(data, 'stationaryNumber'));
    data = omit(data, 'stationaryNumber');

    set(data, 'basicDetails.billStationaryId', get(data, 'stationaryId'));
    data = omit(data, 'stationaryId');

    let consignmentBillDetailsList = [];
    let filteredConsignments = filter(consignments, (item, index) => includes(selectedConsignments, item.id));

    let freightTotalAmount = 0;
    let totalDiscountAmount = 0;
    let igstTotalAmount = 0;
    let sgstTotalAmount = 0;
    let cgstTotalAmount = 0;
    let vatTotalAmount = 0;
    let detentionTotalAmount = 0;
    let extraTotalChargesOne = 0;
    let extraTotalChargesTwo = 0;
    let extraTotalChargesThree = 0;
    let totalSurchargeValue = 0;
    let customOtherChargesWithoutLedger = 0;
    let otherChargesTotalAllCN = 0;

    const entityToIterate = filteredConsignments;
    const property = 'billingSummary';

    forEach(entityToIterate, (item, index) => {
        const totalDiscount = get(item, 'billingSummary.discountAmount', get(item, `billingSummary.totalDiscount`, 0));
        const detentionAmount = get(item, `${property}.detentionAmount`, 0);
        const freightBeforeGST = +get(item, 'billingSummary.freightBeforeGST', 0);
        const igstAmount = +get(item, `${property}.IGST`, 0);
        const sgstAmount = +get(item, `${property}.SGST`, 0);
        const cgstAmount = +get(item, `${property}.CGST`, 0);
        const vatAmount = +get(item, `${property}.VAT`, 0);
        const gstTotal = +get(item, `${property}.gstTotal`, 0);
        const otherChargesTotal = get(item, `${property}.otherCharges`, 0);
        const extraChargesOne = get(item, `${property}.extraChargesOne`, 0);
        const extraChargesTwo = get(item, `${property}.extraChargesTwo`, 0);
        const extraChargesThree = get(item, `${property}.extraChargesThree`, 0);

        freightTotalAmount += freightBeforeGST;
        totalDiscountAmount += totalDiscount;
        igstTotalAmount += igstAmount;
        sgstTotalAmount += sgstAmount;
        cgstTotalAmount += cgstAmount;
        extraTotalChargesOne += extraChargesOne;
        extraTotalChargesTwo += extraChargesTwo;
        extraTotalChargesThree += extraChargesThree;
        vatTotalAmount += vatAmount;
        detentionTotalAmount += detentionAmount;

        const idKey = `consignmentId`;
        const tax = isInternationalAccount()
            ? vatAmount
            : get(values, 'basicDetails.gstNature') !== GST_NATURES.FCM
            ? 0
            : igstAmount + sgstAmount + cgstAmount;
        const totalAmount = +(
            freightBeforeGST +
            tax +
            extraChargesOne +
            extraChargesTwo +
            extraChargesThree +
            detentionAmount
        );
        let bill = find(consignmentBillDetailsListInitial, { consignmentId: get(item, 'id') });
        if (!bill) {
            bill = {};
        }
        forEach(get(item, 'customOtherCharges', []), (charge) => {
            if (!get(charge, 'ledgerId')) {
                customOtherChargesWithoutLedger += +get(charge, 'chargeAmount', 0);
            }
        });
        otherChargesTotalAllCN += otherChargesTotal;

        let consignmentBillList = {
            ...bill,
            [idKey]: get(item, 'id', ''),
            totalDiscount,
            freightBeforeGST,
            igstAmount: !isInteger(igstAmount) ? igstAmount.toFixed(5) : igstAmount,
            sgstAmount: !isInteger(sgstAmount) ? sgstAmount.toFixed(5) : sgstAmount,
            cgstAmount: !isInteger(cgstAmount) ? cgstAmount.toFixed(5) : cgstAmount,
            vatAmount: !isInteger(vatAmount) ? vatAmount.toFixed(5) : vatAmount,
            gstTotal: !isInteger(gstTotal) ? gstTotal.toFixed(5) : gstTotal,
            otherChargesTotal,
            total: !isInteger(totalAmount) ? totalAmount.toFixed(5) : totalAmount,
            kmRun: get(item, 'kmRun'),
        };

        if (+extraChargesOne != 0) {
            set(consignmentBillList, 'extraChargesOne', extraChargesOne);
        }
        if (+extraChargesTwo != 0) {
            set(consignmentBillList, 'extraChargesTwo', extraChargesTwo);
        }
        if (+extraChargesThree != 0) {
            set(consignmentBillList, 'extraChargesThree', extraChargesThree);
        }
        if (+detentionAmount != 0) {
            set(consignmentBillList, 'detentionAmount', detentionAmount);
        }

        consignmentBillDetailsList.push(consignmentBillList);
    });

    igstTotalAmount = +(!isInteger(igstTotalAmount) ? igstTotalAmount.toFixed(5) : igstTotalAmount);
    cgstTotalAmount = +(!isInteger(cgstTotalAmount) ? cgstTotalAmount.toFixed(5) : cgstTotalAmount);
    sgstTotalAmount = +(!isInteger(sgstTotalAmount) ? sgstTotalAmount.toFixed(5) : sgstTotalAmount);
    vatTotalAmount = +(!isInteger(vatTotalAmount) ? vatTotalAmount.toFixed(5) : vatTotalAmount);
    set(data, 'basicDetails.igstAmount', igstTotalAmount);
    set(data, 'basicDetails.sgstAmount', sgstTotalAmount);
    set(data, 'basicDetails.cgstAmount', cgstTotalAmount);
    set(data, 'basicDetails.vatAmount', vatTotalAmount);
    set(data, 'basicDetails.discountAmount', totalDiscountAmount);
    set(data, 'basicDetails.freightAmount', freightTotalAmount);
    set(data, 'basicDetails.gstTotal', igstTotalAmount + sgstTotalAmount + cgstTotalAmount + vatTotalAmount);
    set(data, 'basicDetails.extraOneAmount', extraTotalChargesOne);
    set(data, 'basicDetails.extraTwoAmount', extraTotalChargesTwo);
    set(data, 'basicDetails.extraThreeAmount', extraTotalChargesThree);

    if (data.ledgerDetails?.sgstLedgerEntry?.ledgerId && sgstTotalAmount > 0) {
        set(data, 'ledgerDetails.sgstLedgerEntry.amount', sgstTotalAmount);
    } else {
        data = omit(data, 'ledgerDetails.sgstLedgerEntry');
    }

    if (data.ledgerDetails?.igstLedgerEntry?.ledgerId && igstTotalAmount > 0) {
        set(data, 'ledgerDetails.igstLedgerEntry.amount', igstTotalAmount || 0);
    } else {
        data = omit(data, 'ledgerDetails.igstLedgerEntry');
    }

    if (data.ledgerDetails?.cgstLedgerEntry?.ledgerId && cgstTotalAmount > 0) {
        set(data, 'ledgerDetails.cgstLedgerEntry.amount', cgstTotalAmount || 0);
    } else {
        data = omit(data, 'ledgerDetails.cgstLedgerEntry');
    }
    if (data.ledgerDetails?.vatLedgerEntry?.ledgerId && vatTotalAmount > 0) {
        set(data, 'ledgerDetails.vatLedgerEntry.amount', vatTotalAmount || 0);
    } else {
        data = omit(data, 'ledgerDetails.vatLedgerEntry');
    }

    if (+extraTotalChargesOne != 0) {
        set(data, 'ledgerDetails.extraLedgerEntryOne.amount', extraTotalChargesOne);
    } else {
        data = omit(data, 'ledgerDetails.extraLedgerEntryOne');
    }

    if (+extraTotalChargesTwo != 0) {
        set(data, 'ledgerDetails.extraLedgerEntryTwo.amount', extraTotalChargesTwo);
    } else {
        data = omit(data, 'ledgerDetails.extraLedgerEntryTwo');
    }

    if (+extraTotalChargesThree != 0) {
        set(data, 'ledgerDetails.extraLedgerEntryThree.amount', extraTotalChargesThree);
    } else {
        data = omit(data, 'ledgerDetails.extraLedgerEntryThree');
    }

    if (data.ledgerDetails?.salesLedgerEntry?.ledgerId) {
        set(
            data,
            'ledgerDetails.salesLedgerEntry.amount',
            freightTotalAmount +
                totalDiscountAmount +
                totalSurchargeValue +
                detentionTotalAmount +
                (!multipleFreightBillForCN() ? customOtherChargesWithoutLedger - otherChargesTotalAllCN : 0)
        );
    } else {
        data = omit(data, 'ledgerDetails.salesLedgerEntry');
    }

    if (data.ledgerDetails?.discountLedgerEntry?.ledgerId && totalDiscountAmount > 0) {
        set(data, 'ledgerDetails.discountLedgerEntry.amount', totalDiscountAmount);
    } else {
        data = omit(data, 'ledgerDetails.discountLedgerEntry');
    }

    if (get(consignmentBillDetailsList, 'length') <= 0) {
        return {
            data,
            error: true,
        };
    }

    set(data, 'consignmentBillDetailsList', consignmentBillDetailsList);
    set(
        data,
        'basicDetails.billingDate',
        getFormattedTimeStringForAPI(get(values, 'basicDetails.billingDate'), DATE_FORMAT_TIMESTAMP_T)
    );
    set(
        data,
        'basicDetails.submissionDate',
        getFormattedTimeStringForAPI(get(values, 'basicDetails.submissionDate'), DATE_FORMAT_TIMESTAMP_T)
    );

    if (gstNature == GST_NATURES.EXEMPTED) {
        unset(data, 'basicDetails.chargeHeadId');
    }
    set(data, 'customFields', handleCustomFieldsNull(get(data, 'customFields')));
    if (!get(data, 'basicDetails.gstNature')) unset(data, 'basicDetails.gstNature');
    return {
        data,
    };
};
export const BUSINESS_TYPES = {
    NON_ECOMMERCE: 'NON_ECOMMERCE',
    ECOMMERCE: 'ECOMMERCE',
    BOTH: 'BOTH',
};

export const billsToBusinessTypeMap = {
    jobFreightBill: [BUSINESS_TYPES.BOTH, BUSINESS_TYPES.ECOMMERCE],
    freightBill: [BUSINESS_TYPES.NON_ECOMMERCE, BUSINESS_TYPES.BOTH],
    supplementaryBill: [BUSINESS_TYPES.NON_ECOMMERCE, BUSINESS_TYPES.ECOMMERCE, BUSINESS_TYPES.BOTH],
    nonSupplementaryBill: [BUSINESS_TYPES.NON_ECOMMERCE, BUSINESS_TYPES.ECOMMERCE, BUSINESS_TYPES.BOTH],
    vendorBill: [BUSINESS_TYPES.NON_ECOMMERCE, BUSINESS_TYPES.ECOMMERCE, BUSINESS_TYPES.BOTH],
};
export const STATE_CODE = [
    {
        label: 'JAMMU AND KASHMIR',
        value: 1,
    },
    {
        label: 'HIMACHAL PRADESH',
        value: 2,
    },
    {
        label: 'PUNJAB',
        value: 3,
    },
    {
        label: 'CHANDIGARH',
        value: 4,
    },
    {
        label: 'UTTARAKHAND',
        value: 5,
    },
    {
        label: 'HARYANA',
        value: 6,
    },
    {
        label: 'DELHI',
        value: 7,
    },
    {
        label: 'RAJASTHAN',
        value: 8,
    },
    {
        label: 'UTTAR PRADESH',
        value: 9,
    },
    {
        label: 'BIHAR',
        value: 10,
    },
    {
        label: 'SIKKIM',
        value: 11,
    },
    {
        label: 'ARUNACHAL PRADESH',
        value: 12,
    },
    {
        label: 'NAGALAND',
        value: 13,
    },
    {
        label: 'MANIPUR',
        value: 14,
    },
    {
        label: 'MIZORAM',
        value: 15,
    },
    {
        label: 'TRIPURA',
        value: 16,
    },
    {
        label: 'MEGHALAYA',
        value: 17,
    },
    {
        label: 'ASSAM',
        value: 18,
    },
    {
        label: 'WEST BENGAL',
        value: 19,
    },
    {
        label: 'JHARKHAND',
        value: 20,
    },
    {
        label: 'ORISSA',
        value: 21,
    },
    {
        label: 'CHHATTISGARH',
        value: 22,
    },
    {
        label: 'MADHYA PRADESH',
        value: 23,
    },
    {
        label: 'GUJARAT',
        value: 24,
    },
    {
        label: 'DADAR AND NAGAR HAVELI & DAMAN AND DIU',
        value: 26,
    },
    {
        label: 'MAHARASHTRA',
        value: 27,
    },
    {
        label: 'KARNATAKA',
        value: 29,
    },
    {
        label: 'GOA',
        value: 30,
    },
    {
        label: 'LAKSHADWEEP',
        value: 31,
    },
    {
        label: 'KERALA',
        value: 32,
    },
    {
        label: 'TAMIL NADU',
        value: 33,
    },
    {
        label: 'PUDUCHERRY',
        value: 34,
    },
    {
        label: 'ANDAMAN AND NICOBAR',
        value: 35,
    },
    {
        label: 'TELANGANA',
        value: 36,
    },
    {
        label: 'ANDHRA PRADESH',
        value: 37,
    },
    {
        label: 'LADAKH',
        value: 38,
    },
    {
        label: 'OTHER TERRITORY',
        value: 97,
    },
    {
        label: 'OTHER COUNTRY',
        value: 99,
    },
];

const splitStringFromLastComma = (inputString) => {
    if (!inputString) {
        return ['', ''];
    }
    const lastCommaIndex = inputString.lastIndexOf(',');
    if (lastCommaIndex !== -1) {
        const beforeLastComma = inputString.substring(0, lastCommaIndex);
        const afterLastComma = inputString.substring(lastCommaIndex + 1);
        return [beforeLastComma.trim(), afterLastComma.trim()];
    } else {
        return [inputString.trim(), ''];
    }
};

export const getAddresses = (address = '') => {
    let addr1 = get(address, 'addressLine1', '');
    let addr2 = get(address, 'addressLine2', '');
    if (!addr2) {
        const separatedString = splitStringFromLastComma(addr1);
        addr1 = separatedString[0];
        addr2 = separatedString[1];
    }
    return [addr1, addr2];
};

export const getCustomChargeTaxAmount = (consignment, { taxType, gstRates }) => {
    const customCharges = get(consignment, 'customOtherCharges', []);
    let totalTaxAmount = 0;
    forEach(customCharges, (charge) => {
        const gstRateId = get(charge, 'gstRateId');
        const chargeAmount = get(charge, 'chargeAmount');
        const currentRateInfo = get(gstRates, gstRateId, {});
        const taxRate = get(currentRateInfo, taxType, 0);
        const taxAmount = (chargeAmount * taxRate) / 100;
        totalTaxAmount += taxAmount;
    });
    return totalTaxAmount;
};

export const getInvoiceNumber = (billNumber) => {
    const lastDashIndex = billNumber.lastIndexOf('-');
    const beforeLastDash = billNumber.substring(0, lastDashIndex);
    const afterLastDash = billNumber.substring(lastDashIndex + 1);
    return `${beforeLastDash}${afterLastDash}`;
};

export const prepareEInvoiceDataForFreightBill = (
    freightBillData = {},
    branches,
    customers,
    chargeHeadData,
    consignmentsData,
    jobBookingData
) => {
    const billingOfficeBranch = find(branches, { id: get(freightBillData, 'basicDetails.billingOfficeId') });
    // const branchDtos = find(customers, { id: get(freightBillData, 'basicDetails.billingPartyId') })?.branchDtos || [];
    const branchDtos = get(customers, 'branchDtos', []);
    const gstNature = get(freightBillData, 'basicDetails.gstNature');
    let buyerBranch = find(branchDtos, (branch) => {
        if (branch?.address.state == get(freightBillData, 'basicDetails.stateOfSupply')) {
            return true;
        }
    });
    const isMaterialBased = isCNOrMaterialBasedEInvoicing() == 'Material Based';
    const isJobBookingBased = isCNOrMaterialBasedEInvoicing() == 'Job Booking Based';
    if (gstNature == GST_NATURES.EXEMPTED) {
        if (get(branchDtos, 'length') === 1) {
            buyerBranch = branchDtos[0];
        } else {
            const firstCNDetails = get(consignmentsData, '0');
            const cnBillingPartyBranchId = get(firstCNDetails, 'basicDetails.billingPartyBranchId');
            const branchDetails = find(branches, { id: cnBillingPartyBranchId });

            if (!branchDetails) {
                return {
                    isError: true,
                    message:
                        'The E-Invoice cannot be generated because the supply state does in the bill not correspond to any of the billing party’s branches.',
                };
            } else {
                buyerBranch = branchDetails;
            }
        }
    } else {
        if (!buyerBranch) {
            return {
                isError: true,
                message:
                    'The E-Invoice cannot be generated because the supply state does in the bill not correspond to any of the billing party’s branches.',
            };
        }
    }
    const billingOfficeBranchStateCode =
        find(STATE_CODE, { label: toUpper(get(billingOfficeBranch, 'address.state', null)) })?.value || 99;
    const buyerBranchStateCode =
        find(STATE_CODE, { label: toUpper(get(buyerBranch, 'address.state', null)) })?.value || 99;

    const EInvoiceData = {
        'version': '1.1',
        'tranDtls': {
            'taxSch': 'GST',
            'supTyp': 'B2B',
            'regRev': gstNature === GST_NATURES.RCM ? 'Y' : 'N',
            'ecmGstin': null,
            'igstOnIntra': 'N',
        },
        'docDtls': {
            'typ': 'INV',
            'no': getInvoiceNumber(get(freightBillData, 'basicDetails.billNumber', '')),
            'dt': getMomentTime(get(freightBillData, 'billingDate')).format(DATE_FORMAT),
        },
        'sellerDtls': {
            'gstin': get(billingOfficeBranch, 'gstNumber', null),
            'lglNm': get(billingOfficeBranch, 'officeName', null),
            'trdNm': get(billingOfficeBranch, 'officeName', null),
            'addr1': getAddresses(get(billingOfficeBranch, 'address', null))[0],
            'addr2': getAddresses(get(billingOfficeBranch, 'address', null))[1],
            'loc': get(billingOfficeBranch, 'address.city', null),
            'pin': get(billingOfficeBranch, 'address.postalCode', null),
            'stcd': billingOfficeBranchStateCode,
            'state': get(billingOfficeBranch, 'address.state', ''),
            'ph': get(billingOfficeBranch, 'phoneNumber', null),
            'em': get(billingOfficeBranch, 'emailPrimary', null),
            'pan': get(billingOfficeBranch, 'pan', ''),
        },
        'buyerDtls': {
            'gstin': get(buyerBranch, 'gstNumber', null),
            'lglNm': get(buyerBranch, 'officeName', null),
            'trdNm': get(buyerBranch, 'officeName', null),
            'addr1': getAddresses(get(buyerBranch, 'address', null))[0],
            'addr2': getAddresses(get(buyerBranch, 'address', null))[1],
            'loc': get(buyerBranch, 'address.city', null),
            'pin': get(buyerBranch, 'address.postalCode', null),
            'stcd': buyerBranchStateCode,
            'state': get(buyerBranch, 'address.state', null),
            'ph': get(buyerBranch, 'phoneNumber', null),
            'em': get(buyerBranch, 'emailPrimary', null),
            'pos': buyerBranchStateCode,
        },
        'dispDtls': {
            'nm': get(billingOfficeBranch, 'gstNumber', null),
            'addr1': getAddresses(get(billingOfficeBranch, 'address', null))[0],
            'addr2': getAddresses(get(billingOfficeBranch, 'address', null))[1],
            'loc': get(billingOfficeBranch, 'address.city', null),
            'pin': get(billingOfficeBranch, 'address.postalCode', null),
            'stcd': billingOfficeBranchStateCode,
            'state': get(billingOfficeBranch, 'address.state', null),
        },
        'shipDtls': {
            'gstin': get(buyerBranch, 'gstNumber', null),
            'lglNm': get(buyerBranch, 'officeName', null),
            'trdNm': get(buyerBranch, 'officeName', null),
            'addr1': getAddresses(get(buyerBranch, 'address', null))[0],
            'addr2': getAddresses(get(buyerBranch, 'address', null))[1],
            'loc': get(buyerBranch, 'address.city', null),
            'pin': get(buyerBranch, 'address.postalCode', null),
            'stcd': buyerBranchStateCode,
            'state': get(buyerBranch, 'address.state', null),
        },
        'payDtls': null,
        // {
        //     "nm": "ABCDE",
        //     "accDet": "5697389713210",
        //     "mode": "Cash",
        //     "finInsBr": "SBIN11000",
        //     "payTerm": "100",
        //     "payInstr": "Gift",
        //     "crTrn": "test",
        //     "dirDr": "test",
        //     "crDay": 100,
        //     "paidAmt": 10000,
        //     "paymtDue": 5000
        // },
        'refDtls': null,
        // {
        //     "invRm": "TEST",
        //     "docPerdDtls": {
        //         "invStDt": "01/08/2020",
        //         "invEndDt": "01/09/2020"
        //     },
        //     "precDocDtls":null,
        //     //  [
        //     //     {
        //     //         "invNo": "DOC/002",
        //     //         "invDt": "01/08/2020",
        //     //         "othRefNo": "123456"
        //     //     }
        //     // ],
        //     "contrDtls":null,
        //     //  [
        //     //     {
        //     //         "recAdvRefr": "Doc/003",
        //     //         "recAdvDt": "01/08/2020",
        //     //         "tendRefr": "Abc001",
        //     //         "contrRefr": "Co123",
        //     //         "extRefr": "Yo456",
        //     //         "projRefr": "Doc-456",
        //     //         "poRefr": "Doc-789",
        //     //         "poRefDt": "01/08/2020"
        //     //     }
        //     // ]
        // },
        'addlDocDtls': null,
        // [
        //     {
        //         "url": "https://einv-apisandbox.nic.in",
        //         "docs": "Test Doc",
        //         "info": "Document Test"
        //     }
        // ],
        'expDtls': null,
        // {
        //     "shipBNo": "A-248",
        //     "shipBDt": "01/08/2020",
        //     "port": "INABG1",
        //     "refClm": "N",
        //     "forCur": "AED",
        //     "cntCode": "AE",
        //     "expDuty": null
        // },
        'ewbDtls': null,
    };
    if (isMaterialBased) {
        let consigneeBranch = get(consignmentsData, '[0].consigneeDetails.branchId');
        const consigneeDetails = find(customers, { id: get(consignmentsData, '[0].consigneeId') });
        const consigneeBranchDtos = get(consigneeDetails, 'branchDtos', []);
        const consigneeBranchData = find(consigneeBranchDtos, { id: consigneeBranch });
        const gstin = get(consigneeBranchData, 'gstNumber', get(consigneeDetails, 'gstNumber', null));
        const lglNm = get(consigneeBranchData, 'officeName', get(consigneeDetails, 'name', null));
        const trdNm = get(consigneeBranchData, 'officeName', get(consigneeDetails, 'name', null));
        const addr1 = getAddresses(
            get(consigneeBranchData, 'address', {
                addressLine1: get(consigneeDetails, 'address', null),
                addressLine2: '',
            })
        )[0];
        const addr2 = getAddresses(
            get(consigneeBranchData, 'address', {
                addressLine1: get(consigneeDetails, 'address', null),
                addressLine2: '',
            })
        )[1];
        const loc = get(consigneeBranchData, 'address.city', get(consigneeDetails, 'address', null));
        const pin = get(consigneeBranchData, 'address.postalCode', get(consigneeDetails, 'pincode', null));
        const state = get(consigneeBranchData, 'address.state', get(consigneeDetails, 'state', null));
        const stcd = find(STATE_CODE, { label: toUpper(state) })?.value || 99;
        const shipDtls = {
            'gstin': gstin,
            'lglNm': lglNm,
            'trdNm': trdNm,
            'addr1': addr1,
            'addr2': addr2,
            'loc': loc,
            'pin': pin,
            'stcd': stcd,
            'state': state,
        };
        set(EInvoiceData, 'shipDtls', shipDtls);
    }
    let itemList = [],
        assAmtSum = 0,
        cgstVal = 0,
        sgstVal = 0,
        igstVal = 0,
        discount = 0,
        totInvVal = 0,
        totItemVal = 0,
        unitPrice = 0,
        totAmt = 0,
        gstRt;

    let currentConsignmentData = {};
    if (isJobBookingBased) {
        const allMaterials = [];
        let isIGST = false;
        forEach(get(freightBillData, 'consignmentBillDetailsList', []), (consignment, index) => {
            currentConsignmentData = find(consignmentsData, { id: get(consignment, 'consignmentId') });
            allMaterials.push(...get(currentConsignmentData, 'materials', []));
            if (!gstRt) {
                if (get(consignment, 'igstAmount', 0) > 0) {
                    isIGST = true;
                    gstRt = get(chargeHeadData, 'gstRate.IGST', 0);
                } else if (get(consignment, 'sgstAmount', 0) > 0 || get(consignment, 'cgstAmount', 0) > 0) {
                    gstRt = get(chargeHeadData, 'gstRate.SGST', 0) + get(chargeHeadData, 'gstRate.CGST', 0);
                }
            }
        });
        const groupedMaterials = groupBy(allMaterials, 'material.id');
        const uniqMaterials = uniqBy(allMaterials, 'material.id');
        forEach(uniqMaterials, (material, index) => {
            const materialId = get(material, 'material.id');
            const quantitySum = sumBy(groupedMaterials[materialId], 'chargedQuantity');
            const discountAmount = sumBy(groupedMaterials[materialId], 'discountAmount');
            const jobBookingMaterialData = find(get(jobBookingData, 'jobBookingMaterials'), { materialId });
            const unitPrice = get(jobBookingMaterialData, 'unitCost', 0);
            totAmt = round(quantitySum * unitPrice, 2);
            const taxAmount = gstRt ? round((totAmt * gstRt) / 100, 2) : 0;
            totItemVal = totAmt - discountAmount + taxAmount;
            totInvVal += totItemVal;
            assAmtSum += totAmt;
            cgstVal += isIGST ? 0 : taxAmount / 2;
            sgstVal += isIGST ? 0 : taxAmount / 2;
            igstVal += isIGST ? taxAmount : 0;
            itemList.push({
                'slNo': index + 1,
                'prdDesc': showMaterialDescriptionInEInvoice()
                    ? get(material, 'material.description')
                    : get(material, 'material.materialName', ''),
                'isServc': 'N',
                'hsnCd': get(material, 'material.hsnCode', ''),
                'barcde': null,
                'qty': quantitySum,
                'freeQty': null,
                'unit': get(materilUnitMapping, get(material, 'rateUnit', '')),
                'unitPrice': unitPrice,
                'totAmt': round(totAmt, 2),
                'discount': 0,
                'preTaxVal': 1, //tbd
                'assAmt': round(totAmt - discountAmount, 2),
                'gstRt': gstRt,
                'igstAmt': isIGST ? round(taxAmount, 2) : 0,
                'cgstAmt': isIGST ? 0 : round(taxAmount, 2) / 2,
                'sgstAmt': isIGST ? 0 : round(taxAmount, 2) / 2,
                'cesRt': null,
                'cesAmt': null,
                'cesNonAdvlAmt': null,
                'stateCesRt': null,
                'stateCesAmt': null,
                'stateCesNonAdvlAmt': null,
                'othChrg': 0,
                'totItemVal': round(totItemVal, 2),
                'ordLineRef': null,
                'orgCntry': 'IN',
                'prdSlNo': null,
                'bchDtls': null,
                'attribDtls': null,
            });
        });
    } else {
        forEach(get(freightBillData, 'consignmentBillDetailsList', []), (consignment, index) => {
            const cnCGstAmount = get(consignment, 'cgstAmount', 0);
            const cnIgstAmount = get(consignment, 'igstAmount', 0);
            const cnSgstAmount = get(consignment, 'sgstAmount', 0);

            if (isMaterialBased) {
                currentConsignmentData = find(consignmentsData, { id: get(consignment, 'consignmentId') });
                unitPrice = get(currentConsignmentData, 'materials.0.rate', 0);
                totAmt = get(currentConsignmentData, 'materials.0.chargedQuantity', 0) * unitPrice;
                totItemVal =
                    totAmt -
                    get(currentConsignmentData, 'materials.0.discountAmount', 0) +
                    get(consignment, 'igstAmount', 0) +
                    get(consignment, 'cgstAmount', 0) +
                    get(consignment, 'sgstAmount', 0);
                totInvVal += totItemVal;
                cgstVal += get(consignment, 'cgstAmount', 0);
                sgstVal += get(consignment, 'sgstAmount', 0);
                igstVal += get(consignment, 'igstAmount', 0);
                discount += get(currentConsignmentData, 'materials.0.discountAmount', 0);
            } else {
                currentConsignmentData = find(consignmentsData, { id: get(consignment, 'consignmentId') });
                unitPrice = get(consignment, 'freightBeforeGST', 0) + get(consignment, 'totalDiscount', 0);
                totAmt = unitPrice;
                cgstVal += cnCGstAmount;
                sgstVal += cnSgstAmount;
                igstVal += cnIgstAmount;

                discount += get(consignment, 'totalDiscount', 0);
                totItemVal =
                    totAmt -
                    get(consignment, 'totalDiscount', 0) +
                    get(consignment, 'igstAmount', 0) +
                    get(consignment, 'cgstAmount', 0) +
                    get(consignment, 'sgstAmount', 0);

                totInvVal += totItemVal;
            }
            assAmtSum += totAmt - get(consignment, 'totalDiscount', 0);
            if (get(consignment, 'igstAmount', 0) > 0) {
                gstRt = get(chargeHeadData, 'gstRate.IGST', 0);
            } else if (get(consignment, 'sgstAmount', 0) > 0 || get(consignment, 'cgstAmount', 0) > 0) {
                gstRt = get(chargeHeadData, 'gstRate.SGST', 0) + get(chargeHeadData, 'gstRate.CGST', 0);
            }
            itemList.push({
                'slNo': index + 1,
                'prdDesc': isMaterialBased
                    ? get(currentConsignmentData, 'materials.0.material.materialName', 'Material')
                    : 'Consignment ' + get(currentConsignmentData, 'basicDetails.consignmentNumber', ''),
                'isServc': isMaterialBased ? 'N' : 'Y',
                'hsnCd': isMaterialBased ? get(currentConsignmentData, 'materials.0.material.hsnCode', '9965') : '9965', //tbd
                'barcde': null,
                'qty': isMaterialBased ? get(currentConsignmentData, 'materials.0.chargedQuantity', 1) : 1,
                'freeQty': null,
                'unit': isMaterialBased
                    ? get(materilUnitMapping, get(currentConsignmentData, 'materials.0.rateUnit', ''))
                    : 'UNT',
                'unitPrice': unitPrice,
                'totAmt': round(totAmt, 2),
                'discount': get(consignment, 'totalDiscount', 0),
                'preTaxVal': 1, //tbd
                'assAmt': round(totAmt - get(consignment, 'totalDiscount', 0), 2),
                'gstRt': gstRt,
                'igstAmt': round(get(consignment, 'igstAmount', 0), 2),
                'cgstAmt': round(get(consignment, 'cgstAmount', 0), 2),
                'sgstAmt': round(get(consignment, 'sgstAmount', 0), 2),
                'cesRt': null,
                'cesAmt': null,
                'cesNonAdvlAmt': null,
                'stateCesRt': null,
                'stateCesAmt': null,
                'stateCesNonAdvlAmt': null,
                'othChrg': 0,
                'totItemVal': round(totItemVal, 2),
                'ordLineRef': null,
                'orgCntry': 'IN',
                'prdSlNo': null,
                'bchDtls': null,
                'attribDtls': null,
            });
        });
    }

    set(EInvoiceData, 'itemList', itemList);
    if (isMaterialBased || isJobBookingBased) {
        set(EInvoiceData, 'cnDetails', {
            cnNumber: get(currentConsignmentData, 'basicDetails.consignmentNumber', 'Consignment No.'),
            cnDate: get(currentConsignmentData, 'basicDetails.consignmentDate', getMomentTime()),
            toPlace: get(currentConsignmentData, 'toPlace', ''),
            licensePlate: get(currentConsignmentData, 'basicDetails.vehicle.licensePlate'),
        });
    }
    set(EInvoiceData, 'valDtls', {
        'assVal': round(assAmtSum, 2),
        'cgstVal': round(cgstVal, 2),
        'sgstVal': round(sgstVal, 2),
        'igstVal': round(igstVal, 2),
        'cesVal': null,
        'stCesVal': null,
        'discount': discount,
        'othChrg': 0,
        'rndOffAmt': 0, //tbd
        'totInvVal': round(totInvVal, 2),
        'totInvValFc': 0,
    });
    return EInvoiceData;
};

export const getCustomChargesSumWithGST = (cn) => {
    return sumBy(get(cn, 'customOtherCharges', []), (charge) => {
        if (get(charge, 'gstRateId')) {
            return +get(charge, 'chargeAmount', 0);
        } else {
            return 0;
        }
    });
};
