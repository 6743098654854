import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Planning = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M15.8077 20.5C15.3987 20.5 15.0465 20.3522 14.751 20.0567C14.4555 19.7612 14.3077 19.4089 14.3077 19V14.8077C14.3077 14.3987 14.4555 14.0465 14.751 13.751C15.0465 13.4555 15.3987 13.3077 15.8077 13.3077H20C20.409 13.3077 20.7612 13.4555 21.0567 13.751C21.3522 14.0465 21.5 14.3987 21.5 14.8077V19C21.5 19.4089 21.3522 19.7612 21.0567 20.0567C20.7612 20.3522 20.409 20.5 20 20.5H15.8077ZM15.8077 19H20V14.8077H15.8077V19ZM3.25002 17.6538C3.03752 17.6538 2.8594 17.5819 2.71565 17.4381C2.5719 17.2943 2.50002 17.1161 2.50002 16.9035C2.50002 16.6909 2.5719 16.5128 2.71565 16.3692C2.8594 16.2256 3.03752 16.1538 3.25002 16.1538H10.3654C10.5779 16.1538 10.756 16.2257 10.8998 16.3696C11.0435 16.5134 11.1154 16.6916 11.1154 16.9042C11.1154 17.1167 11.0435 17.2948 10.8998 17.4384C10.756 17.582 10.5779 17.6538 10.3654 17.6538H3.25002ZM15.8077 10.6923C15.3987 10.6923 15.0465 10.5445 14.751 10.249C14.4555 9.9535 14.3077 9.60127 14.3077 9.1923V4.99998C14.3077 4.59101 14.4555 4.23878 14.751 3.94328C15.0465 3.64776 15.3987 3.5 15.8077 3.5H20C20.409 3.5 20.7612 3.64776 21.0567 3.94328C21.3522 4.23878 21.5 4.59101 21.5 4.99998V9.1923C21.5 9.60127 21.3522 9.9535 21.0567 10.249C20.7612 10.5445 20.409 10.6923 20 10.6923H15.8077ZM15.8077 9.1923H20V4.99998H15.8077V9.1923ZM3.25002 7.84613C3.03752 7.84613 2.8594 7.77422 2.71565 7.6304C2.5719 7.48658 2.50002 7.30838 2.50002 7.0958C2.50002 6.88322 2.5719 6.70513 2.71565 6.56153C2.8594 6.41794 3.03752 6.34615 3.25002 6.34615H10.3654C10.5779 6.34615 10.756 6.41806 10.8998 6.56188C11.0435 6.70568 11.1154 6.88388 11.1154 7.09648C11.1154 7.30906 11.0435 7.48715 10.8998 7.63075C10.756 7.77433 10.5779 7.84613 10.3654 7.84613H3.25002Z", fill: "currentColor" }) }));
};
const PlanningIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Planning, ...props });
};
export default PlanningIcon;
