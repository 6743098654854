import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { FxCol, FxRow, FxSelectField } from 'sharedV2';
import { get } from 'lodash';
import { DATE_FORMAT_TIMESTAMP, getMomentTime } from 'utils/date_utils';
import { DatePicker, Input } from 'sharedV2/FxFormItems';
import { editPayment } from 'actions/dashboard/paymentsNew/actions';
import { handleError } from 'constant';
import { mappedObjectToOptions } from 'components/utils/mapping_utils';
import { INSTRUMENT_TYPES } from 'utils/consignmentsUtils';
const EditPaymentModalComponent = (props) => {
    const { accesstoken, onModalClose, setFormikRef, rowData, fetchData } = props;
    const defaultFormData = {
        paymentAmount: '',
        adviceNumber: '',
        instrumentType: '',
        instrumentNumber: '',
        bankName: '',
        recoveryDate: '',
        recoveryLedger: '',
    };
    const [initialValues, setInitialValues] = useState(defaultFormData);
    useEffect(() => {
        if (rowData) {
            const paymentAmount = get(rowData, 'paymentAmount', '');
            const adviceNumber = get(rowData, 'adviceNumber', null);
            const recoveryLedger = get(rowData, 'recoveryLedger', null);
            const transactionDetails = get(rowData, 'transactionDetails', null);
            const instrumentType = get(transactionDetails, 'instrumentType', null);
            const instrumentNumber = get(transactionDetails, 'instrumentNumber', null);
            const bankName = get(transactionDetails, 'bankName', null);
            const recoveryDate = get(transactionDetails, 'recoveryDate', null);
            // Setting the initial form values
            setInitialValues({
                ...defaultFormData,
                paymentAmount,
                adviceNumber,
                instrumentType,
                instrumentNumber,
                bankName,
                recoveryDate,
                recoveryLedger,
            });
        }
    }, [rowData]);
    const addEditSchema = (values) => {
        return Yup.object().shape({
            paymentAmount: Yup.number().nullable().required('Payment Amount is required'),
            adviceNumber: Yup.string().nullable().required('Advice Number is required'),
            instrumentType: Yup.string()
                .nullable()
                .required('Instrument Type is required')
                .transform((value, originalValue) => {
                return originalValue === '' || originalValue === undefined || originalValue === null ? null : value;
            }),
            instrumentNumber: Yup.string()
                .nullable()
                .required('Instrument Number is required')
                .transform((curr, orig) => (orig === '' ? null : curr)),
            bankName: Yup.string().nullable().required('Advice Number is required'),
            recoveryDate: Yup.date()
                .nullable()
                .required('Recovery Date is required')
                .transform((curr, orig) => (orig === '' ? null : new Date(orig)))
                .typeError('Invalid date format'),
        });
    };
    const onSubmit = async (values, actions) => {
        const { setSubmitting } = actions;
        try {
            const { paymentAmount, adviceNumber, instrumentType, instrumentNumber, bankName, recoveryDate } = values;
            const payload = {
                paymentAmount: paymentAmount,
                adviceNumber: adviceNumber,
                transactionDetails: {
                    instrumentType: instrumentType,
                    instrumentNumber: instrumentNumber,
                    bankName: bankName,
                    recoveryDate: getMomentTime(recoveryDate).format(DATE_FORMAT_TIMESTAMP),
                },
                id: rowData.id,
            };
            const result = await props.editPayment(accesstoken, payload);
            if (!result.error) {
                onModalClose();
                fetchData();
            }
            else {
                throw new Error(handleError(result.payload.response));
            }
        }
        catch (error) {
            alert(handleError(error));
        }
        finally {
            setSubmitting(false);
        }
    };
    return (_jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: () => Yup.lazy((values) => {
            return addEditSchema(values);
        }), validateOnBlur: true, enableReinitialize: true, innerRef: setFormikRef, children: (formikProps) => {
            const { isSubmitting, values, errors, setFieldValue } = formikProps;
            return (_jsx(Form, { children: _jsxs(FxRow, { gutter: 16, children: [_jsx(Input, { label: "Debit Ledger", name: "recoveryLedger", disabled: true, colSpan: 12 }), _jsx(Input, { label: "Payment Amount", name: "paymentAmount", placeholder: "Enter Payment Amount", colSpan: 12 }), _jsx(Input, { label: "Advice Number", name: "adviceNumber", placeholder: "Enter Advice Number", colSpan: 12 }), _jsx(FxCol, { xs: 24, sm: 12, md: 12, children: _jsx(Field, { name: "instrumentType", label: "Instrument Type", component: FxSelectField, options: mappedObjectToOptions(INSTRUMENT_TYPES) }) }), _jsx(Input, { label: "Instrument Number", name: "instrumentNumber", placeholder: "Enter Instrument Number", colSpan: 12 }), _jsx(DatePicker, { name: 'recoveryDate', onChange: (value) => setFieldValue('recoveryDate', value), label: "Recovery Date", required: true, colSpan: 12 }), _jsx(Input, { label: "Bank Name", name: "bankName", placeholder: "Enter Bank Name", colSpan: 12 })] }) }));
        } }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        editPayment,
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        accesstoken: get(state, 'login.data.access_token'),
        ledgers: state.consignments.ledgers,
        ledgersGroups: get(state.consignments.accountGroups, 'content'),
        vendors: state.vendors.vendorList,
        emiData: get(state, 'loans.emiDetails', []),
        loanDetailsById: get(state, 'loans.loanDetailsById', []),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentModalComponent);
