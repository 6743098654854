import { handle } from 'redux-pack';
import moment from 'moment-timezone';

import {
    FETCH_ACCOUNT,
    FETCH_BLOGS_POSTS,
    FETCH_LOGGED_IN_USER,
    UPDATE_ACCOUNT,
} from '../../actions/dashboard/setting_action';
import { get, reduce, set } from 'lodash';
import { defaultTimeZone } from '../../utils/date_utils';
import { getTimeZoneForAccount, getCountryNameFromAccountId } from '../../utils/account_utils';
import { SET_LOCAL_TRANSPORTER_ACCOUNT } from '../../actions/dashboard/local_action';
import { FETCH_CURRENCY_CODES } from 'actions/dashboard/accounts_actions';

const initialState = {
    isLoading: false,
    error: null,
    account: null,
    loggedInUser: null,
};

export default function (state = initialState, action, root) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_ACCOUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, account: payload.data }),
            });
        case FETCH_ACCOUNT:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, account: payload.data }),
            });
        case SET_LOCAL_TRANSPORTER_ACCOUNT:
            const loggedInUser = get(state, 'loggedInUser');
            const accountId = get(payload, 'localTransporterAccount');
            let countryName = getCountryNameFromAccountId(accountId);
            set(loggedInUser, 'timezone', getTimeZoneForAccount(accountId));
            set(loggedInUser, 'country', countryName);
            window.FLEETX_LOGGED_IN_USER = loggedInUser;
            return {
                ...state,
                ...payload,
                loggedInUser,
            };
        case FETCH_LOGGED_IN_USER:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => {
                    let accountId = get(payload, 'data.accountId');
                    if (!accountId) {
                        const localTransporterAccount = get(root, 'local.localTransporterAccount');
                        accountId = localTransporterAccount;
                    }
                    let countryName = getCountryNameFromAccountId(accountId);
                    set(payload.data, 'timezone', get(payload.data, 'timezone', getTimeZoneForAccount(accountId)));
                    set(payload.data, 'country', countryName);
                    window.FLEETX_LOGGED_IN_USER = payload.data;
                    window.FLEETX_LOGGED_IN_DATA = get(root, 'login.data');
                    window.FX_MODULE_MAP = reduce(
                        get(root, 'login.data')?.modules || [],
                        (obj, item) => {
                            obj[item] = true;
                            return obj;
                        },
                        {}
                    );
                    window.FX_SCOPE_MAP = reduce(
                        get(root, 'login.data')?.scope || [],
                        (obj, item) => {
                            obj[item] = true;
                            return obj;
                        },
                        {}
                    );
                    const SWITCHED_USER_ORIGINAL_DATA = {
                        switchedUserOriginalRoleId: get(root, 'login.switchedUserOriginalRoleId'),
                        switchedUserOriginalEmailId: get(root, 'login.switchedUserOriginalEmailId'),
                        switchedUserOriginalId: get(root, 'login.switchedUserOriginalId'),
                        switchedUserOriginalAccessToken: get(root, 'login.switchedUserOriginalAccessToken'),
                    };
                    window.SWITCHED_USER_ORIGINAL_DATA = SWITCHED_USER_ORIGINAL_DATA;
                    const { timezone = defaultTimeZone } = window.FLEETX_LOGGED_IN_USER || {};
                    moment.tz.setDefault(timezone);
                    return { ...prevState, loggedInUser: payload.data };
                },
            });
        case FETCH_BLOGS_POSTS:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, posts: get(payload, 'data.posts', []) }),
            });
        case FETCH_CURRENCY_CODES:
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                    error: null,
                }),
                finish: (prevState) => ({ ...prevState, isLoading: false }),
                failure: (prevState) => ({ ...prevState, error: payload }),
                success: (prevState) => ({ ...prevState, currencyCodes: get(payload, 'data', []) }),
            });
        default:
            return state;
    }
}
