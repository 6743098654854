import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { find, get, lowerCase, upperFirst } from 'lodash';
import { getMomentTime } from '../date_utils';
import { isTransporter } from 'utils/permissionUtils/tmsPermissions';
import { isOrientCementAccount } from 'utils/accountUtils/common';
import { getAccountId } from 'utils/account_utils';
export const STATUS = {
    ACCEPTED: 'ACCEPTED',
    ARRIVED: 'ARRIVED',
    ASSIGNED: 'ASSIGNED',
    LRNOTASSIGNED: 'LRNOTASSIGNED',
    CHANGED: 'CHANGED',
    DELIVERED: 'DELIVERED',
    EXPIRED: 'EXPIRED',
    INDENTED: 'INDENTED',
    REJECTED: 'REJECTED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
    ARRIVAL: 'ARRIVAL',
    ARRIVALDEST: 'ARRIVALDEST',
    ARRIVALATPICKUP: 'ARRIVALATPICKUP',
    DEPARTURE: 'DEPARTURE',
    DEPARTUREATPICKUP: 'DEPARTUREATPICKUP',
    END: 'END',
    CLOSED: 'CLOSED',
};
export const SortOrderConfig = {
    desc: 'descend',
    asc: 'ascend',
    ascend: 'asc',
    descend: 'desc',
};
export const StatusDiv = styled.div `
    padding: 5px 0px;
    font-weight: 800;
    color: ${(props) => (props.color ? props.color : '#2d2d2d')};
    width: 5.5rem;
    white-space: nowrap;
`;
export const getTransporterStatus = (row) => {
    return find(row.indentTransporters, (obj) => obj.transporter?.userId === window.FLEETX_LOGGED_IN_USER?.id)?.status;
};
export const showStatus = (_status, row) => {
    let status = isTransporter() && row.parallelIndenting ? getTransporterStatus(row) : _status;
    if (isTransporter() && isOrientCementAccount(getAccountId()) && row.status === STATUS.EXPIRED) {
        status = row.status;
    }
    const type = get(row, 'type');
    const movementType = get(row, 'movementType');
    const statusDescription = get(row, 'statusDescription');
    const scheduledVehicleAssignmentDate = get(row, 'scheduledVehicleAssignmentDate');
    const scheduledAcceptanceDate = get(row, 'scheduledAcceptanceDate');
    switch (status) {
        case STATUS.DELIVERED:
        case STATUS.ARRIVED:
        case STATUS.COMPLETED:
        case STATUS.ASSIGNED:
            return (_jsxs(_Fragment, { children: [_jsx(StatusDiv, { color: "#00a70f", children: upperFirst(lowerCase(status)) }), type && _jsx("div", { className: "medium text-muted", children: type }), movementType && _jsx("div", { className: "medium text-muted", children: upperFirst(lowerCase(movementType)) })] }));
        case STATUS.LRNOTASSIGNED:
            return (_jsxs(_Fragment, { children: [_jsx(StatusDiv, { color: "#2d2d2d", children: upperFirst(lowerCase('LR Not Assigned')) }), type && _jsx("div", { className: "medium text-muted", children: type }), movementType && _jsx("div", { className: "medium text-muted", children: upperFirst(lowerCase(movementType)) })] }));
        case STATUS.REJECTED:
        case STATUS.EXPIRED:
            return (_jsxs(_Fragment, { children: [_jsx(StatusDiv, { color: "#f86c6b", children: upperFirst(lowerCase(status)) }), type && _jsx("div", { className: "medium text-muted", children: type }), movementType && _jsx("div", { className: "medium text-muted", children: upperFirst(lowerCase(movementType)) })] }));
        case STATUS.STARTED:
        case STATUS.ARRIVAL:
        case STATUS.ARRIVALATPICKUP:
        case STATUS.DEPARTUREATPICKUP:
        case STATUS.DEPARTURE:
        case STATUS.ARRIVALDEST:
        case STATUS.END:
        case STATUS.CLOSED:
            return (_jsxs(_Fragment, { children: [_jsx(StatusDiv, { color: "#2d2d2d", children: upperFirst(lowerCase(statusDescription ? statusDescription : status)) }), type && _jsx("div", { className: "medium text-muted", children: type }), movementType && _jsx("div", { className: "medium text-muted", children: upperFirst(lowerCase(movementType)) })] }));
        case STATUS.INDENTED:
        case STATUS.ACCEPTED:
            const date = status === STATUS.INDENTED ? scheduledAcceptanceDate : scheduledVehicleAssignmentDate;
            if (getMomentTime(date).diff(getMomentTime()) <= 0) {
                return (_jsxs(_Fragment, { children: [_jsx(StatusDiv, { color: "#ffe333", children: upperFirst(lowerCase(status)) }), type && _jsx("div", { className: "medium text-muted", children: type }), movementType && _jsx("div", { className: "medium text-muted", children: upperFirst(lowerCase(movementType)) })] }));
            }
        default:
            return (_jsxs(_Fragment, { children: [_jsx(StatusDiv, { color: "#2d2d2d", children: upperFirst(lowerCase(status)) }), type && _jsx("div", { className: "medium text-muted", children: type }), movementType && _jsx("div", { className: "medium text-muted", children: upperFirst(lowerCase(movementType)) })] }));
    }
};
export const epodNotAllowedStatuses = ['INDENTED', 'ACCEPTED', 'EXPIRED', 'CANCELLED', 'REJECTED'];
