import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Reports = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M8.99997 17.75H15C15.2128 17.75 15.391 17.6782 15.5346 17.5346C15.6782 17.391 15.75 17.2128 15.75 17C15.75 16.7872 15.6782 16.609 15.5346 16.4654C15.391 16.3218 15.2128 16.25 15 16.25H8.99997C8.78716 16.25 8.60896 16.3218 8.46537 16.4654C8.32179 16.609 8.25 16.7872 8.25 17C8.25 17.2128 8.32179 17.391 8.46537 17.5346C8.60896 17.6782 8.78716 17.75 8.99997 17.75ZM8.99997 13.75H15C15.2128 13.75 15.391 13.6782 15.5346 13.5346C15.6782 13.391 15.75 13.2128 15.75 13C15.75 12.7872 15.6782 12.609 15.5346 12.4654C15.391 12.3218 15.2128 12.25 15 12.25H8.99997C8.78716 12.25 8.60896 12.3218 8.46537 12.4654C8.32179 12.609 8.25 12.7872 8.25 13C8.25 13.2128 8.32179 13.391 8.46537 13.5346C8.60896 13.6782 8.78716 13.75 8.99997 13.75ZM6.3077 21.5C5.80257 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.1974 4.5 19.6923V4.3077C4.5 3.80257 4.675 3.375 5.025 3.025C5.375 2.675 5.80257 2.5 6.3077 2.5H13.5019C13.7429 2.5 13.9747 2.5468 14.1971 2.6404C14.4195 2.73398 14.6128 2.86283 14.7769 3.02693L18.973 7.22303C19.1371 7.38714 19.266 7.58042 19.3595 7.80285C19.4531 8.02528 19.5 8.25701 19.5 8.49803V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6922 21.5H6.3077ZM13.5 7.5961V3.99998H6.3077C6.23077 3.99998 6.16024 4.03203 6.09612 4.09613C6.03202 4.16024 5.99997 4.23077 5.99997 4.3077V19.6923C5.99997 19.7692 6.03202 19.8397 6.09612 19.9038C6.16024 19.9679 6.23077 20 6.3077 20H17.6922C17.7692 20 17.8397 19.9679 17.9038 19.9038C17.9679 19.8397 18 19.7692 18 19.6923V8.49995H14.4039C14.1462 8.49995 13.9311 8.41373 13.7587 8.24128C13.5862 8.06884 13.5 7.85378 13.5 7.5961Z", fill: "currentColor" }) }));
};
const ReportsIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Reports, ...props });
};
export default ReportsIcon;
