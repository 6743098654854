import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FxTypography, FxFlex, FxBreadcrumb } from 'sharedV2';
import { isMobileView } from 'constant';
import styled from 'styled-components';
import { useToken } from '../../../theme';
const Container = styled.div `
    background-color: ${(props) => props.$bgColor || '#fff'};
    padding: 1rem;
    border-bottom: 1px solid ${(props) => props.$borderColor || '#dadada'};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
`;
const Filters = styled(FxFlex) `
    > *:not([class*='react-bootstrap-daterangepicker']):not(:has([class*='react-bootstrap-daterangepicker'])):not(.ignore-max-width) {
        max-width: 220px;
    }
`;
const PageControlPanelComponent = (props) => {
    const { tabs, pageLabel, pageSubLabel, icon, labelSuffix, breadcrumbs, children } = props;
    // Combine breadcrumbs with page label
    const allBreadcrumbs = breadcrumbs?.length ? [...breadcrumbs, { title: pageLabel }] : null;
    const { token } = useToken();
    return (_jsxs(Container, { "$borderColor": token.colorSplit, "$bgColor": token.colorBgContainer, children: [_jsxs(FxFlex, { gap: 12, wrap: "wrap", align: "center", justify: "space-between", children: [allBreadcrumbs ? (_jsx(FxBreadcrumb, { themeModify: {
                            fontSize: 16,
                        }, items: allBreadcrumbs })) : (_jsx(FxFlex, { gap: 12, wrap: "wrap", align: "center", justify: "space-between", children: _jsxs("div", { children: [typeof pageLabel === 'string' ? (_jsxs(FxTypography.Title, { level: 4, className: "d-flex action-gap align-items-center mb-0", children: [!!icon && icon, " ", pageLabel, !!labelSuffix && labelSuffix] })) : (_jsxs("div", { className: "d-flex action-gap align-items-center", children: [!!icon && icon, " ", pageLabel] })), !!pageSubLabel && pageSubLabel] }) })), _jsx(Filters, { align: "center", wrap: isMobileView() ? 'wrap' : 'nowrap', gap: 8, children: props.children && props.children.props && props.children.props.children })] }), tabs] }));
};
export default PageControlPanelComponent;
