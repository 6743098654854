import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { isDeletePaymentAllowedForUser } from '../roles/permission_utils';
import { getHumanizeTime } from '../../../utils/date_utils';
import { deletePayment, deleteCreditNoteAdjustment } from 'actions/dashboard/paymentsNew/actions';
import { useFxMessage } from '../customHooks';
import { FxButton, FxCard, FxDropdown, FxModal, FxPopconfirm, FxTable } from 'sharedV2/index';
import { FxDeleteOutlined, FxEditOutlined, FxMoreOutlined, FxPlusIcon } from 'sharedV2/FxIcons';
import EditPaymentModalComponent from './forms/modals/EditPaymentModalComponent';
import { isViewportSMOrAbove } from 'constant';
import { Link } from 'react-router-dom';
import { getColumns } from './paymentUtils';
const AdvanceListingComponent = (props) => {
    const { data, login, page, size, fetchData, accesstoken, activeTab, activeSubTab, totalElements, isInitialLoad, } = props;
    const scope = login.scope;
    const isDeletePaymentAllowed = isDeletePaymentAllowedForUser(scope);
    const showMessage = useFxMessage();
    const isMobileView = !isViewportSMOrAbove();
    const formikRef = useRef(null);
    const [openEditPaymentModal, setOpenEditPaymentModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const onOpenEditPaymentModal = (row) => {
        setSelectedRowData(row);
        setOpenEditPaymentModal(true);
    };
    const onClosePaymentModal = () => {
        setOpenEditPaymentModal(false);
    };
    const showPaymentsButtons = (_, row) => {
        const isEdit = get(row, 'appliedAmount', 0) > 0 ? true : false;
        const items = [
            activeSubTab === 'UNSETTLED' && {
                key: 'settlementmodal',
                label: (_jsx(Link, { to: `/dashboard/payments/bills/settlement/${get(row, 'id')}?source=PAYMENTS&isEdit=${isEdit}`, className: "text-dark d-inline-block hover", title: "Settlement", children: "Settlement" })),
                icon: _jsx(FxPlusIcon, {}),
            },
            {
                key: 'editpaymentmodal',
                label: (_jsx("div", { onClick: (e) => {
                        e.stopPropagation();
                        onOpenEditPaymentModal(row);
                    }, children: "Edit" })),
                icon: _jsx(FxEditOutlined, {}),
            },
            isDeletePaymentAllowed && {
                key: 'delete',
                label: (_jsxs(FxPopconfirm, { title: "Are you sure you want to delete this payment?", onConfirm: () => {
                        props.deletePayment(accesstoken, get(row, 'id')).then((res) => {
                            if (!res.error) {
                                showMessage(`Payment deleted successfully.`);
                                fetchData();
                            }
                            else {
                                showMessage(`Error deleting payment.`);
                            }
                        });
                    }, okText: "Yes", cancelText: "No", children: [_jsx(FxDeleteOutlined, { title: "Delete", className: "mr-2" }), "Delete"] })),
            },
        ].filter(Boolean);
        return (_jsx("div", { onClick: (e) => e.stopPropagation(), children: _jsx(FxDropdown, { trigger: ['click'], menu: { items }, children: _jsx(FxButton, { icon: _jsx(FxMoreOutlined, {}) }) }) }));
    };
    const showAdjustmentsButtons = (_, row) => {
        const isEdit = get(row, 'appliedAmount', 0) > 0 ? true : false;
        const items = [
            {
                key: 'settlementmodal',
                label: (_jsx(Link, { to: `/dashboard/payments/bills/settlement/${get(row, 'id')}?source=CREDIT_NOTE&isEdit=${isEdit}`, className: "text-dark d-inline-block hover", title: "Settlement", children: "Settlement" })),
                icon: _jsx(FxPlusIcon, {}),
            },
            isDeletePaymentAllowed && {
                key: 'delete',
                label: (_jsxs(FxPopconfirm, { title: "Are you sure you want to delete this payment?", onConfirm: () => {
                        props.deleteCreditNoteAdjustment(accesstoken, get(row, 'id')).then((res) => {
                            if (!res.error) {
                                showMessage(`Payment deleted successfully.`);
                                fetchData();
                            }
                            else {
                                showMessage(`Error deleting payment.`);
                            }
                        });
                    }, okText: "Yes", cancelText: "No", children: [_jsx(FxDeleteOutlined, { title: "Delete", className: "mr-2" }), "Delete"] })),
            },
        ].filter(Boolean);
        return (_jsx("div", { onClick: (e) => e.stopPropagation(), children: _jsx(FxDropdown, { trigger: ['click'], menu: { items }, children: _jsx(FxButton, { icon: _jsx(FxMoreOutlined, {}) }) }) }));
    };
    const redirectToSettlement = (record) => {
        const isEdit = get(record, 'appliedAmount', 0) > 0 ? true : false;
        if (activeSubTab === 'CREDIT_NOTE') {
            window.open(`/dashboard/payments/bills/settlement/${get(record, 'id')}?source=CREDIT_NOTE&isEdit=${isEdit}`, '_blank');
        }
        else {
            window.open(`/dashboard/payments/bills/settlement/${get(record, 'id')}?source=PAYMENTS&isEdit=${isEdit}`, '_blank');
        }
    };
    const showVoucherDate = (cell) => {
        return _jsx("div", { children: getHumanizeTime(cell) });
    };
    const paymentColumns = [
        {
            title: 'Stationary Number',
            dataIndex: 'referenceNumber',
            key: 'referenceNumber',
            hidden: false,
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            hidden: false,
        },
        {
            title: 'Receipt Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            hidden: false,
            render: (field, row) => showVoucherDate(field),
        },
        {
            title: 'Billing Office',
            dataIndex: 'officeName',
            key: 'officeName',
            hidden: false,
        },
        {
            title: 'Payment Amount',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            hidden: false,
        },
        {
            title: 'Settled Amount',
            dataIndex: 'appliedAmount',
            key: 'appliedAmount',
            hidden: false,
        },
        {
            title: 'Unsettled Amount',
            dataIndex: 'unAppliedAmount',
            key: 'unAppliedAmount',
            hidden: false,
        },
        {
            title: 'Advice Number',
            dataIndex: 'adviceNumber',
            key: 'adviceNumber',
            hidden: false,
        },
        {
            title: 'Voucher',
            dataIndex: 'voucher',
            key: 'voucher',
            render: (voucher, row) => {
                return (_jsx(Link, { onClick: (e) => e.stopPropagation(), target: "_blank", to: `/dashboard/voucher/edit/${get(voucher, 'id')}`, children: get(row, 'referenceNumber') }));
            },
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
            hidden: false,
            render: (field, row) => showPaymentsButtons(field, row),
            fixed: 'right',
        },
    ];
    const adjustmentNotesColumns = [
        {
            title: 'Stationary No.',
            dataIndex: 'stationaryNumber',
            key: 'stationaryNumber',
            hidden: false,
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            hidden: false,
        },
        {
            title: 'Date',
            dataIndex: 'appliedDate',
            key: 'appliedDate',
            hidden: false,
            render: (field, row) => showVoucherDate(field),
        },
        {
            title: 'Billing Office',
            dataIndex: 'officeName',
            key: 'officeName',
            hidden: false,
        },
        {
            title: 'Payment Amount',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            hidden: false,
        },
        {
            title: 'Settled Amount',
            dataIndex: 'appliedAmount',
            key: 'appliedAmount',
            hidden: false,
        },
        {
            title: 'Unsettled Amount',
            dataIndex: 'unAppliedAmount',
            key: 'unAppliedAmount',
            hidden: false,
        },
        {
            title: 'Advice Number',
            dataIndex: 'adviceNumber',
            key: 'adviceNumber',
            hidden: false,
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            hidden: false,
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
            hidden: activeSubTab === 'DEBIT_NOTE',
            render: (field, row) => showAdjustmentsButtons(field, row),
            fixed: 'right',
        },
    ];
    const customerOverviewColumns = [
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            hidden: false,
            width: 150,
        },
        {
            title: 'Advance Paid',
            dataIndex: 'totalAdvancePaid',
            key: 'totalAdvancePaid',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalAdvancePaid.amount'), "(", get(row, 'totalAdvancePaid.count'), ")"] }));
            },
            width: 100,
        },
        {
            title: 'Credit Note',
            dataIndex: 'totalCreditNote',
            key: 'totalCreditNote',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalCreditNote.amount'), "(", get(row, 'totalCreditNote.count'), ")"] }));
            },
            width: 100,
        },
        {
            title: 'Credit Note Pending',
            dataIndex: 'totalCreditNotePending',
            key: 'totalCreditNotePending',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalCreditNotePending.amount'), "(", get(row, 'totalCreditNotePending.count'), ")"] }));
            },
            width: 150,
        },
        {
            title: 'Credit Note Settled',
            dataIndex: 'totalCreditNoteSettled',
            key: 'totalCreditNoteSettled',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalCreditNoteSettled.amount'), "(", get(row, 'totalCreditNoteSettled.count'), ")"] }));
            },
            width: 150,
        },
        {
            title: 'Debit Note',
            dataIndex: 'totalDebitNote',
            key: 'totalDebitNote',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalDebitNote.amount'), "(", get(row, 'totalDebitNote.count'), ")"] }));
            },
            width: 100,
        },
        {
            title: 'Payment',
            dataIndex: 'totalPayment',
            key: 'totalPayment',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalPayment.amount'), "(", get(row, 'totalPayment.count'), ")"] }));
            },
            width: 100,
        },
        {
            title: 'Payment Pending',
            dataIndex: 'totalPaymentPending',
            key: 'totalPaymentPending',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalPaymentPending.amount'), "(", get(row, 'totalPaymentPending.count'), ")"] }));
            },
            width: 150,
        },
        {
            title: 'Payment Settled',
            dataIndex: 'totalPaymentSettled',
            key: 'totalPaymentSettled',
            hidden: false,
            render: (field, row) => {
                return (_jsxs("div", { children: [get(row, 'totalPaymentSettled.amount'), "(", get(row, 'totalPaymentSettled.count'), ")"] }));
            },
            width: 150,
        },
    ];
    return (_jsx(FxCard, { themeModify: {
            paddingLG: 0,
        }, className: "mb-2", children: _jsxs("div", { className: "fleetx-table-parent-container", children: [_jsx(FxTable, { rowKey: 'id', columns: getColumns(activeTab, paymentColumns, adjustmentNotesColumns, customerOverviewColumns), dataSource: data, className: "table-striped-rows", scroll: { x: 'max-content' }, size: "middle", pagination: {
                        position: ['topRight'],
                        current: page,
                        pageSize: size,
                        onChange: props.onPageChange,
                        total: totalElements,
                        showSizeChanger: true,
                        showTotal: (total) => `${activeSubTab || activeTab} (Total ${total || 0})`,
                    }, loading: isInitialLoad, onRow: (record) => {
                        return {
                            onClick: () => {
                                redirectToSettlement(record);
                            },
                        };
                    } }), openEditPaymentModal && (_jsx(FxModal, { title: "Edit Payment Details", open: true, onCancel: () => onClosePaymentModal(), onOk: () => formikRef.current.submitForm(), width: isMobileView ? '100%' : '800px', closeIcon: false, centered: true, okText: 'Submit', maskClosable: false, children: _jsx(EditPaymentModalComponent, { onModalClose: onClosePaymentModal, openInModal: true, rowData: selectedRowData, setFormikRef: (ref) => (formikRef.current = ref), fetchData: fetchData }) }))] }) }));
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ deletePayment, deleteCreditNoteAdjustment }, dispatch);
}
function mapStateToProps(state, ownProps) {
    return {
        login: state.login.data,
        accesstoken: get(state, 'login.data.access_token'),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceListingComponent);
