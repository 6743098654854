import { endsWith, get, includes } from 'lodash';
import { FLEETX_ACCOUNTS, getAccountId, getFlagValueFromAccountConfig, getReduxState, userIdSpecificLogo, } from 'utils/account_utils';
import { GOOGLE_MAP_STYLES } from 'utils/map/map_utils';
import { ROLE_MAP } from 'constant';
export { createGlobalStyle } from 'styled-components';
export const DEFAULT_SIZE = 'middle';
// theme usage help
/**
 * token.colorTextBase → Base text color
 * token.colorText → Primary text color
 * token.colorTextSecondary → Secondary text color
 * token.colorBgContainer → Background color
 * token.colorBorder → Default border color
 * token.colorSplit → Split border color
 * token.colorFill → Base fill color,This token is used for background fills
 * token.colorFillContent -> If you're using the wave animation, including the Skeleton loader's background, its effect color is controlled by (slight lighter than colorFill)
 *   fill="currentColor" → Use the current color in svg antd component
 */
const externalUserAccounts = [13806];
export function isThemeEnabledForAccount() {
    const accountId = getAccountId();
    const email = get(window.SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalEmailId', get(window.FLEETX_LOGGED_IN_USER, 'email', ''));
    const roleId = get(window.SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalRoleId', get(window.FLEETX_LOGGED_IN_USER, 'role.id', ''));
    return (includes([FLEETX_ACCOUNTS.FLEETX, 6215, ...externalUserAccounts], accountId) ||
        (endsWith(email, 'fleetx.io') && roleId === ROLE_MAP.ROLE_MASTER.id));
}
export function isMapThemeChangeEnabledForAccount() {
    return getFlagValueFromAccountConfig('isMapThemeChangeEnabled');
}
export function isAllowedDarkThemeOnPage() {
    const accountId = getAccountId();
    const currentPath = window.location.pathname;
    const email = get(window.SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalEmailId', get(window.FLEETX_LOGGED_IN_USER, 'email', ''));
    const roleId = get(window.SWITCHED_USER_ORIGINAL_DATA, 'switchedUserOriginalRoleId', get(window.FLEETX_LOGGED_IN_USER, 'role.id', ''));
    const allowedPagesForExternalUser = [
        '/dashboard/routes/siteAnalytics',
        '/dashboard/alarms/analytics',
        '/dashboard/admin/tools/control-tower-new',
        '/dashboard/realtime/overview',
        '/dashboard/admin/tools/control-tower',
        '/dashboard/issues',
        '/dashboard/internalissues',
    ];
    if (includes(externalUserAccounts, accountId) && includes(allowedPagesForExternalUser, currentPath)) {
        return true;
    }
    return endsWith(email, '@fleetx.io') && roleId === ROLE_MAP.ROLE_MASTER.id;
}
export var themeMode;
(function (themeMode) {
    themeMode["light"] = "light";
    themeMode["dark"] = "dark";
})(themeMode || (themeMode = {}));
export function getCurrentThemeMode(theme = null) {
    if (!theme || theme.id === undefined) {
        const reduxState = getReduxState();
        return isAllowedDarkThemeOnPage()
            ? get(reduxState, 'local.currentThemeMode', themeMode.light)
            : themeMode.light;
    }
    return theme.id === 1 ? themeMode.dark : themeMode.light;
}
// Dark Theme Styles
export const darkThemeStylesForMap = (token) => {
    return [
        {
            elementType: 'geometry',
            stylers: [{ color: token.colorBgBase }], // Background color
        },
        {
            elementType: 'labels.text.fill',
            stylers: [{ color: '#ffffff' }], // White text
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [{ visibility: 'off' }], // No stroke around text
        },
        // Roads
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: token.colorBorderSecondary }], // Slightly lighter than background
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{ color: token.colorBorderSecondary }], // Highlighted main roads
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: token.colorBorderSecondary }], // Highways in primary color
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{ color: token.colorBorderSecondary }], // Expressways/controlled access roads
        },
        // Water Bodies
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: token.colorPrimaryBg }], // Water in dark primary background
        },
        // Parks & Green Areas
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: token.colorSuccessBg }], // Parks in success background
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: token.colorSuccess }], // Park text in success color
        },
        // Landmarks (Points of Interest)
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{ color: token.colorInfoBg }], // Landmarks in info background color
        },
        {
            featureType: 'poi.business',
            elementType: 'geometry',
            stylers: [{ color: token.colorWarningBg }], // Businesses in warning background color
        },
        // Borders & Outlines
        {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{ color: token.colorBorderSecondary }], // Borders for administrative areas
        },
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: token.colorWarning }], // City/Town labels in warning color
        },
        // Public Transit
        {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: token.colorBorderSecondary }], // Transit routes
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{ color: token.colorBorderSecondary }], // Transit stations in highlight color
        },
    ];
};
export function getThemeStylesForMap(currentThemeMode, token, moreOptions = {}) {
    const { isOsMap = false } = moreOptions || {};
    const isThemeOnMapEnabled = isMapThemeChangeEnabledForAccount();
    if (isThemeOnMapEnabled && currentThemeMode === themeMode.dark) {
        return [...GOOGLE_MAP_STYLES, ...darkThemeStylesForMap(token)];
    }
    else {
        return GOOGLE_MAP_STYLES;
    }
}
export function getLogoBasedOnTheme(currentThemeMode, moreOptions = {}) {
    const { logoId, userId, logoVersion, uploadedLogo } = moreOptions || {};
    const isDark = currentThemeMode === themeMode.dark;
    let src = `/public/img/logos/clients/${logoId}.png?v=${logoVersion}`;
    if (userIdSpecificLogo()) {
        src = `/public/img/logos/users/${userId}.png?v=${logoVersion}`;
    }
    let fallbackUrl = `/public/img/logos/clients/113.png?v=${logoVersion}`;
    if (isDark && isAllowedDarkThemeOnPage()) {
        if (logoId === FLEETX_ACCOUNTS.FLEETX) {
            src = `/public/img/logos/clients/dark/113.png?v=${logoVersion}`;
        }
        fallbackUrl = `/public/img/logos/clients/dark/113.png?v=${logoVersion}`;
    }
    if (uploadedLogo) {
        src = uploadedLogo;
    }
    return { src, fallbackUrl };
}
