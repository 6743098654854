import axios from 'axios';
import {
    DispatchState,
    ESCALATION_MATRIX_CONFIG,
    parseQueryParams,
    ROOT_API,
    ROOT_API_ALFRED,
    ROOT_API_URL,
} from '../../constant';
import storage from 'utils/storage_utils';
import { SET_TABLE_CONFIG } from 'actions/dashboard/local_action';

export const FETCH_ALL_ACCOUNTS = 'fetch_all_accounts';
export const SWITCH_BY_EMAIL = 'switch_by_email';
export const SWITCH_EXIT = 'switch_exit';
export const FETCH_JOBS_BY_INVOICE = 'fetch_jobs_by_invoice';
export const VALIDATE_PROFILE_PASSWORD_CHECK = 'VALIDATE_PROFILE_PASSWORD_CHECK';
export const FETCH_TMS_DATA_BY_BILL_NUMBER = 'fetch_tms_data_by_bill_number';

export function fetchAllAccounts(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API_URL}accounts/all/`, config);

    return {
        type: FETCH_ALL_ACCOUNTS,
        promise: request,
    };
}

export function switchUserByEmail(accesstoken, emailId) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API}switch_user?username=${encodeURIComponent(emailId)}`, config);
    return {
        type: SWITCH_BY_EMAIL,
        promise: request,
    };
}

export function switchUserExit(accesstoken) {
    const config = { headers: { 'Authorization': `Bearer ${accesstoken}` } };
    const request = axios.get(`${ROOT_API}switch_user_exit`, config);
    try {
        storage.setItem(ESCALATION_MATRIX_CONFIG, '');
        if (window[DispatchState]) {
            window[DispatchState]({ type: SET_TABLE_CONFIG, payload: {} });
        }
    } catch (e) {
        console.log(e);
        if (window && window.Sentry) {
            window.Sentry.captureException(e);
        }
    }

    return {
        type: SWITCH_EXIT,
        promise: request,
    };
}

export function getJobsByInvoice(accesstoken, invoiceNo) {
    const config = {
        headers: {
            Authorization: `Bearer ${accesstoken}`,
        },
        params: parseQueryParams({
            billNumber: invoiceNo,
        }),
    };

    const request = axios.get(`${ROOT_API_URL}dispatch/bill-number`, config);
    return {
        type: FETCH_JOBS_BY_INVOICE,
        promise: request,
    };
}

export function getTmsDetailsByBillNumber(billNumber) {
    const config = {
        params: parseQueryParams({
            billNumber: billNumber,
        }),
    };

    const request = axios.get(`${ROOT_API_ALFRED}/consignment/bill-number-summary`, config);
    return {
        type: FETCH_TMS_DATA_BY_BILL_NUMBER,
        promise: request,
    };
}
