import { jsx as _jsx } from "react/jsx-runtime";
import TextArea from 'antd/es/input/TextArea';
import Form from 'antd/es/form';
const FxTextArea = (props) => {
    const { innerRef, ...rest } = props;
    if (props.withoutLabel) {
        return _jsx(TextArea, { ...rest });
    }
    return (_jsx(Form.Item, { label: props.label, children: _jsx(TextArea, { ...props, ref: innerRef }) }));
};
export default FxTextArea;
