import { jsx as _jsx } from "react/jsx-runtime";
import { ConfigProvider, Table } from 'antd';
import Loader from 'components/utils/loader';
const FxTable = (props) => {
    const { loading, style, themeModify, innerRef, ...rest } = props;
    const themeLocal = {
        token: {
            ...themeModify,
        },
    };
    const Spinner = _jsx(Loader, { inner: true });
    return (_jsx(ConfigProvider, { theme: themeLocal, children: _jsx(Table, { sortDirections: ['ascend', 'descend', 'ascend'], className: `ant-d-table-css`, ...rest, loading: { indicator: Spinner, spinning: !!loading }, style: { width: '100%', ...style }, ref: innerRef }) }));
};
export default FxTable;
