import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const History = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M11.9814 20.5C9.81598 20.5 7.92881 19.7871 6.31984 18.3615C4.71087 16.9359 3.7846 15.1487 3.54102 13H5.07174C5.32431 14.7269 6.10155 16.1586 7.40347 17.2952C8.7054 18.4317 10.2314 19 11.9814 19C13.9314 19 15.5855 18.3208 16.9439 16.9625C18.3022 15.6041 18.9814 13.95 18.9814 12C18.9814 10.05 18.3022 8.39581 16.9439 7.03748C15.5855 5.67914 13.9314 4.99998 11.9814 4.99998C10.8891 4.99998 9.86535 5.2426 8.91022 5.72785C7.9551 6.2131 7.13267 6.88073 6.44292 7.73073H9.05829V9.2307H3.98144V4.15383H5.48139V6.52308C6.29292 5.56539 7.26472 4.82213 8.39677 4.29328C9.52882 3.76443 10.7237 3.5 11.9814 3.5C13.1608 3.5 14.266 3.72308 15.2967 4.16925C16.3275 4.6154 17.2262 5.2218 17.9929 5.98845C18.7595 6.75512 19.3659 7.65383 19.8121 8.6846C20.2582 9.71537 20.4813 10.8205 20.4813 12C20.4813 13.1795 20.2582 14.2846 19.8121 15.3154C19.3659 16.3461 18.7595 17.2448 17.9929 18.0115C17.2262 18.7782 16.3275 19.3846 15.2967 19.8307C14.266 20.2769 13.1608 20.5 11.9814 20.5ZM14.9833 16.0269L11.2602 12.3038V6.99998H12.7602V11.6961L16.0371 14.9731L14.9833 16.0269Z", fill: "currentColor" }) }));
};
const HistoryIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: History, ...props });
};
export default HistoryIcon;
