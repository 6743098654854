import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
const Vehicle = () => {
    return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M5.8434 19.5576C5.06287 19.5576 4.40017 19.2847 3.8553 18.7387C3.31044 18.1928 3.038 17.5299 3.038 16.75H1.3457V6.3077C1.3457 5.80257 1.5207 5.375 1.8707 5.025C2.2207 4.675 2.64826 4.5 3.15338 4.5H16.7688V8.3077H19.4226L22.6534 12.6346V16.75H20.8457C20.8457 17.5299 20.5725 18.1928 20.0261 18.7387C19.4797 19.2847 18.8163 19.5576 18.0357 19.5576C17.2552 19.5576 16.5925 19.2847 16.0476 18.7387C15.5027 18.1928 15.2303 17.5299 15.2303 16.75H8.65335C8.65335 17.532 8.38016 18.1955 7.83378 18.7403C7.28741 19.2852 6.62395 19.5576 5.8434 19.5576ZM5.84568 18.0577C6.21234 18.0577 6.52196 17.9314 6.77453 17.6788C7.02709 17.4263 7.15338 17.1166 7.15338 16.75C7.15338 16.3833 7.02709 16.0737 6.77453 15.8211C6.52196 15.5685 6.21234 15.4423 5.84568 15.4423C5.47899 15.4423 5.16937 15.5685 4.9168 15.8211C4.66424 16.0737 4.53795 16.3833 4.53795 16.75C4.53795 17.1166 4.66424 17.4263 4.9168 17.6788C5.16937 17.9314 5.47899 18.0577 5.84568 18.0577ZM2.84568 15.25H3.56875C3.78155 14.8795 4.08892 14.5689 4.49085 14.3183C4.89279 14.0676 5.34439 13.9423 5.84568 13.9423C6.33414 13.9423 6.78254 14.066 7.19088 14.3135C7.59921 14.5609 7.90979 14.8731 8.1226 15.25H15.2688V5.99998H3.15338C3.07646 5.99998 3.00594 6.03203 2.94183 6.09613C2.87773 6.16024 2.84568 6.23077 2.84568 6.3077V15.25ZM18.038 18.0577C18.4047 18.0577 18.7143 17.9314 18.9669 17.6788C19.2194 17.4263 19.3457 17.1166 19.3457 16.75C19.3457 16.3833 19.2194 16.0737 18.9669 15.8211C18.7143 15.5685 18.4047 15.4423 18.038 15.4423C17.6713 15.4423 17.3617 15.5685 17.1091 15.8211C16.8566 16.0737 16.7303 16.3833 16.7303 16.75C16.7303 17.1166 16.8566 17.4263 17.1091 17.6788C17.3617 17.9314 17.6713 18.0577 18.038 18.0577ZM16.7688 13.25H21.2495L18.6534 9.80765H16.7688V13.25Z", fill: "currentColor" }) }));
};
const VehicleIcon = (props) => {
    const {} = props;
    return _jsx(Icon, { component: Vehicle, ...props });
};
export default VehicleIcon;
