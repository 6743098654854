import { get, set } from 'lodash';
import axios from 'axios';
import { isEnvLive, ROOT_NODE_API_URL, ROOT_LOGGING_NODE_API_URL, handleError } from '../constant';
import { sendAppAlertToSlack } from './analytics_utils';
import { getFormDataAsSimpleString, getParamsAsSimpleString } from '../utils/data_parser_utils';
import { isSwitchVehicles } from '../components/dashboard/roles/permission_utils';
import { FETCH_REPORT_S3_LINK } from 'actions/dashboard/route_dispatch/jobs_action';
import { getAccountId } from './account_utils';
import { getMomentTime } from './date_utils';

function prepareCommonData(action, state) {
    const data = { token: 'hNwSWgT9ppcJOoJnaFoyHi1l' };
    const error = `${get(action, 'payload.response.data.error', get(action, 'payload.response.data.data'))}`;
    const error_description = `${get(
        action,
        'payload.response.data.error_description',
        get(action, 'payload.response.data.message')
    )}`;

    set(data, 'error', error);
    set(data, 'error_description', error_description);
    set(data, 'type', get(action, 'type', ''));
    set(data, 'method', get(action, 'payload.config.method', ''));
    set(data, 'url', get(action, 'payload.config.url', ''));
    set(data, 'status', get(action, 'payload.response.status', 0));
    set(data, 'status_text', get(action, 'payload.response.statusText', ''));
    set(data, 'location', get(window, 'location.href', ''));
    set(data, 'base_location', `${get(window, 'location.origin', '')}${get(window, 'location.pathname', '')}`);
    set(data, 'user_email', get(state, 'settings.loggedInUser.email', ''));
    set(data, 'account_id', get(state, 'settings.loggedInUser.accountId', 0));
    set(data, 'user_id', get(state, 'settings.loggedInUser.id', 0));
    set(data, 'is_switched_user', get(state, 'login.isSwitchedUser', false));
    set(data, 'switched_user_original_email', get(state, 'login.switchedUserOriginalEmailId', ''));
    return data;
}

export async function prepareAppAlertDataAndSaveInBigquery(action, state) {
    if (!isEnvLive()) {
        return;
    }
    const data = prepareCommonData(action, state);
    if (!get(data, 'account_id')) return;
    try {
        await axios.post(`${ROOT_NODE_API_URL}lapp/alerts/savewebappalert`, data, null);
    } catch (err) {
        const text = handleError(get(err, 'response'));
        sendAppAlertToSlack(`Could not save Web App alert data to bigquery ${text}`);
    }
}
export async function prepareAppAlertEmptyResponseDataAndSaveInBigquery(action, state) {
    if (!isEnvLive()) {
        return;
    }
    const data = prepareCommonData(action, state);
    set(data, 'stack', `${get(action, 'payload.stack', '')}`);

    try {
        await axios.post(`${ROOT_NODE_API_URL}lapp/alerts/savewebappalertemptyresponse`, data, null);
    } catch (err) {
        console.log(err);
        // const text = handleError(get(err, 'response', err));
        // sendAppAlertToSlack(`Could not save Web App alert empty response data to bigquery ${text}`, '#empty-responses');
    }
}
export async function prepareNearestAddressMisMacthDataAndSaveInBigquery(info) {
    if (!isEnvLive()) {
        return;
    }
    const data = { token: 'hNwSWgT9ppcJOoJnaFoyHi1l' };

    set(data, 'account_id', `${get(info, 'account_id', '')}`);
    set(data, 'vehicle_id', `${get(info, 'vehicle_id', '')}`);
    set(data, 'original_nearest_address_id', `${get(info, 'original_nearest_address_id', '')}`);
    set(data, 'new_nearest_address_id', `${get(info, 'new_nearest_address_id', '')}`);
    set(data, 'original_is_inside', `${get(info, 'original_is_inside', '')}`);
    set(data, 'new_is_inside', `${get(info, 'new_is_inside', '')}`);
    set(data, 'vehicle_lat', `${get(info, 'vehicle_lat', '')}`);
    set(data, 'vehicle_lng', `${get(info, 'vehicle_lng', '')}`);
    try {
        await axios.post(`${ROOT_NODE_API_URL}lapp/alerts/savenearestaddressmismatch`, data, null);
    } catch (err) {
        console.log(err);
        // const text = handleError(get(err, 'response'));
        // sendAppAlertToSlack(`Could not save Nearest Address Mismatch alert data to bigquery ${err}`);
    }
}

export function logMapLoadError(error = {}, loggedInUser = {}) {
    const { name, message = 'Map cannot be loaded right now, sorry.', stack } = error;
    const { firstName, lastName, id, accountId } = loggedInUser || {};
    const text = `
      ERROR:
      ${name}
      ----------
      ${message}
      ----------
      ${stack}
      USER:${firstName} ${lastName}(${id})
      AccountId:${accountId}
    `;
    sendAppAlertToSlack(text);
}
export async function logMapLoad(type) {
    const { accountId, id } = window.FLEETX_LOGGED_IN_USER || {};

    const data = {
        token: 'ZmxlZXR4IC0gU2F0aXNoIEt1bWFy',
        application: 'WEB',
        account_id: accountId,
        location: get(window, 'location.href', ''),
        base_location: `${get(window, 'location.origin', '')}${get(window, 'location.pathname', '')}`,
        type: type,
        userId: id,
    };
    try {
        await axios.post(`${ROOT_LOGGING_NODE_API_URL}apicalllogging/mapLoad`, data, null);
    } catch (err) {
        console.log(err);
    }
}

export async function logSwitchedUserAPICalls(action, state) {
    if (!isEnvLive() || action.type === FETCH_REPORT_S3_LINK) {
        return;
    }

    const method = get(action, 'payload.config.method', '');
    const is_switched_user = get(state, 'login.isSwitchedUser', false);
    const is_switched_user_allowed = is_switched_user || isSwitchVehicles(get(state, 'login.data.scope'));
    if (!method || method === 'get' || !is_switched_user_allowed) {
        return;
    }
    const data = { token: 'ZmxlZXR4IC0gU2F0aXNoIEt1bWFy', application: 'WEB' };

    set(data, 'type', get(action, 'type', ''));
    set(data, 'method', method);
    set(data, 'url', get(action, 'payload.config.url', ''));
    set(data, 'status', get(action, 'payload.status', get(action, 'payload.response.status', 0)));
    set(data, 'status_text', get(action, 'payload.statusText', get(action, 'payload.response.statusText', '')));
    set(data, 'location', get(window, 'location.href', ''));
    set(data, 'base_location', `${get(window, 'location.origin', '')}${get(window, 'location.pathname', '')}`);
    set(data, 'account_id', get(state, 'settings.loggedInUser.accountId', 0));
    set(data, 'is_switched_user', is_switched_user);
    set(data, 'switched_user_original_email', get(state, 'login.switchedUserOriginalEmailId', ''));
    set(data, 'switched_user_original_id', get(state, 'login.switchedUserOriginalId', ''));
    set(data, 'switched_user_original_role', get(state, 'login.switchedUserOriginalRoleId', ''));
    set(data, 'body', getFormDataAsSimpleString(get(action, 'payload.config.data')));
    set(data, 'params', getParamsAsSimpleString(get(action, 'payload.config')));

    try {
        await axios.post(`${ROOT_LOGGING_NODE_API_URL}apicalllogging/switcheduserapi`, data, null);
    } catch (err) {
        console.log(err);
    }
}

export async function logReverseGeocodeAPICalls(options = {}) {
    if (!isEnvLive()) {
        return;
    }

    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};

    const { source = '', url = '', geocoder } = options;
    const data = {
        token: 'ZmxlZXR4IC0gU2F0aXNoIEt1bWFy',
        application: 'WEB',
        account_id: accountId,
        location: get(window, 'location.href', ''),
        base_location: `${get(window, 'location.origin', '')}${get(window, 'location.pathname', '')}`,
        source: source,
        url: url,
        geocoder: geocoder,
    };
    try {
        await axios.post(`${ROOT_LOGGING_NODE_API_URL}apicalllogging/reversegeocode`, data, null);
    } catch (err) {
        console.log(err);
    }
}

export async function logAutoFillFailure(options = {}) {
    if (!isEnvLive()) {
        return;
    }

    const { accountId } = window.FLEETX_LOGGED_IN_USER || {};

    const { url = '', deviceId, type, location, maker, model, errorString, licensePlate, meta } = options;

    const data = {
        token: 'ZmxlZXR4IC0gU2F0aXNoIEt1bWFy',
        account_id: accountId,
        deviceId,
        type,
        location,
        maker,
        model,
        errorString,
        licensePlate,
        meta,
    };
    try {
        await axios.post(`${ROOT_LOGGING_NODE_API_URL}apicalllogging/autofill`, data, null);
    } catch (err) {
        console.log(err);
    }
}

export async function prepareAppFirewallGrantLogsAndSaveInBigQuery(data) {
    if (!isEnvLive()) {
        return;
    }
    set(data, 'token', 'hNwSWgT9ppcJOoJnaFoyHi1l');
    try {
        await axios.post(`${ROOT_NODE_API_URL}lapp/alerts/saveappfirewallgrantlogs`, data, null);
    } catch (err) {
        const text = handleError(get(err, 'response'));
        sendAppAlertToSlack(`Could not save App Firewall Grant logs data to bigquery ${text}`);
    }
}

export async function prepareVtResourceDownloadDataAndSaveInBigquery(resourceLink, module) {
    const { switchedUserOriginalEmailId, switchedUserOriginalId } = window.SWITCHED_USER_ORIGINAL_DATA;
    const { isSwitchedUser } = window.FLEETX_LOGGED_IN_DATA;
    const { email, id } = window.FLEETX_LOGGED_IN_USER;
    const data = {
        account_id: getAccountId(),
        email: switchedUserOriginalEmailId || email,
        is_switched_user: isSwitchedUser ? true : false,
        user_id: switchedUserOriginalId || id,
        page_url: window.location.href,
        resource_link: resourceLink,
        module: module,
        token: 'hNwSWgT9ppcJOoJnaFoyHi1l',
    };

    try {
        await axios.post(`${ROOT_LOGGING_NODE_API_URL}alerts/logVtResourceDownload`, data, null);
    } catch (err) {
        console.log(err);
    }
}
