import {
    assign,
    concat,
    filter,
    find,
    forEach,
    get,
    indexOf,
    isArray,
    keys,
    map,
    omitBy,
    orderBy,
    reduce,
    replace,
    round,
    set,
    split,
    startsWith,
    sortBy,
    toLower,
    toSafeInteger,
    trim,
    join,
    includes,
    isEmpty,
    findIndex,
    floor,
    slice,
    some,
    toUpper,
    last,
    first,
    isUndefined,
    upperCase,
    uniqBy,
} from 'lodash';
import { getCurrencyFromCountry, getPhonePrefixFromCountry } from 'utils/internationalization_utils';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { queryString } from './utils/string_utils';
import {
    DATE_FORMAT_HUMANIZE,
    getEndDate,
    getMomentTime,
    HOURS_24,
    HOURS_1,
    HOURS_6,
    DATE_FORMAT_TIMESTAMP,
    getHumanizeTime,
    MINUTES_1,
} from './utils/date_utils';
import { getAt4BatteryPercent } from './utils/battery_utils';
import {
    getVehicleCategory,
    orderVehiclesByStatus,
    VEHICLE_STATUS,
    VEHICLE_TYPE_VALUE,
    PLANT_DIRECTION_VALUE,
    getMotionPointsFromRoutes,
} from './utils/vehicle_utils';
import {
    getNearestAddressFromAddressbook,
    getNearestAddressText,
    isInsideNearestAddress,
    P2P_DISTANCE_NORMALIZATION_FACTOR,
    getNearestAddressFromWithinQTNode,
    normalizeLatitude,
    deNormalizeLatitude,
    normalizeLongitude,
    deNormalizeLongitude,
    getNearestAddress,
} from './utils/map/geocode_utils';
import { isVehicleOnJob, VEHICLE_JOB_STATE } from './utils/job_route_utils';
import axios from 'axios';
import moment from 'moment-timezone';
import { fetchSelectableDevices } from './actions/dashboard/device_action';
import { getParentsFromAttachedTagIds, isTagsFilterConditionMatched } from './utils/tag_utils';
import { getGoogleMapLink, nearestLatLng, pythagorasEquirectangular } from './utils/map/map_utils';
import {
    FLEETX_ACCOUNTS,
    isExpressRoadwaysAccount,
    appendCustomJobsOnVehicleRealtime,
    getParkedVehicleDetailedTimeForAccount,
    getNearestAddressRangeForAccount,
    isQuadTreeSearchOpenForAccount,
    checkForNearestAddressMismatch,
    EXPRESS_ROADWAYS_SYSTEM_TAGS,
    looseCheckPhoneLength,
    showPinCodeInAddressForAccount,
    parkedVehicleGT2AndLT4Hour,
    parkedVehicleGT4AndLT5Hour,
    parkedVehicleLT5Hour,
    isOperatorOpenToAccount,
    getIdleMarkerDelayTimeoutForAccount,
    isDeviceTypeOpenToAccount,
    runningVehicleDetailedSummaryAvailable,
    showMovementTypeInJobBooking,
    extraSkipForAccount,
    hideTamperedTripsForAccount,
    dontClubStoppagesForAccount,
    parkedVehicleGTXAndLTXHours,
    getIdleVehicleDetailedTimeForAccount,
    idleVehicleGTXAndLTYHours,
    parkedVehicleGT0AndLT24Hours,
    parkedVehicleGT24AndLT48Hours,
    parkedVehicleGT48Hours,
    parkedVehicleGTX4AndLTY4Hour,
    getAccountId,
    getJobBookingSortBasedOn,
    isRMCAccount,
} from './utils/account_utils';
import storage from 'utils/storage_utils';
import { concatTagsForFiltering } from 'utils/tag_utils';
import { Circle } from './vendors/quadtree';
import { AADHAR_CARD_VALIDATION_REGEX } from './utils/validation_utils';
import { DEVICE_SOURCES } from './utils/device_utils';
import { isVehicleAnalyticsVisible } from '../src/components/dashboard/roles/permission_utils';
import { ALL_WHITE_LABEL_URLS } from '../white_label_constants';
import {
    getIdleTimeInMs,
    NO_NEXT_ROUTE_POINT_CATEGORY,
    shouldIncludeInFilteredListOnJobStatusTimeFilter,
    showSearchVehicleByProjectInfo,
    NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY,
    VEHICLE_DRIVER_FILTER_OPTION,
    VEHICLE_WORKORDER_FILTER_OPTION,
} from 'utils/realtime_utils';
import { getPhoneNumberWithDifferentSeparator } from 'utils/phoneNumberUtils';
import { getRangeInMSFromJobDelayFilterValue, isSImPingPointSnapEnabled } from 'utils/job_utils';
import { getParsedLockData } from 'utils/lock_utils';
import { isVehicleInSelectedBatteryLevelRange } from 'utils/ev_vehicle_utils';
import { isShreeCementAll } from 'utils/accountUtils/common';
// import { DEVICE_TYPE } from 'fx-utils';
export const wallConfig = storage.getItem('wallConfig', 'staging');

export function checkWallConfig(staging, preprod, prod, dev, staging2) {
    if (wallConfig === 'staging') {
        return staging;
    }
    if (wallConfig === 'preprod') {
        return preprod;
    }
    if (wallConfig === 'prod') {
        return prod;
    }
    if (wallConfig === 'dev') {
        return dev;
    }
    if (wallConfig === 'staging2') {
        return staging2;
    }
}

export function isEnvStaging() {
    return process.env.ENV === 'staging';
}

export function isLocalEnv() {
    return process.env.ENV === 'development';
}

export let ROOT_API_URL = isEnvLive()
    ? process.env.ROOT_API_URL
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_STAGING_URL,
          process.env.ROOT_API_PREPROD_URL,
          process.env.ROOT_API_URL,
          process.env.ROOT_API_DEV_URL,
          process.env.ROOT_API_STAGING_2_URL
      )
    : process.env.ROOT_API_STAGING_URL;
export let ROOT_API_ALFRED_V2 = isEnvLive()
    ? process.env.ROOT_API_ALFRED_V2
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_ALFRED_STAGING_V2,
          process.env.ROOT_API_ALFRED_PREPROD_V2,
          process.env.ROOT_API_ALFRED_V2,
          process.env.ROOT_API_ALFRED_DEV_V2,
          process.env.ROOT_API_ALFRED_STAGING_2_V2
      )
    : process.env.ROOT_API_ALFRED_STAGING_V2;
export let ROOT_API_ALFRED = isEnvLive()
    ? process.env.ROOT_API_ALFRED
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_ALFRED_STAGING,
          process.env.ROOT_API_ALFRED_PREPROD,
          process.env.ROOT_API_ALFRED,
          process.env.ROOT_API_ALFRED_DEV,
          process.env.ROOT_API_ALFRED_STAGING_2
      )
    : process.env.ROOT_API_ALFRED_STAGING;
export let ROOT_API_URL_V2 = isEnvLive()
    ? process.env.ROOT_API_URL_V2
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_STAGING_URL_V2,
          process.env.ROOT_API_PREPROD_URL_V2,
          process.env.ROOT_API_URL_V2,
          process.env.ROOT_API_DEV_URL_V2,
          process.env.ROOT_API_STAGING_2_URL_V2
      )
    : process.env.ROOT_API_STAGING_URL_V2;

export let ROOT_API_ERP = isEnvLive()
    ? process.env.ROOT_API_ERP
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_ERP_STAGING,
          process.env.ROOT_API_ERP_PREPROD,
          process.env.ROOT_API_ERP,
          process.env.ROOT_API_ERP_DEV,
          process.env.ROOT_API_ERP_STAGING_2
      )
    : process.env.ROOT_API_ERP_STAGING;

export let ROOT_API_DMS = isEnvLive()
    ? process.env.ROOT_API_DMS
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_DMS_STAGING,
          process.env.ROOT_API_DMS_PREPROD,
          process.env.ROOT_API_DMS,
          process.env.ROOT_API_DMS_DEV,
          process.env.ROOT_API_DMS_STAGING_2
      )
    : process.env.ROOT_API_DMS_STAGING;

export let ROOT_API_RMS = isEnvLive()
    ? process.env.ROOT_API_RMS
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_RMS_STAGING,
          process.env.ROOT_API_RMS_PREPROD,
          process.env.ROOT_API_RMS,
          process.env.ROOT_API_RMS_DEV,
          process.env.ROOT_API_RMS_STAGING_2
      )
    : process.env.ROOT_API_RMS_STAGING;

export let ROOT_API_NMS = isEnvLive()
    ? process.env.ROOT_API_NMS
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_NMS_STAGING,
          process.env.ROOT_API_NMS_PREPROD,
          process.env.ROOT_API_NMS,
          process.env.ROOT_API_NMS_DEV,
          process.env.ROOT_API_NMS_STAGING
      )
    : process.env.ROOT_API_RMS_STAGING;
//  isEnvLive() ? process.env.ROOT_API_NMS : process.env.ROOT_API_NMS_STAGING;

export let ROOT_API = isEnvLive()
    ? process.env.ROOT_API
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_STAGING,
          process.env.ROOT_API_PREPROD,
          process.env.ROOT_API,
          process.env.ROOT_API_DEV,
          process.env.ROOT_API_STAGING_2
      )
    : process.env.ROOT_API_STAGING;

export let ROOT_API_BASE = isEnvLive()
    ? process.env.ROOT_API_BASE
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_STAGING_BASE,
          process.env.ROOT_API_PREPROD_BASE,
          process.env.ROOT_API_BASE,
          process.env.ROOT_API_DEV_BASE,
          process.env.ROOT_API_STAGING_2_BASE
      )
    : process.env.ROOT_API_STAGING_BASE;
export const ROOT_API_URL_API_PROXY = isEnvLive()
    ? process.env.ROOT_API_URL_API_PROXY
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_URL,
          process.env.ROOT_API_PREPROD_URL,
          process.env.ROOT_API_URL_API_PROXY,
          process.env.ROOT_API_DEV_URL
      )
    : process.env.ROOT_API_URL;
export const ROOT_API_API_PROXY = isEnvLive()
    ? process.env.ROOT_API_API_PROXY
    : isEnvWall()
    ? checkWallConfig(
          process.env.ROOT_API_PREPROD_URL,
          process.env.ROOT_API,
          process.env.ROOT_API_API_PROXY,
          process.env.ROOT_API_API_PROXY
      )
    : process.env.ROOT_API;
export const ROOT_API_STAGING = process.env.ROOT_API_STAGING;
export const ROOT_NODE_API_URL = isLocalEnv()
    ? 'http://127.0.0.1:3002/'
    : isEnvLive()
    ? 'https://app.fleetx.io/'
    : isEnvWall()
    ? checkWallConfig(
          'https://staging.fleetx.io/',
          'https://wall.fleetx.io/',
          'https://app.fleetx.io/',
          'https://www.fleetx.io/',
          'https://wall.fleetx.io/'
      )
    : 'https://staging.fleetx.io/';
export const ROOT_LOGGING_NODE_API_URL = isLocalEnv()
    ? 'http://127.0.0.1:3004/lapp/'
    : isEnvLive()
    ? 'https://www.fleetx.io/lapp/'
    : isEnvWall()
    ? checkWallConfig(
          'https://staging.fleetx.io/lapp/',
          'https://wall.fleetx.io/lapp/',
          'https://www.fleetx.io/lapp/',
          'https://www.fleetx.io/lapp/',
          'https://wall.fleetx.io/lapp/'
      )
    : 'https://staging.fleetx.io/lapp/';
export const ROOT_REPORTING_NODE_API_URL = isLocalEnv()
    ? 'http://127.0.0.1:3006/rapp/'
    : isEnvLive()
    ? 'https://www.fleetx.io/rapp/'
    : isEnvWall()
    ? checkWallConfig(
          'https://staging.fleetx.io/rapp/',
          'https://wall.fleetx.io/rapp/',
          'https://app.fleetx.io/rapp/',
          'https://www.fleetx.io/rapp/',
          'https://wall.fleetx.io/rapp/'
      )
    : 'https://staging.fleetx.io/rapp/';
export const USE_API_PROXY_KEY = 'USE_API_PROXY';
export const ACCOUNT_CONFIG = 'ACCOUNT_CONFIG';
export const WIDGET_CONFIG = 'WIDGET_CONFIG';
export const TABLE_CONFIG = 'TABLE_CONFIG';
export const ESCALATION_MATRIX_CONFIG = 'ESCALATION_MATRIX_CONFIG';
export const REPORT_CONFIG = 'REPORT_CONFIG';
export const ALLOWED_DOMAIN = isEnvLive()
    ? 'https://api.fleetx.io'
    : isEnvWall()
    ? checkWallConfig(
          'https://api-staging.fleetx.io',
          'https://api-preprod.fleetx.io',
          'https://api.fleetx.io',
          'https://api-dev.fleetx.io',
          'https://api-staging2.fleetx.io'
      )
    : 'https://api-staging.fleetx.io';
export const ALLOWED_DOMAIN_FOR_AXIOS = [
    'https://api.fleetx.io',
    'https://api-uat.fleetx.io',
    'https://api-preprod.fleetx.io',
    'https://api-staging.fleetx.io',
    'https://api-preproddev.fleetx.io',
];
export const NOT_ALLOWED_ENDPOINT = 'alfred';
export const IMAGEKIT_PUBLIC_API_KEY = 'pSXss5Lf2CoGmkbBvrouOrrH2kU=';
export const DASHBOARD_LANDING_PATH = '/dashboard/realtime/overview';
export const TRIPS_LANDING_PATH = '/dashboard/trips';
export const MASTER_LANDING_PATH = '/dashboard/admin/home';
export const IMAGE_KIT_URL_PREFIX = 'https://ik.imagekit.io/fx';
export const DASHBOARD_ROUTE_PATH = '/dashboard/routes/jobs/';

export const MAX_VEHICLES_TO_SHOW_FOR_MASTER = 300;
export const MAX_VEHICLES_TO_SHOW_FOR_LIVE = 100;
export const MAX_LIMIT_FOR_NIGHT_DRIVING_DAYS = 30;
export const MAX_LIMIT_FOR_NIGHT_DRIVING_ALL_VEHICLE_DAYS = 1;
export const MAX_LIMIT_FOR_VEHICLE_UPTIME_DAYS = 31;
export const MAX_LIMIT_FOR_REVERSE_GEOCODE_LOCATION_CALL = 500;

export const SHELL_TELEMATICS_FORGOT_PASSWORD =
    'https://account.shell.com/change-password?access_type=offline&client_id=r4gqrahmc3kgzgmymwptqu7btqyx8jd9&code=b4dd3qsg6bvyhm&nonce=0BF6KBo36qc64pwhlFCM6jyl4f5MwcV2wwOEsGhlTzOr7Fwgc7&prompt=consent&redirect_uri=https://shelltelematcis.in/users/autologin&response_type=code&scope=openid&state=lbQWfBbcIX';

export const HIDE_UNLOADING_JOBS_IN_CONTROL_ROOM = false;

export const GREEN_BACKGROUND = '#DCF6F2';
export const BLUE_HOME = '#1f3254';
export const SHOW_HEADER_IMAGE_BOUNDS = 9;
export const POLYLINE_NEAREST_POINT_RANGE = 0.06; // 6 km

export const getShellForgotPasswordUrl = () => {
    const hostName = window.location.hostname;
    return `https://account.shell.com/change-password?access_type=offline&client_id=r4gqrahmc3kgzgmymwptqu7btqyx8jd9&code=b4dd3qsg6bvyhm&nonce=0BF6KBo36qc64pwhlFCM6jyl4f5MwcV2wwOEsGhlTzOr7Fwgc7&prompt=consent&response_type=code&scope=openid&state=lbQWfBbcIX&redirect_uri=https://shelltelematics.in/users/autologin`;
};
export const getShellSignoutUrl = () => {
    const hostName = window.location.hostname;
    return `https://account.shell.com/sign-out?access_type=offline&client_id=r4gqrahmc3kgzgmymwptqu7btqyx8jd9&code=b4dd3qsg6bvyhm&nonce=0BF6KBo36qc64pwhlFCM6jyl4f5MwcV2wwOEsGhlTzOr7Fwgc7&prompt=consent&response_type=code&scope=openid&state=lbQWfBbcIX&redirect_uri=https://shelltelematics.in/users/autologin`;
};

export const getShellPersonalDetailsUrl = () => {
    const hostName = window.location.hostname;
    return `https://account.shell.com/personal-details?access_type=offline&client_id=r4gqrahmc3kgzgmymwptqu7btqyx8jd9&code=b4dd3qsg6bvyhm&nonce=0BF6KBo36qc64pwhlFCM6jyl4f5MwcV2wwOEsGhlTzOr7Fwgc7&prompt=consent&response_type=code&scope=openid&state=lbQWfBbcIX&redirect_uri=https://shelltelematics.in/users/autologin`;
};

export const ROLE_MAP = {
    ROLE_OWNER: { name: 'ROLE_OWNER', description: 'Owner', id: 1 },
    ROLE_ADMIN: { name: 'ROLE_ADMIN', description: 'Administrator', id: 2 },
    ROLE_MANAGER: { name: 'ROLE_MANAGER', description: 'Fleet Manager', id: 3 },
    ROLE_NOTIFICATION: { name: 'ROLE_NOTIFICATION', description: 'Notifications-Only', id: 5 },
    ROLE_VIEWER: { name: 'ROLE_VIEWER', description: 'Viewer', id: 4 },
    ROLE_REALTIME_HISTORY: {
        name: 'ROLE_REALTIME_HISTORY',
        description: 'Viewer (Only Realtime And History)',
        id: 58,
    },
    ROLE_NON_OPERATOR: { name: 'ROLE_NON_OPERATOR', description: 'Non operator', id: 6 },
    ROLE_MASTER: { name: 'ROLE_MASTER', description: 'MASTER', id: 7 },
    ROLE_SUB_MASTER: { name: 'ROLE_SUB_MASTER', description: 'SALES_MASTER', id: 18 },
    ROLE_DATA_MASTER: { name: 'ROLE_DATA_MASTER', description: 'DATA_MASTER', id: 23 },
    ROLE_DATA_MASTER_WITH_EDIT: {
        name: 'ROLE_DATA_MASTER_WITH_EDIT',
        description: 'DATA_MASTER_WITH_EDIT',
        id: 56,
    },
    ROLE_FINANCE: { name: 'ROLE_FINANCE', description: 'Finance', id: 12 },
    ROLE_TECHNICIAN_MASTER: { name: 'ROLE_TECHNICIAN_MASTER', description: 'Technician Master', id: 45 },
    ROLE_DRIVER: { name: 'ROLE_DRIVER', description: 'Driver', id: 73 },
    ROLE_CUSTOMER_EPOD: { name: 'ROLE_CUSTOMER_EPOD', description: 'Customer EPOD', id: 67 },
    ROLE_TRANSPORTER: { name: 'ROLE_TRANSPORTER', description: 'Role Transporter', id: 13733 },
    ROLE_GPS_TRACKING_DRIVER_INFO_VEHICLE_INFO: {
        name: 'GPS TRACKING / DRIVER INFO / VEHICLE INFO',
        description: 'GPS TRACKING / DRIVER INFO / VEHICLE INFO',
        id: 13733,
    },
};

export const DispatchState = Symbol('DispatchState');

export const VEHICLE_SPECS_FIELDS_TYPE = {
    BODY_TYPE: 'BODY_TYPE',
    BODY_SUB_TYPE: 'BODY_SUB_TYPE',
    ENGINE_ASPIRATION: 'ENGINE_ASPIRATION',
    ENGINE_BLOCK_TYPE: 'ENGINE_BLOCK_TYPE',
    ENGINE_CAM_TYPE: 'ENGINE_CAM_TYPE',
    ENGINE_FUEL_INDUCTION: 'ENGINE_FUEL_INDUCTION',
    ENGINE_TRANSMISSION_TYPE: 'ENGINE_TRANSMISSION_TYPE',
    DRIVE_TYPE: 'DRIVE_TYPE',
    BRAKE_SYSTEM: 'BRAKE_SYSTEM',
};

export const CAMERA_FILTER = [
    {
        value: 'CAMERA',
        label: 'CAMERA',
    },
    {
        value: 'PORTABLE_DEVICE',
        label: 'PORTABLE DEVICE',
    },
    {
        value: 'GPS_LOCK',
        label: 'GPS LOCK',
    },
];

export const DEVICE_CATEGORY = {
    FLEETX_INSTALLED: { name: 'Wired', id: 'WIRED' },
    OBD: { name: 'OBD', id: 'OBD' }, // CLIENT_PORTED: { name: 'Client Ported', id: 'CLIENT_PORTED' },
    ASSET_TRACKER: { name: 'Asset Tracker', id: 'ASSET_TRACKER' },
    SIM: { name: 'SIM', id: 'SIM' },
    API: { name: 'API', id: 'API' },
    MOBILE_APP: { name: 'Mobile App', id: 'MOBILE_APP' },
    AIS140: { name: 'AIS140', id: 'AIS140' },
    APP: { name: 'APP', id: 'APP' },
};

export const USERTYPES = {
    GUNMAN: 'Gunman',
    ESCORTER: 'Escorter',
};
export const INDENT_TYPE = {
    LEASE: 'LEASE',
    MARKET: 'MARKET',
};
export const INDENT_DEVICE_OPTIONS = [
    { value: 'SIM', label: 'SIM', isDisabled: false },
    {
        value: 'APP',
        label: 'App',
        isDisabled: false,
    },
    { value: 'PORTABLE', label: 'Portable Device', isDisabled: false },
    {
        value: 'FITTED_DEVICE',
        label: 'Fitted Device',
        isDisabled: true,
        disabled: true,
    },
];

export const VEHICLE_DEPRECIATION_FORMULA = [
    { value: 'runningKMMethod', label: 'Running KM Method' },
    { value: 'compoundedDecline', label: 'Compounded Decline' },
    { value: 'constantDepreciationMethod', label: 'Constant Depreciation Method' },
    { value: 'linearMapping', label: 'Linear Mapping' },
];
export const AXLE_NUMBER_OPTIONS = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    {
        value: '3',
        label: '3',
    },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7 and above' },
];

export const GRAPH_TYPES = [
    {
        value: 'speed',
        label: 'Speed',
    },
    {
        value: 'temperature',
        label: 'Temperature',
    },
];
export const INDENT_TYPE_OPTIONS = [
    {
        value: 'LEASE',
        label: 'Lease',
    },
    {
        value: 'MARKET',
        label: 'Market',
    },
];
export const INDENT_TYPE_OPTIONS_NonMaersk = [
    ...INDENT_TYPE_OPTIONS,
    {
        value: 'OWNED',
        label: 'Owned',
    },
];
export const REGIONS = [
    {
        value: 'NORTH',
        label: 'North',
    },
    {
        value: 'SOUTH',
        label: 'South',
    },
    {
        value: 'EAST',
        label: 'East',
    },
    {
        value: 'WEST',
        label: 'West',
    },
];
export const INDENT_CATEGORY_OPTIONS = [
    {
        value: 'Empty',
        label: 'Empty',
    },
    {
        value: 'Laden',
        label: 'Laden',
    },
    {
        value: 'Empty + Laden',
        label: 'Empty + Laden',
    },
    {
        value: 'Laden + Empty',
        label: 'Laden + Empty',
    },
];

export const VENDOR_LEVELS = [
    {
        value: 'L1',
        label: 'L1',
    },
    {
        value: 'L2',
        label: 'L2',
    },
    {
        value: 'L3',
        label: 'L3',
    },
    {
        value: 'L4',
        label: 'L4',
    },
    {
        value: 'L5',
        label: 'L5',
    },
    {
        value: 'L6',
        label: 'L6',
    },
    {
        value: 'SPOT',
        label: 'SPOT',
    },
];

export const BULK_INDENT_UPDATE_OPTIONS = [
    {
        label: 'Bulk Accept',
        value: 'BULK_ACCEPT',
    },
    {
        label: 'Bulk Reject',
        value: 'BULK_REJECT',
    },
    {
        label: 'Bulk Assign Vehicles',
        value: 'BULK_ASSIGN_VEHICLES',
    },
];

const OBD_200_DEFAULTS = {
    name: '213GN-OBD200',
    protocol: 'sino',
    smsProtocol: 'sino200',
    type: 'OBD',
    fuelUnit: 'L',
    category: DEVICE_CATEGORY.OBD.id,
};

export const DEVICE_TYPE = {
    W213: {
        name: '213W',
        deviceIdCode: '213W',
        protocol: 'sino',
        smsProtocol: 'sino',
        type: 'OBD',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.OBD.id,
    },
    GL213: {
        name: '213GL',
        deviceIdCode: '213GL',
        protocol: 'sino',
        smsProtocol: 'sino',
        type: 'OBD',
        fuelUnit: '%',
        category: DEVICE_CATEGORY.OBD.id,
    },
    GDP213: {
        name: '213GD',
        deviceIdCode: '213GDP20',
        protocol: 'sino',
        smsProtocol: 'sino',
        type: 'OBD',
        fuelUnit: '%',
        category: DEVICE_CATEGORY.OBD.id,
    },
    GDPA213: {
        name: '213GDPA',
        deviceIdCode: '213GDPA20',
        protocol: 'sino',
        smsProtocol: 'sino',
        type: 'OBD',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.OBD.id,
    },
    E213: {
        name: '213E',
        deviceIdCode: '213E',
        protocol: 'sino',
        smsProtocol: 'sino',
        type: 'OBD',
        fuelUnit: '%',
        category: DEVICE_CATEGORY.OBD.id,
    },
    'GN213-OBD200': {
        deviceIdCode: '861445',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_1': {
        deviceIdCode: '862643',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_2': {
        deviceIdCode: '864369',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_3': {
        deviceIdCode: '864713',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_4': {
        deviceIdCode: '864764',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_5': {
        deviceIdCode: '865210',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_6': {
        deviceIdCode: '865674',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_7': {
        deviceIdCode: '865992',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_8': {
        deviceIdCode: '866029',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_9': {
        deviceIdCode: '866262',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_10': {
        deviceIdCode: '867856',
        ...OBD_200_DEFAULTS,
    },
    'GN213-OBD200_11': {
        deviceIdCode: '213GN',
        ...OBD_200_DEFAULTS,
    },
    FMB920: {
        name: 'FMB920',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_1: {
        name: 'FMB920',
        deviceIdCode: '35848',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_2: {
        name: 'FMB920',
        deviceIdCode: '35963',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_3: {
        name: 'FMB920',
        deviceIdCode: '35401',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_4: {
        name: 'FMB920',
        deviceIdCode: '35262',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_5: {
        name: 'FMB920',
        deviceIdCode: '35754',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_6: {
        name: 'FMB920',
        deviceIdCode: '35745',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_7: {
        name: 'FMB920',
        deviceIdCode: '35042',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_8: {
        name: 'FMB920',
        deviceIdCode: '35707',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB920_9: {
        name: 'FMB920',
        deviceIdCode: '35054',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB910: {
        name: 'FMB910',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB911: {
        name: 'FMB911',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB120: {
        name: 'FMB120',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
        temp: true,
    },
    FMB130: {
        name: 'FMB120',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
        temp: true,
    },
    FMB125: {
        name: 'FMB125',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMC125: {
        name: 'FMC125',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB225: {
        name: 'FMB225',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMC130: {
        name: 'FMC130',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMB641: {
        name: 'FMB641',
        deviceIdCode: '35209',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TFT100: {
        name: 'TFT100',
        deviceIdCode: '35963000000',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    BHARAT101: {
        name: 'BHARAT101',
        deviceIdCode: '869244',
        protocol: 'itriangle',
        smsProtocol: 'itrianglebharat101',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    BHARAT101_NEW_CHIP: {
        name: 'BHARAT101',
        deviceIdCode: '358250',
        protocol: 'itriangle',
        smsProtocol: 'itrianglebharat101_newchip',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    BHARAT101_4G: {
        name: 'BHARAT101',
        deviceIdCode: '869244',
        protocol: 'itriangle',
        smsProtocol: 'itrianglebharat101',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    TS101Basic: {
        name: 'TS101',
        deviceIdCode: '861359',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101: {
        name: 'TS101',
        deviceIdCode: '180',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101_1: {
        name: 'TS101',
        deviceIdCode: '181',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101_2: {
        name: 'TS101',
        deviceIdCode: '170',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101_3: {
        name: 'TS101',
        deviceIdCode: '202034',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101_4: {
        name: 'TS101',
        deviceIdCode: '190',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101_5: {
        name: 'TS101',
        deviceIdCode: '210',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101_6: {
        name: 'TS101',
        deviceIdCode: '211',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101EV: {
        name: 'TS101EV',
        deviceIdCode: '191',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS101EV_1: {
        name: 'TS101EV',
        deviceIdCode: '201',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    IAC140: {
        name: 'IAC140',
        deviceIdCode: '868728',
        protocol: 'ais140',
        smsProtocol: 'iac140',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    WP30L: {
        name: 'WP30L',
        deviceIdCode: '864626',
        protocol: 'ais140',
        smsProtocol: 'atlantaais',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    WP30L_GPCB: {
        name: 'WP30L_GPCB',
        deviceIdCode: '864626',
        protocol: 'ais140',
        smsProtocol: 'atlantaais',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    PRITHVI140: {
        name: 'PRITHVI140',
        deviceIdCode: '866567',
        protocol: 'ais140',
        smsProtocol: 'watsooais',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    PRITHVI140_2: {
        name: 'PRITHVI140',
        deviceIdCode: '860103',
        protocol: 'ais140',
        smsProtocol: 'watsooais',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    PRITHVI140_Gujrat_Mining: {
        name: 'PRITHVI140',
        deviceIdCode: '860103',
        protocol: 'ais140',
        smsProtocol: 'watsooais',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },

    MZCW4: {
        name: 'MZCW4',
        deviceIdCode: '868997',
        protocol: 'ais140',
        smsProtocol: 'mzcw4',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    MZCW4_1: {
        name: 'MZCW4',
        deviceIdCode: '869247',
        protocol: 'ais140',
        smsProtocol: 'mzcw4',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    MZCW4_2: {
        name: 'MZCW4',
        deviceIdCode: '862818044',
        protocol: 'ais140',
        smsProtocol: 'mzcw4',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    MZCW4_3: {
        name: 'MZCW4',
        deviceIdCode: '862818043',
        protocol: 'ais140',
        smsProtocol: 'mzcw4',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    BSTPL: {
        name: 'BSTPL',
        deviceIdCode: '862818048',
        protocol: 'bstpl',
        smsProtocol: 'bstpl',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    BSTPL_1: {
        name: 'BSTPL',
        deviceIdCode: '860697',
        protocol: 'bstpl',
        smsProtocol: 'bstpl',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    BSTPL_15: {
        name: 'BSTPL',
        deviceIdCode: '864710',
        protocol: 'bstpl',
        smsProtocol: 'bstpl15',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    DISCOVERY2G: {
        name: 'DISCOVERY2G',
        deviceIdCode: '868053',
        protocol: 'transight',
        smsProtocol: 'transight',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    DISCOVERY2GEV: {
        name: 'DISCOVERY2GEV',
        deviceIdCode: '868053',
        protocol: 'transight',
        smsProtocol: 'transight',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    DISCOVERY2GPLUS: {
        name: 'DISCOVERY2GPLUS',
        deviceIdCode: '869137',
        protocol: 'transight',
        smsProtocol: 'transight',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    DISCOVERY2PLUS485: {
        name: 'DISCOVERY2PLUS485',
        deviceIdCode: '868053',
        protocol: 'transight',
        smsProtocol: 'transight',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },

    TK06: {
        name: 'TK06',
        deviceIdCode: '355488',
        protocol: 'topten',
        smsProtocol: 'topten',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GPS_LOCK: {
        name: 'GPS_LOCK',
        deviceIdCode: '1h',
        protocol: 'openapp',
        smsProtocol: 'openapp',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    GPS_LOCK_1: {
        name: 'GPS_LOCK',
        deviceIdCode: '1f',
        protocol: 'openapp',
        smsProtocol: 'openapp',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G300_LOCK: {
        name: 'G300',
        deviceIdCode: '13798',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G300_LOCK_1: {
        name: 'G300',
        deviceIdCode: '22045',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G300_LOCK_2: {
        name: 'G300',
        deviceIdCode: '22046',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G300_LOCK_3: {
        name: 'G300',
        deviceIdCode: '30045',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G300N: {
        name: 'G300N',
        deviceIdCode: '8805',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G310: {
        name: 'G310',
        deviceIdCode: '65055',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G310_K_4G_10MH: {
        name: 'G310_K_4G_10MH',
        deviceIdCode: '3990',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G310N: {
        name: 'G310N',
        deviceIdCode: '65055',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G508: {
        name: 'G508',
        deviceIdCode: '65055',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    G508N: {
        name: 'G508N',
        deviceIdCode: '65055',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    GT03C: {
        name: 'GT03C',
        deviceIdCode: '358739',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    S20: {
        name: 'S20',
        deviceIdCode: '860465',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    S20_1: {
        name: 'S20',
        deviceIdCode: '867946',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    S20_2: {
        name: 'S20',
        deviceIdCode: '359097',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    WETRACK2: {
        name: 'WETRACK2',
        deviceIdCode: '358735',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACK2_1: {
        name: 'WETRACK2',
        deviceIdCode: '351608',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACK2_2: {
        name: 'WETRACK2',
        deviceIdCode: '358899',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACK2_3: {
        name: 'WETRACK2',
        deviceIdCode: '357591',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACK2_4: {
        name: 'WETRACK2',
        deviceIdCode: '865784',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACK2_V5: {
        name: 'WETRACK2',
        deviceIdCode: '355172',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACK2_V5_1: {
        name: 'WETRACK2',
        deviceIdCode: '866330',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT08: {
        name: 'GT08',
        deviceIdCode: '351742',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    EG02: {
        name: 'EG02',
        deviceIdCode: '358979',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TR02: {
        name: 'TR02',
        deviceIdCode: '351777',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACKLITE: {
        name: 'WETRACKLITE',
        deviceIdCode: '352503',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    WETRACKLITE_1: {
        name: 'WETRACKLITE',
        deviceIdCode: '866551',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    AT06N: {
        name: 'AT06N',
        deviceIdCode: '860016',
        protocol: 'concox',
        smsProtocol: 'at06n',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    BOFAN: {
        name: 'BOFAN',
        deviceIdCode: '868259',
        protocol: 'pt502',
        smsProtocol: 'pt502',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT06N: {
        name: 'GT06N',
        deviceIdCode: '343419',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT06N_1: {
        name: 'GT06N',
        deviceIdCode: '353701',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT06N_2: {
        name: 'GT06N',
        deviceIdCode: '862304',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT06N_3: {
        name: 'GT06N',
        deviceIdCode: '866561',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT06N_4: {
        name: 'GT06N',
        deviceIdCode: '359857',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT06N_5: {
        name: 'GT06N',
        deviceIdCode: '354718',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GT06N_6: {
        name: 'GT06N',
        deviceIdCode: '351510',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    PT06: {
        name: 'PT06',
        deviceIdCode: '356218',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    VT05: {
        name: 'VT05',
        deviceIdCode: '355139',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    AT101: {
        name: 'AT101',
        deviceIdCode: '202035',
        protocol: 'itriangle',
        smsProtocol: 'itriangle',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    AM100: {
        name: 'AM100',
        deviceIdCode: '866104',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    C100: {
        name: 'C100',
        regex: '860157',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    C100_1: {
        name: 'EV100',
        regex: '864866',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    C100_2: {
        name: 'LC100',
        regex: '867717',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    C100_3: {
        name: 'LC100',
        regex: '867857',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    C100_4: {
        name: 'LC100',
        regex: '869630',
        deviceIdCode: '869630',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    C100_5: {
        name: 'EV100F',
        regex: '864866',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    C100_6: {
        name: 'EV200',
        regex: '864866',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    C100_7: {
        name: 'EV201',
        regex: '864866',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        isElectric: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    E101: {
        name: 'E101',
        regex: '863221',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    AT4: {
        name: 'AT4',
        deviceIdCode: '353549',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    LG301: {
        name: 'LG301',
        deviceIdCode: '353549',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    LG300: {
        name: 'LG300',
        deviceIdCode: '869888',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    BR06_4G: {
        name: 'BR06_4G',
        deviceIdCode: '868738',
        protocol: 'concox',
        smsProtocol: 'concoxgt06',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
    },
    GL600: {
        name: 'GL600',
        deviceIdCode: '869926',
        protocol: 'gl600',
        smsProtocol: 'gl600',
        type: 'ASSET',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    TMD102: {
        name: 'TMD102',
        deviceIdCode: '35891',
        protocol: 'trakmate',
        smsProtocol: 'trakmate',
        type: 'WIRED',
        category: DEVICE_CATEGORY.OBD.id,
    },
    TMD364: {
        name: 'TMD364',
        deviceIdCode: '35921',
        protocol: 'trakmate',
        smsProtocol: 'trakmate',
        type: 'WIRED',
        category: DEVICE_CATEGORY.OBD.id,
    },
    GV56: {
        name: 'GV56',
        deviceIdCode: '86558',
        protocol: 'queclink',
        smsProtocol: 'queclink',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    TS: {
        name: 'TS',
        deviceIdCode: 'FX-SIM',
        protocol: 'TS',
        smsProtocol: 'TS',
        type: 'ASSET',
        category: DEVICE_CATEGORY.SIM.id,
    },
    SIM: {
        name: 'SIM',
        deviceIdCode: '91',
        protocol: 'SIM',
        smsProtocol: 'SIM',
        type: 'ASSET',
        category: DEVICE_CATEGORY.SIM.id,
    },
    APP: {
        name: 'APP',
        deviceIdCode: 'APP-',
        protocol: 'APP',
        smsProtocol: 'APP',
        type: 'WIRED',
        category: DEVICE_CATEGORY.APP.id,
    },

    API_DEVICE_0: {
        name: 'API',
        deviceIdCode: '-',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE: {
        name: 'API',
        deviceIdCode: 'Sushila',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_1: {
        name: 'API',
        deviceIdCode: 'VL',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_2: {
        name: 'API',
        deviceIdCode: 'Pruthi',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_3: {
        name: 'API',
        deviceIdCode: 'API',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_4: {
        name: 'API',
        deviceIdCode: 'JCCI',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_5: {
        name: 'API',
        deviceIdCode: 'SFC',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_6: {
        name: 'API',
        deviceIdCode: 'EXPRESSROADWAYS',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_7: {
        name: 'API',
        deviceIdCode: 'BLRLOG',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_8: {
        name: 'API',
        deviceIdCode: 'MOTHERDAIRY',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_9: {
        name: 'API',
        deviceIdCode: 'SABD',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_10: {
        name: 'API',
        deviceIdCode: 'IBPGAS',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_11: {
        name: 'API',
        deviceIdCode: 'DTDC',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_12: {
        name: 'API',
        deviceIdCode: 'SeaHawk',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_13: {
        name: 'API',
        deviceIdCode: 'NAVNEETTREHAN',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    API_DEVICE_14: {
        name: 'API',
        deviceIdCode: 'SADHAN',
        protocol: 'api',
        smsProtocol: 'api',
        type: 'API',
        category: DEVICE_CATEGORY.API.id,
    },
    ET300: {
        name: 'ET300',
        deviceIdCode: '866968',
        protocol: 'concox',
        smsProtocol: 'et300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    ET300_1: {
        name: 'ET300',
        deviceIdCode: '358511',
        protocol: 'concox',
        smsProtocol: 'et300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    ET300_2: {
        name: 'ET300',
        deviceIdCode: '868003',
        protocol: 'concox',
        smsProtocol: 'et300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    LT162: {
        name: 'LT162',
        deviceIdCode: '802210',
        protocol: 'lt162',
        smsProtocol: 'lt162',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GAGAN: {
        name: 'GAGAN',
        deviceIdCode: '869867',
        protocol: 'ais140',
        smsProtocol: 'rpgagan',
        type: 'WIRED',
        category: DEVICE_CATEGORY.AIS140.id,
    },
    GEOSAT: {
        name: 'GEOSAT_TEMP',
        deviceIdCode: '862549',
        protocol: 'geosat',
        smsProtocol: 'geosat',
        type: 'WIRED',
        temp: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GEOSAT_NORMAL: {
        name: 'GEOSAT_NORMAL',
        deviceIdCode: '862549',
        protocol: 'geosat',
        smsProtocol: 'geosat',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GEOSAT_TEMP_HUMIDITY: {
        name: 'GEOSAT_TEMP_HUMIDITY',
        deviceIdCode: '862549',
        protocol: 'geosat',
        smsProtocol: 'geosat',
        type: 'WIRED',
        temp: true,
        humidity: true,
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    GRID_FX10: {
        name: 'FX10',
        deviceIdCode: '866777',
        protocol: 'atlanta',
        smsProtocol: 'atlanta',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    FMC920: {
        name: 'FMC920',
        deviceIdCode: '864454',
        protocol: 'teltonika',
        smsProtocol: 'teltonika',
        type: 'WIRED',
        fuelUnit: 'L',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
    P10MAH: {
        name: '310P 10MAH',
        deviceIdCode: '768066',
        protocol: 'g300',
        smsProtocol: 'g300',
        type: 'WIRED',
        category: DEVICE_CATEGORY.ASSET_TRACKER.id,
        isELock: true,
    },
    LCD40: {
        name: 'LCD40',
        deviceIdCode: '863353',
        protocol: 'LOTIM',
        smsProtocol: 'LCD40',
        type: 'WIRED',
        category: DEVICE_CATEGORY.FLEETX_INSTALLED.id,
    },
};

export const CUSTOMER_TYPE = {
    CONSIGNOR: 'Consignor',
    CONSIGNEE: 'Consignee',
    CONSIGNEE_CONSIGNOR: 'Consignor & Consignee',
    CUSTOMER: 'Customer',
};

export const TYRE_STATUS = [
    { id: 'NEW', name: 'New' },
    { id: 'INSTALLED', name: 'Installed' },
    {
        id: 'IN_REPAIR',
        name: 'In Repair',
    },
    { id: 'IN_USE', name: 'In Use' },
];

export const TYRE_STATUS_V2 = [
    { id: 'NEW', name: 'New' },
    { id: 'IDLE', name: 'Idle' },
    {
        id: 'IN_REPAIR',
        name: 'In Repair',
    },
    { id: 'ATTACHED', name: 'Attached' },
];

export const TYRE_ATTACHED_TYPES = [
    { id: 'NEW_ATTACHED', name: 'New Attached' },
    { id: 'OLD_ATTACHED', name: 'Old Attached' },
    { id: 'NEW_UNATTACHED', name: 'New Unattached' },
    { id: 'OLD_UNATTACHED', name: 'Old Unattached' },
];

export const BATTERY_STATUS = [
    { id: 'NEW', name: 'New' },
    { id: 'IDLE', name: 'Idle' },
    {
        id: 'IN_REPAIR',
        name: 'In Repair',
    },
    { id: 'ATTACHED', name: 'Attached' },
];

export const TYRE_TYPE = [
    { value: 'RADIAL', label: 'Radial' },
    { value: 'NYLON', label: 'Nylon' },
];

export function getCustomerType(type) {
    if (!type) {
        return '';
    }
    if (CUSTOMER_TYPE[type]) {
        return CUSTOMER_TYPE[type];
    } else {
        return '';
    }
}

export const BILLING_CYCLE = [
    {
        value: 'MONTHLY',
        label: 'Monthly',
    },
    {
        value: 'QUARTERLY',
        label: 'Quaterly',
    },
    {
        value: 'HALFYEARLY',
        label: 'HALF YEARLY',
    },
    {
        value: 'ANNUALLY',
        label: 'Yearly',
    },
    {
        value: 'ANNUALWITHONEMONTH',
        label: 'Annual With One Month',
    },
    {
        value: 'ANNUALWITHTWOMONTHS',
        label: 'Annual With Two Months',
    },
    {
        value: 'ANNUALWITHTHREEMONTHS',
        label: 'Annual With Three Months',
    },
    {
        value: 'TWOYEARSPLAN',
        label: 'Two Years Plan',
    },
    {
        value: 'THREEYEARSPLAN',
        label: 'Three Years Plan',
    },
    {
        value: 'FOURYEARSPLAN',
        label: 'Four Years Plan',
    },
    {
        value: 'FIVEYEARSPLAN',
        label: 'Five Years Plan',
    },
];

export const ACCOUNT_STATUS = [
    {
        value: 'TRIAL',
        label: 'Trial',
    },
    {
        value: 'ACTIVE',
        label: 'Active',
    },
    {
        value: 'SUSPENDED',
        label: 'Suspended',
    },
    {
        value: 'INACTIVE',
        label: 'Inactive',
    },
];

export const TRIAL_PAID_OPTIONS = [
    {
        value: 'TRIAL',
        label: 'TRIAL',
    },
    {
        value: 'ACTIVE',
        label: 'ACTIVE',
    },
    {
        value: 'FREE',
        label: 'FREE',
    },
    {
        value: 'PAIDWITHNORATE',
        label: 'PAIDWITHNORATE',
    }, // {
    //     value: 'SUSPENDED',
    //     label: 'SUSPENDED',
    // },
    {
        value: 'INACTIVE',
        label: 'INACTIVE',
    },
    {
        value: 'UNKNOWN',
        label: 'UNKNOWN',
    },
];

export const TRIAL_OPTIONS = [
    {
        value: 'TRIAL',
        label: 'TRIAL',
    },
    {
        value: 'UNKNOWN',
        label: 'UNKNOWN',
    },
];

export const FLEET_TYPE = {
    CAR: 'Car',
    TRUCK: 'Truck',
    BUS: 'Bus',
    MOTORBIKE: 'Motorbike',
    MOTORCYCLE: 'Motorcycle',
    CONCRETE_MIXER: 'Transit Mixer',
    TOW_VAN: 'Tow Van',
    PUMP: 'Pump',
    AUTO: 'Auto',
    EME: 'EME',
    ELECTRIC_CAR: 'Electric Car',
    RAKE: 'Rake',
    TRACTOR: 'Tractor',
    JCB: 'JCB',
    PICKUP: 'Pickup',
    SHOP: 'Shop',
    TRAILER: 'Trailer',
    SEDAN: 'sedan',
    SUV: 'suv',
    MINICAB: 'mini cab',
    LUXURY_CAB: 'luxury Cabs',
    EXCAVATOR: 'Excavator',
    TIPPER: 'Loader',
    LUXURY_BUS: 'luxury Bus',
    MINI_BUS: 'mini Bus',
    URBANIA_BUS: 'urbania Bus',
    DOZER: 'Dozer',
    DRILL: 'Drill',
    DUMPER: 'Dumper',
    EXCAVATOR_CUSTOM: 'Excavator Custom',
};

export const FLEET_TYPE_OPTIONS = [
    {
        value: 'Car',
        label: 'Car',
    },
    {
        value: 'Bus',
        label: 'Bus',
    },
    {
        value: 'Truck',
        label: 'Truck',
    },
    {
        value: 'Motorbike',
        label: 'Motorbike',
    },
    {
        value: 'EME',
        label: 'EME',
    },
    {
        value: 'Electric Car',
        label: 'Electric Car',
    },
    {
        value: 'Auto',
        label: 'Auto (3 wheeler)',
    },
    {
        value: 'Rake',
        label: 'Rake',
    },
    {
        value: 'NA',
        label: 'NA',
    },
];

export const HAUL_TYPE_OPTIONS = [
    {
        value: 'LONG_HAUL',
        label: 'Long Haul',
    },
    {
        value: 'MEDIUM_HAUL',
        label: 'Medium Haul',
    },
    {
        value: 'SHORT_HAUL',
        label: 'Short Haul',
    },
];

export const INDUSTRY_TYPE_OPTIONS = [
    {
        value: 'Agriculture',
        label: 'Agriculture',
    },
    {
        value: 'Automobile',
        label: 'Automobile',
    },
    {
        value: 'Food & Beverages',
        label: 'Food & Beverages',
    },
    {
        value: 'FMCG',
        label: 'FMCG',
    },
    {
        value: 'Pharmaceuticals & Healthcare',
        label: 'Pharmaceuticals & Healthcare',
    },
    {
        value: 'Oil, Gas & Chemical',
        label: 'Oil, Gas & Chemical',
    },
    {
        value: 'Construction',
        label: 'Construction',
    },
    {
        value: 'Utilities',
        label: 'Utilities',
    },
    {
        value: 'Passenger Transit',
        label: 'Passenger Transit',
    },
    {
        value: 'Power & Energy',
        label: 'Power & Energy',
    },
    {
        value: 'Cement',
        label: 'Cement',
    },
    {
        value: 'Mining & Metals',
        label: 'Mining & Metals',
    },
    {
        value: 'E-Commerce',
        label: 'E-Commerce',
    },
    {
        value: 'IT & Telecom',
        label: 'IT & Telecom',
    },
    {
        value: 'Education',
        label: 'Education',
    },
    {
        value: 'Cold Chain Logistics',
        label: 'Cold Chain Logistics',
    },
    {
        value: 'Security & Insurance',
        label: 'Security & Insurance',
    },
    {
        value: 'Non-Profit',
        label: 'Non-Profit',
    },
    {
        value: 'Others',
        label: 'Others',
    },
];

export const PRODUCT_LINE_OPTIONS = [
    {
        value: 'VEHICLE_TELEMATICS',
        label: 'Vehicle Telematics',
    },
    {
        value: 'ERP',
        label: 'ERP',
    },
    {
        value: 'TMS',
        label: 'TMS',
    },
];

export const FMS_PRODUCT_LINE_OPTIONS = [
    {
        value: 'WIRED_GPS_TRACKING',
        label: 'Wired GPS Tracking',
    },
    {
        value: 'OBD',
        label: 'OBD',
    },
    {
        value: 'FUEL_MONITORING',
        label: 'Fuel Monitoring',
    },
    {
        value: 'TEMP_MONITORING',
        label: 'Temp Monitoring',
    },
    {
        value: 'VIDEO_TELEMATICS',
        label: 'Video Telematics',
    },
    {
        value: 'E_LOCK',
        label: 'E-lock',
    },
];

export const DEVICE_MODEL_OPTIONS = {
    'AIS': [
        {
            value: 'BHARAT101',
            label: 'BHARAT101',
        },
    ],
    'OBD': [
        {
            value: '213GDPA',
            label: '213GDPA',
        },
        {
            value: '213GN-OBD200',
            label: '213GN-OBD200',
        },
        {
            value: 'TMD102',
            label: 'TMD102',
        },
        {
            value: 'TMD364',
            label: 'TMD364',
        },
    ],
    'PORTABLE_DEVICE': [
        {
            value: 'AT4',
            label: 'AT4',
        },
        {
            value: 'LG301',
            label: 'LG301',
        },
        {
            value: 'LG300',
            label: 'LG300',
        },
        {
            value: 'AT5',
            label: 'AT5',
        },
        {
            value: 'S20',
            label: 'S20',
        },
    ],
    'PORTABLE_LOCK': [
        {
            value: 'G300',
            label: 'G300',
        },
        {
            value: 'G300N',
            label: 'G300N',
        },
        {
            value: 'G310',
            label: 'G310',
        },
        {
            value: 'G310N',
            label: 'G310N',
        },
        {
            value: 'G508',
            label: 'G508',
        },
        {
            value: 'G508N',
            label: 'G508N',
        },
        {
            value: 'JT701',
            label: 'JT701',
        },
        {
            value: 'GL600',
            label: 'GL600',
        },
    ],
    'NON_OBD': [
        {
            value: 'BSTPL-15',
            label: 'BSTPL-15',
        },
        {
            value: 'BSTPL-17IS',
            label: 'BSTPL-17IS',
        },
        {
            value: 'C100',
            label: 'C100',
        },
        {
            value: 'DISCOVERY2G',
            label: 'DISCOVERY2G',
        },
        {
            value: 'DISCOVERY2GEV',
            label: 'DISCOVERY2GEV',
        },
        {
            value: 'DISCOVERY2PLUS485',
            label: 'DISCOVERY2PLUS485',
        },
        {
            value: 'E101',
            label: 'E101',
        },
        {
            value: 'EV100',
            label: 'EV100',
        },
        {
            value: 'EV100F',
            label: 'EV100F',
        },
        {
            value: 'EV200',
            label: 'EV200',
        },
        {
            value: 'EV201',
            label: 'EV201',
        },
        {
            value: 'FMB120',
            label: 'FMB120',
        },
        {
            value: 'FMB130',
            label: 'FMB130',
        },
        {
            value: 'FMB125',
            label: 'FMB125',
        },
        {
            value: 'FMC125',
            label: 'FMC125',
        },
        {
            value: 'FMB225',
            label: 'FMB225',
        },
        {
            value: 'FMB641',
            label: 'FMB641',
        },
        {
            value: 'FMB910',
            label: 'FMB910',
        },
        {
            value: 'FMB911',
            label: 'FMB911',
        },
        {
            value: 'FMB920',
            label: 'FMB920',
        },
        {
            value: 'V5',
            label: 'V5',
        },
        {
            value: 'VT05',
            label: 'VT05',
        },
        {
            value: 'VT05R',
            label: 'VT05R',
        },
        {
            value: 'LC100',
            label: 'LC100',
        },
        {
            value: 'TFT100',
            label: 'TFT100',
        },
        {
            value: 'WP30L',
            label: 'WP30L',
        },
        {
            value: 'GV56',
            lable: 'GV56',
        },
        {
            value: 'PRITHVI140',
            label: 'PRITHVI140',
        },
        {
            value: 'TS101',
            label: 'TS101',
        },
        {
            value: 'TS101EV',
            label: 'TS101EV',
        },
        {
            value: 'normal',
            label: 'Normal',
        },
        {
            value: 'fuel',
            label: 'Fuel',
        },
        {
            value: 'temperature',
            label: 'Temperature',
        },
    ],
};

export const DEVICE_TYPE_OPTIONS = [
    {
        value: 'Wired',
        label: 'Wired',
    },
    {
        value: 'OBD',
        label: 'OBD',
    },
    {
        value: 'Sim Port Only',
        label: 'Sim Port Only',
    },
    {
        value: 'Wired + OBD',
        label: 'Wired + OBD',
    },
    {
        value: 'Wired + Sim Port',
        label: 'Wired + Sim Port',
    },
    {
        value: 'OBD + Sim Port',
        label: 'OBD + Sim Port',
    },
    {
        value: 'OBD + Wired + Sim Port',
        label: 'OBD + Wired + Sim Port',
    },
    {
        value: 'AIS',
        label: 'AIS',
    },
    {
        value: 'PORTABLE',
        label: 'PORTABLE',
    },
];

export const TARIFF_TYPES_KEYS = {
    EIGHT_EIGHTY: 'EIGHT_EIGHTY',
    FOUR_FOURTY: 'FOUR_FOURTY',
    OUTSTATION: 'OUTSTATION',
    FIXED: 'FIXED',
};

export const TARIFF_TYPES = [
    {
        value: TARIFF_TYPES_KEYS.EIGHT_EIGHTY,
        label: TARIFF_TYPES_KEYS.EIGHT_EIGHTY,
    },
    {
        value: TARIFF_TYPES_KEYS.FOUR_FOURTY,
        label: TARIFF_TYPES_KEYS.FOUR_FOURTY,
    },
    {
        value: TARIFF_TYPES_KEYS.OUTSTATION,
        label: TARIFF_TYPES_KEYS.OUTSTATION,
    },
    {
        value: TARIFF_TYPES_KEYS.FIXED,
        label: TARIFF_TYPES_KEYS.FIXED,
    },
];

export const TRIP_SHEET_STATUS = [
    {
        value: 'OPEN',
        label: 'Open',
    },
    {
        value: 'CLOSED',
        label: 'Closed',
    },
];

export const TRANSACTION_TYPES_OBJECT = {
    CR: 'Credit',
    DR: 'Debit',
};

export const REVERSE_TRIP_OBJECT = {
    YES: 'Yes',
    NO: 'No',
};

export const TRANSACTION_TYPES = [
    {
        value: 'CR',
        label: 'Credit',
    },
    {
        value: 'DR',
        label: 'Debit',
    },
];

export const TRANSACTION_TYPES_WITH_LABELS = [
    {
        value: 'CR',
        label: 'Credit (Expenses)',
    },
    {
        value: 'DR',
        label: 'Debit (Advance)',
    },
];

export const REVERSE_TRIP = [
    {
        value: 'YES',
        label: 'Yes',
    },
    {
        value: 'NO',
        label: 'No',
    },
];

export const VOUCHER_UPLOAD_TYPES = [
    {
        value: 'ReceiptAndPayment',
        label: 'Receipt & Payment',
    },
    {
        value: 'PurchaseAndSale',
        label: 'Purchase & Sale',
    },
    {
        value: 'ContraAndJournal',
        label: 'Contra & Journal',
    },
];

export const CN_NATURE_TYPES = [
    {
        value: 'BilledAndToPay',
        label: 'Billed & To Pay',
    },
    {
        value: 'PaidAndMarketMaterial',
        label: 'Paid & Market Material',
    },
];

export const EXPENSE_TYPES = [
    {
        value: 'Fuel_IOCL',
        label: 'Fuel (IOCL)',
    },
    {
        value: 'Fuel_HPCL',
        label: 'Fuel (HPCL)',
    },

    {
        value: 'FASTag_ICICI',
        label: 'FASTag(ICICI)',
    },
    {
        value: 'Default',
        label: 'Default',
    },
];

export const DUTY_STATUS = [
    {
        value: 'OPEN',
        label: 'Open',
    },
    {
        value: 'CLOSED',
        label: 'Closed',
    },
];

export const INVOICE_STATUS = {
    OPEN: {
        id: 'OPEN',
        name: 'Open',
        className: 'text-primary',
    }, // FINALISED: {
    //     id: 'FINALISED',
    //     name: 'Raised',
    //     className: 'text-danger',
    // },
    CLOSED: {
        id: 'CLOSED',
        name: 'Paid',
        className: 'text-success',
    },

    //PARTIALLY_PAID: {
    //         id: 'PARTIALLY_PAID', name: 'Partially Paid', className: 'text-danger'
    //     }
};

export const ASSIGNED_DUTY_STATUS = {
    SCHEDULED: {
        code: 'SCHEDULED',
        value: 'Scheduled',
        className: 'text-secondary',
    },
    STARTED: {
        code: 'STARTED',
        value: 'Started',
        className: 'text-primary',
    },
    COMPLETED: {
        code: 'COMPLETED',
        value: 'Completed',
        className: 'text-success',
    },
    REJECTED: {
        code: 'REJECTED',
        value: 'Rejected',
        className: 'text-danger',
    },
    MISSED: {
        code: 'MISSED',
        value: 'Missed',
        className: 'text-secondary',
    },
    NO_SHOW: {
        code: 'NO_SHOW',
        value: 'No Show',
        className: 'text-secondary',
    },
    VEHICLE_NOT_AVAILABLE: {
        code: 'VEHICLE_NOT_AVAILABLE',
        value: 'Vehicle not available',
        className: 'text-secondary',
    },
};

export const PAYMENT_STATUS = {
    OPEN: {
        id: 'OPEN',
        name: 'Open',
        className: 'text-primary',
    },
    CLOSED: {
        id: 'CLOSED',
        name: 'Closed',
        className: 'text-success',
    },
};

export const INVOICE_MODE = {
    DUTY: 'DUTY',
    JOB: 'JOB',
};

export const ACCOUNT_SOURCE_LIST = [
    'WALK-IN',
    'REFERRAL FROM CLIENT',
    'REFERRAL PROGRAM',
    'REFERRAL YES BANK',
    'DEMO REQUEST',
    'CHANNEL PARTNER',
    'INTERNATIONAL SALES',
    'Channel Distributor',
];

export const ISSUE_TO_LIST = [
    'OFFICE',
    'OFFICE REUSE',
    'OFFICE REUSE ACTUAL',
    'ALKESH',
    'ALOK',
    'AMIT',
    'BALRAM',
    'GOPAL',
    'KAMAL',
    'KHURSHID',
    'LOVE KUSH',
    'MITHILESH YADAV',
    'PAWAN',
    'PUSHPENDER',
    'RAJESH PANDEY',
    'RAVINDER',
    'SANDEEP',
    'TITU',
    'VIJAY',
    'WAHID',
    'UDBHAV',
    'DEFECTIVE WITH UDBHAV',
    'DEFECTIVE WITH UDBHAV ACTUAL',
    'SINOCASTEL',
    'ROADPOINT',
    'BANGALORE',
    'CHANDIGARH',
    'GUJARAT RAJEEV',
    'GIRISH TRANSPORT MAHARASHTRA',
    'INDORE',
    'LUCKNOW',
    'OM LOGISTICS JAMSHEDPUR',
    'QATEEL MASOOD JAMSHEDPUR',
    'OM LOGISTICS PUNE',
    'OM LOGISTICS BANGALORE',
    'OM LOGISTICS KOLKATA',
    'OM LOGISTICS MUMBAI',
    'OM LOGISTICS PATNA',
    'OM LOGISTICS RUDRAPUR',
    'OM LOGISTICS PUNJAB',
    'PVM KANPUR',
    'PUNJAB LOGISTICS',
    'KOLKATA',
    'ARORA TRANSPORT',
    'SHIVANI INDORE',
    'VIJAY CHENNAI',
    'VIKAS ROAD CARRIER',
    'AJAY BANGALORE SALES',
    'JAIPUR',
    'SANDEEP LOGISTICS CHENNAI',
    'ROSHAN',
    'LOST NOT PAID',
    'LOST PAID BY CLIENT',
    'BHIWANDI',
    'M R SHAH',
    'ANKUR SHARMA GUJARAT',
    'SHOUKAT',
    'DAYANAND',
    'NAUSAD PATNA',
    'SOPAN PUNE',
    'RAVI OM LOGISTIC',
    'DHARAMVEER PART TIME',
    'PARVIN NAGPUR',
    'SS CARRIER',
    'PARVEEN SILIGURI NSL',
    'SHAH ALAM CHENNAI',
    'RAJINDER HISAR',
    'AKASH RUDRAPUR',
    'SATISH VISHAKHAPATNAM',
    'RASHID INDORE',
    'SONU JAIPUR',
    'EXPLORE LOGISTIC',
    'LETS TRANSPORT',
    'MOHAMED ISMAIL BANGLORE',
    'JAHID',
    'MUKIT INDORE',
    'SAMAR KHAN',
    'RAMAN HYDERABAD',
    'PATHGIRI ASSAM',
    'RAJESH OM TELECOM',
    'JABED MOTIN ASSAM',
    'MANAS KANPUR',
    'SOURABH HYDERABAD',
    'SHANTANU SACHDEVA',
    'OM LOGISTICS AMBALA',
    'HARI KRISHANA',
    'AKASH RUDRAPUR',
    'YASHU AHMEDABAD',
    'SHASHIKANT GOA',
    'RONAK MAHESANA',
    'AMIT VADODRA',
    'GAGAN AMBALA',
    'PRAVEEN HYDERABAD',
    'VIKAS ARIVON',
    'PREM MUZAFFARPUR',
    'SHUBHAM HARIDWAR',
    'FAISAL METTUPALAYAM',
    'EXPRESS ROADWAYS',
    'NEERAJ ALWAR',
    'ULTRATECH JAMNAGAR',
    'ULTRATECH GUJARAT',
    'ULTRATECH MUNDRA',
    'MP BIRLA GUJARAT',
    'MP BIRLA JAMNAGER',
    'ARUP GUWAHATI',
    'KARTIK MUMBAI',
    'NIKHIL SILIGURI',
    'AVINASH MUMBAI',
    'MUNNA DURGAPUR KOLKATA',
    'VISHWA CARWAN LOGISTICS',
    'JIGNESH GUJARAT',
    'HASIM DURGAPUR KOLKATA',
    'AKHILESH JAIPUR',
    'PARIVAHAN ROAD CARRIERS',
    'KHILESH GUJARAT',
    'ABHAY GUJARAT',
    'MERAJ ALAM KOLKATA',
    'SHAHNAWAZ KOLKATA',
    'HARSHIT AHMEDABAD',
    'SHAIK VIJAYWADA',
    'HANSH RAJ RUDRAPUR',
    'SYED AFROZ',
    'ANIL KUMAR MUMBAI',
    'SASTHI PADA DURGAPUR KOLKATA',
    'NAIM ALAM KOLKATA',
    'PVM LOGISTICS',
    'PAWAN KUMAR MAJOR',
    'MUKESH KUMAR PUNE',
    'Chartered Logistics',
    'RAVI HISAR',
    'ASHARAF KOCHI',
    'AMIT RAIPUR',
    'VISHAL SAHARANPUR',
    'API',
    'BHUNESHWER OM LOGISTICS',
    'KULANDAIVELU SALEM',
    'UMESH AURANGABAD',
    'AJEB SINGH ETAH',
    'DTDC GHAZIABAD',
    'DTDC KOLKATA',
    'DTDC INDORE',
    'DTDC NAGPUR',
    'DTDC GOREGAON',
    'DTDC DELHI',
    'DTDC SAMAKLHA',
    'DTDC PUNE',
    'DTDC HYDERABAD',
    'DTDC BANGLORE',
    'DTDC CHENNAI',
    'DTDC COIMBATORE',
    'DTDC AMBALA',
    'AJAY AHMEDABAD',
    'AMRENDRA AHMEDABAD',
    'JAVED AMRELI',
    'Esanul Ansari KOLKATA',
    'QUICKDIGITAL',
    'MOHIT BHIWANDI',
    'Ambuja Cement',
    'SUDHIR  MUMBAI',
    'Inderjeet Kolkata',
    'HASHIM',
    'SOMNATH',
    'Praveen Mumbai',
    'TELTONIKA',
    'Deepak Kumar Part time',
    'Parvin Mumbai Part time',
    'Prashant Fridabad',
    'MD Arish Ejaz Chennai',
    'Amol Mumbai',
    'Pradeep Hyderabad',
    'Sunil Hyderabad',
    'Manu Mangalore',
    'Ajay Ahmedabad',
    'Mukesh Kotputli',
    'Ensual Haldiya',
    'Ismail Bangalore',
    'Sukhwinder PART TIME PUNJAB',
    'DEBABRATA Odisha',
    'ANAND YADAV',
    'ULTRATECH MANGALORE',
    'ULTRATECH Krishnapatnam',
    'CHATARPAL Part Time',
    'Ambuja KANDLA',
    'Ambuja MUNDRA',
    'DTDC KERALA',
    'ULTRATECH ANKLESHWAR',
    'SHEKHAR HARIDWAR',
    'Praveen Ludhiana',
    'Saheeram Haridwar',
];

export function getDeviceTypeFromDeviceTypeString(deviceTypeString) {
    let deviceType = {};
    map(DEVICE_TYPE, (deviceTypeDetail) => {
        if (deviceTypeString === deviceTypeDetail.name) {
            deviceType = deviceTypeDetail;
        }
    });
    return deviceType;
}

export function getDeviceTypeFromDeviceId(deviceId, deviceTypeString) {
    let deviceType = {};
    const deviceTypeKeys = Object.keys(DEVICE_TYPE);
    if (deviceTypeString) {
        const deviceTypeKey = getDeviceTypeFromDeviceTypeString(deviceTypeString);
        if (deviceTypeKey && !isEmpty(deviceTypeKey)) {
            return deviceTypeKey;
        }
    }
    if (deviceId && !!('' + deviceId).match(/APP/g)) {
        return DEVICE_TYPE.APP;
    }
    if (deviceId && !!('' + deviceId).match(/-/g)) {
        return DEVICE_TYPE.API_DEVICE;
    }
    map(DEVICE_TYPE, (deviceTypeDetail) => {
        if (startsWith(deviceId, deviceTypeDetail.deviceIdCode)) {
            deviceType = deviceTypeDetail;
        }
    });
    return deviceType;
}

export function getDeviceCategoryByDeviceId(deviceId, deviceTypeString) {
    let deviceType = {};
    const deviceTypeKeys = Object.keys(DEVICE_TYPE);
    if (deviceTypeString) {
        const deviceTypeKey = getDeviceTypeFromDeviceTypeString(deviceTypeString);
        if (deviceTypeKey && !isEmpty(deviceTypeKey)) {
            return deviceTypeKey.category;
        }
    }
    for (let i = 0; i < deviceTypeKeys.length; i++) {
        const currentKey = deviceTypeKeys[i];
        const deviceTypeDetail = DEVICE_TYPE[currentKey];
        if (deviceId && !!('' + deviceId).match(/APP/g)) {
            return DEVICE_TYPE.APP.category;
        }
        if (deviceId && !!('' + deviceId).match(/-/g)) {
            return DEVICE_TYPE.API_DEVICE.category;
        }
        if (startsWith(deviceId, deviceTypeDetail.deviceIdCode)) {
            deviceType = deviceTypeDetail;
        }
    }
    return deviceType.category;
}

export function isNetworkValid(point) {
    if (!point) {
        return false;
    }

    const dataReceivedTime = get(point, 'otherAttributes.ct', null);
    const dataTime = get(point, 'createDate', null);
    const DIFF_THRESHOLD = 15 * 60 * 1000;
    if (dataReceivedTime && dataTime && +dataReceivedTime - getMomentTime(dataTime).valueOf() > DIFF_THRESHOLD) {
        return false;
    }

    return true;

    /*
    let isNetworkValidForPoint = get(point, 'otherAttributes.isNetworkValid', true);

    if (isNetworkValidForPoint) {
        // If network is valid, the check more conditions
        const rssi = get(point, 'otherAttributes.rssi', 0);
        if (rssi == 0) {
            isNetworkValidForPoint = false;
        } else {
            const dataReceivedTime = get(point, 'otherAttributes.ct', null);
            const dataTime = get(point, 'time', null);
            if (dataReceivedTime && dataTime) {
                const diff = dataReceivedTime - dataTime;
                const DIFF_THRESHOLD = 20 * 60 * 1000;
                if (diff > DIFF_THRESHOLD) {
                    isNetworkValidForPoint = false;
                }
            }
        }
    }

    return isNetworkValidForPoint;

     */
}

export function getTotalOdometerTillNow(point, p2pCurrentTripOdometer) {
    if (!point) {
        return 0;
    }
    const { totalOdometer, deviceId } = point;
    let totalOdometerTillNow = !!totalOdometer
        ? +totalOdometer
        : +get(point, 'otherAttributes.obdOdometer', get(point, 'otherAttributes.odometer', 0));
    let currentOdometer = 0;
    if (!isCurrentTripOdometerIncludedInTotalOdometer(deviceId)) {
        currentOdometer = get(point, 'currentOdometer', get(point, 'otherAttributes.tripOdometer', 0));
        currentOdometer = +currentOdometer;
    }
    if (!currentOdometer && p2pCurrentTripOdometer) {
        currentOdometer = p2pCurrentTripOdometer;
    }
    totalOdometerTillNow += currentOdometer;
    return totalOdometerTillNow;
}

/**
 *
 * @param {*} point
 * @param {number} [index]
 * @param {*} [defaultValue]
 * @returns {*}
 */
export function getVehicleTemperature(point, index = 1, defaultValue = 0) {
    if (!point) {
        return 0;
    }
    let key = 'otherAttributes.temp';
    if (index == 2) {
        key = 'otherAttributes.temp2';
    } else if (index == 3) {
        key = 'otherAttributes.temp3';
    } else if (index == 4) {
        key = 'otherAttributes.temp4';
    }
    const temp = get(point, key);
    if (!isUndefined(temp)) {
        return round(+temp, 1);
    } else {
        return defaultValue;
    }
}

export function isHumidityVehicle(vId) {
    if (!vId) {
        return false;
    }
    return vId == 359226;
}

export function getVehicleHumidity(point) {
    if (!point) {
        return 0;
    }
    const humidity = +get(point, 'otherAttributes.humidity', 0);
    if (!!humidity) {
        return round(humidity);
    }
    return 0;
}

export function setTotalOdometerTillNow(point, startOdo = 0, currentOdometer = 0) {
    const deviceId = get(point, 'deviceId');
    let totalOdometer = startOdo;
    if (isCurrentTripOdometerIncludedInTotalOdometer(deviceId)) {
        totalOdometer += currentOdometer;
    }
    set(point, 'currentOdometer', currentOdometer);
    set(point, 'otherAttributes.tripOdometer', currentOdometer);
    set(point, 'otherAttributes.odometer', totalOdometer);
    set(point, 'totalOdometer', totalOdometer);
    return { ...point };
}

export function isCurrentTripOdometerIncludedInTotalOdometer(deviceId) {
    return !isSinoDevice(deviceId);
}

export function isApiDevice(deviceId) {
    if (getDeviceTypeFromDeviceId(deviceId).type === 'API') {
        return true;
    }
    return false;
}

export function isSinoDevice(deviceId) {
    if (getDeviceTypeFromDeviceId(deviceId).protocol === 'sino') {
        return true;
    }
    return false;
}

export function isTemperatureDevice(deviceId, deviceType, tempSensorCount = 0) {
    if (tempSensorCount > 0) {
        return true;
    }
    if (!!getDeviceTypeFromDeviceId(deviceId, deviceType).temp) {
        return true;
    }
    return false;
}

export function isVehicleEV(deviceId, deviceType) {
    if (getDeviceTypeFromDeviceTypeString(deviceType).isElectric) {
        return true;
    }
    if (isElectricApiDevice(deviceId, deviceType)) {
        return true;
    }
    if (getDeviceTypeFromDeviceId(deviceId, deviceType).isElectric) {
        return true;
    }
    return false;
}

export function isFuelDevice(deviceId, deviceType) {
    let isFuelDevice = false;

    if (
        deviceId &&
        deviceId.match(
            /^213G|^213W|^861445|^862643|^864369|^864713|^864764|^865210|^865674|^865992|^866029|^866262|^867856/
        )
    ) {
        isFuelDevice = true;
    }

    if (deviceType && (!!deviceType.match(/FMB125/) || !!deviceType.match(/FMC125/) || !!deviceType.match(/FMB641/))) {
        isFuelDevice = true;
    }
    return isFuelDevice;
}

export function isAssetTrackingDevice(deviceId, vehicleDetails, oaDeviceType) {
    const deviceType = get(vehicleDetails, 'deviceType', get(vehicleDetails, 'otherAttributes.deviceType'));

    let deviceConfig = getDeviceTypeFromDeviceTypeString(deviceType);
    if (deviceConfig?.type === 'ASSET') {
        return true;
    }

    if (oaDeviceType) {
        deviceConfig = getDeviceTypeFromDeviceTypeString(oaDeviceType);
        if (deviceConfig?.type === 'ASSET') {
            return true;
        }
    }

    deviceConfig = getDeviceTypeFromDeviceId(deviceId);
    return deviceConfig?.type === 'ASSET';
}

export function isHideBatteryData(oaDeviceType) {
    switch (oaDeviceType) {
        case 'PRITHVI140':
        case 'BHARAT101':
            return true;
    }
    return false;
}

export function isElectricVehicle(deviceId, vehicleDetails, oaDeviceType) {
    if (getDeviceTypeFromDeviceTypeString(get(vehicleDetails, 'deviceType')).isElectric) {
        return true;
    }

    if (oaDeviceType && getDeviceTypeFromDeviceTypeString(oaDeviceType).isElectric) {
        return true;
    }

    if (getDeviceTypeFromDeviceId(deviceId).isElectric) {
        return true;
    }
    return false;
}

export const hasCamera = (assetData) => {
    if (get(assetData, '0.type') === 'CAMERA') {
        return true;
    }
    return false;
};

export function isELockDevice(deviceId, vehicleDetails) {
    if (getDeviceTypeFromDeviceId(deviceId, get(vehicleDetails, 'deviceType')).isELock) {
        return true;
    }
    return false;
}

export function isGT03CDevice(deviceId) {
    if (getDeviceTypeFromDeviceId(deviceId).name === 'GT03C') {
        return true;
    }
    return false;
}

export function isV5Device(vehicle) {
    if (get(vehicle, 'otherAttributes.deviceType') == 'V5' || get(vehicle, 'otherAttributes.deviceType') == 'VT05R') {
        return true;
    }
    return false;
}

export function isS20Device(vehicle) {
    if (get(vehicle, 'otherAttributes.deviceType') == 'S20') {
        return true;
    }
    return false;
}

export function isGT08Device(vehicle) {
    if (get(vehicle, 'otherAttributes.deviceType') == 'GT08') {
        return true;
    }
    return false;
}

export function isEG02Device(deviceId) {
    if (getDeviceTypeFromDeviceId(deviceId).name === 'EG02') {
        return true;
    }
    return false;
}

export function isAt4Device(deviceId, oaDeviceType) {
    if (getDeviceTypeFromDeviceId(deviceId, oaDeviceType).name === 'AT4') {
        return true;
    }
    if (getDeviceTypeFromDeviceId(deviceId, oaDeviceType).name === 'LG301') {
        return true;
    }
    if (getDeviceTypeFromDeviceId(deviceId, oaDeviceType).name === 'LG300') {
        return true;
    }
    return false;
}

export function isAT101Device(deviceId, oaDeviceType) {
    if (getDeviceTypeFromDeviceId(deviceId, oaDeviceType).name === 'AT101') {
        return true;
    }
    return false;
}

export function isOpenAppLockDevice(deviceId, oaDeviceType) {
    if (getDeviceTypeFromDeviceId(deviceId, oaDeviceType).name === 'GPS_LOCK') {
        return true;
    }
    return false;
}

export function isG300LockDevice(deviceId, oaDeviceType) {
    const deviceName = get(getDeviceTypeFromDeviceId(deviceId, oaDeviceType), 'name');
    if (
        deviceName === 'G300' ||
        deviceName === 'G310' ||
        deviceName === 'G310N' ||
        deviceName === 'G508' ||
        deviceName === 'G508N' ||
        deviceName === 'G300N' ||
        deviceName === 'G310_K_4G_10MH'
    ) {
        return true;
    }
    return false;
}

export function isJT701LockDevice(deviceId, oaDeviceType) {
    if (oaDeviceType === 'GL600') {
        return true;
    }
    return false;
}

export function isTFT100(deviceId, oaDeviceType) {
    if (getDeviceTypeFromDeviceId(deviceId, oaDeviceType).name === 'TFT100') {
        return true;
    }
    return false;
}

export function isElectricApiDevice(deviceId, oaDeviceType) {
    if (deviceId != null && (deviceId.startsWith('3977-') || deviceId.startsWith('12033-'))) {
        return true;
    }
    return false;
}

export function isAM100Device(deviceId, oaDeviceType) {
    if (getDeviceTypeFromDeviceId(deviceId, oaDeviceType).name === 'AM100') {
        return true;
    }
    return false;
}

export function isSimDevice(deviceId) {
    if (deviceId) {
        if (getDeviceTypeFromDeviceId(deviceId).name === 'SIM') {
            return true;
        }
        return false;
    }
    return false;
}

export function isPointInsideAddressList(lat, lng, addressList, addressBookAsMap) {
    let isInside = false;
    map(addressList, (address) => {
        if (!address.radius) {
            address = get(addressBookAsMap, `${address.addressBookId}`);
        }
        if (get(address, 'radius')) {
            const distance = pythagorasEquirectangular(lat, lng, address.latitude, address.longitude);
            if (distance < address.radius / 1000) {
                isInside = true;
            }
        }
    });
    return isInside;
}

export function isAppDevice(deviceId) {
    if (deviceId) {
        if (getDeviceTypeFromDeviceId(deviceId).name === 'APP') {
            return true;
        }
        return false;
    }
    return false;
}

export const JOB_INTERNAL_STATUS = [
    {
        label: 'CHECKED',
        value: 'CHECKED',
    },
    {
        value: 'HOLD',
        label: 'HOLD',
    },
    {
        value: 'PENDING',
        label: 'PENDING',
    },
];

export const INSTALLATION_STATUS = [
    {
        label: 'EXCELLENT',
        value: 'EXCELLENT',
    },
    {
        value: 'GOOD',
        label: 'GOOD',
    },
    {
        value: 'AVERAGE',
        label: 'AVERAGE',
    },
    {
        value: 'POOR',
        label: 'POOR',
    },
];

export const WORK_MODE_LIST = [
    {
        id: -1,
        name: 'Select a work mode',
        commandSubType: 'others',
    },
    {
        id: 0,
        name: 'Passenger',
        commandSubType: 'passenger',
    },
    {
        id: 1,
        name: 'Heavy Duty',
        commandSubType: 'heavyDuty',
    },
    {
        id: 2,
        name: 'Tracker',
        commandSubType: 'tracker',
        disabled: true,
    },
];

export const VEHICLE_OWNERSHIP_TYPE = [
    {
        id: 'OWNED',
        name: 'Owned',
    },
    {
        id: 'LEASED',
        name: 'Leased',
    },
    {
        id: 'RENTED',
        name: 'Rented',
    },
    {
        id: 'CUSTOMER',
        name: 'Client',
    },
    {
        id: 'ATTACHED',
        name: 'Attached',
    },
    {
        id: 'MARKET',
        name: 'Market',
    },
];

export const VEHICLE_OWNERSHIP_TYPE_MAP = {
    OWNED: 'OWNED',
    LEASED: 'LEASED',
    RENTED: 'RENTED',
    CUSTOMER: 'CUSTOMER',
    ATTACHED: 'ATTACHED',
    MARKET: 'MARKET',
};

export const WORK_MODE_NEW_LIST = [
    {
        id: '0',
        name: 'Passenger',
    },
    {
        id: '1',
        name: 'Heavy Duty',
    },
    {
        id: '2',
        name: 'Tracker',
        disabled: true,
    },
];

export const FREQUENCY = {
    YEARS: 'YEARS',
    MONTHS: 'MONTHS',
    WEEKS: 'WEEKS',
    DAYS: 'DAYS',
    HOURS: 'HOURS',
};

export const DOCUMENT_TYPE = {
    IMAGE: 'IMAGE',
    DOCUMENT: 'DOCUMENT',
};

export const SHARE_STATUS = {
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    INACTIVE: 'INACTIVE',
};

export const EMIT_ACTION_CLEAR_FILTERS = 'EMIT_ACTION_CLEAR_FILTERS';
export const EMIT_PLAY_EMERGENCY_ALARM = 'EMIT_PLAY_EMERGENCY_ALARM';
export const EMIT_ACTION_SUMMARY_ICON_FILTER = 'EMIT_ACTION_SUMMARY_ICON_FILTER';
export const EMIT_ACTION_COMMENTED_FILTER = 'EMIT_ACTION_COMMENTED_FILTER';
export const EMIT_ACTION_SUMMARY_LOADED_FILTER = 'EMIT_ACTION_SUMMARY_LOADED_FILTER';
export const EMIT_ACTION_DOOR_STATUS_FILTER = 'EMIT_ACTION_DOOR_STATUS_FILTER';
export const EMIT_ACTION_READING_FILTER = 'EMIT_ACTION_READING_FILTER';
export const EMIT_ACTION_SUMMARY_JOB_TIMING_FILTER = 'EMIT_ACTION_SUMMARY_JOB_TIMING_FILTER';
export const EMIT_ACTION_VEHICLE_TYPE_FILTER = 'EMIT_ACTION_VEHICLE_TYPE_FILTER';
export const EMIT_ACTION_PLANT_DIRECTION_FILTER = 'EMIT_ACTION_PLANT_DIRECTION_FILTER';
export const EMIT_ACTION_SUMMARY_DIRECTIONS_FILTER = 'EMIT_ACTION_SUMMARY_DIRECTIONS_FILTER';
export const EMIT_ACTION_SUMMARY_SORT_FILTER = 'EMIT_ACTION_SUMMARY_SORT_FILTER';
export const EMIT_ACTION_VEHICLE_SEARCH_FILTER = 'EMIT_ACTION_VEHICLE_SEARCH_FILTER';
export const EMIT_ACTION_ALARM_SUMMARY_ICON_ALARM_FILTER = 'EMIT_ACTION_ALARM_SUMMARY_ICON_ALARM_FILTER';
export const EMIT_ACTION_ALARM_SUMMARY_ICON_ALARM_SUB_FILTER = 'EMIT_ACTION_ALARM_SUMMARY_ICON_ALARM_SUB_FILTER';
export const EMIT_ACTION_ALARM_SUMMARY_ICON_ISSUE_FILTER = 'EMIT_ACTION_ALARM_SUMMARY_ICON_ISSUE_FILTER';
export const EMIT_ACTION_REALTIME_VEHICLE_SUMMARY_ICON_ALARM_FILTER = 'EMIT_ACTION_ALARM_SUMMARY_ICON_ALARM_FILTER';
export const EMIT_ACTION_SET_ALL_TRIP_POINTS = 'EMIT_ACTION_SET_ALL_TRIP_POINTS';
export const EMIT_ACTION_SHOW_PLAYBACK_RECENTER = 'EMIT_ACTION_SHOW_PLAYBACK_RECENTER';
export const EMIT_ACTION_SHOW_RECENTER = 'EMIT_ACTION_SHOW_RECENTER';
export const EMIT_ACTION_HIDE_RECENTER = 'EMIT_ACTION_HIDE_RECENTER';
export const EMIT_ACTION_UPDATE_PLAYBACK_INFO = 'EMIT_ACTION_UPDATE_PLAYBACK_INFO';
export const EMIT_ACTION_SET_CURRENT_PLAYBACK = 'EMIT_ACTION_SET_CURRENT_PLAYBACK';
export const EMIT_ACTION_SPEED_GRAPH_MOVE = 'EMIT_ACTION_SPEED_GRAPH_MOVE';
export const EMIT_ACTION_UPDATE_PLAYBACK_SLIDER = 'EMIT_ACTION_UPDATE_PLAYBACK_SLIDER';
export const EMIT_ALL_ROUTE_POINTS_TIMELINE = 'EMIT_ALL_ROUTE_POINTS_TIMELINE';
export const EMIT_ACTION_CLEAR_CURRENT_PLAYBACK = 'EMIT_ACTION_CLEAR_CURRENT_PLAYBACK';
export const EMIT_ACTION_SHOW_TAMPERED_TRIPS = 'EMIT_ACTION_SHOW_TAMPERED_TRIPS';
export const EMIT_ACTION_FORCE_SHOW_TIMELINE = 'EMIT_ACTION_FORCE_SHOW_TIMELINE';
export const EMIT_ACTION_ACTUAL_TRIP_DISTANCE = 'EMIT_ACTION_ACTUAL_TRIP_DISTANCE';
export const EMIT_ACTION_SHOW_TOLLS = 'EMIT_ACTION_SHOW_TOLLS';
export const EMIT_ACTION_SHOW_ALARMS = 'EMIT_ACTION_SHOW_ALARMS';
export const EMIT_ACTION_FETCHING_ALARMS_LIST = 'EMIT_ACTION_FETCHING_ALARMS_LIST';
export const EMIT_ACTION_SHOW_TOLLS_FROM_TOLLS = 'EMIT_ACTION_SHOW_TOLLS_FROM_TOLLS';
export const EMIT_ACTION_SHOW_ADDRESS = 'EMIT_ACTION_SHOW_ADDRESS';
export const EMIT_ACTION_SHOW_JOB_DESTINATION = 'EMIT_ACTION_SHOW_JOB_DESTINATION';
export const EMIT_ACTION_HIDE_IDLES = 'EMIT_ACTION_HIDE_IDLES';
export const EMIT_ACTION_SHOW_UNSNAPPED = 'EMIT_ACTION_SHOW_UNSNAPPED';
export const EMIT_ACTION_FETCHING_TOLLS_LISTS = 'EMIT_ACTION_FETCHING_TOLLS_LISTS';
export const EMIT_ACTION_FETCHING_ADDRESS_LIST = 'EMIT_ACTION_FETCHING_ADDRESS_LIST';
export const EMIT_ACTION_FETCHING_JOB_DESTINATION_LIST = 'EMIT_ACTION_FETCHING_JOB_DESTINATION_LIST';
export const EMIT_ACTION_KM_RANGE = 'EMIT_ACTION_KM_RANGE';
export const EMIT_ACTION_EDIT_ROUTE_ON_MAP = 'EMIT_ACTION_EDIT_ROUTE_ON_MAP';
export const EMIT_ACTION_HIDE_EDIT_ROUTE_ON_MAP = 'EMIT_ACTION_HIDE_EDIT_ROUTE_ON_MAP';
export const EMIT_ACTION_SHOW_VEHICLE_VIEW = 'EMIT_ACTION_SHOW_VEHICLE_VIEW';
export const EMIT_ACTION_UNLOADING_POINT_JOB = 'EMIT_ACTION_UNLOADING_POINT_JOB';
export const EMIT_ACTION_SHOW_VEHICLE_VIEW_CHANGE_TOGGLE = 'EMIT_ACTION_SHOW_VEHICLE_VIEW_CHANGE_TOGGLE';
export const EMIT_ACTION_FUEL_SENSOR_STATUS_CHANGE = 'EMIT_ACTION_FUEL_SENSOR_STATUS_CHANGE';
export const EMIT_ACTION_TEMP_SENSOR_STATUS_CHANGE = 'EMIT_ACTION_TEMP_SENSOR_STATUS_CHANGE';
export const CAMERA_FILTER_CHANGE = 'CAMERA_FILTER_CHANGE';
export const EMIT_ACTION_JOB_DATA_SOURCE_CHANGE = 'EMIT_ACTION_JOB_DATA_SOURCE_CHANGE';
export const EMIT_ACTION_SELECTED_VEHICLE = 'EMIT_ACTION_SELECTED_VEHICLE';
export const EMIT_ACTION_SELECT_VEHICLE = 'EMIT_ACTION_SELECT_VEHICLE';
export const EMIT_ACTION_SELECT_GROUP = 'EMIT_ACTION_SELECT_GROUP';
export const EMIT_SET_VEHICLE_LIST = 'EMIT_SET_VEHICLE_LIST';
export const EMIT_FEED_VEHICLE_LIST = 'EMIT_FEED_VEHICLE_LIST';
export const EMIT_ACTION_NEXT_ADDRESS_BOOK_CATEGORY_FILTER = 'EMIT_ACTION_NEXT_ADDRESS_BOOK_CATEGORY_FILTER';
export const EMIT_ACTION_CURRENT_ADDRESS_BOOK_CATEGORY_FILTER = 'EMIT_ACTION_CURRENT_ADDRESS_BOOK_CATEGORY_FILTER';
export const EMIT_ACTION_SCHEDULE_TO_ADDRESS_BOOK_CATEGORY_FILTER =
    'EMIT_ACTION_SCHEDULE_TO_ADDRESS_BOOK_CATEGORY_FILTER';
export const EMIT_ACTION_CURRENT_TRANSPORTER = 'EMIT_ACTION_CURRENT_TRANSPORTER';
export const EMIT_ACTION_SHOW_ADDRESS_FILTER_SELECT = 'EMIT_ACTION_SHOW_ADDRESS_FILTER_SELECT';

export const EMIT_ACTION_SHOW_DEVIATION_LINES = 'EMIT_ACTION_SHOW_DEVIATION_LINES';
export const EMIT_ACTION_SHOW_SALES_VISUALIZATION = 'EMIT_ACTION_SHOW_SALES_VISUALIZATION';

export const EMIT_ACTION_SHOW_DEPOT_LOCATIONS = 'EMIT_ACTION_SHOW_DEPOT_LOCATIONS';
export const EMIT_ACTION_SHOW_DISTRICT_BOUNDRIES = 'EMIT_ACTION_SHOW_DISTRICT_BOUNDRIES';
export const EMIT_ACTION_SELECTED_DISTRICT = 'EMIT_ACTION_SELECTED_DISTRICT';

export const SA_LOGIN = 'superadminlogin';
export const SA_PASSWORD = 'SimplePasswordForParveenAndVishal';
export const REFRESH_JOB_EMITTER = 'REFRESH_JOB_EMITTER';
export const EMIT_ACTION_FOR_UPDATE = 'EMIT_ACTION_FOR_UPDATE';
export const EMIT_FOR_INCLUDE_EXCLUDE = 'EMIT_FOR_INCLUDE_EXCLUDE';
export const MARK_UNLOADING_EMIT = 'MARK_UNLOADING_EMIT';
export const MARK_POINT_OF_INTREST_EMIT = 'MARK_POINT_OF_INTREST_EMIT';

export const AFTER_FORM_SUBMITTED_UNLOADING = 'AFTER_FORM_SUBMITTED_UNLOADING';
export const SUBMIT_FORM_UNLOADING = 'SUBMIT_FORM_UNLOADING';

export const ALL_USER_TYPES = ['operator', 'driver', 'employee', 'technician'];

export const USER_TYPE_LIST = [
    {
        id: 'OPERATOR',
        name: 'Operator',
    },
    {
        id: 'EMPLOYEE',
        name: 'Employee',
    },
    {
        id: 'TECHNICIAN',
        name: 'Technician',
    },
    {
        id: 'DRIVER',
        name: 'Driver',
    },
];

export const USER_TYPE_ID_MAPPING = {
    'DRIVER': 1,
};

export const MOBILE_VIEW_TYPE = {
    LIST: 'LIST',
    MAP: 'MAP',
    DEFAULT: 'MAP',
};

export const REALTIME_VIEWS = {
    LIST: 'LIST',
    MAP: 'MAP',
    SPLIT_VIEW: 'SPLIT_VIEW',
    DEFAULT: 'SPLIT_VIEW',
};

export const CONTROLROOM_VIEW_TYPE = {
    JOB_DASHBOARD: 'JOB_DASHBOARD',
    LOCATION_DASHBOARD: 'LOCATION_DASHBOARD',
    RUNNING_DASHBOARD: 'RUNNING_DASHBOARD',
    PLANT_DASHBOARD_TVS: 'PLANT_DASHBOARD_TVS',
    DISCONNECT_DASHBOARD: 'DISCONNECT_DASHBOARD',
    CONTROL_TOWER: 'CONTROL_TOWER',
    VARUNA_EVENT_BOOK: 'VARUNA_EVENT_BOOK',
    VAARUNA_ORDER_REPORT: 'VAARUNA_ORDER_REPORT',
};

export const CONTROLROOM_VIEW_ORDER = [
    {
        key: CONTROLROOM_VIEW_TYPE.JOB_DASHBOARD,
        value: 0,
    },
    { key: CONTROLROOM_VIEW_TYPE.LOCATION_DASHBOARD, value: 1 },
    // {
    //     key: CONTROLROOM_VIEW_TYPE.RUNNING_DASHBOARD,
    //     value: 2,
    // },
    { key: CONTROLROOM_VIEW_TYPE.PLANT_DASHBOARD_TVS, value: 2 },
    {
        key: CONTROLROOM_VIEW_TYPE.DISCONNECT_DASHBOARD,
        value: 3,
    },
    { key: CONTROLROOM_VIEW_TYPE.CONTROL_TOWER, value: 4 },
    { key: CONTROLROOM_VIEW_TYPE.VARUNA_EVENT_BOOK, value: 5 },
    { key: CONTROLROOM_VIEW_TYPE.VAARUNA_ORDER_REPORT, value: 6 },
];

export const TRIP_VIEW_TYPE = {
    LIST: 'LIST',
    TIMELINE: 'TIMELINE',
    SPEEDING: 'SPEEDING',
    DEFAULT: 'LIST',
    ASSET_MOVEMENT: 'ASSET_MOVEMENT',
    STOPPAGE: 'STOPPAGE',
    STOPPAGE_LIST: 'STOPPAGE_LIST',
    DETECT_A_TO_B_TRIPS: 'A_TO_B_TRIPS',
    HISTORICAL_LOCATION: 'HISTORICAL_LOCATION',
};

export const REAL_TIME_TIMER_TRIGGER_SECONDS_MULTI_VEHICLES = 30;
export const REAL_TIME_TIMER_TRIGGER_SECONDS_SINGLE_VEHICLES = 15;
export const CONTROL_ROOM_TIMER_TRIGGER_SECONDS = 180;
export const CONTROL_TOWER_TIMER_TRIGGER_SECONDS = 45;
export const CONTROL_ROOM_PAGE_CHANGE_TRIGGER_SECONDS = 30;
export const TIMELINE_TIMER_TRIGGER_SECONDS = 30;
export const HIERARCHY_ARRAY_IN_ORDER = ['ZONE', 'CLUSTER', 'CITY', 'PLANT', 'VENDOR'];

export const FEATURE_COLORS = {
    GREY: 'grey',
    WHITE: 'white',
};

export const LANDING_PAGE = {
    HOME: 'HOME',
    PULSE: 'PULSE',
    EASY: 'EASY',
    DRIVE: 'DRIVE',
    ABOUT: 'ABOUT',
    REFERRAL: 'REFERRAL',
    FINANCE: 'FINANCE',
    AIS140: 'AIS140',
    FASTAG: 'FASTAG',
};

export const JOB_TAGS = {
    JOB_UP: 729,
    JOB_DOWN: 730,
    IN_BOUND: 5429,
    OUT_BOUND: 5430,
};

export const JOB_TAGS_NAME = {
    JOB_UP: 'UP',
    JOB_DOWN: 'DOWN',
    IN_BOUND: 'IN BOUND',
    OUT_BOUND: 'OUT BOUND',
};

export const CUSTOMISE_REPORT = {
    TMS_REPORT: 'TMS_REPORT',
    INDENT_REPORT: 'INDENT_REPORT',
    HorizontalJobReport: 'HORIZONTAL_JOB_REPORT',
};

export const MAX_ADDRESSBOOK_FOR_ALLOWING_MULTI_VEHICLE_STOPPAGE_REPORT = 500;

export function getDirectionText(angle) {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    return directions[Math.round(((angle * 30) % 360) / 45) % 8];
}

export function getTripOdo(distance = 0, showUnit = true, format = true) {
    const roundedDistance = round(distance, distance > 100 ? 0 : 1);
    return (format ? numberToLocaleString(roundedDistance) : roundedDistance) + (showUnit ? ' km' : '');
}

export function image(relativeUrl) {
    if (!isEnvLive()) {
        return relativeUrl;
    } else if (relativeUrl.match('/public/img/')) {
        return 'https://ik.imagekit.io/fx/' + relativeUrl.substring(12);
    } else {
        return relativeUrl;
    }
}

export function getTelephoneLink(value) {
    const phoneNumberPrefix = getPhonePrefixFromCountry();
    if (!value) {
        return null;
    }

    const parsedNumber = getPhoneNumberWithDifferentSeparator(value, '-');
    if (parsedNumber) {
        value = parsedNumber;
    } else {
        if (!startsWith(value, `${phoneNumberPrefix}`)) {
            value = `${phoneNumberPrefix}-${value}`;
        }
    }
    return (
        <a
            href={`tel:${value}`}
            onClick={(e) => {
                e.stopPropagation();
            }}
            style={{ textDecoration: 'none', cursor: 'pointer', userSelect: 'text' }}
            className="phone-number-link"
        >
            {value}
        </a>
    );
}

export function getEmailLink(value) {
    return (
        <a
            href={`mailto:${value}`}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {value}
        </a>
    );
}

export function getWebsiteLink(value) {
    return (
        <a
            href={`http://${value}`}
            target="_blank"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {value}
        </a>
    );
}

export function renderRowAttribute(
    name,
    value,
    rowClass,
    isTelephoneNumber,
    textClass,
    badgeText,
    suptext,
    hrefLink,
    unitClass = '',
    callbackText,
    callbackFunction,
    customText,
    icon,
    info
) {
    return (
        <Row className={rowClass}>
            <Col xs="5" className="text-gray">
                <span>{name}</span>
                <span className="pull-right">{name ? ':' : ''}</span>
                <span className="clearfix" />
            </Col>
            <Col xs="7" className={textClass ? textClass : 'text-normal'}>
                {isTelephoneNumber && getTelephoneLink(value)}
                {hrefLink && (
                    <a target="_blank" href={hrefLink} style={{ color: '#427fed', fontWeight: '400' }}>
                        <span>{value}</span>
                    </a>
                )}
                {!isTelephoneNumber && !hrefLink && value}
                {unitClass && <i className={`${unitClass} ml-1`} />}
                {suptext && value && <sup>{suptext}</sup>}
                {badgeText && <span className="ml-1 badge badge-dark">{badgeText}</span>}
                {callbackText && callbackFunction && (
                    <Button size="sm" onClick={callbackFunction}>
                        {callbackText}
                    </Button>
                )}
                {customText && customText}
                {icon && icon}
            </Col>
        </Row>
    );
}

export function renderRowAttributeV2({
    name,
    value,
    rowClass,
    isTelephoneNumber,
    textClass,
    badgeText,
    suptext,
    hrefLink,
    unitClass = '',
    callbackText,
    callbackFunction,
    customText,
    icon,
    info,
}) {
    return (
        <Row className={rowClass}>
            <Col xs="5" className="text-gray">
                <span>{name}</span>
                {name && info && <i className="fa fa-info-circle cursor-pointer ml-1" title={info} />}
                <span className="pull-right">{name ? ':' : ''}</span>
                <span className="clearfix" />
            </Col>
            <Col xs="7" className={textClass ? textClass : 'text-normal'}>
                {isTelephoneNumber && getTelephoneLink(value)}
                {hrefLink && (
                    <a target="_blank" href={hrefLink} style={{ color: '#427fed', fontWeight: '400' }}>
                        <span>{value}</span>
                    </a>
                )}
                {!isTelephoneNumber && !hrefLink && value}
                {unitClass && <i className={`${unitClass} ml-1`} />}
                {suptext && value && <sup>{suptext}</sup>}
                {badgeText && <span className="ml-1 badge badge-dark">{badgeText}</span>}
                {callbackText && callbackFunction && (
                    <Button size="sm ml-1" onClick={callbackFunction}>
                        {callbackText}
                    </Button>
                )}
                {customText && customText}
                {icon && icon}
            </Col>
        </Row>
    );
}

export function renderRowAttributeWithCurrency(name, value, rowClass, textClass, badgeText, suptext, loggedInUser) {
    return (
        <Row className={rowClass}>
            <Col xs="5" className="text-gray">
                <span>{name}</span>
                <span className="pull-right">{name ? ':' : ''}</span>
                <span className="clearfix" />
            </Col>
            <Col xs="7" className={textClass ? textClass : 'text-normal'}>
                {value} {getCurrencyFromCountry(loggedInUser, true)}
                {suptext && value && <sup>{suptext}</sup>}
                {badgeText && <span className="ml-1 badge badge-dark">{badgeText}</span>}
            </Col>
        </Row>
    );
}

export function renderRowAttributeZigZag(name, zigZagPoints = [], zigZagLocations = [], loggedInUser, onZigZagHover) {
    const zigZagLength = get(zigZagPoints, 'length', 0);
    const zigZagLocationsLength = get(zigZagLocations, 'length', 0);
    return zigZagLength > 0 ? (
        <>
            <Row>
                <Col xs="5" className="text-gray">
                    <span>{name}</span>
                    <span className="pull-right">{name ? ':' : ''}</span>
                    <span className="clearfix" />
                </Col>
                <Col xs="7" className="text-normal">
                    {map(zigZagPoints, (zigZag, index) => {
                        const zigZagLatLng = split(zigZag, '|');
                        return (
                            <>
                                <div
                                    onMouseOver={() => onZigZagHover(zigZagLatLng)}
                                    onMouseOut={() => onZigZagHover(null)}
                                    className="cursor-pointer hover"
                                >
                                    <span>{`${get(zigZagLatLng, '0')}, ${get(zigZagLatLng, '1')} `}</span>
                                    {/* <span>
                                <a
                                    target="_blank"
                                    href={getGoogleMapLink(zigZagLatLng[0], zigZagLatLng[1])}
                                    style={{ color: '#427fed', fontWeight: '400' }}
                                >
                                    <span>
                                        <i class="fa fa-external-link" aria-hidden="true"></i>
                                        {'Map'}
                                    </span>
                                </a>
                            </span> */}
                                    <span>{index < zigZagLength - 1 ? ',' : ''}</span>
                                </div>
                            </>
                        );
                    })}
                </Col>
            </Row>
            <Row>
                <Col xs="5" className="text-gray">
                    <span className="clearfix" />
                </Col>
                <Col xs="7" className="text-normal">
                    {map(zigZagLocations, (v, i) => {
                        return (
                            <div>
                                <span className="text-success">{upperCase(v)}</span>
                                <span>{i < zigZagLocationsLength - 1 ? ',' : ''}</span>
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </>
    ) : (
        <div></div>
    );
}

export function renderRowAttributeForAddress(name, address, rowClass, isTelephoneNumber, textClass, badgeText) {
    const { addressLine1, addressLine2, city, state, postalCode, country } = address;
    return (
        <Row className={rowClass}>
            <Col xs="5" className="text-gray">
                <span>{name}</span>
                <span className="pull-right">{name ? ':' : ''}</span>
                <span className="clearfix" />
            </Col>
            <Col xs="7" className={textClass ? textClass : 'text-normal'}>
                {addressLine1 && <div>{addressLine1}</div>}
                {addressLine2 && <div>{addressLine2}</div>}
                {(city || postalCode) && (
                    <div>
                        {city && <span>{`${city} `}</span>} {postalCode && <span>{postalCode}</span>}
                    </div>
                )}
                {(state || country) && (
                    <div>
                        {state && <span>{`${state} `}</span>} {country && <span>{country}</span>}
                    </div>
                )}
            </Col>
        </Row>
    );
}

export const isAndroid = () => {
    if (navigator.platform === 'Android') {
        return true;
    }
    if (navigator.userAgent.match(/Android/i)) {
        return true;
    }
    return false;
};

export function renderReportIncludeCol(label, key, state, onCheckChanged, customClass, index, disabled) {
    return (
        <span className="d-inline-block px-2 py-2 w-50" key={key ? key : index}>
            <Label className={`switch switch-3d mr-2 mb-0 ${disabled ? 'switch-secondary' : 'switch-success'}`}>
                <Input
                    type="checkbox"
                    className="switch-input"
                    data-key={key}
                    checked={state}
                    onChange={onCheckChanged}
                    disabled={disabled}
                />
                <span className="switch-label" />
                <span className="switch-handle" />
            </Label>
            <span className={customClass} style={!state ? { 'opacity': '0.75' } : { 'opacity': '1' }}>
                {label}
            </span>
        </span>
    );
}

export function getScoreClass(score) {
    if (score > 90) {
        return 'score-con excellent-color';
    } else if (score >= 80 && score <= 90) {
        return 'score-con good-color';
    } else if (score >= 70 && score < 80) {
        return 'score-con average-color';
    } else if (score >= 60 && score < 70) {
        return 'score-con below-average-color';
    } else if (score >= 50 && score < 60) {
        return 'score-con bad-color';
    } else if (score < 50) {
        return 'score-con very-bad-color';
    }
}

export function getScoreTextColor(score) {
    if (score > 90) {
        return 'text-excellent-color';
    } else if (score >= 80 && score <= 90) {
        return 'text-good-color';
    } else if (score >= 70 && score < 80) {
        return 'text-average-color';
    } else if (score >= 60 && score < 70) {
        return 'text-below-average-color';
    } else if (score >= 50 && score < 60) {
        return 'text-bad-color';
    } else if (score < 50) {
        return 'text-very-bad-color';
    }
}

export function getScoreText(score) {
    if (score > 90) {
        return 'Excellent';
    } else if (score >= 80 && score <= 90) {
        return 'Good';
    } else if (score >= 70 && score < 80) {
        return 'Okay';
    } else if (score >= 60 && score < 70) {
        return 'Bad';
    } else if (score >= 50 && score < 60) {
        return 'Concerning';
    } else if (score < 50) {
        return 'Very Bad';
    } else {
        return '';
    }
}

export function getScoreClassRatingScale(score) {
    if (score > 4) {
        return 'score-con bad-color';
    } else if (score > 2 && score <= 4) {
        return 'score-con average-color';
    } else if (score > 0 && score <= 2) {
        return 'score-con excellent-color';
    } else if (score === 0) {
        return 'score-con excellent-color';
    }
}

export function getScoreTextColorRatingScale(score) {
    if (score > 4) {
        return 'text-bad-color';
    } else if (score > 2 && score <= 4) {
        return 'text-average-color';
    } else if (score > 0 && score <= 2) {
        return 'text-excellent-color';
    } else if (score === 0) {
        return 'text-excellent-color';
    }
}

export function getScoreTextRatingScale(score) {
    if (score > 4) {
        return 'Bad';
    } else if (score > 2 && score <= 4) {
        return 'Okay';
    } else if (score > 0 && score <= 2) {
        return 'Good';
    } else if (score === 0) {
        return 'Good';
    } else {
        return '';
    }
}

export function getScoreTextColorRatingScore(score) {
    if (score > 4) {
        return 'text-bad-color';
    } else if (score > 2 && score <= 4) {
        return 'text-average-color';
    } else if (score > 0 && score <= 2) {
        return 'text-excellent-color';
    }
}

export function renderRowAttributeSmall(name, value, classes, isTelephoneNumber) {
    return (
        <Row className={classes}>
            <Col xs="8" className="text-gray">
                <span>{name}</span>
                <span className="pull-right">{name ? ':' : ''}</span>
                <span className="clearfix" />
            </Col>
            <Col xs="4" className="text-normal">
                {isTelephoneNumber ? getTelephoneLink(value) : value}
            </Col>
        </Row>
    );
}

export function stringifyUrl(
    groupId,
    vehicleId,
    startDate,
    endDate,
    alertType,
    dtcType,
    view,
    status,
    tyreId,
    tagFilter,
    deviceCategoryFilter,
    currentPage,
    isLive,
    storeId,
    runningPercentage,
    accountId,
    ewayExpiredDayFilter,
    isEwayExpiredFilter,
    category,
    notOnJobVehicleFilter,
    vehicleKmRangeFilter,
    issueStatus,
    alertSubType,
    transporterId,
    jobStatusTime,
    excludeVehicleStatusFilter,
    currentTransporter,
    jobDelayFilter,
    onJobAlarm,
    moreOptions = {}
) {
    const {
        vehicleDriverAssignmentFilter,
        vehicleWorkorderStatusFilter,
        vehicleSizeFilter,
        jobTimingFilter,
        currentAddressBookFilter,
        routeIdFilter,
        evBatteryLevelFilter,
        parkingStatusDurationFilter,
        showVehicleWithCriticalDtcs,
        currentTransporterId,
        selectedTagType,
        geoJsonNamePropertyFilter,
    } = moreOptions || {};

    console.log(moreOptions, 'parsed 2');

    let parseObject = {};

    if (geoJsonNamePropertyFilter) {
        set(parseObject, 'geoJsonNamePropertyFilter', geoJsonNamePropertyFilter);
    }

    if (showVehicleWithCriticalDtcs) {
        set(parseObject, 'showVehicleWithCriticalDtcs', showVehicleWithCriticalDtcs);
    }
    if (currentAddressBookFilter) {
        set(parseObject, 'currentAddressBookFilter', currentAddressBookFilter);
    }
    if (routeIdFilter) {
        set(parseObject, 'routeIdFilter', routeIdFilter);
    }
    if (jobTimingFilter) {
        set(parseObject, 'jobTimingFilter', jobTimingFilter);
    }
    if (vehicleSizeFilter) {
        set(parseObject, 'vehicleSizeFilter', vehicleSizeFilter);
    }

    if (vehicleDriverAssignmentFilter) {
        set(parseObject, 'vehicleDriverAssignmentFilter', vehicleDriverAssignmentFilter);
    }

    if (vehicleWorkorderStatusFilter) {
        set(parseObject, 'vehicleWorkorderStatusFilter', vehicleWorkorderStatusFilter);
    }

    if (groupId) {
        set(parseObject, 'groupId', groupId);
    }
    if (vehicleId) {
        set(parseObject, 'vehicleId', vehicleId);
    }

    if (startDate) {
        const sDate = startDate.valueOf();
        set(parseObject, 'startDate', sDate);

        if (endDate) {
            const eDate = endDate.valueOf();
            if (sDate !== eDate) {
                set(parseObject, 'endDate', eDate);
            }
        }
    }

    if (alertType) {
        set(parseObject, 'alertType', encodeURIComponent(alertType));
    }

    if (alertSubType) {
        set(parseObject, 'alertSubType', encodeURIComponent(alertSubType));
    }

    if (issueStatus) {
        set(parseObject, 'issueStatus', encodeURIComponent(issueStatus));
    }

    if (dtcType) {
        set(parseObject, 'dtcType', encodeURIComponent(dtcType));
    }

    if (view) {
        set(parseObject, 'view', encodeURIComponent(view));
    }
    if (category) {
        set(parseObject, 'category', encodeURIComponent(category));
    }

    if (get(status, 'value')) {
        set(parseObject, get(status, 'type'), encodeURIComponent(get(status, 'value')));
    }
    if (tyreId) {
        set(parseObject, 'tyreId', encodeURIComponent(tyreId));
    }

    if (tagFilter) {
        set(
            parseObject,
            'filterByTags',
            join(
                map(tagFilter.selectedTags, (tg) => {
                    return tg.value;
                }),
                ','
            )
        );
        set(parseObject, 'filterByTagsType', tagFilter.selectedFilter);
    }
    if (deviceCategoryFilter) {
        set(
            parseObject,
            'deviceCategory',
            typeof deviceCategoryFilter === 'string' ? deviceCategoryFilter : join(deviceCategoryFilter, ',')
        );
    }
    if (currentPage) {
        set(parseObject, 'currentPage', currentPage);
    }

    if (isLive) {
        set(parseObject, 'isLive', encodeURIComponent(isLive));
    }

    if (storeId) {
        set(parseObject, 'storeId', storeId);
    }

    if (runningPercentage) {
        set(parseObject, 'runningPercentage', runningPercentage);
    }

    if (accountId) {
        set(parseObject, 'accountId', accountId);
    }

    if (get(notOnJobVehicleFilter, 'value')) {
        set(parseObject, 'job_status', encodeURIComponent(get(notOnJobVehicleFilter, 'value')));
        if (get(notOnJobVehicleFilter, 'range')) {
            set(parseObject, 'range', encodeURIComponent(get(notOnJobVehicleFilter, 'range')));
        }
    }

    if (get(vehicleKmRangeFilter, 'value')) {
        set(parseObject, 'location_status', encodeURIComponent(get(vehicleKmRangeFilter, 'value')));
        set(parseObject, 'km_range', encodeURIComponent(get(vehicleKmRangeFilter, 'km_range')));
        set(parseObject, 'lat', encodeURIComponent(get(vehicleKmRangeFilter, 'lat')));
        set(parseObject, 'lng', encodeURIComponent(get(vehicleKmRangeFilter, 'lng')));
    }
    if (ewayExpiredDayFilter) {
        set(parseObject, 'ewayExpiredDayFilter', ewayExpiredDayFilter);
    }
    if (isEwayExpiredFilter) {
        set(parseObject, 'isEwayExpiredFilter', isEwayExpiredFilter);
    }
    if (transporterId) {
        set(parseObject, 'transporterId', transporterId);
    }
    if (jobStatusTime) {
        set(parseObject, 'jobStatusTime', jobStatusTime);
    }
    if (
        excludeVehicleStatusFilter &&
        (isArray(excludeVehicleStatusFilter) ? excludeVehicleStatusFilter.length : true)
    ) {
        set(
            parseObject,
            'excludeVehicleStatusFilter',
            isArray(excludeVehicleStatusFilter) ? join(excludeVehicleStatusFilter, ',') : excludeVehicleStatusFilter
        );
    }
    if (currentTransporter) {
        set(parseObject, 'currentTransporter', currentTransporter);
    }
    if (currentTransporterId) {
        set(parseObject, 'currentTransporterId', currentTransporterId);
    }
    if (jobDelayFilter) {
        set(parseObject, 'jobDelayFilter', join(jobDelayFilter, ','));
    }
    if (onJobAlarm) {
        set(parseObject, 'onJobAlarm', onJobAlarm);
    }
    if (selectedTagType) {
        set(parseObject, 'selectedTagType', selectedTagType);
    }
    const stringified = queryString.stringify(parseObject);
    window.location.hash = decodeURIComponent(stringified);
}

export function stringifyFiltersUrl(filters, navigate) {
    const parseObject = {};
    Object.keys(filters).forEach((key) => {
        if (filters[key] !== null && filters[key] !== undefined) {
            parseObject[key] = filters[key];
        }
    });

    if (filters.startDate) {
        set(parseObject, 'startDate', getMomentTime(filters.startDate).valueOf());
    }

    if (filters.endDate) {
        set(parseObject, 'endDate', getMomentTime(filters.endDate).valueOf());
    }

    const stringUrl = queryString.stringify(parseObject);

    if (!!navigate) {
        navigate(`#${decodeURIComponent(stringUrl)}`, {
            replace: true,
        });
    } else {
        window.location.hash = decodeURIComponent(stringUrl);
    }
}

export function stringifyUrlThroughState(state) {
    const parseObject = {};
    if (state.jobBookingCreatedBy) {
        set(parseObject, 'jobBookingCreatedBy', state.jobBookingCreatedBy);
    }
    if (state.showOnlyLive) {
        set(parseObject, 'showOnlyLive', state.showOnlyLive);
    }
    if (state.groupId) {
        set(parseObject, 'groupId', state.groupId);
    }
    if (state.hideSummary) {
        set(parseObject, 'hideSummary', state.hideSummary);
    }

    if (state.city) {
        set(parseObject, 'city', state.city);
    }
    if (state.settled) {
        set(parseObject, 'settled', state.settled);
    }
    if (state.jobStatus) {
        set(parseObject, 'jobStatus', state.jobStatus);
    }

    if (state.innerGeofenceStatus) {
        set(parseObject, 'innerGeofenceStatus', state.innerGeofenceStatus);
    }
    if (state.outerGeofenceStatus) {
        set(parseObject, 'outerGeofenceStatus', state.outerGeofenceStatus);
    }
    if (state.jobClosingReason) {
        set(parseObject, 'jobClosingReason', state.jobClosingReason);
    }
    if (state.workOrderStatus) {
        set(parseObject, 'workOrderStatus', state.workOrderStatus);
    }
    if (state.workOrderCategory) {
        set(parseObject, 'workOrderCategory', state.workOrderCategory);
    }
    if (state.workOrderPriority) {
        set(parseObject, 'workOrderPriority', state.workOrderPriority);
    }
    if (state.transporterId) {
        set(parseObject, 'transporterId', state.transporterId);
    }
    if (state.searchValue) {
        set(parseObject, 'searchValue', state.searchValue);
    }
    if (state.sourcePlant) {
        set(parseObject, 'sourcePlant', state.sourcePlant);
    }
    if (state.shipToTradeName) {
        set(parseObject, 'shipToTradeName', state.shipToTradeName);
    }
    if (state.destinationState) {
        set(parseObject, 'destinationState', state.destinationState);
    }
    if (state.destinationDistrict) {
        set(parseObject, 'destinationDistrict', state.destinationDistrict);
    }
    if (state.destination) {
        set(parseObject, 'destination', state.destination);
    }
    if (state.liveHalting) {
        set(parseObject, 'liveHalting', state.liveHalting);
    }
    if (state.tagFilter) {
        set(
            parseObject,
            'filterByTags',
            join(
                map(state.tagFilter.selectedTags, (tg) => {
                    return get(tg, 'value', tg);
                }),
                ','
            )
        );
        set(parseObject, 'filterByTagsType', state.tagFilter.selectedFilter);
    }
    if (state.vehicleId) {
        set(parseObject, 'vehicleId', state.vehicleId);
    }
    if (state.jobGroupId) {
        set(parseObject, 'jobGroupId', state.jobGroupId);
    }
    if (state.batteryStatus) {
        set(parseObject, 'batteryStatus', state.batteryStatus);
    }
    if (state.chargingStatus) {
        set(parseObject, 'chargingStatus', state.chargingStatus);
    }
    if (state.mappingStatus) {
        set(parseObject, 'mappingStatus', state.mappingStatus);
    }
    if (state.indentType) {
        set(parseObject, 'indentType', state.indentType);
    }

    if (state.startDate) {
        const sDate = state.startDate.valueOf();
        set(parseObject, 'startDate', sDate);

        if (state.endDate) {
            const eDate = state.endDate.valueOf();
            if (sDate !== eDate) {
                set(parseObject, 'endDate', eDate);
            }
        }
    }

    if (state.alertType) {
        set(parseObject, 'alertType', encodeURIComponent(state.alertType));
    }

    if (state.dtcType) {
        set(parseObject, 'dtcType', encodeURIComponent(state.dtcType));
    }

    if (state.serviceTaskId) {
        set(parseObject, 'serviceTaskId', state.serviceTaskId);
    }

    if (state.vehicleRenewalTypeId) {
        set(parseObject, 'vehicleRenewalTypeId', state.vehicleRenewalTypeId);
    }
    if (state.dueSoon) {
        set(parseObject, 'dueSoon', state.dueSoon);
    }
    if (state.contactRenewalTypeId) {
        set(parseObject, 'contactRenewalTypeId', state.contactRenewalTypeId);
    }

    if (state.userId) {
        set(parseObject, 'userId', state.userId);
    }

    if (state.currentStatusFilter) {
        set(parseObject, 'currentStatusFilter', state.currentStatusFilter);
    }
    if (state.jobRunningStatus) {
        set(parseObject, 'jobRunningStatus', state.jobRunningStatus);
    }

    if (state.pointStatusFilter) {
        set(parseObject, 'pointStatusFilter', state.pointStatusFilter);
    }

    if (state.onlyPointsFilter) {
        set(parseObject, 'onlyPointsFilter', state.onlyPointsFilter);
    }

    if (state.routeIdFilter) {
        set(parseObject, 'routeIdFilter', state.routeIdFilter);
    }

    if (state.notesFilter) {
        set(parseObject, 'notesFilter', state.notesFilter);
    }
    if (state.invoiceFilter) {
        set(parseObject, 'invoiceFilter', state.invoiceFilter);
    }

    if (state.issueFilter) {
        set(parseObject, 'issueFilter', state.issueFilter);
    }

    if (state.customerId) {
        set(parseObject, 'customerId', state.customerId);
    }

    if (state.dutyStatus) {
        set(parseObject, 'dutyStatus', state.dutyStatus);
    }

    if (state.invoiceStatus) {
        set(parseObject, 'invoiceStatus', state.invoiceStatus);
    }

    if (state.activeTab) {
        set(parseObject, 'activeTab', state.activeTab);
    }

    if (state.activeSubTab) {
        set(parseObject, 'activeSubTab', state.activeSubTab);
    }

    if (state.addressBookId) {
        set(parseObject, 'addressBookId', state.addressBookId);
    }
    if (state.paymentId) {
        set(parseObject, 'paymentId', state.paymentId);
    }
    if (state.paymentStatus) {
        set(parseObject, 'status', state.paymentStatus);
    }
    if (state.vendorId) {
        set(parseObject, 'vendorId', state.vendorId);
    }
    if (state.rfidMismatch) {
        set(parseObject, 'rfidMismatch', state.rfidMismatch);
    }
    if (state.currentPage) {
        set(parseObject, 'currentPage', state.currentPage);
    }
    if (state.riskLevel) {
        set(parseObject, 'riskLevel', state.riskLevel);
    }
    if (state.minUnloadingTime) {
        set(parseObject, 'minUnloadingTime', state.minUnloadingTime);
    }
    if (state.maxUnloadingTime) {
        set(parseObject, 'maxUnloadingTime', state.maxUnloadingTime);
    }
    if (state.minLoadingTime) {
        set(parseObject, 'minLoadingTime', state.minLoadingTime);
    }
    if (state.maxLoadingTime) {
        set(parseObject, 'maxLoadingTime', state.maxLoadingTime);
    }
    if (state.minDelay) {
        set(parseObject, 'minDelay', state.minDelay);
    }
    if (state.maxDelay) {
        set(parseObject, 'maxDelay', state.maxDelay);
    }
    if (state.minDeviationKm) {
        set(parseObject, 'minDeviationKm', state.minDeviationKm);
    }
    if (state.maxDeviationKm) {
        set(parseObject, 'maxDeviationKm', state.maxDeviationKm);
    }
    if (state.riskLevel) {
        set(parseObject, 'riskLevel', state.riskLevel);
    }
    if (state.sortOrder) {
        set(parseObject, 'sortOrder', state.sortOrder);
    }
    if (state.sortName) {
        set(parseObject, 'sortName', state.sortName);
    }
    if (state.status) {
        set(parseObject, 'status', state.status);
    }
    if (state.priority) {
        set(parseObject, 'priority', state.priority);
    }
    if (state.type) {
        set(parseObject, 'type', state.type);
    }
    if (state.subType) {
        set(parseObject, 'subType', state.subType);
    }
    if (state.reportedById) {
        set(parseObject, 'reportedById', state.reportedById);
    }
    if (state.assignedToId) {
        set(parseObject, 'assignedToId', state.assignedToId);
    }
    if (state.assignedToIds) {
        set(parseObject, 'assignedToIds', join(state.assignedToIds, ','));
    }
    if (state.notAssigned) {
        set(parseObject, 'notAssigned', state.notAssigned);
    }
    if (state.overdue) {
        set(parseObject, 'overdue', state.overdue);
    }
    if (state.includePastRunning) {
        set(parseObject, 'includePastRunning', state.includePastRunning);
    }
    if (state.includeCompleteDuring) {
        set(parseObject, 'includeCompleteDuring', state.includeCompleteDuring);
    }
    if (state.includeOnlyCompleteDuring) {
        set(parseObject, 'includeOnlyCompleteDuring', state.includeOnlyCompleteDuring);
    }
    if (state.byCreatedDate) {
        set(parseObject, 'byCreatedDate', state.byCreatedDate);
    }
    if (state.category) {
        set(parseObject, 'category', state.category);
    }
    if (state.isRealtime) {
        set(parseObject, 'isRealtime', state.isRealtime);
    }
    if (state.view) {
        set(parseObject, 'view', state.view);
    }
    if (state.currentView) {
        set(parseObject, 'view', state.currentView);
    }
    if (state.accountId) {
        set(parseObject, 'accountId', state.accountId);
    }
    if (state.ewayExpiredDayFilter) {
        set(parseObject, 'ewayExpiredDayFilter', state.ewayExpiredDayFilter);
    }
    if (state.isEwayExpiredFilter) {
        set(parseObject, 'isEwayExpiredFilter', state.isEwayExpiredFilter);
    }
    if (state.clientCode) {
        set(parseObject, 'clientCode', state.clientCode);
    }
    if (state.partId) {
        set(parseObject, 'partId', state.partId);
    }
    if (state.storeId) {
        set(parseObject, 'storeId', state.storeId);
    }
    if (state.partEntryId) {
        set(parseObject, 'partEntryId', state.partEntryId);
    }
    if (state.bidType) {
        set(parseObject, 'bidType', state.bidType);
    }
    if (state.deviceType) {
        set(parseObject, 'deviceType', state.deviceType);
    }
    if (state.driverId) {
        set(parseObject, 'driverId', state.driverId);
    }
    if (state.transporterId) {
        set(parseObject, 'transporterId', state.transporterId);
    }

    if (state.activeTabChildren) {
        set(parseObject, 'subTab', state.activeTabChildren);
    }

    if (state.fuelTypeId) {
        set(parseObject, 'fuelTypeId', state.fuelTypeId);
    }
    if (state.senderIds) {
        set(parseObject, 'senderIds', state.senderIds);
    }
    if (state.receiverIds) {
        set(parseObject, 'receiverIds', state.receiverIds);
    }
    if (state.createdBy) {
        set(parseObject, 'createdBy', state.createdBy);
    }
    if (state.region) {
        set(parseObject, 'region', state.region);
    }
    if (state.currentLineItemFilter) {
        set(parseObject, 'currentLineItemFilter', state.currentLineItemFilter);
    }
    if (state.zigZagStop) {
        set(parseObject, 'zigZagStop', state.zigZagStop);
    }
    if (state.addressBookId) {
        set(parseObject, 'addressBookId', state.addressBookId);
    }
    if (state.includeDrafts) {
        set(parseObject, 'includeDrafts', state.includeDrafts);
    }
    if (state.segmentId) {
        set(parseObject, 'segmentId', state.segmentId);
    }
    if (state.sizePerPage) {
        set(parseObject, 'sizePerPage', state.sizePerPage);
    }
    if (state.transporterStatus) {
        set(parseObject, 'transporterStatus', state.transporterStatus);
    }
    if (state.customerStatus) {
        set(parseObject, 'customerStatus', state.customerStatus);
    }
    if (state.shipperTransporterStatus) {
        set(parseObject, 'shipperTransporterStatus', state.shipperTransporterStatus);
    }
    if (state.shipperCustomerStatus) {
        set(parseObject, 'shipperCustomerStatus', state.shipperCustomerStatus);
    }
    if (state.epodStatus) {
        set(parseObject, 'epodStatus', state.epodStatus);
    }
    if (state.customerId) {
        set(parseObject, 'customerId', state.customerId);
    }
    if (state.weighmentJobs) {
        set(parseObject, 'weighmentJobs', state.weighmentJobs);
    }
    if (state.movementType) {
        set(parseObject, 'movementType', state.movementType);
    }
    if (state.billNumber) {
        set(parseObject, 'billNumber', state.billNumber);
    }

    if (state.vehicleMakeId) {
        set(parseObject, 'vehicleMakeId', state.vehicleMakeId);
    }
    if (state.vehicleModelId) {
        set(parseObject, 'vehicleModelId', state.vehicleModelId);
    }
    if (state.deviceCategoryFilter) {
        set(
            parseObject,
            'deviceCategoryFilter',
            isArray(state.deviceCategoryFilter) ? join(state.deviceCategoryFilter, ',') : state.deviceCategoryFilter
        );
    }
    if (state.vehicleOwner) {
        set(parseObject, 'vehicleOwner', state.vehicleOwner);
    }

    if (state.allVehicles) {
        set(parseObject, 'allVehicles', !!state.allVehicles);
    }

    if (state.showRemovedVehicles) {
        set(parseObject, 'showRemovedVehicles', !!state.showRemovedVehicles);
    }

    if (state.destinationDeviationType) {
        set(parseObject, 'destinationDeviationType', state.destinationDeviationType);
    }

    if (state.tab) {
        set(parseObject, 'tab', state.tab);
    }
    if (state.id) {
        set(parseObject, 'id', state.id);
    }
    if (state.lorryArrival) {
        set(parseObject, 'lorryArrival', state.lorryArrival);
    }
    if (state.clientContractChartJob) {
        set(parseObject, 'clientContractChartJob', state.clientContractChartJob);
    }

    if (state.reportDownloadType) {
        set(parseObject, 'reportDownloadType', state.reportDownloadType);
    }
    if (state.uploaderType) {
        set(parseObject, 'uploaderType', state.uploaderType);
    }
    if (state.laneIds) {
        set(parseObject, 'laneIds', state.laneIds);
    }

    if (state.shipToParty) {
        set(parseObject, 'shipToParty', state.shipToParty);
    }
    if (state.soldToParty) {
        set(parseObject, 'soldToParty', state.soldToParty);
    }
    if (state.industry) {
        set(parseObject, 'industry', state.industry);
    }

    if (state.commodity) {
        set(parseObject, 'commodity', state.commodity);
    }

    if (state.transporterIds) {
        if (isArray(state.transporterIds)) {
            if (!isEmpty(state.transporterIds)) {
                set(parseObject, 'transporterIds', state.transporterIds);
            }
        } else {
            set(parseObject, 'transporterIds', state.transporterIds);
        }
    }
    if (state.dispatchId) {
        set(parseObject, 'dispatchId', state.dispatchId);
    }
    if (state.epodUploaded) {
        set(parseObject, 'epodUploaded', state.epodUploaded);
    }

    if (state.winningTransporterId) {
        set(parseObject, 'winningTransporterId', state.winningTransporterId);
    }

    if (state.routeIds) {
        set(parseObject, 'routeIds', state.routeIds);
    }

    if (state.preBidding) {
        set(parseObject, 'preBidding', state.preBidding);
    }

    if (state.partBidding) {
        set(parseObject, 'partBidding', state.partBidding);
    }

    if (state.autoExtension) {
        set(parseObject, 'autoExtension', state.autoExtension);
    }

    if (state.vehicleSizeIds) {
        set(parseObject, 'vehicleSizeIds', state.vehicleSizeIds);
    }

    if (state.userFlow) {
        set(parseObject, 'userFlow', state.userFlow);
    }

    // if (state.winningTransporter) {
    //     set(parseObject, 'winningTransporter', state.winningTransporter);
    // }

    if (state.consignmentNumber) {
        set(parseObject, 'consignmentNumber', state.consignmentNumber);
    }
    if (state.paymentId) {
        set(parseObject, 'paymentId', state.paymentId);
    }
    if (state.customerIds) {
        set(parseObject, 'customerIds', state.customerIds);
    }
    if (state.destinationAddress) {
        set(parseObject, 'destinationAddress', state.destinationAddress);
    }
    if (state.vendorIds) {
        set(parseObject, 'vendorIds', state.vendorIds);
    }
    if (state.bookingNumber) {
        set(parseObject, 'bookingNumber', state.bookingNumber);
    }
    if (state.documentNumber) {
        set(parseObject, 'documentNumber', state.documentNumber);
    }
    if (state.sourceAddressBookId) {
        set(parseObject, 'sourceAddressBookId', state.sourceAddressBookId);
    }
    if (state.isParent) {
        set(parseObject, 'isParent', state.isParent);
    }
    if (state.dutyType) {
        set(parseObject, 'dutyType', state.dutyType);
    }
    if (state.installationType) {
        set(parseObject, 'installationType', state.installationType);
    }
    if (state.auditStatus) {
        set(parseObject, 'auditStatus', state.auditStatus);
    }
    if (state.vehicleNumber) {
        set(parseObject, 'vehicleNumber', state.vehicleNumber);
    }
    if (state.macIds) {
        set(parseObject, 'macIds', state.macIds);
    }
    if (state.stageId) {
        set(parseObject, 'stageId', state.stageId);
    }
    if (state.orderId) {
        set(parseObject, 'orderId', state.orderId);
    }
    if (state.dutyType) {
        set(parseObject, 'dutyType', state.dutyType);
    }
    if (state.installationType) {
        set(parseObject, 'installationType', state.installationType);
    }
    if (state.auditStatus) {
        set(parseObject, 'auditStatus', state.auditStatus);
    }
    if (state.vehicleNumber) {
        set(parseObject, 'vehicleNumber', state.vehicleNumber);
    }

    if (state.bookType) {
        set(parseObject, 'bookType', state.bookType);
    }
    if (state.bookName) {
        set(parseObject, 'bookName', state.bookName);
    }
    if (state.branchId) {
        set(parseObject, 'branchId', state.branchId);
    }
    if (state.stageStatus) {
        set(parseObject, 'stageStatus', state.stageStatus);
    }
    if (state.bookType) {
        set(parseObject, 'bookType', state.bookType);
    }
    if (state.bookName) {
        set(parseObject, 'bookName', state.bookName);
    }
    if (state.branchId) {
        set(parseObject, 'branchId', state.branchId);
    }
    if (state.fromTime) {
        set(parseObject, 'fromTime', state.fromTime);
    }
    if (state.toTime) {
        set(parseObject, 'toTime', state.toTime);
    }
    if (state.accountIds) {
        set(parseObject, 'accountIds', state.accountIds);
    }
    if (state.jobId) {
        set(parseObject, 'jobId', state.jobId);
    }
    if (state.issueId) {
        set(parseObject, 'issueId', state.issueId);
    }
    if (state.analyticsPoint) {
        set(parseObject, 'analyticsPoint', state.analyticsPoint);
    }
    if (state.analyticsPointRange) {
        set(parseObject, 'analyticsPointRange', state.analyticsPointRange);
    }
    if (state.vehicleStatusId) {
        set(parseObject, 'vehicleStatusId', state.vehicleStatusId);
    }
    if (state.cargoType) {
        set(parseObject, 'cargoType', state.cargoType);
    }
    if (state.channel) {
        set(parseObject, 'channel', state.channel);
    }
    if (state.planId) {
        set(parseObject, 'planId', state.planId);
    }
    if (state.expenseTypeId) {
        set(parseObject, 'expenseTypeId', state.expenseTypeId);
    }

    const stringified = queryString.stringify(parseObject);
    window.location.hash = decodeURIComponent(stringified);
}

export function getQueryStringParams() {
    if (!window.location.hash) {
        return {};
    } else {
        return queryString.parse(window.location.hash);
    }
}

export function getQueryStringSearch() {
    if (!window.location.search) {
        return {};
    } else {
        return queryString.parse(window.location.search);
    }
}

export function formErrors(error) {
    if (isArray(error)) {
        return <ul>forEach(error, function(value) {<li>value</li>});</ul>;
    } else {
        return <strong>{error}</strong>;
    }
}

export function pushUrl(navigate, baseUrl, vehicleId, startDate, endDate, others) {
    let url = baseUrl + '#';
    if (startDate) {
        url += 'startDate=' + startDate.valueOf();
        if (endDate) {
            url += '&endDate=' + endDate.valueOf();
        }
    }
    if (vehicleId) {
        url += startDate ? '&' : '';
        url += 'vehicleId=' + vehicleId;
    }

    if (others) {
        url += others;
    }

    if (isArray(navigate)) {
        navigate.push(url);
        return;
    }

    if (navigate && typeof navigate === 'function') {
        navigate(url);
    } else {
        window.location.href = url;
    }
}

export function handleError(error) {
    let errorMessage = 'Something went wrong, please try again later!';

    if (error) {
        const { error_description, message } = get(error, 'data', typeof error === 'object' ? error : {});
        if (error_description || message) {
            if (error_description) {
                errorMessage = error_description;
            } else {
                errorMessage = message;
            }
        }
    }

    return errorMessage;
}

export function getAddress(addressObj) {
    if (!addressObj) {
        return '';
    }
    let address = '';
    if (addressObj) {
        if (addressObj.addressLine1) {
            address = addressObj.addressLine1;
        }

        if (addressObj.addressLine2) {
            address += ' ' + addressObj.addressLine2;
        }
        if (addressObj.city) {
            address += ' ' + addressObj.city;
        }

        if (addressObj.postalCode) {
            address += ' ' + addressObj.postalCode;
        }
        if (addressObj.state) {
            address += ' ' + addressObj.state;
        }
        if (addressObj.country) {
            address += ' ' + addressObj.country;
        }
    }

    return trim(address);
}

export function parseAddress(address) {
    if (!address || !address.replace) {
        return '';
    }
    const showPinCode = showPinCodeInAddressForAccount();
    if (showPinCode) {
        return address
            .replace(/,[ ]*india/gi, '')
            .replace(/,[ ]*indonesia,[ ]*\d{5}/gi, '')
            .replace(/,[ ]*indonesia/gi, '')
            .replace(/, /g, ',')
            .replace(/,/g, ', ');
    }
    return address
        .replace(/,[ ]*india,[ ]*\d{6}/gi, '')
        .replace(/,[ ]*\d{6}/g, '')
        .replace(/ \d{6}/g, '')
        .replace(/,[ ]*india/gi, '')
        .replace(/,[ ]*indonesia,[ ]*\d{5}/gi, '')
        .replace(/,[ ]*indonesia/gi, '')
        .replace(/, /g, ',')
        .replace(/,/g, ', ');
}

export function parseCityFromAddress(address) {
    const parsedAddress = parseAddress(address);
    if (!parsedAddress || !parsedAddress.split) {
        return '';
    }
    const addressSplits = parsedAddress.split(', ');
    const length = addressSplits.length;
    let cityDistrict = '';
    if (length >= 3) {
        cityDistrict += addressSplits[length - 3];
    }
    if (length >= 2) {
        cityDistrict += (cityDistrict ? ', ' : '') + addressSplits[length - 2];
    }
    if (length >= 1 && length < 3) {
        cityDistrict += (cityDistrict ? ', ' : '') + addressSplits[length - 1];
    }
    return cityDistrict;
}

export function getCityFromAddress(address) {
    const parsedAddress = parseAddress(address);
    if (!parsedAddress || !parsedAddress.split) {
        return '';
    }
    const addressSplits = parsedAddress.split(', ');
    const length = addressSplits.length;
    let city = '';
    if (length >= 3) {
        city += addressSplits[length - 2];
    } else if (length >= 1) {
        city += addressSplits[length - 1];
    }
    return city;
}

export function combineNameAddress(name, address) {
    if (!name && !address) {
        return '';
    }

    if (!name || (address && address.startsWith(name))) {
        return parseAddress(address);
    }

    if (address) {
        return `${name}, ${parseAddress(address)}`;
    } else if (name) {
        return `${name}`;
    }

    return '';
}

export function setSelectedView(view, selectedView) {
    if (view && view.classList.contains('selected')) {
        view.classList.remove('selected');
        return null;
    }
    if (view) {
        view.classList.add('selected');
    }
    if (selectedView) {
        selectedView.classList.remove('selected');
    }
    return view;
}

export function emitActionValue(emitter, action, value, config) {
    if (emitter) {
        emitter.emit(action, value, config);
    }
}

export function isPageVisible() {
    return true;
    // Adapted slightly from Sam Dutton
    // Set name of hidden property and visibility change event
    // since some browsers only offer vendors-prefixed support
    let hidden, state, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
        state = 'visibilityState';
    } else if (typeof document.mozHidden !== 'undefined') {
        hidden = 'mozHidden';
        visibilityChange = 'mozvisibilitychange';
        state = 'mozVisibilityState';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
        state = 'msVisibilityState';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
        state = 'webkitVisibilityState';
    }
    return document[state] === 'visible';
}

export function getUrlSuffix(includeVehicle = true, includeGroup = true, includeDates = true) {
    const parsed = getQueryStringParams();
    let suffix = '';
    if (includeVehicle && parsed.vehicleId) {
        suffix += 'vehicleId=' + parsed.vehicleId;
    }
    if (includeGroup && parsed.groupId) {
        suffix += (suffix ? '&' : '') + 'groupId=' + parsed.groupId;
    }
    if (includeDates && parsed.startDate) {
        suffix += (suffix ? '&' : '') + 'startDate=' + parsed.startDate;
    }
    if (includeDates && parsed.endDate) {
        suffix += (suffix ? '&' : '') + 'endDate=' + parsed.endDate;
    }
    return suffix;
}

export function copyText(element) {
    const copyShareInputText = element;
    copyShareInputText.select();

    try {
        const successful = document.execCommand('copy');
        if (successful) {
            return true;
        } else {
            return copyText2(element);
        }
    } catch (err) {
        return copyText2(element);
    }
}

function copyText2(element) {
    const range = document.createRange();
    range.selectNode(element);
    window.getSelection().addRange(range);

    let success = false;
    try {
        // Now that we've selected the anchor text, execute the copy command
        const successful = document.execCommand('copy');
        success = !!successful;
    } catch (err) {}

    // Remove the selections - NOTE: Should use
    // removeRange(range) when it is supported
    window.getSelection().removeAllRanges();

    return success;
}

export function timeFrequencyText(timeFrequency) {
    switch (timeFrequency) {
        case FREQUENCY.YEARS:
            return 'year(s)';
            break;
        case FREQUENCY.MONTHS:
            return 'month(s)';
            break;
        case FREQUENCY.WEEKS:
            return 'week(s)';
            break;
        case FREQUENCY.DAYS:
            return 'day(s)';
            break;
        default:
            return 'hour(s)';
    }
}

export function isEnvLive() {
    return process.env.ENV === 'production';
}

export function isEnvWall() {
    return process.env.ENV == 'wall';
}

export function isEnvApp() {
    return process.env.ENV == 'app';
}

export function isViewportLGOrAbove() {
    return window.screen.width >= '1199';
}

export function isViewportMDOrAbove() {
    return window.screen.width >= '992';
}

export function isViewportSMOrAbove() {
    return window.screen.width >= '768';
}

export const isMobileView = () => {
    return !isViewportSMOrAbove();
};

export function getMergedListWithVehicles(list, vehicleListMiniAsMap) {
    return map(list, (o) => {
        const actualVehicle = vehicleListMiniAsMap[o.vehicleId];
        let driverNumber = '';
        // If driver phone number is present directly in vehicleListMiniAsMap then pick the driver phone number directly from there
        // else take the driver Phone Number from activeAssignment object
        if (get(actualVehicle, 'driverPhoneNumber')) {
            driverNumber = get(actualVehicle, 'driverPhoneNumber');
        } else {
            driverNumber = get(actualVehicle, 'activeAssignment.user.phoneNumber', o.driverNumber);
        }
        return {
            ...o,
            vehicleName: get(actualVehicle, 'name', o.vehicleName),
            vehicleNumber: get(actualVehicle, 'licensePlate', o.vehicleNumber),
            driverName: get(actualVehicle, 'activeAssignment.user.name', o.driverName),
            driverNumber,
            groupId: get(o, 'groupId'),
            tags: get(actualVehicle, 'tagIdsSet', []),
        };
    });
}

export function getTamperedTrips(tripList) {
    if (hideTamperedTripsForAccount()) {
        return [];
    }
    return filter(tripList, (trip) => isTamperedTrip(trip));
}

export function getTamperedTripsCount(tripList) {
    if (hideTamperedTripsForAccount()) {
        return 0;
    }
    let count = 0;
    map(tripList, (trip) => {
        count += isTamperedTrip(trip) ? 1 : 0;
    });
    return count;
}

export function isTamperedTrip(trip) {
    if (hideTamperedTripsForAccount()) {
        return null;
    }
    return trip && trip.score == -1;
}

export function strCmp(text, searchFilter) {
    if (!text || !searchFilter) {
        return false;
    }
    try {
        const encodedText = encodeURIComponent(toLower(trim(text)));
        const encodedFilter = encodeURI(toLower(trim(searchFilter)));
        return encodedText.match(encodedFilter) || encodedText === encodedFilter;
    } catch (e) {
        return false;
    }
}

export function getGraphFrequency(days) {
    if (days > 200) {
        return FREQUENCY.MONTHS;
    } else if (days > 30) {
        return FREQUENCY.WEEKS;
    } else {
        return FREQUENCY.DAYS;
    }
}

export function getFrequencyTitle(frequency) {
    switch (frequency) {
        case FREQUENCY.YEARS:
            return 'Yearly';
        case FREQUENCY.MONTHS:
            return 'Monthly';
        case FREQUENCY.WEEKS:
            return 'Weekly';
        case FREQUENCY.HOURS:
            return 'Hourly';
        case FREQUENCY.DAYS:
        default:
            return 'Daily';
    }
}

export function parseImageNameFromUrl(url) {
    if (url && url.indexOf('/') != -1) {
        return url.substring(url.lastIndexOf('/') + 1);
    }
}

export function parseJobNotes(notes, pattern) {
    let returnValue = '-';
    const allStrings = split(notes, ',');
    if (allStrings.length > 0) {
        map(allStrings, (text) => {
            if (text.match(`${pattern}`)) {
                returnValue = replace(text, pattern, '');
                return returnValue;
            }
        });
    }
    return returnValue;
}

export function startUsingApiProxyUrl() {
    return;
    storage.setItem(USE_API_PROXY_KEY, 'true');
    ROOT_API_URL = ROOT_API_URL_API_PROXY;
    ROOT_API = ROOT_API_API_PROXY;
}

export function isValidEmail(email) {
    return trim(email) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(trim(email));
}

export function isValidAadhar(aadharNumber) {
    return trim(aadharNumber) && AADHAR_CARD_VALIDATION_REGEX.test(trim(aadharNumber));
}

export function isValidMobileNo(mobileNo, forceLooseCheck) {
    if (looseCheckPhoneLength() || forceLooseCheck) {
        return mobileNo && trim(mobileNo) && /^(\+\d{1,3}[- ]?)?\d{3,13}$/i.test(trim(mobileNo.toString()));
    }
    return mobileNo && trim(mobileNo) && /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(trim(mobileNo.toString()));
}

export function isTextOnly(text) {
    return text && trim(text) && /[^a-zA-Z ]/.test(trim(text));
}

export function isValidPan(pan) {
    return trim(pan) && /[A-Z]{5}\d{4}[A-Z]{1}/i.test(trim(pan));
}

export function isValidContainerNo(num) {
    return trim(num) && /^[A-Z]{4}\d{7}$/i.test(trim(num));
}

export function isValidVehicleNo(num) {
    return trim(num) && /[a-zA-Z]{2}\d{2}[a-zA-Z]{2}\d{4}$/.test(trim(num));
}

export function isValidGst(gst) {
    const isValid =
        trim(gst) && `${gst}`.length === 15 && /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1}$/i.test(trim(gst));
    const isNill = trim(gst) && /nill/i.test(trim(gst));
    return isValid || isNill;
}

export function getFilteredAddressBookByTag(addressBook, tagFilter, tags) {
    const filteredAddressBook = filter(addressBook, (addr) => {
        if (addr.tagIdsList) {
            let found = false;
            const allParentsList = getParentsFromAttachedTagIds(addr.tagIdsList, tags, tagFilter);
            if (tagFilter.selectedFilter === 'OR' || tagFilter.selectedFilter === 'HIERARCHY') {
                found = !!find(tagFilter.selectedTags, (tag) => allParentsList.includes(tag.value));
            } else if (tagFilter.selectedFilter === 'AND') {
                const tagLength = tagFilter.selectedTags.length;
                let totalFound = 0;
                map(tagFilter.selectedTags, (tag) => {
                    if (allParentsList.includes(tag.value)) {
                        totalFound++;
                    }
                    if (tagLength === totalFound) {
                        found = true;
                    }
                });
            }
            return found;
        } else {
            return false;
        }
    });
    return filteredAddressBook;
}

export function filterRealtimeVehicles(vehicles, config) {
    let {
        vehicleSearchFilter,
        groupList,
        currentStatusFilter,
        isSwitchUserAllowed,
        vehicleCategoryFilter,
        vehicleJobStateFilter,
        vehicleLocationFilter,
        sortVehicleFilter,
        vehicleDoorStatusFilter,
        vehicleReadingFilter,
        sortVehicleDirection,
        vehicleJobTimingFilter,
        vehicleDirectionFilter,
        vehicleCommentedFilter,
        commentVehicleIdObject,
        tagFilter,
        tags,
        addressBook,
        loggedInUser,
        addressBookAsMap,
        vehiclesOnJobAsMap,
        alertIssuesCountPerVehicleAsMap,
        deviceCategoryFilter,
        filterByUserTags,
        vehicleTypeFilter,
        plantDirectionFilter,
        range,
        km_range,
        pos,
        addressBookAsQT,
        isQuadTreeSearchOpen,
        neareastAddressData,
        isBulkNearestAddressAPIEnabledFlag,
        filterBySystemTags,
        fuelSensorStatusFilter,
        nextAddressBookCategoryFilter,
        nextAddressBookIdFilter,
        jobStatusTimeFilter,
        excludeVehicleStatusFilter,
        currentAddressBookCategoryFilter,
        scheduleToAddressBookCategoryFilter,
        jobDelayFilter,
        cameraFilter,
        vehicleWorkorderStatusFilter,
        vehicleDriverAssignmentFilter,
        workOrderObj,
        vehicleSizeFilter,
        currentAddressBookFilter,
        tempSensorStatusFilter,
        routeIdFilter,
        evBatteryLevelFilter,
        parkingStatusDurationFilter,
        showVehicleWithCriticalDtcs,
        visibleVehicleDtcsMap,
    } = config;
    let filteredRealtimeVehicleList = vehicles;

    let groupsMap = {};
    forEach(groupList, (g) => {
        groupsMap[g.id] = g;
    });
    let vehiclesOnJobAsObject = vehiclesOnJobAsMap || {};
    let vehiclesAlertsAsObject = alertIssuesCountPerVehicleAsMap || {};

    if (tagFilter) {
        addressBook = getFilteredAddressBookByTag(addressBook, tagFilter, tags);
    }

    const vehicleToNearestAddressMap = get(neareastAddressData, 'vehicleToNearestAddressMap');
    const isRunningVehicleDetailedSummaryAvailable = runningVehicleDetailedSummaryAvailable(loggedInUser).value;
    const idleVehicleDetailedTimeForAccount = getIdleVehicleDetailedTimeForAccount();
    const idleVehicleGTXAndLTYHoursVal = idleVehicleGTXAndLTYHours();
    const parkedVehicleGT0AndLT24HoursVal = parkedVehicleGT0AndLT24Hours();
    const parkedVehicleGT24AndLT48HoursVal = parkedVehicleGT24AndLT48Hours();
    const parkedVehicleGT48HoursVal = parkedVehicleGT48Hours();
    const parkedVehicleGTXAndLTXHoursVal = parkedVehicleGTXAndLTXHours();
    const parkedVehicleGT2AndLT4HourVal = parkedVehicleGT2AndLT4Hour();
    const parkedVehicleGT4AndLT5HourVal = parkedVehicleGT4AndLT5Hour();
    const parkedVehicleLT5HourVal = parkedVehicleLT5Hour();
    const showSearchVehicleByProjectInfoVal = showSearchVehicleByProjectInfo();
    const isExpressAccount = isExpressRoadwaysAccount(loggedInUser);

    if (filteredRealtimeVehicleList.length > 0) {
        filteredRealtimeVehicleList = filter(
            filteredRealtimeVehicleList,
            ({
                vehicleMake,
                vehicleModel,
                currentStatus,
                deviceId,
                groupId,
                vehicleId,
                vehicleName,
                vehicleNumber,
                driverName,
                driverId,
                address,
                accountId,
                otherAttributes = {},
                tagIds,
                latitude,
                longitude,
                lastStatusTime,
                vehicleTypeValue,
                durationEngineOn,
            }) => {
                const {
                    lockName,
                    lockId,
                    tripOdometer,
                    deviceType,
                    doorStatus,
                    fuel,
                    fuelReading,
                    fuelReading2,
                    assets,
                    vSize,
                } = otherAttributes;

                let toFilter = true;

                if (vehicleSizeFilter) {
                    toFilter = toFilter && vSize === vehicleSizeFilter;
                }

                if (
                    vehicleDriverAssignmentFilter &&
                    vehicleDriverAssignmentFilter !== VEHICLE_DRIVER_FILTER_OPTION.BOTH
                ) {
                    if (vehicleDriverAssignmentFilter === VEHICLE_DRIVER_FILTER_OPTION.WITH_DRIVER) {
                        toFilter = toFilter && !!driverId;
                    }

                    if (vehicleDriverAssignmentFilter === VEHICLE_DRIVER_FILTER_OPTION.WITHOUT_DRIVER) {
                        toFilter = toFilter && !driverId;
                    }
                }

                if (
                    vehicleWorkorderStatusFilter &&
                    vehicleWorkorderStatusFilter !== VEHICLE_WORKORDER_FILTER_OPTION.BOTH
                ) {
                    const vehicleWorkOrder = get(workOrderObj, vehicleId);
                    if (vehicleWorkorderStatusFilter === VEHICLE_WORKORDER_FILTER_OPTION.ON_WORKORDER) {
                        toFilter = toFilter && !!vehicleWorkOrder;
                    }

                    if (vehicleWorkorderStatusFilter === VEHICLE_WORKORDER_FILTER_OPTION.NOT_ON_WORKORDER) {
                        toFilter = toFilter && !vehicleWorkOrder;
                    }
                }

                if (vehicleCategoryFilter) {
                    toFilter = toFilter && getVehicleCategory(vehicleMake, vehicleModel) === vehicleCategoryFilter;
                }
                if (get(cameraFilter, 'cameraFilter')) {
                    {
                        if (get(cameraFilter, 'cameraFilter') === 'PORTABLE_DEVICE') {
                            toFilter =
                                toFilter &&
                                includes(
                                    map(DEVICE_MODEL_OPTIONS.PORTABLE_DEVICE, 'value'),
                                    getDeviceTypeFromDeviceId(null, deviceType)?.name
                                );
                        } else {
                            const cameraData = getParsedLockData(assets);
                            const cameDateValue = cameraData?.length ? cameraData[0] : cameraData;
                            toFilter = toFilter && get(cameDateValue, 'type') == get(cameraFilter, 'cameraFilter');
                        }
                    }
                }

                if (currentStatusFilter) {
                    toFilter = toFilter && currentStatus === currentStatusFilter;
                    let lastStatusTimeAsMinutes = moment
                        .duration(getMomentTime().diff(getMomentTime(lastStatusTime)))
                        .asMinutes();

                    let idleTimeInMs = getIdleTimeInMs({
                        durationEngineOn,
                        lastStatusTime,
                    });
                    const idleTimeInMinutes = idleTimeInMs ? idleTimeInMs / MINUTES_1 : 0;

                    const tripOdo = parseFloat(tripOdometer);
                    if (
                        currentStatus === VEHICLE_STATUS.RUNNING &&
                        currentStatusFilter === VEHICLE_STATUS.RUNNINGLTXKM &&
                        tripOdo <= isRunningVehicleDetailedSummaryAvailable
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.IDLE &&
                        currentStatusFilter === VEHICLE_STATUS.IDLEGTXMINUTES &&
                        idleTimeInMinutes > idleVehicleDetailedTimeForAccount
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.IDLE &&
                        currentStatusFilter === VEHICLE_STATUS.IDLELTXMINUTES &&
                        idleTimeInMinutes < idleVehicleDetailedTimeForAccount
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.IDLE &&
                        currentStatusFilter === VEHICLE_STATUS.IDLEGTXANDLTYHOURS &&
                        idleTimeInMinutes > idleVehicleGTXAndLTYHoursVal.greaterThan &&
                        idleTimeInMinutes <= idleVehicleGTXAndLTYHoursVal.lessThan
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDGTXMINUTES &&
                        lastStatusTimeAsMinutes > getParkedVehicleDetailedTimeForAccount(loggedInUser)
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDLTXMINUTES &&
                        lastStatusTimeAsMinutes < getParkedVehicleDetailedTimeForAccount(loggedInUser)
                    ) {
                        toFilter = true;
                    }
                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDGTZEROANDLTTWENTYFOURSHOUR &&
                        lastStatusTimeAsMinutes > parkedVehicleGT0AndLT24HoursVal.greaterThan &&
                        lastStatusTimeAsMinutes <= parkedVehicleGT0AndLT24HoursVal.lessThan
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDGTTWENTYFOURANDLTFOURTYEIGHT &&
                        lastStatusTimeAsMinutes > parkedVehicleGT24AndLT48HoursVal.greaterThan &&
                        lastStatusTimeAsMinutes <= parkedVehicleGT24AndLT48HoursVal.lessThan
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDVEHICLEGREATERTHAN48H &&
                        lastStatusTimeAsMinutes > parkedVehicleGT48HoursVal.greaterThan
                    ) {
                        toFilter = true;
                    }

                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDGTONEANDLTTWOHOUR &&
                        lastStatusTimeAsMinutes > parkedVehicleGTXAndLTXHoursVal.greaterThan &&
                        lastStatusTimeAsMinutes <= parkedVehicleGTXAndLTXHoursVal.lessThan
                    ) {
                        toFilter = true;
                    }
                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDGTTWOANDLTTFOURHOUR &&
                        lastStatusTimeAsMinutes > parkedVehicleGT2AndLT4HourVal.greaterThan &&
                        lastStatusTimeAsMinutes <= parkedVehicleGT2AndLT4HourVal.lessThan
                    ) {
                        toFilter = true;
                    }
                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDGTFOURANDLTFIVEHOUR &&
                        lastStatusTimeAsMinutes > parkedVehicleGT4AndLT5HourVal.greaterThan &&
                        lastStatusTimeAsMinutes <= parkedVehicleGT4AndLT5HourVal.lessThan
                    ) {
                        toFilter = true;
                    }
                    if (
                        currentStatus === VEHICLE_STATUS.PARKED &&
                        currentStatusFilter === VEHICLE_STATUS.PARKEDGTFIVEHOUR &&
                        lastStatusTimeAsMinutes >= parkedVehicleLT5HourVal.greaterThan
                    ) {
                        toFilter = true;
                    }
                }
                const vehicleAlerts = get(vehiclesAlertsAsObject[vehicleId], 'openIssuesCount');
                if (
                    currentStatusFilter &&
                    get(vehicleAlerts, 'length') &&
                    some(vehicleAlerts, ['subType', currentStatusFilter])
                ) {
                    toFilter = true;
                }

                if (!isEmpty(excludeVehicleStatusFilter)) {
                    toFilter = toFilter && !includes(excludeVehicleStatusFilter, currentStatus);
                }

                const vehicleJob = vehiclesOnJobAsObject[vehicleId];

                if (vehicleSearchFilter) {
                    let toVehicleSearchFilter = false;

                    toVehicleSearchFilter =
                        toVehicleSearchFilter ||
                        strCmp(vehicleName, vehicleSearchFilter) ||
                        strCmp(vehicleNumber, vehicleSearchFilter) ||
                        strCmp(lockName, vehicleSearchFilter) ||
                        strCmp(lockId, vehicleSearchFilter);
                    // strCmp(driverName, vehicleSearchFilter) ||
                    // || strCmp(parseAddress(geoAddress), vehicleSearchFilter)

                    if (groupList && groupList.length > 1) {
                        const groupName = groupsMap[groupId]?.name || '';
                        if (groupName && strCmp(groupName, vehicleSearchFilter)) {
                            toVehicleSearchFilter = true;
                        }
                    }
                    if (showSearchVehicleByProjectInfoVal) {
                        const customerCode = get(vehicleJob, 'otherAttributes.customerCode', '');
                        const customerName = get(vehicleJob, 'otherAttributes.customerName', '');
                        toVehicleSearchFilter =
                            toVehicleSearchFilter ||
                            strCmp(customerCode, vehicleSearchFilter) ||
                            strCmp(customerName, vehicleSearchFilter);
                    }

                    toFilter = toFilter && toVehicleSearchFilter;
                }

                if (vehicleJobStateFilter) {
                    if (vehicleJobStateFilter === VEHICLE_JOB_STATE.NOT_AVAILABLE && isExpressAccount) {
                        toFilter =
                            toFilter &&
                            !isVehicleOnJob(vehicleJob) &&
                            (includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.MAINTENANCE) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.ACCIDENT) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.WITHOUT_DRIVER) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.EXPRESS_HUB) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.ON_ROUTE_MAINTENANCE) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.ATN_HUB) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.MEDCHAL_HUB) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.BILASPUR_HUB) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.TALOJA_HUB) ||
                                includes(tagIds, EXPRESS_ROADWAYS_SYSTEM_TAGS.Dhulagarh_HUB));
                    } else if (!vehicleJob && !range) {
                        toFilter =
                            toFilter &&
                            VEHICLE_JOB_STATE.NOT_ON_JOB === vehicleJobStateFilter &&
                            currentStatus !== 'REMOVED';
                    } else if (vehicleJobStateFilter === VEHICLE_JOB_STATE.ON_JOB) {
                        toFilter = toFilter && get(vehicleJob, 'status') !== VEHICLE_JOB_STATE.NOT_ON_JOB;
                    } else if (range && !vehicleSearchFilter && get(vehicleJob, 'status') === vehicleJobStateFilter) {
                        const numberOfDaysNotOnJob = moment
                            .duration(getMomentTime().diff(getMomentTime(vehicleJob.lastStatusTime)))
                            .asDays();
                        if (range == '0-1') {
                            toFilter = numberOfDaysNotOnJob > 0 && numberOfDaysNotOnJob <= 1;
                        }
                        if (range == '1-2') {
                            toFilter = numberOfDaysNotOnJob > 1 && numberOfDaysNotOnJob <= 2;
                        } else if (range == '0-2') {
                            toFilter = numberOfDaysNotOnJob > 0 && numberOfDaysNotOnJob <= 2;
                        } else if (range == '2-5') {
                            toFilter = numberOfDaysNotOnJob > 2 && numberOfDaysNotOnJob <= 5;
                        } else if (range == '5-15') {
                            toFilter = numberOfDaysNotOnJob > 5 && numberOfDaysNotOnJob <= 15;
                        } else if (range == '1-15') {
                            toFilter = numberOfDaysNotOnJob > 1 && numberOfDaysNotOnJob <= 15;
                        } else if (range == '0-15') {
                            toFilter = numberOfDaysNotOnJob > 0 && numberOfDaysNotOnJob <= 15;
                        } else if (range == '15-30') {
                            toFilter = numberOfDaysNotOnJob > 15 && numberOfDaysNotOnJob <= 30;
                        } else if (range == '30-60') {
                            toFilter = numberOfDaysNotOnJob > 30 && numberOfDaysNotOnJob <= 60;
                        } else if (range == '>60') {
                            toFilter = numberOfDaysNotOnJob > 60;
                        }
                    } else {
                        toFilter = toFilter && get(vehicleJob, 'status') === vehicleJobStateFilter;
                    }
                    const lastJobStatusTime = get(vehicleJob, 'lastStatusTime');
                    toFilter =
                        toFilter &&
                        shouldIncludeInFilteredListOnJobStatusTimeFilter({
                            vehicleJobStateFilter,
                            lastJobStatusTime,
                            jobStatusTimeFilter,
                            toFilter,
                        });
                }

                if (!isEmpty(jobDelayFilter)) {
                    if (vehicleJob && isVehicleOnJob(vehicleJob)) {
                        console.log('jobFilter', jobDelayFilter);
                        const predictedDelay = get(vehicleJob, 'predictedDelay', 0);
                        let minDelay = '';
                        let maxDelay = '';
                        let toFilterLocal = false;
                        forEach(jobDelayFilter, (item) => {
                            if (toFilterLocal) {
                                return;
                            }

                            if (minDelay === 0) {
                                toFilter = toFilter && predictedDelay > minDelay;
                            } else {
                                toFilter = toFilter && predictedDelay >= minDelay;
                            }

                            toFilterLocal = predictedDelay >= minDelay;
                            if (maxDelay) {
                                toFilterLocal = toFilterLocal && predictedDelay <= maxDelay;
                            }
                        });

                        toFilter = toFilter && toFilterLocal;
                    } else {
                        toFilter = false;
                    }
                }

                if (vehicleLocationFilter && km_range && pos) {
                    const distance =
                        pythagorasEquirectangular(pos.lat, pos.lng, latitude, longitude) *
                        P2P_DISTANCE_NORMALIZATION_FACTOR;
                    const roundedDistance = round(distance);
                    if (km_range == '0-1') {
                        toFilter = distance >= 0 && distance <= 1;
                    } else if (km_range == '0-50') {
                        toFilter = roundedDistance <= 50 && !isVehicleOnJob(vehicleJob);
                        if (toFilter) {
                            console.log(
                                roundedDistance,
                                vehicleJob,
                                roundedDistance <= 50,
                                !isVehicleOnJob(vehicleJob)
                            );
                        }
                    } else if (km_range == '50-100') {
                        toFilter = roundedDistance > 50 && roundedDistance <= 100 && !isVehicleOnJob(vehicleJob);
                    } else if (km_range == '100-150') {
                        toFilter = roundedDistance > 100 && roundedDistance <= 150 && !isVehicleOnJob(vehicleJob);
                    } else if (km_range == '>150') {
                        toFilter = roundedDistance > 150 && !isVehicleOnJob(vehicleJob);
                    }
                }

                if (vehicleReadingFilter) {
                    toFilter = toFilter && fuelReading && !fuel;
                }

                if (vehicleDoorStatusFilter === 'OPEN') {
                    toFilter = toFilter && get(otherAttributes, 'doorStatus') == 'false';
                }

                if (vehicleDoorStatusFilter === 'CLOSED') {
                    toFilter = toFilter && get(otherAttributes, 'doorStatus') == 'true';
                }

                if (vehicleJobTimingFilter) {
                    if (get(vehicleJob, 'status') !== VEHICLE_JOB_STATE.NOT_ON_JOB) {
                        toFilter = toFilter && get(vehicleJob, 'timeStatus') === vehicleJobTimingFilter;
                    } else {
                        toFilter = false;
                    }
                }
                if (vehicleDirectionFilter && vehicleJob) {
                    if (get(vehicleJob, 'status') !== VEHICLE_JOB_STATE.NOT_ON_JOB) {
                        toFilter = toFilter && indexOf(get(vehicleJob, 'tags', []), vehicleDirectionFilter) >= 0;
                    } else {
                        toFilter = false;
                    }
                }

                if (nextAddressBookCategoryFilter) {
                    const nextAddressBookCategory = get(vehicleJob, 'nextAddressBookCategory');
                    const currentAddressBookCategory = get(vehicleJob, 'currentAddressBookCategory');
                    const lastCrossedAddressBookId = get(vehicleJob, 'lastCrossedAddressBookId');
                    toFilter =
                        toFilter &&
                        !currentAddressBookCategory &&
                        lastCrossedAddressBookId &&
                        ((!nextAddressBookCategory && nextAddressBookCategoryFilter === NO_NEXT_ROUTE_POINT_CATEGORY) ||
                            nextAddressBookCategory === nextAddressBookCategoryFilter);
                }
                if (currentAddressBookCategoryFilter) {
                    const currentAddressBookCategory = get(vehicleJob, 'currentAddressBookCategory');
                    const lastCrossedAddressBookId = get(vehicleJob, 'lastCrossedAddressBookId');
                    toFilter =
                        toFilter &&
                        currentAddressBookCategory &&
                        lastCrossedAddressBookId &&
                        currentAddressBookCategory === currentAddressBookCategoryFilter;
                }
                if (scheduleToAddressBookCategoryFilter) {
                    const nextAddressBookCategory = get(vehicleJob, 'nextAddressBookCategory');
                    const currentAddressBookCategory = get(vehicleJob, 'currentAddressBookCategory');
                    const lastCrossedAddressBookId = get(vehicleJob, 'lastCrossedAddressBookId');
                    toFilter =
                        toFilter &&
                        !currentAddressBookCategory &&
                        !lastCrossedAddressBookId &&
                        ((!nextAddressBookCategory &&
                            scheduleToAddressBookCategoryFilter === NO_SCHEDULE_TO_ROUTE_POINT_CATEGORY) ||
                            nextAddressBookCategory === scheduleToAddressBookCategoryFilter);
                }
                if (nextAddressBookIdFilter) {
                    const nextAddressBookId = get(vehicleJob, 'nextAddressBookId');
                    toFilter = toFilter && nextAddressBookId === toSafeInteger(nextAddressBookIdFilter);
                }

                if (vehicleCommentedFilter) {
                    toFilter = toFilter && !!commentVehicleIdObject[vehicleId];
                }

                if (deviceCategoryFilter && deviceCategoryFilter.length > 0) {
                    toFilter =
                        toFilter && includes(deviceCategoryFilter, getDeviceCategoryByDeviceId(deviceId, deviceType));
                }
                if (vehicleTypeFilter) {
                    toFilter = toFilter && VEHICLE_TYPE_VALUE[vehicleTypeFilter] === vehicleTypeValue;
                }
                if (plantDirectionFilter && vehicleJob) {
                    switch (PLANT_DIRECTION_VALUE[plantDirectionFilter]) {
                        case 'TO_PLANT':
                            if (get(vehicleJob, 'status') !== VEHICLE_JOB_STATE.NOT_ON_JOB) {
                                toFilter = false;
                            }
                            break;
                        case 'TO_CUSTOMER':
                            if (get(vehicleJob, 'status') === VEHICLE_JOB_STATE.NOT_ON_JOB) {
                                toFilter = false;
                            }
                            break;
                    }
                }

                if (get(fuelSensorStatusFilter, 'fuelSensorStatus')) {
                    toFilter =
                        toFilter &&
                        get(otherAttributes, 'fuelSensorStatus') == get(fuelSensorStatusFilter, 'fuelSensorStatus');
                }

                if (get(tempSensorStatusFilter, 'tempSensorStatus')) {
                    toFilter =
                        toFilter &&
                        get(otherAttributes, 'isTempValid') &&
                        get(otherAttributes, 'tempSensorConnectionStatus') ===
                            get(tempSensorStatusFilter, 'tempSensorStatus');
                }

                if (currentAddressBookFilter) {
                    const nearestAddress =
                        addressBookAsMap && addressBookAsMap[currentAddressBookFilter]
                            ? getNearestAddressFromAddressbook(
                                  latitude,
                                  longitude,
                                  [addressBookAsMap[currentAddressBookFilter]],
                                  true,
                                  loggedInUser
                              )
                            : null;
                    toFilter = toFilter && isInsideNearestAddress(nearestAddress);
                }

                if (routeIdFilter) {
                    toFilter = toFilter && get(vehicleJob, 'routeId') === toSafeInteger(routeIdFilter);
                }

                if (tagFilter || filterBySystemTags) {
                    let nearestAddress, nearestAddressFromQT;
                    if (isBulkNearestAddressAPIEnabledFlag) {
                        nearestAddress = getNearestAddress(
                            vehicleId,
                            vehicleToNearestAddressMap,
                            null,
                            null,
                            accountId
                        );
                    } else if (
                        !isShreeCementAll() &&
                        addressBookAsQT &&
                        !isEmpty(addressBookAsQT) &&
                        !isEmpty(addressBookAsMap) &&
                        isQuadTreeSearchOpen
                    ) {
                        nearestAddressFromQT = addressBookAsQT.query(
                            new Circle(
                                normalizeLatitude(latitude),
                                normalizeLongitude(longitude),
                                getNearestAddressRangeForAccount(loggedInUser)
                            )
                        );
                        if (isEmpty(nearestAddressFromQT)) {
                            nearestAddress =
                                addressBook && addressBook.length > 0
                                    ? getNearestAddressFromAddressbook(
                                          latitude,
                                          longitude,
                                          addressBook,
                                          true,
                                          loggedInUser,
                                          addressBookAsMap
                                      )
                                    : null;
                        } else {
                            nearestAddress =
                                addressBook &&
                                addressBook.length > 0 &&
                                getNearestAddressFromWithinQTNode(
                                    latitude,
                                    longitude,
                                    nearestAddressFromQT,
                                    addressBook,
                                    addressBookAsMap,
                                    true,
                                    loggedInUser
                                );
                        }
                    } else {
                        if (!isShreeCementAll()) {
                            nearestAddress =
                                addressBook && addressBook.length > 0
                                    ? getNearestAddressFromAddressbook(
                                          latitude,
                                          longitude,
                                          addressBook,
                                          true,
                                          loggedInUser,
                                          addressBookAsMap
                                      )
                                    : null;
                        }
                    }

                    let tagsAttachedToSavedAddress = [];
                    let tagsAttachedToVehicle = tagIds ? tagIds : [];
                    if (isInsideNearestAddress(nearestAddress)) {
                        const addressObj = addressBookAsMap[nearestAddress.id];
                        tagsAttachedToSavedAddress = addressObj && addressObj.tagIdsList ? addressObj.tagIdsList : [];
                    }
                    let tagsAttachedToSavedEndAddress = [];
                    let tagsAttachedToSavedStartAddress = [];
                    if (vehicleJob && vehicleJob.endAddressBookId) {
                        const endAddressObj = addressBookAsMap[vehicleJob.endAddressBookId];
                        tagsAttachedToSavedEndAddress =
                            endAddressObj && endAddressObj.tagIdsList ? endAddressObj.tagIdsList : [];
                    }
                    if (vehicleJob && vehicleJob.startAddressBookId) {
                        const startAddressObj = addressBookAsMap[vehicleJob.startAddressBookId];
                        tagsAttachedToSavedStartAddress =
                            startAddressObj && startAddressObj.tagIdsList ? startAddressObj.tagIdsList : [];
                    }

                    const allTagsAttached = concatTagsForFiltering(
                        loggedInUser,
                        tagsAttachedToVehicle,
                        tagsAttachedToSavedAddress,
                        tagsAttachedToSavedEndAddress,
                        tagsAttachedToSavedStartAddress
                    );

                    // show only vehicles for user attached tags
                    // if (filterByUserTags && toFilter) {
                    //     toFilter =
                    //         toFilter &&
                    //         isTagsFilterConditionMatched(
                    //             allTagsAttached,
                    //             tags,
                    //             filterByUserTags,
                    //             toFilter,
                    //             vehicleJob,
                    //             loggedInUser
                    //         );
                    // }
                    if (filterBySystemTags && toFilter) {
                        toFilter =
                            toFilter &&
                            isTagsFilterConditionMatched(
                                allTagsAttached,
                                tags,
                                filterBySystemTags,
                                toFilter,
                                vehicleJob,
                                loggedInUser
                            );
                    }

                    if (tagFilter && toFilter) {
                        toFilter =
                            toFilter &&
                            isTagsFilterConditionMatched(
                                allTagsAttached,
                                tags,
                                tagFilter,
                                toFilter,
                                vehicleJob,
                                loggedInUser
                            );
                    }
                }

                return toFilter;
            }
        );
    }
    const isAppendCustomJobsOnVehicleRealtime = appendCustomJobsOnVehicleRealtime();

    // add few params required for sorting
    forEach(filteredRealtimeVehicleList, (v) => {
        const vehicleJob = vehiclesOnJobAsObject ? vehiclesOnJobAsObject[v.vehicleId] : '';
        v.lastJobStatusTime = get(vehicleJob, 'lastStatusTime', 0);
        v.predictedDelay = get(vehicleJob, 'predictedDelay', 0);
        v.jobName = get(vehicleJob, 'jobName', '');
        v.consignor = get(vehicleJob, 'consigner', '');
        if (isAppendCustomJobsOnVehicleRealtime) {
            v.jobCustomFields = get(vehicleJob, 'jobCustomFields', []);
            v.otherJobAttributes = get(vehicleJob, 'otherAttributes', {});
            v.originActualDeparture = get(vehicleJob, 'originActualDeparture', '');
        }
        v.idleTimeInMin = getIdleTimeInMs(v);
    });

    if (isSwitchUserAllowed) {
        if (sortVehicleFilter && sortVehicleDirection) {
            return orderVehiclesByStatus(assign(filteredRealtimeVehicleList), sortVehicleFilter, sortVehicleDirection);
        } else {
            return filteredRealtimeVehicleList;
        }
    } else {
        return orderVehiclesByStatus(filteredRealtimeVehicleList, sortVehicleFilter, sortVehicleDirection);
    }
}

export function filterRealtimeVehiclesByNearBy(vehicles, config) {
    let {
        vehicleLocationFilter,

        km_range,
        pos,
    } = config;

    let filteredRealtimeVehicleList = vehicles;

    if (filteredRealtimeVehicleList.length > 0) {
        filteredRealtimeVehicleList = filter(filteredRealtimeVehicleList, ({ latitude, longitude }) => {
            let toFilter = true;

            if (vehicleLocationFilter && km_range && pos) {
                const distance =
                    pythagorasEquirectangular(pos.lat, pos.lng, latitude, longitude) *
                    P2P_DISTANCE_NORMALIZATION_FACTOR;
                const roundedDistance = round(distance);
                if (km_range == '0-1') {
                    toFilter = distance >= 0 && distance <= 1;
                } else if (km_range == '0-100') {
                    toFilter = roundedDistance <= 100;
                } else if (km_range == '50-100') {
                    toFilter = roundedDistance > 50 && roundedDistance <= 100;
                } else if (km_range == '100-150') {
                    toFilter = roundedDistance > 100 && roundedDistance <= 150;
                } else if (km_range == '>150') {
                    toFilter = roundedDistance > 150;
                }
            }

            return toFilter;
        });
    }

    return filteredRealtimeVehicleList;
}

export function getGroupName(groupId, groupList) {
    let groupName = null;
    if (groupId && groupList && groupList.length > 0) {
        const group = find(groupList, ['id', groupId]);
        if (group && group.name) {
            groupName = group.name;
        }
    }
    return groupName;
}

export function showGroupDropdown(availableGroupList, loggedInUser = window.FLEETX_LOGGED_IN_USER) {
    if (!availableGroupList || availableGroupList.length === 0) {
        return false;
    }
    if (!!loggedInUser.group) {
        return false;
    }
    if (get(loggedInUser, 'groups', []).length === 1) {
        return false;
    }
    return true;
}

export function mappedJobBooking(bookings, accountId) {
    let label = '';
    const sortBasedOn = getJobBookingSortBasedOn();
    if (sortBasedOn !== 'NONE') {
        if (sortBasedOn === 'bookingDate') {
            bookings = orderBy(bookings, ['bookingDate'], ['asc']);
        } else if (sortBasedOn === 'dispatchDate') {
            bookings = orderBy(bookings, ['dispatchDate'], ['asc']);
        }
    }

    return map(bookings, (booking) => {
        label = '';
        if (get(booking, 'bookingNumber')) {
            label = label + get(booking, 'bookingNumber') + '-';
        }
        if (get(booking, 'customer.name')) {
            label = label + get(booking, 'customer.name') + '-';
        }
        if (get(booking, 'route.name')) {
            label = label + get(booking, 'route.name') + '-';
        }
        if (get(booking, 'movementType') && showMovementTypeInJobBooking(accountId)) {
            label = label + get(booking, 'movementType') + '-';
        }
        if (get(booking, 'routeName')) {
            label = label + get(booking, 'routeName') + '-';
        }
        label = label + getHumanizeTime(get(booking, 'dispatchDate'));
        return {
            value: get(booking, 'id'),
            label: label,
        };
    });
}

export function mappedPercentCurrencyDropdownOptions(currencyType) {
    return [currencyType, { value: '%', label: '%' }];
}

export function getAllowedTariffTypes(selectedCustomerId, customers) {
    if (!customers || !customers.length || !selectedCustomerId) {
        return false;
    }
    return map(find(customers, ['id', selectedCustomerId]).tariffs, (tariff) => ({
        value: tariff.id,
        label: `${tariff.tariffType} - ${tariff.vehicleType}`,
    }));
}

export function getBatteryPercent(deviceId, batteryLevelString, oaDeviceType, PDBattery) {
    let batteryPercent = 0;
    const deviceTypeObj = getDeviceTypeFromDeviceId(deviceId, oaDeviceType);
    if (includes(['310P 10MAH'], deviceTypeObj?.name) && batteryLevelString) {
        batteryPercent = +batteryLevelString;
    } else if (isGT03CDevice(deviceId)) {
        batteryPercent = (toSafeInteger(batteryLevelString) * 100) / 6;
    } else if (isEG02Device(deviceId)) {
        const voltage = parseFloat(batteryLevelString);
        if (voltage >= 87.5) {
            batteryPercent = 100;
        } else if (voltage >= 84.8) {
            batteryPercent = 90;
        } else if (voltage >= 81.6) {
            batteryPercent = 80;
        } else if (voltage >= 78.8) {
            batteryPercent = 70;
        } else if (voltage >= 75.5) {
            batteryPercent = 60;
        } else if (voltage >= 73) {
            batteryPercent = 50;
        } else if (voltage >= 70.5) {
            batteryPercent = 40;
        } else if (voltage >= 68) {
            batteryPercent = 30;
        } else if (voltage >= 66) {
            batteryPercent = 20;
        } else if (voltage >= 64) {
            batteryPercent = 10;
        }
    } else if (isAt4Device(deviceId, oaDeviceType)) {
        batteryPercent = !isUndefined(PDBattery) ? PDBattery : getAt4BatteryPercent(batteryLevelString);
    } else if (isAM100Device(deviceId, oaDeviceType)) {
        batteryPercent = !isUndefined(PDBattery) ? PDBattery : batteryLevelString;
    } else if (isAT101Device(deviceId, oaDeviceType)) {
        batteryPercent = +batteryLevelString;
    } else if (
        isG300LockDevice(deviceId, oaDeviceType) ||
        isOpenAppLockDevice(deviceId, oaDeviceType) ||
        isJT701LockDevice(deviceId, oaDeviceType)
    ) {
        //Directly use lockBattery Attibute
        return batteryLevelString;
    } else if (isTFT100(deviceId, oaDeviceType)) {
        //Directly use ev Attibute
        return batteryLevelString;
    } else if (isElectricApiDevice(deviceId, oaDeviceType)) {
        //Directly use ev Attibute
        return batteryLevelString;
    } else if (isV5Device({ otherAttributes: { deviceType: oaDeviceType } })) {
        batteryPercent = toSafeInteger(batteryLevelString);
    } else if (!isUndefined(PDBattery) && isS20Device({ otherAttributes: { deviceType: oaDeviceType } })) {
        batteryPercent = PDBattery;
    } else {
        batteryPercent = (toSafeInteger(batteryLevelString) * 100) / 6;
    }
    return batteryPercent;
}

export function getFreightSum(job) {
    let sum = 0;
    map(get(job, 'jobAnalytics'), (jobAnalytics) => (sum += get(jobAnalytics, 'freightFare', 0)));

    return sum;
}

export function mappedJobPoints(jobAnalytics, includeFirstPoint = false) {
    let start = includeFirstPoint ? 0 : 1;
    let jobPoints = [];
    for (let i = start; i < jobAnalytics.length; i++) {
        let jobPoint = jobAnalytics[i];
        jobPoints.push({
            value: jobPoint.id,
            label: get(jobPoint, 'routePoint.address', ''),
            challanId: get(jobPoint, 'challanId'),
            erpJobAnalytics: get(jobPoint, 'erpJobAnalytics'),
        });
    }
    return jobPoints;
}

export function mappedFirms(firmsList) {
    return map(firmsList, (firm) => {
        let address = '';
        if (get(firm, 'gst.address.addressLine1')) {
            address += ` ${get(firm, 'gst.address.addressLine1')}`;
        }
        if (get(firm, 'gst.address.addressLine2')) {
            address += ` ${get(firm, 'gst.address.addressLine2')}`;
        }
        if (get(firm, 'gst.address.city')) {
            address += ` ${get(firm, 'gst.address.city')}`;
        }
        if (get(firm, 'gst.address.country')) {
            address += ` ${get(firm, 'gst.address.country')}`;
        }
        if (get(firm, 'gst.address.postalCode')) {
            address += ` ${get(firm, 'gst.address.postalCode')}`;
        }
        return {
            value: firm.id,
            label: `${firm.name}`,
            address: address,
        };
    });
}

export function mappedLRCopyType() {
    const lrCopyTe = [
        { value: 'Driver', label: 'Driver' },
        { value: 'Consigner', label: 'Consignor' },
        {
            value: 'Consignee',
            label: 'Consignee',
        },
        { value: 'Transporter', label: 'Transporter' },
    ];
    return lrCopyTe;
}

export function getNextControlRoomViewType(currentViewType, allControlRoomViewTypeEnabled) {
    let currentView;
    let foundNext = false;
    let nextView = '';
    let maxOrder = CONTROLROOM_VIEW_ORDER.length - 1;
    if (currentViewType) {
        currentView = find(CONTROLROOM_VIEW_ORDER, { key: currentViewType });
        let nextValue = currentView.value;
        while (!foundNext && nextValue <= maxOrder) {
            nextValue = nextValue + 1;
            nextView = find(CONTROLROOM_VIEW_ORDER, { value: nextValue });
            if (nextView && isControlRoomDashboardEnabled(nextView.key, allControlRoomViewTypeEnabled)) {
                foundNext = true;
            }
        }
        if (nextValue > maxOrder) {
            nextView = find(CONTROLROOM_VIEW_ORDER, { value: 0 });
        }
    }
    return nextView ? nextView.key : CONTROLROOM_VIEW_TYPE.JOB_DASHBOARD;
}

export const isControlRoomDashboardEnabled = (viewType, allControlRoomViewTypeEnabled) => {
    const result = filter(allControlRoomViewTypeEnabled, (view) => {
        return view === viewType;
    });
    return result.length ? true : false;
};

export function getPreviousControlRoomViewType(currentViewType, showPlantDashboard, showControlTower, varunaEventBook) {
    switch (currentViewType) {
        case CONTROLROOM_VIEW_TYPE.JOB_DASHBOARD:
            return showPlantDashboard
                ? CONTROLROOM_VIEW_TYPE.PLANT_DASHBOARD_TVS
                : showControlTower
                ? CONTROLROOM_VIEW_TYPE.CONTROL_TOWER
                : varunaEventBook
                ? CONTROLROOM_VIEW_TYPE.VARUNA_EVENT_BOOK
                : CONTROLROOM_VIEW_TYPE.LOCATION_DASHBOARD;
        case CONTROLROOM_VIEW_TYPE.LOCATION_DASHBOARD:
            return CONTROLROOM_VIEW_TYPE.JOB_DASHBOARD;
        // case CONTROLROOM_VIEW_TYPE.RUNNING_DASHBOARD:
        //     return CONTROLROOM_VIEW_TYPE.LOCATION_DASHBOARD;
        case CONTROLROOM_VIEW_TYPE.PLANT_DASHBOARD_TVS:
            return CONTROLROOM_VIEW_TYPE.LOCATION_DASHBOARD;

        default:
            return CONTROLROOM_VIEW_TYPE.JOB_DASHBOARD;
    }
}

export function numberToLocaleString(number) {
    const locale = 'en-IN';
    const splits = (number + '').split('.');
    let numberString = '';
    const digits = splits[0].length;
    for (let i = 0; i < digits; i++) {
        const d = digits - i;
        if (i > 0 && (d === 3 || d === 5 || d === 7 || d === 9 || d === 11)) {
            numberString += ',';
        }
        numberString += splits[0][i];
    }
    if (splits[1]) {
        numberString += '.' + splits[1];
    }
    return numberString;
}

export function formatOdometer(val) {
    return val ? numberToLocaleString(val) : val;
}

export function getTypeFilterOptions(address) {
    if (!address) {
        return null;
    }
    let typeFilterOptions = {};
    appendTypeFilterOptions(address.vehiclesTypeWithinRadius, typeFilterOptions);
    appendTypeFilterOptions(address.vehiclesTypeNotOnJob, typeFilterOptions);
    appendTypeFilterOptions(address.vehiclesTypeOnJobLoading, typeFilterOptions);
    appendTypeFilterOptions(address.vehiclesTypeOnJobLoaded, typeFilterOptions);
    appendTypeFilterOptions(address.vehiclesTypeOnJobUnloading, typeFilterOptions);
    appendTypeFilterOptions(address.vehiclesTypeOnJobUnloaded, typeFilterOptions);
    if (keys(typeFilterOptions).length == 0) {
        return null;
    }
    return typeFilterOptions;
}

export function appendTypeFilterOptions(typeObject, typeFilterOptions) {
    if (!typeObject) {
        return typeFilterOptions;
    }
    const allKeys = keys(typeObject);
    if (!allKeys || allKeys.length == 0) {
        return typeFilterOptions;
    }
    map(allKeys, (key) => {
        typeFilterOptions[key] = key;
    });
    return typeFilterOptions;
}

export function isAnyTypeCount(address) {
    if (!address) {
        return false;
    }
    let count = 0;
    count += vehicleTypeCount(address.vehiclesTypeWithinRadius);
    count += vehicleTypeCount(address.vehiclesTypeNotOnJob);
    count += vehicleTypeCount(address.vehiclesTypeOnJobLoading);
    count += vehicleTypeCount(address.vehiclesTypeOnJobLoaded);
    count += vehicleTypeCount(address.vehiclesTypeOnJobUnloading);
    count += vehicleTypeCount(address.vehiclesTypeOnJobUnloaded);
    return !!count;
}

export function vehicleTypeCount(typeObject) {
    if (!typeObject) {
        return 0;
    }
    const allKeys = keys(typeObject);
    if (!allKeys || allKeys.length == 0) {
        return 0;
    }
    let anyGreater0 = 0;
    map(allKeys, (key) => {
        if (!!typeObject[key]) {
            anyGreater0 += typeObject[key];
        }
    });
    return anyGreater0;
}

export function sendCommandToDevice(
    accesstoken,
    deviceId,
    actualDeviceIdToUseForGettingMobile,
    commandType,
    callback,
    moreData
) {
    const { commandId, commandName } = moreData || {};
    return fetchSelectableDevices(accesstoken, actualDeviceIdToUseForGettingMobile, true).promise.then((result) => {
        const mobile = get(result, 'data[0].mobile', null);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const splits = deviceId.split('::');
        const actualDeviceId = splits[0];
        const secondValue = splits[1] ? splits[1] : null;
        const thirdValue = splits[2] ? splits[2] : null;
        const userEmail = get(
            window,
            'SWITCHED_USER_ORIGINAL_DATA.switchedUserOriginalEmailId',
            get(window, 'FLEETX_LOGGED_IN_USER.email', '')
        );
        const data = {
            token: 'udbhav',
            mobile: mobile,
            type: commandType,
            deviceid: deviceId,
            mergedDeviceId: deviceId,
            actualDeviceId: actualDeviceId,
            userEmail: userEmail,
        };
        if (secondValue) {
            data.secondValue = secondValue;
        }
        if (thirdValue) {
            data.thirdValue = thirdValue;
        }

        let p = axios.post(`${ROOT_NODE_API_URL}trigger/sendcommands`, queryString.stringify(data), config);

        p.then((response) => {
            if (callback) {
                callback(response);
            }
        });

        return p;
    });
}

export function getMappedAccountSourceList(extraVal) {
    const extraObj = !!extraVal
        ? {
              label: extraVal,
              value: extraVal,
          }
        : {};
    return [
        ...map(ACCOUNT_SOURCE_LIST, (to) => ({
            label: to,
            value: to,
        })),
        extraObj,
    ];
}

export function getStoppageCount(
    tripCount,
    firstTripStartDate,
    lastTripEndDate,
    searchStartDate,
    searchEndDate,
    useStart = true,
    useEnd = true
) {
    let stoppages = 0;
    if (!tripCount) {
        return stoppages;
    }
    stoppages = +tripCount - 1;
    if (
        useStart &&
        searchStartDate &&
        firstTripStartDate &&
        getMomentTime(firstTripStartDate).diff(getMomentTime(searchStartDate)) > 0
    ) {
        stoppages++;
    }

    if (
        useEnd &&
        searchEndDate &&
        lastTripEndDate &&
        getMomentTime(searchEndDate).diff(getMomentTime(lastTripEndDate)) > 0
    ) {
        stoppages++;
    }

    return stoppages;
}

export function isObjectEquals(newObject, oldObject) {
    const diff = omitBy(oldObject, function (oldVal, oldKey) {
        return oldVal === get(newObject, oldKey);
    });
    return keys(diff).length !== 0;
}

export function createStoppageData(
    tripList,
    stoppageTimeMinutes,
    startDate,
    endDate,
    addressBook,
    loggedInUser,
    showOnlyAddressbookStops,
    forceIncludeFirstAndLastPoint = false,
    isAddressBookAsMap = false
) {
    let data = [];
    const tripsObject = reduce(
        tripList,
        (acc, trip) => ({
            ...acc,
            [trip.vehicleId]: [trip, ...get(acc, trip.vehicleId, [])],
        }),
        {}
    );
    const vehicleIds = keys(tripsObject);
    map(vehicleIds, (vehicleId) => {
        let sumOdoTillNow = 0;
        const tripListVehicle = orderBy(tripsObject[vehicleId], ['eDate'], ['asc']);
        const totalTrips = tripListVehicle.length;
        map(tripsObject[vehicleId], (trip, index) => {
            const vehicleObject = {
                vehicleId: trip.vehicleId,
                vehicleName: trip.vehicleName,
                vehicleNumber: trip.vehicleNumber,
                groupId: trip.groupId,
                driverName: trip.driverName,
                tripId: trip.tripId,
            };
            const sDate = getMomentTime(trip.sDate);
            const eDate = getMomentTime(trip.eDate);

            const sNearestAddress = getNearestAddressFromAddressbook(
                trip.sLat,
                trip.sLon,
                addressBook,
                true,
                loggedInUser,
                isAddressBookAsMap
            );
            const sAddressProcessed = getNearestAddressText(sNearestAddress, trip.sAddress);
            const eNearestAddress = getNearestAddressFromAddressbook(
                trip.eLat,
                trip.eLon,
                addressBook,
                true,
                loggedInUser,
                isAddressBookAsMap
            );
            const eAddressProcessed = getNearestAddressText(eNearestAddress, trip.eAddress);
            const prevEDate = index > 0 ? getMomentTime(tripListVehicle[index - 1].eDate) : null;

            if (index === 0 && (sDate.diff(startDate) > 1000 || forceIncludeFirstAndLastPoint)) {
                if (
                    !showOnlyAddressbookStops ||
                    isInsideNearestAddress(sNearestAddress) ||
                    forceIncludeFirstAndLastPoint
                ) {
                    data.push({
                        ...vehicleObject,
                        stopId: startDate.valueOf(),
                        tOdo: 0,
                        sDate: startDate,
                        eDate: sDate,
                        duration: sDate.diff(startDate),
                        address: sAddressProcessed,
                        nearestAddress: sNearestAddress,
                        sLat: trip.sLat,
                        sLon: trip.sLon,
                        eLat: trip.eLat,
                        eLon: trip.eLon,
                    });
                    sumOdoTillNow = 0;
                }
                sumOdoTillNow += trip.tOdo;
            }
            if (index > 0) {
                if (!showOnlyAddressbookStops || isInsideNearestAddress(sNearestAddress)) {
                    data.push({
                        ...vehicleObject,
                        stopId: prevEDate.valueOf(),
                        tOdo: sumOdoTillNow,
                        sDate: prevEDate,
                        eDate: sDate,
                        duration: sDate.diff(prevEDate),
                        address: sAddressProcessed,
                        nearestAddress: sNearestAddress,
                        sLat: trip.sLat,
                        sLon: trip.sLon,
                        eLat: trip.eLat,
                        eLon: trip.eLon,
                    });
                    sumOdoTillNow = 0;
                }
                sumOdoTillNow += trip.tOdo;
            }

            if (
                eDate &&
                index === totalTrips - 1 &&
                (getEndDate(endDate).diff(eDate) > 1000 || forceIncludeFirstAndLastPoint)
            ) {
                const stopEndDate = getEndDate(endDate);
                if (
                    !showOnlyAddressbookStops ||
                    isInsideNearestAddress(eNearestAddress) ||
                    forceIncludeFirstAndLastPoint
                ) {
                    data.push({
                        ...vehicleObject,
                        stopId: eDate.valueOf(),
                        tOdo: sumOdoTillNow,
                        sDate: eDate,
                        eDate: stopEndDate,
                        duration: stopEndDate.diff(eDate),
                        address: eAddressProcessed,
                        nearestAddress: eNearestAddress,
                        sLat: trip.sLat,
                        sLon: trip.sLon,
                        eLat: trip.eLat,
                        eLon: trip.eLon,
                    });
                }
            }
        });
    });
    const joinedData = [];
    let joinedLength = 0;
    map(data, (d, index) => {
        if (index == 0) {
            joinedData.push({ ...d });
            joinedLength++;
        } else {
            if (
                !dontClubStoppagesForAccount() &&
                d.address === joinedData[joinedLength - 1].address &&
                d.tOdo < 1 &&
                (!d.address || !get(d, 'address').match(/,india|, India/gi))
            ) {
                joinedData[joinedLength - 1].eDate = d.eDate;
                joinedData[joinedLength - 1].duration = getMomentTime(d.eDate).diff(
                    getMomentTime(joinedData[joinedLength - 1].sDate)
                );
            } else {
                joinedData.push({ ...d });
                joinedLength++;
            }
        }
    });

    const filteredData = [];
    let kmForStop = 0;
    map(joinedData, (d, index) => {
        if (
            forceIncludeFirstAndLastPoint ||
            getMomentTime(d.eDate).diff(getMomentTime(d.sDate)) > stoppageTimeMinutes * 60000
        ) {
            filteredData.push({ ...d, tOdo: d.tOdo + kmForStop });
            kmForStop = 0;
        } else {
            kmForStop += d.tOdo;
        }
    });
    return filteredData;
}

export const getIdlePointInBetweenTrip = (allMotionPoints, sDate, eDate) => {
    let inBtwIdle = [];
    map(allMotionPoints, (motionPoint) => {
        if (
            motionPoint.status == 'IDLE' &&
            motionPoint.sDate &&
            motionPoint.eDate &&
            getMomentTime(sDate).valueOf() <= getMomentTime(motionPoint.sDate) &&
            getMomentTime(eDate).valueOf() >= getMomentTime(motionPoint.eDate)
        ) {
            inBtwIdle.push(motionPoint);
        }
    });
    return inBtwIdle;
};

export const mergeStoppageAndIdleData = (
    stoppages,
    idlePoints,
    from,
    to,
    addressBook,
    loggedInUser,
    vehicleMetadata
) => {
    let mergedIdleAndStops = [];
    let inBtwIdle = [];

    if (isEmpty(stoppages)) {
        inBtwIdle = getIdlePointInBetweenTrip(idlePoints, from, stop.sDate);
        if (!isEmpty(inBtwIdle)) {
            map(inBtwIdle, (idle) => {
                mergedIdleAndStops.push({
                    ...vehicleMetadata,
                    ...idle,
                    tOdo: 0,
                    duration: getMomentTime(idle.eDate).diff(getMomentTime(idle.sDate)),
                    nearestAddress: getNearestAddressFromAddressbook(
                        idle.sLat,
                        idle.sLon,
                        addressBook,
                        true,
                        loggedInUser
                    ),
                });
            });
        }
        return mergedIdleAndStops;
    }
    map(stoppages, (stop, index) => {
        const vehicleObject = {
            vehicleId: stop.vehicleId,
            vehicleName: stop.vehicleName,
            vehicleNumber: stop.vehicleNumber,
            groupId: stop.groupId,
        };
        if (index === 0) {
            let fromDate;
            let toDate;
            let inCase;
            if (getMomentTime(from).valueOf() < getMomentTime(stop.sDate).valueOf()) {
                fromDate = from;
                toDate = stop.sDate;
                inCase = 1;
            } else if (stoppages.length === 1) {
                fromDate = stop.eDate;
                toDate = to;
                inCase = 2;
            }
            if (!fromDate) {
                mergedIdleAndStops.push(stop);
            } else {
                if (inCase === 2) {
                    mergedIdleAndStops.push(stop);
                }
                inBtwIdle = getIdlePointInBetweenTrip(idlePoints, fromDate, toDate);
                if (!isEmpty(inBtwIdle)) {
                    map(inBtwIdle, (idle) => {
                        mergedIdleAndStops.push({
                            ...vehicleObject,
                            ...idle,
                            tOdo: 0,
                            duration: getMomentTime(idle.eDate).diff(getMomentTime(idle.sDate)),
                            nearestAddress: getNearestAddressFromAddressbook(
                                idle.sLat,
                                idle.sLon,
                                addressBook,
                                true,
                                loggedInUser
                            ),
                        });
                    });
                }
                if (inCase === 1) {
                    mergedIdleAndStops.push(stop);
                }
            }
        } else if (get(stoppages, 'length') === index - 1) {
            if (getMomentTime(to).valueOf() > getMomentTime(stop.eDate)) {
                inBtwIdle = getIdlePointInBetweenTrip(idlePoints, stop.eDate, to);
                if (!isEmpty(inBtwIdle)) {
                    map(inBtwIdle, (idle) => {
                        mergedIdleAndStops.push({
                            ...vehicleMetadata,
                            ...idle,
                            duration: getMomentTime(idle.eDate).diff(getMomentTime(idle.sDate)),
                            nearestAddress: getNearestAddressFromAddressbook(
                                idle.sLat,
                                idle.sLon,
                                addressBook,
                                true,
                                loggedInUser
                            ),
                        });
                    });
                }
            }
            mergedIdleAndStops.push(stop);
        } else {
            inBtwIdle = getIdlePointInBetweenTrip(idlePoints, stoppages[index - 1].eDate, stop.sDate);
            if (!isEmpty(inBtwIdle)) {
                map(inBtwIdle, (idle) => {
                    mergedIdleAndStops.push({
                        ...vehicleObject,
                        ...idle,
                        duration: getMomentTime(idle.eDate).diff(getMomentTime(idle.sDate)),
                        nearestAddress: getNearestAddressFromAddressbook(
                            idle.sLat,
                            idle.sLon,
                            addressBook,
                            true,
                            loggedInUser
                        ),
                    });
                });
            }
            mergedIdleAndStops.push(stop);
        }
    });
    return mergedIdleAndStops;
};

export const getAllMotionPointsFromTrip = (tripList, loggedInUser) => {
    let allMotionPoints = [];
    map(tripList, ({ routes }, index) => {
        let motionPoints = getMotionPointsFromRoutes(routes, getIdleMarkerDelayTimeoutForAccount(loggedInUser));
        if (motionPoints && !isEmpty(motionPoints)) {
            allMotionPoints.push(...motionPoints);
        }
    });
    return allMotionPoints;
};

export const processedMergedTrips = (allMergedTrips, startDate, endDate, excludeOverlappingTrips) => {
    let mergedTrips = [];
    if (excludeOverlappingTrips) {
        mergedTrips = map(allMergedTrips, (mergedTrip, index) => {
            if (
                mergedTrip.trip.sDate < startDate.format(DATE_FORMAT_TIMESTAMP) ||
                mergedTrip.trip.eDate > getEndDate(endDate).format(DATE_FORMAT_TIMESTAMP)
            ) {
                const newTrip = { ...mergedTrip.trip };
                if (newTrip.sDate < startDate.format(DATE_FORMAT_TIMESTAMP)) {
                    newTrip.sDate = startDate.format(DATE_FORMAT_TIMESTAMP);
                }
                if (!newTrip.eDate || newTrip.eDate >= getEndDate(endDate).format(DATE_FORMAT_TIMESTAMP)) {
                    newTrip.eDate = getEndDate(endDate).format(DATE_FORMAT_TIMESTAMP);
                }
                newTrip.duration = getMomentTime(newTrip.eDate).diff(getMomentTime(newTrip.sDate));
                const newRoutes = [];
                map(mergedTrip.routes, (point) => {
                    if (point.timeStamp >= newTrip.sDate && point.timeStamp <= getEndDate(newTrip.eDate)) {
                        newRoutes.push(point);
                    }
                });
                newTrip.tOdo = get(last(newRoutes), 'currentOdometer', 0) - get(first(newRoutes), 'currentOdometer', 0);
                return { trip: newTrip, routes: newRoutes };
            } else {
                return mergedTrip;
            }
        });
    } else {
        return allMergedTrips;
    }
    return mergedTrips;
};

export function redirectToFreshdesk(current_user) {
    const newUrl = `https://fleetx.freshdesk.com/login/sso?name=${encodeURIComponent(
        current_user.username
    )}&email=${encodeURIComponent(current_user.email)}&timestamp=${current_user.timestamp}&hash=${current_user.hash}`;
    window.location.href = newUrl;
}

export function shouldReferralBannerBeEnabled(referralShownTimeInfo) {
    const lastShownTime = referralShownTimeInfo.lastTime;
    const noOfTimesClosed = referralShownTimeInfo.noOfTimesClosed;
    if (!lastShownTime) {
        return true;
    }

    let days = 1; // show after last seen was more than days

    let diff = getMomentTime().valueOf() - lastShownTime;

    return diff > days * 24 * 60 * 60 * 1000 && noOfTimesClosed < 3;
}

export function parseQueryParams(params) {
    const parsedParams = {};
    forEach(params, (value, key) => {
        if (typeof value === 'boolean') {
            set(parsedParams, key, value);
        } else if (value) {
            set(parsedParams, key, value);
        }
    });
    return parsedParams;
}

export function getCurrentFinancialYear() {
    const currentMonth = getMomentTime().month();
    let current_fiscal_year_start;
    let current_fiscal_year_end;
    if (currentMonth > 3) {
        current_fiscal_year_start = getMomentTime().month('april').startOf('month');
        current_fiscal_year_end = getMomentTime().add(1, 'year').month('march').endOf('month');
    } else {
        current_fiscal_year_start = getMomentTime().subtract(1, 'year').month('april').startOf('month');
        current_fiscal_year_end = getMomentTime().month('march').endOf('month');
    }
    return [current_fiscal_year_start, current_fiscal_year_end];
}

export function getLastFinancialYear() {
    const currentMonth = getMomentTime().month();
    let last_fiscal_year_start;
    let last_fiscal_year_end;

    if (currentMonth > 3) {
        last_fiscal_year_start = getMomentTime().subtract(1, 'year').month('april').startOf('month');
        last_fiscal_year_end = getMomentTime().month('march').endOf('month');
    } else {
        last_fiscal_year_start = getMomentTime().subtract(2, 'year').month('april').startOf('month');
        last_fiscal_year_end = getMomentTime().subtract(1, 'year').month('march').endOf('month');
    }
    return [last_fiscal_year_start, last_fiscal_year_end];
}

export function renderHelpIcon(helpUrl) {
    return null;
    return (
        <a
            data-gacategory="Realtime"
            data-gaaction="Click"
            data-galabel="filter-by-tags-help-icon"
            className="ml-1 v-align-text-top"
            title="help"
            target="_blank"
            href={`${helpUrl}`}
        >
            <i className="fa fa-question-circle font-xl" aria-hidden="true" />
        </a>
    );
}

export const SELECT_OPTION_LIMIT = 10;
export const SELECT_OPTION_LIMIT_ROUTE = 25;

export const isKnownRiskyPoint = {
    [FLEETX_ACCOUNTS.ADITYA_CEMENT]: [
        { id: 11931 },
        { id: 12147 },
        { id: 12150 },
        { id: 11932 },
        { id: 12148 },
        { id: 11921 },
    ],
    [FLEETX_ACCOUNTS.ULTRATECH_RAJASHREE]: [],
    [FLEETX_ACCOUNTS.GUJARAT_CEMENT_WORKS]: [
        { id: 27925 },
        { id: 27930 },
        { id: 27931 },
        { id: 27932 },
        { id: 27935 },
        { id: 27936 },
        { id: 27937 },
    ],
    [FLEETX_ACCOUNTS.SHREE_CEMENT]: [
        {
            id: 30465,
        },
        {
            id: 30422,
        },
    ],
};
export const isAllowedStopPoint = {
    [FLEETX_ACCOUNTS.BHARATHI_CEMENT]: { 136761: [31718, 31717, 31719], 136763: [31720, 31721, 31716] },
};

export const isKnownRoutePoint = [
    //mundra
    { id: 67402, thresholdsDistance: [700, 800, 900], thresholdsDelay: [3000, 3300, 3600], name: 'N.A.' },
    {
        id: 11710,
        thresholdsDistance: [700, 800, 900],
        thresholdsDelay: [3000, 3300, 3600],
        name: 'N.A.',
    },
    {
        id: 63206,
        thresholdsDistance: [700, 800, 900],
        thresholdsDelay: [3000, 3300, 3600],
        name: 'MUNDRA LOADING POINT 2',
    },
    {
        id: 71208,
        thresholdsDistance: [700, 800, 900],
        thresholdsDelay: [3000, 3300, 3600],
        name: 'Mundra Loading point To Aditya Cement Yard-20190729',
    }, //kandla/tuna same as default
    {
        id: 37921,
        thresholdsDistance: [636, 700, 800],
        thresholdsDelay: [1500, 2100, 2400],
        name: 'Kandla Sealing Point To Aditya Cement Chittorgarh Plant',
    }, //Jamnagar
    {
        id: 69330,
        thresholdsDistance: [700, 750, 850],
        thresholdsDelay: [1560, 1920, 2400],
        name: 'BED JAMANNAGAR TO ADITYA CEMENT CHITTAURGARH',
    },
    {
        id: 69333,
        thresholdsDistance: [700, 750, 850],
        thresholdsDelay: [1560, 1920, 2400],
        name: 'JAMANNAGAR ESSAR LC8 RELIANCE TO ADITYA CEMENT CHITTAURGARH',
    }, // Dahej
    {
        id: 99648,
        thresholdsDistance: [526, 560, 600],
        thresholdsDelay: [1800, 2160, 2400],
        name: 'Dahej Port to Aditya Birla Cement Chittorgarh Puran',
    },
    {
        id: 99655,
        thresholdsDistance: [526, 560, 600],
        thresholdsDelay: [1800, 2160, 2400],
        name: 'Dahej Port to Aditya Birla Cement Chittorgarh Puran',
    },
    {
        id: 100595,
        thresholdsDistance: [526, 560, 600],
        thresholdsDelay: [1800, 2160, 2400],
        name: 'Dahej Port to Aditya Birla Cement Chittorgarh Puran',
    },
    {
        id: 100725,
        thresholdsDistance: [526, 560, 600],
        thresholdsDelay: [1800, 2160, 2400],
        name: 'Dahej Port to Aditya Birla Cement Chittorgarh Puran',
    },
    {
        id: 100733,
        thresholdsDistance: [526, 560, 600],
        thresholdsDelay: [1800, 2160, 2400],
        name: 'Dahej Port to Aditya Birla Cement Chittorgarh Puran',
    },
    {
        id: 100743,
        thresholdsDistance: [526, 560, 600],
        thresholdsDelay: [1800, 2160, 2400],
        name: 'Dahej Port to Aditya Birla Cement Chittorgarh Puran',
    }, //KRISHNAPATNAM LOADING POINT TO ENTRY COMING YARD
    {
        id: 105975,
        thresholdsDistance: [610, 640, 640],
        thresholdsDelay: [2400, 2700, 2700],
        name: 'KRISHNAPATNAM LOADING POINT TO ENTRY COMING YARD',
    } /* GUJARAT CEMENT WORKS  */,
    {
        id: 114836,
        thresholdsDistance: [312, 330, 330],
        thresholdsDelay: [720, 900, 900],
        name: 'Jamnagar Port to GCW Plant',
    },
    {
        id: 114832,
        thresholdsDistance: [312, 330, 330],
        thresholdsDelay: [720, 900, 900],
        name: 'Jamnagar Port to GCW Plant',
    } /* Bharati cement*/,
    {
        id: 136763,
        thresholdsDistance: [620, 640, 640],
        thresholdsDelay: [1680, 1920, 1920],
        name: 'Krishnapatnam to Kulbargi',
        thresholdStoppage: HOURS_6,
        allowedAddressStoppageCount: 3,
    },
    {
        id: 136761,
        thresholdsDistance: [240, 260, 260],
        thresholdsDelay: [540, 660, 660],
        name: 'Krishnapatnam to Bharathi Kadapa',
        thresholdStoppage: HOURS_1,
        allowedAddressStoppageCount: 1,
    } /*Shree Cement*/,
    {
        id: 141604,
        thresholdsDistance: [550, 570, 590],
        thresholdsDelay: [2280, 2400, 2700],
        name: 'Jaigad to Kodla',
    },
    {
        id: 142587,
        thresholdsDistance: [660, 680, 700],
        thresholdsDelay: [2520, 2700, 3000],
        name: 'Gangavaram to Raipur',
    },
    {
        id: 139733,
        thresholdsDistance: [760, 790, 860],
        thresholdsDelay: [2100, 2400, 2700],
        name: 'Mundra to Ras',
    },
    {
        id: 141564,
        thresholdsDistance: [740, 760, 780],
        thresholdsDelay: [2100, 2400, 2700],
        name: 'Mundra to Beawer',
    },
];

export const delay = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(Math.floor(Math.random(10000) * 10000));
        }, ms);
    });
};

// VEHICLE_TYRE_AUDIT_CODES is a sharable constant in both react project as well as napp
// please sync the value at both places(dashboard/src/constant.js and dashboard/napp/models/reports/tyre_management_model.js) if you are changing it at one place
export const VEHICLE_TYRE_AUDIT_CODES = {
    NEW: 'NEW',
    ATTACHED: 'ATTACHED',
    IDLE: 'IDLE',
    TREAD_DEPTH_RECORDED: 'TREAD_DEPTH_RECORDED',
    RF_ATTACHED: 'RF_ATTACHED',
    RF_DETACHED: 'RF_DETACHED',
    TYRE_MRN: 'TYRE_MRN',
    CHASSIS_MRN: 'CHASSIS_MRN',
    REMOULD_TYRE_MRN: 'REMOULD_TYRE_MRN',
    TYRE_AGAINST_CLAIM_MRN: 'TYRE_AGAINST_CLAIM_MRN',
    TYRE_STOCK_IN: 'TYRE_STOCK_IN',
    SENT_TO_REMOULD: 'SENT_TO_REMOULD',
    SENT_TO_CLAIM: 'SENT_TO_CLAIM',
    TYRE_THEFT: 'TYRE_THEFT',
    TYRE_SCRAPPING: 'TYRE_SCRAPPING',
    TYRE_STOCK_OUT: 'TYRE_STOCK_OUT',
    REPAIR_TYRE_MRN: 'REPAIR_TYRE_MRN',
    SENT_TO_REPAIR: 'SENT_TO_REPAIR',
};

export const VEHICLE_BATTERY_AUDIT_CODES = {
    NEW: 'NEW',
    ATTACHED: 'ATTACHED',
    IDLE: 'IDLE',
    BATTERY_MRN: 'BATTERY_MRN',
    CHASSIS_MRN: 'CHASSIS_MRN',
    REFURBISHED_BATTERY: 'REFURBISHED_BATTERY',
    BATTERY_AGAINST_CLAIM: 'BATTERY_AGAINST_CLAIM',
    BATTERY_STOCK_IN: 'BATTERY_STOCK_IN',
    REFURBISHED_REJECTION: 'REFURBISHED_REJECTION',
    CLAIM_REJECTION: 'CLAIM_REJECTION',
    SENT_TO_REMOULD: 'SENT_TO_REMOULD',
    SENT_TO_CLAIM: 'SENT_TO_CLAIM',
    BATTERY_THEFT: 'BATTERY_THEFT',
    BATTERY_SCRAPPING: 'BATTERY_SCRAPPING',
    BATTERY_STOCK_OUT: 'BATTERY_STOCK_OUT',
};

export const SERVICE_LINE_ITEM_UNITS = [
    { value: 'LITRES', label: 'LITRES' },
    {
        value: 'KILOGRAMS',
        label: 'KILOGRAMS',
    },
    { value: 'METERS', label: 'METERS' },
    { value: 'FEET', label: 'FEET' },
    {
        value: 'TONNES',
        label: 'TONNES',
    },
    { value: 'PIECES', label: 'PIECES' },
];

export function mappedServiceLineItemUnits() {
    return map(SERVICE_LINE_ITEM_UNITS, (o) => {
        return {
            value: o.value,
            label: o.label,
        };
    });
}

export const DEVICE_TYPE_FORM_SELECT = [
    {
        value: '213EP',
        label: '213EP',
    },
    {
        value: '213W',
        label: '213W',
    },
    {
        value: '213GD',
        label: '213GD',
    },
    {
        value: '213GDPA',
        label: '213GDPA',
    },
    {
        value: '213GDS',
        label: '213GDS',
    },
    {
        value: '213GDX',
        label: '213GDX',
    },
    {
        value: '213GL',
        label: '213GL',
    },
    {
        value: '213L',
        label: '213L',
    },
    {
        value: '213GN-OBD200',
        label: '213GN-OBD200',
    },
    {
        value: 'AT1',
        label: 'AT1',
    },
    {
        value: 'AT2',
        label: 'AT2',
    },
    {
        value: 'AT4',
        label: 'AT4',
    },
    {
        value: 'LG301',
        label: 'LG301',
    },
    {
        value: 'LG300',
        label: 'LG300',
    },
    {
        value: 'BW08',
        label: 'BW08',
    },
    {
        value: 'BW09',
        label: 'BW09',
    },
    {
        value: 'C100',
        label: 'C100',
    },
    {
        value: 'E101',
        label: 'E101',
    },
    {
        value: 'ET300',
        label: 'ET300',
    },
    {
        value: 'DISCOVERY2G',
        label: 'DISCOVERY2G',
    },
    {
        value: 'DISCOVERY2GEV',
        label: 'DISCOVERY2GEV',
    },
    {
        value: 'DISCOVERY2GPLUS',
        label: 'DISCOVERY2GPLUS',
    },
    {
        value: 'DISCOVERY2PLUS485',
        label: 'DISCOVERY2PLUS485',
    },
    {
        value: 'FMB910',
        label: 'FMB910',
    },
    {
        value: 'FMB911',
        label: 'FMB911',
    },
    {
        value: 'FMB120',
        label: 'FMB120',
    },
    {
        value: 'FMB130',
        label: 'FMB130',
    },
    {
        value: 'FMB125',
        label: 'FMB125',
    },
    {
        value: 'FMC125',
        label: 'FMC125',
    },
    {
        value: 'FMC130',
        label: 'FMC130',
    },
    {
        value: 'FMB641',
        label: 'FMB641',
    },
    {
        value: 'FMA120',
        label: 'FMA120',
    },
    {
        value: 'TFT100',
        label: 'TFT100',
    },
    {
        value: 'FMB920',
        label: 'FMB920',
    },
    {
        value: 'FMB001',
        label: 'FMB001',
    },
    {
        value: 'GAGAN01',
        label: 'GAGAN01',
    },
    {
        value: 'GEOSAT_TEMP',
        label: 'GEOSAT_TEMP',
    },
    {
        value: 'GEOSAT_NORMAL',
        label: 'GEOSAT_NORMAL',
    },
    {
        value: 'GEOSAT_TEMP_HUMIDITY',
        label: 'GEOSAT_TEMP_HUMIDITY',
    },
    {
        value: 'GT02',
        label: 'GT02',
    },
    {
        value: 'GT03C',
        label: 'GT03C',
    },
    {
        value: 'GPS_LOCK',
        label: 'GPS_LOCK',
    },
    {
        value: 'G300',
        label: 'G300',
    },
    {
        value: 'G300N',
        label: 'G300N',
    },
    {
        value: 'G310',
        label: 'G310',
    },
    {
        value: 'G310N',
        label: 'G310N',
    },
    {
        value: 'G508',
        label: 'G508',
    },
    {
        value: 'G508N',
        label: 'G508N',
    },
    {
        value: 'S20',
        label: 'S20',
    },
    {
        value: 'GT06',
        label: 'GT06',
    },
    {
        value: 'AT06N',
        label: 'AT06N',
    },
    {
        value: 'GT08',
        label: 'GT08',
    },
    {
        value: 'IAC140',
        label: 'IAC140',
    },
    {
        value: 'JV200',
        label: 'JV200',
    },
    {
        value: 'LT162',
        label: 'LT162',
    },
    {
        value: 'MZCW4-AIS140',
        label: 'MZCW4-AIS140',
    },
    {
        value: 'WP30L',
        label: 'WP30L',
    },
    {
        value: 'PRITHVI140',
        label: 'PRITHVI140',
    },
    {
        value: 'BSTPL-17IS',
        label: 'BSTPL-17IS',
    },
    {
        value: 'BSTPL-15',
        label: 'BSTPL-15',
    },
    {
        value: 'BSTPL-4',
        label: 'BSTPL-4',
    },
    {
        value: 'BHARAT101',
        label: 'BHARAT101',
    },
    {
        value: 'PortableMagnetic',
        label: 'PortableMagnetic',
    },
    {
        value: 'PT05',
        label: 'PT05',
    },
    {
        value: 'PT06',
        label: 'PT06',
    },
    {
        value: 'G19',
        label: 'G19',
    },
    {
        value: 'RP02',
        label: 'RP02',
    },
    {
        value: 'SIMCHIP',
        label: 'SIMCHIP',
    },
    {
        value: 'TK06',
        label: 'TK06',
    },
    {
        value: 'TPAPI',
        label: 'TPAPI',
    },
    {
        value: 'FXAPI',
        label: 'FXAPI',
    },
    {
        value: 'TR02',
        label: 'TR02',
    },
    {
        value: 'TR06',
        label: 'TR06',
    },
    {
        value: 'TS101',
        label: 'TS101',
    },
    {
        value: 'TS101EV',
        label: 'TS101EV',
    },
    {
        value: 'V5',
        label: 'V5',
    },
    {
        value: 'VT05R',
        label: 'VT05R',
    },
    {
        value: 'VT05',
        label: 'VT05',
    },
    {
        value: 'VT08S',
        label: 'VT08S',
    },
    {
        value: 'WETRACK2',
        label: 'WETRACK2',
    },
    {
        value: 'WETRACKLITE',
        label: 'WETRACKLITE',
    },
    {
        value: 'GL600',
        label: 'GL600',
    },
    {
        value: 'TMD102',
        label: 'TMD102',
    },
    {
        value: 'TMD364',
        label: 'TMD364',
    },
    {
        value: 'GV56',
        label: 'GV56',
    },
];

export const DEVICE_TYPE_FORM_SELECT_FOR_ADD = [
    {
        value: '213GDPA',
        label: '213GDPA',
    },
    {
        value: 'AT1',
        label: 'AT1',
    },
    {
        value: 'AT4',
        label: 'AT4',
    },
    {
        value: 'LG301',
        label: 'LG301',
    },
    {
        value: 'LG300',
        label: 'LG300',
    },
    {
        value: 'BW08',
        label: 'BW08',
    },
    {
        value: 'BW09',
        label: 'BW09',
    },
    {
        value: 'BSTPL-4',
        label: 'BSTPL-4',
    },
    {
        value: 'E101',
        label: 'E101',
    },
    {
        value: 'ET300',
        label: 'ET300',
    },
    {
        value: 'FMB910',
        label: 'FMB910',
    },
    {
        value: 'FMB911',
        label: 'FMB911',
    },
    {
        value: 'FMB920',
        label: 'FMB920',
    },
    {
        value: 'FMB125',
        label: 'FMB125',
    },
    {
        value: 'FMB641',
        label: 'FMB641',
    },
    {
        value: 'FMA120',
        label: 'FMA120',
    },
    {
        value: 'FMC125',
        label: 'FMC125',
    },
    {
        value: 'FMC130',
        label: 'FMC130',
    },
    {
        value: 'GT02',
        label: 'GT02',
    },
    {
        value: 'GT03C',
        label: 'GT03C',
    },
    {
        value: 'GT06',
        label: 'GT06',
    },
    {
        value: 'AT06N',
        label: 'AT06N',
    },
    {
        value: 'LC100',
        label: 'LC100',
    },

    {
        value: 'TS101',
        label: 'TS101',
    },
    {
        value: 'GT08',
        label: 'GT08',
    },
    {
        value: 'PT05',
        label: 'PT05',
    },
    {
        value: 'PT06',
        label: 'PT06',
    },
    {
        value: 'G19',
        label: 'G19',
    },
    {
        value: 'RP02',
        label: 'RP02',
    },
    {
        value: 'TK06',
        label: 'TK06',
    },
    {
        value: 'TPAPI',
        label: 'TPAPI',
    },
    {
        value: 'FXAPI',
        label: 'FXAPI',
    },
    {
        value: 'TR02',
        label: 'TR02',
    },
    {
        value: 'TR06',
        label: 'TR06',
    },
    {
        value: 'TS101',
        label: 'TS101',
    },
    {
        value: 'V5',
        label: 'V5',
    },
    {
        value: 'VT05',
        label: 'VT05',
    },
    {
        value: 'VT05R',
        label: 'VT05R',
    },
    {
        value: 'VT08S',
        label: 'VT08S',
    },
    {
        value: 'WETRACK2',
        label: 'WETRACK2',
    },
    {
        value: 'WETRACKLITE',
        label: 'WETRACKLITE',
    },
    {
        value: 'VT200',
        label: 'VT200',
    },
    {
        value: 'VT100',
        label: 'VT100',
    },
    {
        value: 'VT200L',
        label: 'VT200L',
    },
    {
        value: 'VT100L',
        label: 'VT100L',
    },
    {
        value: 'GL600',
        label: 'GL600',
    },
    {
        value: 'TMD102',
        label: 'TMD102',
    },
    {
        value: 'TMD364',
        label: 'TMD364',
    },
    {
        value: 'GV56',
        label: 'GV56',
    },
    {
        value: 'FMB120',
        label: 'FMB120',
    },
    {
        value: 'ICOMPASS140',
        label: 'ICOMPASS140',
    },
];

export const DEVICE_SUPPLIER_FORM_SELECT_FOR_ADD = [
    {
        value: 'CLIENT',
        label: 'CLIENT',
    },
];

export const DEVICE_SUPPLIER_FORM_SELECT = [
    {
        value: 'ATLANTA',
        label: 'ATLANTA',
    },
    {
        value: 'BSTECH',
        label: 'BSTECH',
    },
    {
        value: 'Cantrack',
        label: 'Cantrack',
    },
    {
        value: 'ClientOmGT03',
        label: 'ClientOmGT03',
    },
    {
        value: 'CONCOX',
        label: 'CONCOX',
    },
    {
        value: 'PROTRACK',
        label: 'PROTRACK',
    },
    {
        value: 'IAC',
        label: 'IAC',
    },
    {
        value: 'ITRIANGLE',
        label: 'ITRIANGLE',
    },
    {
        value: 'MOTOZEN',
        label: 'MOTOZEN',
    },
    {
        value: 'ROADPOINT',
        label: 'ROADPOINT',
    },
    {
        value: 'SinoCastel',
        label: 'SinoCastel',
    },
    {
        value: 'GEOSAT',
        label: 'GEOSAT',
    },
    {
        value: 'Concox',
        label: 'Concox',
    },
    {
        value: 'Taisys',
        label: 'Taisys',
    },
    {
        value: 'OPEN_APP',
        label: 'OPEN_APP',
    },
    {
        value: 'HHD',
        label: 'HHD',
    },
    {
        value: 'Teltonika',
        label: 'Teltonika',
    },
    {
        value: 'Technician',
        label: 'Technician',
    },
    {
        value: 'WATSOO',
        label: 'WATSOO',
    },
    {
        value: 'TRANSIGHT',
        label: 'TRANSIGHT',
    },
    {
        value: 'CLIENT',
        label: 'CLIENT',
    },
];

export const BLOCK_INSTALL_REASON = [
    {
        value: 'GPS DEFECT',
        label: 'GPS DEFECT',
    },
    {
        value: 'NETWORK ISSUE',
        label: 'NETWORK ISSUE',
    },
    {
        value: 'LIGHTS NOT WORKING',
        label: 'LIGHTS NOT WORKING',
    },
    {
        value: 'WATER DAMAGE',
        label: 'WATER DAMAGE',
    },
    {
        value: 'OTHER',
        label: 'OTHER',
    },
    {
        value: 'TESTED AND FOUND DEFECTIVE BY SUPPLIER',
        label: 'TESTED AND FOUND DEFECTIVE BY SUPPLIER',
    },
    {
        value: 'SENT TO SUPPLIER',
        label: 'SENT TO SUPPLIER',
    },
];

export const FLEET_SIZES = [
    {
        value: '24',
        label: 'Less than 25 assets',
    },
    {
        value: '100',
        label: '25-100 assets',
    },
    {
        value: '250',
        label: '100-250 assets',
    },
    {
        value: '600',
        label: 'More than 500 assets',
    },
];
export const LOOKING_FOR = [
    {
        value: 'FASTag',
        label: 'FASTag',
    },
    {
        value: 'Sim based Tracking',
        label: 'Sim based Tracking',
    },
    {
        value: 'AIS140 device',
        label: 'AIS140 device',
    },
    {
        value: 'Fleet Management',
        label: 'Fleet Management',
    },
];

export const STATES_IN_INDIA = [
    {
        value: 'Andhra Pradesh',
        label: 'Andhra Pradesh',
    },
    {
        value: 'Arunachal Pradesh',
        label: 'Arunachal Pradesh',
    },
    {
        value: 'Assam',
        label: 'Assam',
    },
    {
        value: 'Bihar',
        label: 'Bihar',
    },
    {
        value: 'Chhattisgarh',
        label: 'Chhattisgarh',
    },
    {
        value: 'Goa',
        label: 'Goa',
    },
    {
        value: 'Gujarat',
        label: 'Gujarat',
    },
    {
        value: 'Haryana',
        label: 'Haryana',
    },
    {
        value: 'Himachal Pradesh',
        label: 'Himachal Pradesh',
    },
    {
        value: 'Jammu and Kashmir',
        label: 'Jammu and Kashmir',
    },
    {
        value: 'Jharkhand',
        label: 'Jharkhand',
    },
    {
        value: 'Karnataka',
        label: 'Karnataka',
    },
    {
        value: 'Kerala',
        label: 'Kerala',
    },
    {
        value: 'Madhya Pradesh',
        label: 'Madhya Pradesh',
    },
    {
        value: 'Maharashtra',
        label: 'Maharashtra',
    },
    {
        value: 'Manipur',
        label: 'Manipur',
    },
    {
        value: 'Meghalaya',
        label: 'Meghalaya',
    },
    {
        value: 'Mizoram',
        label: 'Mizoram',
    },
    {
        value: 'Nagaland',
        label: 'Nagaland',
    },
    {
        value: 'Odisha',
        label: 'Odisha',
    },
    {
        value: 'Punjab',
        label: 'Punjab',
    },
    {
        value: 'Rajasthan',
        label: 'Rajasthan',
    },
    {
        value: 'Sikkim',
        label: 'Sikkim',
    },
    {
        value: 'Tamil Nadu',
        label: 'Tamil Nadu',
    },
    {
        value: 'TELANGANA',
        label: 'Telangana',
    },
    {
        value: 'Tripura',
        label: 'Tripura',
    },
    {
        value: 'Uttarakhand',
        label: 'Uttarakhand',
    },
    {
        value: 'UTTAR PRADESH',
        label: 'Uttar Pradesh',
    },
    {
        value: 'WEST BENGAL',
        label: 'West Bengal',
    },
    {
        value: 'Andaman and Nicobar Islands',
        label: 'Andaman and Nicobar Islands',
    },
    {
        value: 'Chandigarh',
        label: 'Chandigarh',
    },
    {
        value: 'Dadra and Nagar Haveli',
        label: 'Dadra and Nagar Haveli',
    },
    {
        value: 'Daman and Diu',
        label: 'Daman and Diu',
    },
    {
        value: 'DELHI',
        label: 'Delhi',
    },
    {
        value: 'Lakshadweep',
        label: 'Lakshadweep',
    },
    {
        value: 'Puducherry',
        label: 'Puducherry',
    },
    {
        value: 'Ladakh',
        label: 'Ladakh',
    },
    {
        value: 'Others',
        label: 'Others',
    },
];

export const ACCOUNT_PLAN_TYPES = [
    { value: 1, label: 'Tracking' },
    {
        value: 33,
        label: 'Tracking + Simtracking',
    },
    { value: 6, label: 'Easy (Reminder-Records)' },
    { value: 8, label: 'Easy + Issues' },
    {
        value: 18,
        label: 'Easy + Simtracking',
    },
    { value: 27, label: 'Easy + Jobs + Simtracking' },
    { value: 28, label: 'Easy + Jobs + Apptracking' },
    {
        value: 30,
        label: 'Easy + Jobs + Simtracking + Apptracking',
    },
    { value: 7, label: 'Jobs' },
    { value: 9, label: 'Jobs + Finance' },
    { value: 10, label: 'Jobs + Issue' },
    {
        value: 14,
        label: 'Jobs + Tyre',
    },
    { value: 17, label: 'Jobs + Tyre + Simtracking' },
    { value: 12, label: 'Jobs + Tyre + Finance + Issue ' },
    {
        value: 15,
        label: 'Jobs + Tyre + Finance + Issue + Simtracking',
    },
    { value: 34, label: 'Fleetx channel partner' },
    { value: 35, label: 'PULSE + EASY + ROUTE' },
    {
        value: 37,
        label: 'PULSE + EASY + ROUTE + VENDOR COLLABORATION',
    },
    { value: 38, label: 'PULSE + EASY + ROUTE + FINANCE + PARTS-INVENTORY' },
    {
        value: 39,
        label: 'PULSE + EASY + ROUTE + FINANCE + PARTS-INVENTORY + TYRES',
    },
    { value: 20, label: 'PULSE + EASY + ROUTE + FINANCE + LOCKS' },
    {
        value: 41,
        label: 'PULSE + EASY + ROUTE + FINANCE + VENDOR COLLABORATION + APP + SIM',
    },
    { value: 45, label: 'PULSE + EASY + ROUTE + FINANCE + LOCKS + SIM-TRACKING' },
    {
        value: 50,
        label:
            'PULSE + EASY + TYRES + SIMTRACKING + ROUTE + FINANCE + PARTS-INVENTORY + VENDOR-COLLABORATION + CMS + APPTRACKING',
    },
    {
        value: 54,
        label: 'PULSE + EASY + ROUTE + APPTRACKING + EPOD + ISSUE',
    },
    {
        value: 55,
        label: 'PULSE + EASY + FINANCE + ROUTE + PARTS-INVENTORY + STATIONARY + TYRES',
    },
    {
        value: 61,
        label: 'JOBS + SHIFT-WISE',
    },
    {
        value: 62,
        label:
            'PULSE + EASY + TYRES + SIMTRACKING + ROUTE + FINANCE + PARTS-INVENTORY + VENDOR-COLLABORATION + CMS + APPTRACKING + Freight-Bill + EPOD',
    },
    {
        value: 63,
        label: 'PULSE + EASY + FINANCE + ROUTE + PARTS INVENTORY + STATIONARY + TYRE MANAGEMENT + Operations ERP',
    },
    {
        value: 70,
        label:
            'PULSE + EASY + TYRE MANAGEMENT + SIM TRACKING + ROUTE + FINANCE + PARTS INVENTORY + VENDOR COLLABORATION + CMS + APP TRACKING + Freight Bill + EPOD + Operations ERP',
    },
    {
        value: 75,
        label:
            'PULSE + EASY + ROUTE + FINANCE + PARTS INVENTORY + TYRE MANAGEMENT + ISSUES + LOCKS + SIM TRACKING + EPOD + STATIONARY',
    },
    {
        value: 85,
        label: 'PULSE + EASY + ROUTE + VIDEO TELEMATICS',
    },
    {
        value: 86,
        label: 'PULSE + EASY + ROUTE + ISSUES + FINANCE + TYRE MANAGEMENT + VIDEO TELEMATICS',
    },
    {
        value: 90,
        label:
            'PULSE + EASY + ROUTE + FINANCE + LOCKS + Stationary Module + Operations ERP + Parts-Inventory + TYRE MANAGEMENT',
    },
    {
        value: 42,
        label: 'PULSE + EASY + ROUTE + DEVICE + APP TRACKING + SIM',
    },
    {
        value: 92,
        label: 'PULSE + EASY + ROUTE + ISSUES + FINANCE + TYRE MANAGEMENT + SHIFTWISE',
    },
    {
        value: 106,
        label: 'MASTER_PLAN + ERP',
    },
];
export const BILL_NATURES = {
    TYRES: 'TYRES',
    PARTS: 'PARTS',
    BATTERY: 'BATTERIES',
};

export function REPORT_ON_UI_NAMES(scope) {
    const reportOnUiNames = [
        {
            value: 'jobReport',
            label: 'Job Report',
            isExtraInfoRequired: !isRMCAccount(),
            isExtraInfoType: isRMCAccount() ? [] : ['plant'],
            showFilters: true,
            filterList: isRMCAccount() ? ['GROUP'] : ['PLANT'],
        }, // { value: 'jobSpeedViolationReport', label: 'Job Speed Violation Report', isExtraInfoRequired: false },
        { value: 'latestJobReport', label: 'Latest Job Report', isExtraInfoRequired: false },
        {
            value: 'intraPlantReport',
            label: 'Intra Plant Report',
            isExtraInfoRequired: true,
            isExtraInfoType: ['plant'],
            showFilters: true,
            filterList: ['PLANT'],
        },
        {
            value: 'vehiclePerformanceReport',
            label: 'Vehicle Performance Report',
            isExtraInfoRequired: true,
            isExtraInfoType: ['plant'],
            showFilters: true,
            filterList: ['PLANT'],
        },
    ];

    // if (isVehicleAnalyticsVisible(scope)) {
    //     reportOnUiNames.push({
    //         value: 'realtimeReportRMC',
    //         label: 'Realtime Report RMC',
    //         isExtraInfoRequired: false,
    //     });
    // }

    return reportOnUiNames;
}

export const REPORT_ON_UI_NAMES_CASTROL = [
    {
        value: 'jobReportCastrol',
        label: 'Job Report',
        isExtraInfoRequired: false,
        showFilters: true,
        filterList: ['GROUP', 'VEHICLE', 'JOB_STATUS'],
    },
];
export const REPORT_ON_UI_NAMES_BHARATHI = [
    {
        value: 'jobReportBharathi',
        label: 'Job Report',
        isExtraInfoRequired: false,
        showFilters: true,
        filterList: ['GROUP', 'VEHICLE', 'JOB_STATUS'],
    },
    {
        value: 'exceptionJobReportBharathi',
        label: 'Exception Job Report',
        isExtraInfoRequired: false,
        showFilters: true,
        filterList: ['GROUP', 'VEHICLE', 'JOB_STATUS'],
    },
];
export const REPORT_ON_UI_NAMES_NSL = [
    {
        value: 'jobReportManual',
        label: 'Manual Job Report',
        isExtraInfoRequired: false,
        showFilters: true,
        filterList: ['GROUP', 'VEHICLE', 'JOB_STATUS'],
    },
];

export const SIM_PROVIDER = {
    'VODAFONE': 'VODAFONE',
    'AIRTEL': 'AIRTEL',
    'IDEA': 'IDEA',
    'JIO': 'JIO',
    'BSNL': 'BSNL',
};

export const SIM_PROVIDER_OPTIONS = [
    {
        value: SIM_PROVIDER.BSNL,
        label: SIM_PROVIDER.BSNL,
        toShow: isOperatorOpenToAccount(),
    },
    {
        value: SIM_PROVIDER.VODAFONE,
        label: SIM_PROVIDER.VODAFONE,
        toShow: isOperatorOpenToAccount(),
    },
    {
        value: SIM_PROVIDER.AIRTEL,
        label: SIM_PROVIDER.AIRTEL,
        toShow: isOperatorOpenToAccount(),
    },
    { value: SIM_PROVIDER.IDEA, label: SIM_PROVIDER.IDEA, toShow: isOperatorOpenToAccount() },
    {
        value: SIM_PROVIDER.JIO,
        label: SIM_PROVIDER.JIO,
        toShow: isOperatorOpenToAccount(),
    },
];

/**
 *
 * @param {*} simProviders
 * @param {*} [localTransporterAccount]
 * @returns {string[]}
 */
export const mapSimProviders = (simProviders, localTransporterAccount = null) => {
    return filter(simProviders, (provider) => {
        return isOperatorOpenToAccount(provider.value, localTransporterAccount);
    });
};
export const mapIndentDevice = (devices, accountId) => {
    const country = get(window.FLEETX_LOGGED_IN_USER, 'country', 'INDIA');
    return filter(devices, (device) => {
        return isDeviceTypeOpenToAccount(device, country, accountId);
    });
};

export function getSimTrackingMobileNumber(mobileNumber) {
    let mobileNumberFormated = trim(mobileNumber).replace(/\+|-| /gi, '');
    let prefix = getPhonePrefixFromCountry();
    prefix = prefix.substr(1);
    if (prefix != 91) {
        mobileNumberFormated = `${prefix}` + mobileNumberFormated;
    } else {
        mobileNumberFormated =
            mobileNumberFormated.length === 10 ? `${prefix}` + mobileNumberFormated : mobileNumberFormated;
    }
    return mobileNumberFormated;
}

export function getConsentMessage(deviceSupplier) {
    switch (deviceSupplier) {
        case SIM_PROVIDER.AIRTEL:
            return 'Pending Consent. SMS "FLTX Y" to 56979';
        case SIM_PROVIDER.VODAFONE:
            return 'Pending Consent. SMS "Y" to 9167500066';
        case SIM_PROVIDER.IDEA:
            return 'Pending Consent. SMS "Y" to 55502';
        case SIM_PROVIDER.JIO:
            return 'Pending Consent. SMS "Y" to 51712036 OR Dial this number 9982256700';
        default:
            return 'Pending Consent. Check SMS';
    }
}

export function getImageKitLink(uploadedRelativeUrl, resize = '') {
    return !uploadedRelativeUrl || (uploadedRelativeUrl && uploadedRelativeUrl.match(/^http/))
        ? uploadedRelativeUrl
        : `https://ik.imagekit.io/fx${resize}${uploadedRelativeUrl}`;
}

export function filterAssetMovementPoints(data, calculateSpeed = false) {
    const mappedData = map(data, (p) => ({ ...p, type: 'GPS' }));
    const filteredData = [];
    let len = 0;
    map(mappedData, (d, index) => {
        if (!d || !d.latitude || !d.longitude) {
            return;
        }
        const dist =
            len > 0 && index > 0
                ? pythagorasEquirectangular(
                      d.latitude,
                      d.longitude,
                      mappedData[index - 1].latitude,
                      mappedData[index - 1].longitude
                  )
                : 0;
        if (len > 0 && dist > 100) {
            return;
        } else {
            if (calculateSpeed && dist > 0) {
                const timeDiff = getMomentTime(d.timeStamp).diff(filteredData[len - 1].timeStamp, 'h', true);
                d.speed = timeDiff > 0 ? dist / timeDiff : 0;
            } else {
                d.speed = 0;
            }
            filteredData.push(d);
            len++;
        }
    });
    return filteredData;
}

export function isSkipped(fromEpoch, toEpoch) {
    if (extraSkipForAccount()) {
        return true;
    }
    return toEpoch - fromEpoch > 2 * HOURS_24;
}

export function getSkipLevel(fromEpoch, toEpoch) {
    const days = (toEpoch - fromEpoch) / HOURS_24;
    if (extraSkipForAccount()) {
        return 3;
    }
    if (days > 14) {
        return 4;
    } else if (days > 6) {
        return 3;
    }
    if (days > 1) {
        return 2;
    }
    return 1;
}

export const TAX_TYPES = [
    {
        value: 'IGST',
        label: 'IGST',
    },
    {
        value: 'SGST',
        label: 'SGST',
    },
    {
        value: 'CGST',
        label: 'CGST',
    },
];
export const TAX_TYPES_V2 = [
    {
        value: 'IGST',
        label: 'IGST',
    },
    {
        value: 'SGST',
        label: 'SGST',
    },
    {
        value: 'CGST',
        label: 'CGST',
    },
    {
        value: 'VAT',
        label: 'VAT',
    },
];
export const FSC_FREQUENCY_TYPES = [
    {
        value: 'DAILY',
        label: 'DAILY',
    },
    {
        value: 'WEEKLY',
        label: 'WEEKLY',
    },
    {
        value: 'MONTHLY',
        label: 'MONTHLY',
    },
];
export const BASE_RATE_REVISION_LOGIC = [
    {
        value: 'BASED_ON_FUEL_COMPONENT',
        label: 'Fuel Component Based',
    },
    {
        value: 'INDEPENDENT_OF_FUEL_COMPONENT',
        label: 'Surcharged Based',
    },
];
export const CHARGE_TYPES = [
    {
        value: 'COUNT',
        label: 'Count',
    },
    {
        value: 'WEIGHT',
        label: 'Weight',
    },
    {
        value: 'VOLUMETRIC',
        label: 'Volumetric',
    },
    {
        value: 'DEADWEIGHT',
        label: 'Deadweight',
    },
    {
        value: 'VOLUMETRIC_DEADWEIGHT',
        label: 'Volumetric Deadweight',
    },
];
export const ODA_CALCULATION_TYPES = [
    {
        value: 'NearestServiceablePincode',
        label: 'Nearest Serviceable Pincode',
    },
    {
        value: 'SourcePincode',
        label: 'Source Pincode',
    },
];
export const CALCULATION_TYPES = [
    {
        value: 'LOADING_DETENTION',
        label: 'Loading Detention Day wise',
    },
    {
        value: 'UNLOADING_DETENTION',
        label: 'Unloading Detention Day wise',
    },
    {
        value: 'TRANSIT_DELAY_PENALTY_PER_HOUR',
        label: 'Transit Delay Calculation Per Hour',
    },
    {
        value: 'TRANSIT_DELAY_PENALTY_PER_DAY',
        label: 'Transit Delay Calculation Per Day',
    },
    {
        value: 'LOADING_CHARGES_PER_CASE',
        label: 'Loading Charge Per Unit',
    },
    {
        value: 'UNLOADING_CHARGES_PER_CASE',
        label: 'Unloading Charge Per Unit',
    },
    {
        value: 'FLAT_PLACEMENT_FAILURE',
        label: 'Flat Placement Failure',
    },
    {
        value: 'LOADING_DETENTION_PER_DAY',
        label: 'Loading Detention Per Day',
    },
    {
        value: 'UNLOADING_DETENTION_PER_DAY',
        label: 'Unloading Detention Per Day',
    },
];

export const PTL_CALCULATION_TYPES = [
    {
        value: 'FLAT',
        label: 'Flat',
    },
    {
        value: 'ROV',
        label: 'ROV',
    },
    {
        value: 'FOV',
        label: 'FOV',
    },
    {
        value: 'LOADING_DETENTION_TILL_1',
        label: 'Loading Detention Till 1 Days',
    },
    {
        value: 'LOADING_DETENTION_TILL_3',
        label: 'Loading Detention Till 3 Days',
    },
    {
        value: 'LOADING_DETENTION_TILL_5',
        label: 'Loading Detention Till 5 Days',
    },
    {
        value: 'LOADING_DETENTION_BEYOND_5',
        label: 'Loading Detention Beyond 5 Days',
    },
    {
        value: 'UNLOADING_DETENTION_TILL_1',
        label: 'Unloading Detention Till 1 Days',
    },
    {
        value: 'UNLOADING_DETENTION_TILL_3',
        label: 'Unloading Detention Till 3 Days',
    },
    {
        value: 'UNLOADING_DETENTION_TILL_5',
        label: 'Unloading Detention Till 5 Days',
    },
    {
        value: 'UNLOADING_DETENTION_BEYOND_5',
        label: 'Unloading Detention Beyond 5 Days',
    },
    {
        value: 'LOADING_CHARGES_PER_CASE',
        label: 'Loading Charges Per Case',
    },
    {
        value: 'UNLOADING_CHARGES_PER_CASE',
        label: 'Unloading Charges Per Case',
    },
    {
        value: 'TRANSIT_DELAY_PENALTY',
        label: 'Transit Delay Penalty',
    },
];
export const FUEL_ALARM_SUB_TYPE = [
    {
        value: 'fill',
        label: 'Fill',
    },
    {
        value: 'theft',
        label: 'Theft',
    },
];

export const TRANSACTION_STATUS_TYPES = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
};

export function getTransactionStatusColorClass(status) {
    let statusColor = '';
    switch (status) {
        case TRANSACTION_STATUS_TYPES.APPROVED:
            statusColor = 'text-success';
            break;
        case TRANSACTION_STATUS_TYPES.REJECTED:
            statusColor = 'text-danger';
            break;
        case TRANSACTION_STATUS_TYPES.PENDING:
            statusColor = 'text-warning';
            break;
        default:
            statusColor = '';
    }

    return statusColor;
}

export const OPERATIONS_DUTY_STATUS = [
    {
        value: 'SCHEDULED',
        label: 'Scheduled',
    },
    {
        value: 'STARTED',
        label: 'Started',
    },
    {
        value: 'COMPLETED',
        label: 'Completed',
    },
    {
        value: 'REJECTED',
        label: 'Rejected',
    },
    {
        value: 'MISSED',
        label: 'Missed',
    },
    {
        value: 'NO_SHOW',
        label: 'No Show',
    },
    {
        value: 'VEHICLE_NOT_AVAILABLE',
        label: 'VEHICLE NOT AVAILABLE',
    },
];

export const USER_MATCH_FLAGS = {
    RED: 'RED',
    GREEN: 'GREEN', // ORANGE: 'ORANGE'
};

export function getColorClassSchemeForFlag(value) {
    switch (value) {
        case USER_MATCH_FLAGS.RED:
            return 'text-danger';
        case USER_MATCH_FLAGS.GREEN:
            return 'text-success';
        default:
            return 'orange';
    }
}

export const ALL_FEATURES_DISABLED_REASONS = [
    {
        value: 'ACCOUNT_MERGED',
        label: 'Account Merged',
    },
    {
        value: 'ACTIVE_WITHOUT_INSTALLATION',
        label: 'Active without installation',
    },
    {
        value: 'API_ISSUE',
        label: 'API Issue',
    },
    {
        value: 'ALL_DEVICE_REMOVED',
        label: 'All Device Removed',
    },

    {
        value: 'CERTIFICATION_ISSUE',
        label: 'Certification Issue',
    },
    {
        value: 'CUSTOMIZATION_ISSUE',
        label: 'Customization Issue',
    },
    {
        value: 'DEVICE_ISSUE',
        label: 'Device Issue',
    },
    {
        value: 'DEVICE_DISCONNECTION',
        label: 'Device Disconnection',
    },
    {
        value: 'FAKE_COMMITMENTS',
        label: 'Fake Commitments',
    },
    {
        value: 'PRICE',
        label: 'Price',
    },
    {
        value: 'MILEAGE_ISSUE',
        label: 'Mileage Issue',
    },
    {
        value: 'KM_ISSUE',
        label: 'KM Issue',
    },
    {
        value: 'LOCATION_ISSUE',
        label: 'Location Issue',
    },
    {
        value: 'RELATIONSHIP_ISSUE',
        label: 'Relationship Issue',
    },
    {
        value: 'OVERALL_SERVICE_ISSUE',
        label: 'Overall Service Issue',
    },
    {
        value: 'NOT_INTERESTED',
        label: 'Not Interested',
    },
    {
        value: 'NOT_REACHABLE',
        label: 'Not Reachable',
    },
    {
        value: 'SINGLE_PAYMENT_ISSUE',
        label: 'Single Payment Issue',
    },
    {
        value: 'PAYMENT_ISSUE',
        label: 'Payment Issue',
    },
    {
        value: 'ONE_TIME_PROJECT/CONSIGNMENT',
        label: 'One Time Project/Consignment',
    },
    {
        value: 'OVERCOMMITMENT_THROUGH_SALES',
        label: 'Overcommitment Throught Sales',
    },
    {
        value: 'NO_DEMAND',
        label: 'No Demand',
    },
    {
        value: 'SERVICE_NOT_USED_AS_PER_CLIENT',
        label: 'Service not used as per client',
    },
    {
        value: 'TRAINING_ISSUE',
        label: 'Training Issue',
    },
    {
        value: 'VEHICLE_ACCIDENT',
        label: 'Vehicle accident',
    },
    {
        value: 'VEHICLES_SOLD',
        label: 'Vehicles Sold',
    },
    {
        value: 'VEHICLE_ON_STANDBY',
        label: 'Vehicle On Standby',
    },
    {
        value: 'OWNER_NO_MORE',
        label: 'Owner No More',
    },
    {
        value: 'OEM_MANDATE',
        label: 'OEM Mandate',
    },
    {
        value: 'LOST_TO_COMPETITOR',
        label: 'Lost To Competitor',
    },
    {
        value: 'SLOW_BUSINESS',
        label: 'Slow Business',
    },
    {
        value: 'SALES_NOT_COMPLETE',
        label: 'Sales Not Complete',
    },
    {
        value: 'TRIAL_NOT_CONVERTED',
        label: 'Trial Not Converted',
    },
    {
        value: 'Not Interested in renewal',
        label: 'Not Interested in renewal',
    },
    {
        value: 'IOT Issue',
        label: 'IOT Issue',
    },
    {
        value: 'OTHER',
        label: 'Other',
    },
];

export function getRFIDPositionTOLabelMapping(rfCount) {
    const mapping = {};
    let position = 1;

    if (rfCount <= 10) {
        for (let i = 1; i <= (rfCount - 1) / 2; i++) {
            mapping[position] = 'L' + i;
            position++;
        }
        for (let i = 1; i <= (rfCount - 1) / 2; i++) {
            mapping[position] = 'R' + i;
            position++;
        }
        if (rfCount % 2 === 0) {
            // Even number
            mapping[position] = 'F1';
            position++;

            mapping[position] = 'B1';
            position++;
        } else {
            // Odd Number
            mapping[position] = 'B1';
            position++;
        }
    } else {
        // 11 - 4,4,3
        // 12 - 4,4,4
        // 13 - 5,5,3
        // 14 - 5,5,4
        // 15 - 6,6,3
        // 16 - 6,6,4
        for (let i = 1; i <= (rfCount - 3) / 2; i++) {
            mapping[position] = 'L' + i;
            position++;
        }
        for (let i = 1; i <= (rfCount - 3) / 2; i++) {
            mapping[position] = 'R' + i;
            position++;
        }
        if (rfCount % 2 === 0) {
            mapping[position] = 'F1';
            position++;

            mapping[position] = 'F2';
            position++;

            mapping[position] = 'B1';
            position++;

            mapping[position] = 'B2';
            position++;
        } else {
            mapping[position] = 'F1';
            position++;

            mapping[position] = 'B1';
            position++;

            mapping[position] = 'B2';
            position++;
        }
    }
    return mapping;
}

export const TYRE_RUNNING_PERCENTAGES_FILTER = [
    {
        value: 'LessThan25',
        label: '0 To 25%',
    },
    {
        value: '25to50',
        label: '25 To 50%',
    },
    {
        value: '50to75',
        label: '50 To 75%',
    },
    {
        value: '75to100',
        label: '75 To 100%',
    },
    {
        value: 'MoreThan100',
        label: 'More Than 100%',
    },
];

export const EXCEL_COL = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
    7: 'G',
    8: 'H',
    9: 'I',
    10: 'J',
    11: 'K',
    12: 'L',
    13: 'M',
    14: 'N',
    15: 'O',
    16: 'P',
    17: 'Q',
    18: 'R',
    19: 'S',
    20: 'T',
    21: 'U',
    22: 'V',
    23: 'W',
    24: 'X',
    25: 'Y',
    26: 'Z',
    27: 'AA',
    28: 'AB',
    29: 'AC',
    30: 'AD',
    31: 'AE',
    32: 'AF',
    33: 'AG',
    34: 'AH',
    35: 'AI',
    36: 'AJ',
    37: 'AK',
    38: 'AL',
    39: 'AM',
    40: 'AN',
    41: 'AO',
    42: 'AP',
    43: 'AQ',
    44: 'AR',
    45: 'AS',
    46: 'AT',
    47: 'AU',
    48: 'AV',
    49: 'AW',
    50: 'AX',
    51: 'AY',
    52: 'AZ',
    53: 'BA',
    54: 'BB',
    55: 'BC',
    56: 'BD',
    57: 'BE',
    58: 'BF',
    59: 'BG',
    60: 'BH',
    61: 'BI',
    62: 'BJ',
    63: 'BK',
    64: 'BL',
    65: 'BM',
    66: 'BN',
    67: 'BO',
    68: 'BP',
    69: 'BQ',
    70: 'BR',
    71: 'BS',
    72: 'BT',
    73: 'BU',
    74: 'BV',
    75: 'BW',
    76: 'BX',
    77: 'BY',
    78: 'BZ',
    79: 'CA',
    80: 'CB',
    81: 'CC',
    82: 'CD',
    83: 'CE',
    84: 'CF',
    85: 'CG',
    86: 'CH',
    87: 'CI',
    88: 'CJ',
    89: 'CK',
    90: 'CL',
    91: 'CM',
    92: 'CN',
    93: 'CO',
    94: 'CP',
    95: 'CQ',
    96: 'CR',
    97: 'CS',
    98: 'CT',
    99: 'CU',
    100: 'CV',
    101: 'CW',
    102: 'CX',
    103: 'CY',
    104: 'CZ',
    105: 'DA',
    106: 'DB',
    107: 'DC',
    108: 'DD',
    109: 'DE',
    110: 'DF',
    111: 'DG',
    112: 'DH',
    113: 'DI',
    114: 'DJ',
};

export const getExcelColFromNumber = (colNo) => {
    if (colNo < 26) {
        return EXCEL_COL[colNo];
    }
    const mod = floor(colNo / 26, 0);
    const remainder = colNo % 26;
    if (!remainder) {
        return `${EXCEL_COL[colNo]}`;
    }
    return `${mod ? EXCEL_COL[mod] : ''}${remainder ? EXCEL_COL[remainder] : ''}`;
};

export const months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
};

export const PLANNED_VERSUS_ACTUAL = {
    TOTAL_DISTANCE: 'total_distance',
    RUNNING_TIME: 'running_time',
};

export const RESCHEDULED_DUTY_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
};

export const getNearestAddressFromQT = (
    latitude,
    longitude,
    addressBookAsQT,
    addressBookAsMap,
    addressBook,
    loggedInUser
) => {
    let nearestAddress, nearestAddressFromQT;
    const isQuadTreeSearchOpen = isQuadTreeSearchOpenForAccount(loggedInUser);
    const nearestAddressRange = getNearestAddressRangeForAccount(loggedInUser);
    if (addressBookAsQT && !isEmpty(addressBookAsQT) && !isEmpty(addressBookAsMap) && isQuadTreeSearchOpen) {
        nearestAddressFromQT = addressBookAsQT.query(
            new Circle(normalizeLatitude(latitude), normalizeLongitude(longitude), nearestAddressRange)
        );
        if (isEmpty(nearestAddressFromQT)) {
            nearestAddress =
                addressBook && addressBook.length > 0
                    ? getNearestAddressFromAddressbook(
                          latitude,
                          longitude,
                          addressBook,
                          true,
                          loggedInUser,
                          addressBookAsMap
                      )
                    : null;
        } else {
            nearestAddress =
                addressBook &&
                addressBook.length > 0 &&
                getNearestAddressFromWithinQTNode(
                    latitude,
                    longitude,
                    nearestAddressFromQT,
                    addressBook,
                    addressBookAsMap,
                    true,
                    loggedInUser
                );
        }
    }
    //fallback
    if (isEmpty(nearestAddress)) {
        nearestAddress = getNearestAddressFromAddressbook(
            latitude,
            longitude,
            !isEmpty(addressBookAsMap) ? addressBookAsMap : addressBook,
            true,
            loggedInUser,
            !isEmpty(addressBookAsMap)
        );
    }
    return nearestAddress;
};

export const getTotalDuration = (durations) => {
    let totalTime = 0;

    map(durations, (duration, index) => {
        totalTime =
            totalTime +
            moment
                .duration(getMomentTime(duration.lastEventTime).diff(getMomentTime(duration.firstEventTime)))
                .asMinutes();
    });

    return <div>{`${round(totalTime, 2)} min`}</div>;
};

export const isSwitchedUserFeatureNotDisabled = (isSwitchedUser, switchedUserRoleId, featureName) => {
    // add a feature here to disable
    const featuresMappingToRole = {
        18: [],
    };
    if (switchedUserRoleId && featuresMappingToRole[switchedUserRoleId]) {
        const allFeatures = featuresMappingToRole[switchedUserRoleId] || [];
        return !(findIndex(allFeatures, (feature) => get(feature, 'name') == featureName) != -1);
    }
    return isSwitchedUser;
};

export const onReloadPage = (forceReload = true) => {
    window.location.reload(forceReload);
};

export const SERVICE_ENTRY_TYPES = {
    SERVICE_TASK: 'SERVICE_TASK',
    ISSUE: 'ISSUE',
};
export const SERVICE_ENTRY_TYPES_LABEL = {
    SERVICE_TASK: 'Service Task',
    ISSUE: 'Issue',
};
export const SERVICE_WORK_TYPES = [
    {
        value: 'NEW',
        label: 'New',
    },
    {
        value: 'REPLACEMENT',
        label: 'Replacement',
    },
    {
        value: 'TOP_UP',
        label: 'Top Up',
    },
    {
        value: 'REPAIR',
        label: 'Repair',
    },
];

export const USER_IMAGE_TAGS = [
    {
        value: 'AADHAR_CARD',
        label: 'Aadhar Card',
    },
    {
        value: 'APOLOGY_LETTER',
        label: 'Apology Letter',
    },
    {
        value: 'APPOINTMENT_LETTER',
        label: 'Appointment Letter',
    },
    {
        value: 'AWARDS',
        label: 'Awards',
    },
    {
        value: 'BLANK_CHEQUE',
        label: 'Blank Cheque',
    },
    {
        value: 'BANK_DETAILS',
        label: 'Bank Details',
    },
    {
        value: 'CERTIFICATES',
        label: 'Certificates',
    },
    {
        value: 'COUNSELLING',
        label: 'Counselling',
    },
    {
        value: 'DDT',
        label: 'DDT',
    },
    {
        value: 'DRIVER',
        label: 'Driver',
    },
    {
        value: 'HAZARDOUS_LICENSE',
        label: 'Hazardous License',
    },
    {
        value: 'INCABIN',
        label: 'Incabin',
    },
    {
        value: 'LICENSE',
        label: 'License',
    },
    {
        value: 'MEDICAL',
        label: 'Medical',
    },
    {
        value: 'PAN_CARD',
        label: 'Pan Card',
    },
    {
        value: 'POLICE_VERIFICATION',
        label: 'Police verification',
    },
    {
        value: 'TRAINING',
        label: 'Training',
    },
];

export const DASHBOARD_HEADERS = {
    REALTIME: 'REALTIME',
    VEHICLES: 'VEHICLES',
    CHANNEL_SALES: 'CHANNEL_SALES',
    HISTORY: 'HISTORY',
    OVERVIEW: 'OVERVIEW',
    CREATE_ACCOUNT: 'CREATE_ACCOUNT',
    VIEW_ACCOUNT: 'VIEW_ACCOUNT',
    VIEW_ACCOUNT_HANDOVERS: 'VIEW_ACCOUNT_HANDOVERS',
    MANAGE_LOCKS: 'MANAGE_LOCKS',
    ADD_VEHICLE: 'ADD_VEHICLE',
    ALARMS: 'ALARMS',
    ISSUES: 'ISSUES',
    DTC: 'DTC',
    JOBS: 'JOBS',
    ADDRESSES: 'ADDRESSES',
    REMINDERS: 'REMINDERS',
    FINANCE: 'FINANCE',
    RECORDS: 'RECORDS',
    VENDOR_MANAGEMENT: 'VENDOR_MANAGEMENT',
    REPORTS: 'REPORTS',
    INTERNAL_FINANCE: 'INTERNAL_FINANCE',
    OPERATIONS: 'OPERATIONS',
    PROFILE: 'PROFILE',
    HELP: 'HELP',
    INTERNAL_ISSUES: 'INTERNAL_ISSUES',
    REPORTS_DOWNLOAD: 'REPORTS_DOWNLOAD',
    VERIFY_DEVICES: 'VERIFY_DEVICES',
    ASSETS: 'ASSETS',
    SETTINGS: 'SETTINGS',
    VENDOR_LIST: 'VENDOR_LIST',
    FUEL_LEVEL_V2: 'FUEL_LEVEL_V2',
    BILLING_INFO: 'BILLING_INFO',
    UPLOADERS: 'UPLOADERS',
    JOBCHECKLIST: 'JOB_CHECHLIST',
    CONTROL_TOWER: 'CONTROL_TOWER',
    BILL_SUMMARY: 'BILL_SUMMARY',
    NOTIFICATION: 'NOTIFICATION',
};

export const TIMESTAMP_FORMATS = [
    {
        value: 'DD/MM/YY HH:mm',
        label: 'DD/MM/YY HH:mm',
    },
    {
        value: 'DD MMM YY, hh:mm:ss A',
        label: 'DD MMM YY, hh:mm:ss A',
    },
    {
        value: 'DD MMM YY, hh:mm A',
        label: 'DD MMM YY, hh:mm A',
    },
    {
        value: 'DD/MM/YY, HH:mm:ss',
        label: 'DD/MM/YY, HH:mm:ss',
    },
    {
        value: 'DD MMM YYYY, HH:mm',
        label: 'DD MMM YYYY, HH:mm ',
    },
    {
        value: 'YYYY-MM-DD HH:mm:ss',
        label: 'YYYY-MM-DD HH:mm:ss',
    },
];

export const TRUCK_LOAD_TYPE = [
    {
        value: 'FTL',
        label: 'FTL(Full Truck Load)',
    },
    {
        value: 'PTL',
        label: 'PTL(Partial Truck Load)',
    },
    {
        value: 'FTL_PTL',
        label: 'FTL and PTL both',
    },
    {
        value: 'NONE',
        label: 'NONE',
    },
];

export const JOB_BOOKING_SORT_BASED_ON = [
    {
        value: 'bookingDate',
        label: 'Booking Date',
    },
    {
        value: 'dispatchDate',
        label: 'Dispatch Date',
    },
    {
        value: 'NONE',
        label: 'NONE',
    },
];

export const BUSINESS_TYPES = [
    {
        value: 'BOTH',
        label: 'Both',
    },
    {
        value: 'ECOMMERCE',
        label: 'E-Commerce',
    },
    {
        value: 'NON_ECOMMERCE',
        label: 'Non E-Commerce',
    },
];

export const FUEL_UNIT = {
    PERCENT: '%',
    LITRE: 'L',
};

export const FUEL_GRAPH_TYPES = {
    'FUEL_VS_SPEED': 'FUEL_VS_SPEED',
    'FUEL_VS_ALTITUDE': 'FUEL_VS_ALTITUDE',
    'FUEL_VS_ODOMETER': 'FUEL_VS_ODOMETER',
};

export function stopPropagation(event) {
    if (event && event.stopPropagation) {
        event.stopPropagation();
    }
}

export const IRC_GROUPLIMIT = {
    2440: 280, //bechara
    2434: 300, //siliguri
    2435: 400, //gurugram
    2436: 400, //jamshedpur
    2437: 500, //sohna
};

export const TEMPLATE_TYPE = {
    LR: 'LR',
    INVOICE: 'INVOICE',
    AUTO_INVOICE_FORMAT: 'auto_invoice_format',
    DETENTION_INVOICE_FORMAT: 'detention_invoice_format',
    REIMBURSEMENT_INVOICE_FORMAT: 'reimbursement_invoice_format',
};

export const getDeviceTypeObj = (deviceType) => {
    if (deviceType) {
        return {
            simTracking: deviceType === DEVICE_SOURCES.SIM ? true : null,
            gpsTracking: deviceType === DEVICE_SOURCES.DEVICE ? true : null,
            appTracking: deviceType === DEVICE_SOURCES.APP ? true : null,
            noTracking: deviceType === DEVICE_SOURCES.NO ? true : null,
        };
    }
    return null;
};
//  utility function which when passed a point that returns the list of nearest point candidates within the range supplied to the quadtree.
export const getNearestPointCandidatesFromQT = (point, QT, range) => {
    const latitude = get(point, 'latitude');
    const longitude = get(point, 'longitude');
    return QT.query(new Circle(normalizeLatitude(latitude), normalizeLongitude(longitude), range));
};

export const getInBetweenPolylineCoordinate = (prevNearestPointData, allCoordinates, nearestPoint, index) => {
    return slice(allCoordinates, prevNearestPointData + 1, nearestPoint.data);
};

export const getLanLngCandidates = (candidates) => {
    return map(candidates, (candidate) => {
        return {
            ...candidate,
            latitude: candidate.x,
            longitude: candidate.y,
        };
    });
};

// snaps simpoints onto the suggested route
export const getNearestPointOnPolyline = (points, QT, allCoordinates, isForPolyline) => {
    let nearestPoints = [];
    let inBtwPoints = [];
    let prevNearestPointData = 0;
    let maxNearestPoint = 0;
    let nearestPoint;
    let populate = true;
    map(uniqBy(points, 'serverTime'), (point, index) => {
        if (!isSImPingPointSnapEnabled()) {
            nearestPoints.push({
                ...point,
                lat: point.latitude,
                lng: point.longitude,
                snappedLat: point.latitude,
                snappedLng: point.longitude,
                index,
                showAsMarker: true,
            });
            return;
        }
        let candidates = getNearestPointCandidatesFromQT(point, QT, POLYLINE_NEAREST_POINT_RANGE);
        const deviationFlag = get(point, 'otherAttributes.valid');
        const candidatesWithLatLng = getLanLngCandidates(candidates);
        nearestPoint = nearestLatLng(
            normalizeLatitude(point.latitude),
            normalizeLongitude(point.longitude),
            candidatesWithLatLng,
            true
        );
        // do not snap last point
        if (nearestPoint && index < points.length - 1) {
            // if nearest point is found, first push all the points on the polyline leading to the nearest point and then push the point to the polyline
            if (!maxNearestPoint) {
                maxNearestPoint = nearestPoint.data;
            }
            if (populate && prevNearestPointData < nearestPoint.data && maxNearestPoint <= nearestPoint.data) {
                inBtwPoints = getInBetweenPolylineCoordinate(prevNearestPointData, allCoordinates, nearestPoint);
                nearestPoints = concat(nearestPoints, inBtwPoints);
            } else {
                populate = true;
            }

            if (nearestPoint.data > maxNearestPoint) {
                maxNearestPoint = nearestPoint.data;
            }
            nearestPoints.push({
                ...point,
                lat: deNormalizeLatitude(nearestPoint.x),
                lng: deNormalizeLongitude(nearestPoint.y),
                snappedLat: deNormalizeLatitude(nearestPoint.x),
                snappedLng: deNormalizeLongitude(nearestPoint.y),
                index,
                showAsMarker: true,
            });
        } else {
            // if no nearest point on the polyline is available push the deviated point on the route
            if (deviationFlag !== 'false' || !isForPolyline) {
                nearestPoints.push({
                    ...point,
                    lat: point.latitude,
                    lng: point.longitude,
                    snappedLat: point.latitude,
                    snappedLng: point.longitude,
                    deviation: true,
                    index,
                    showAsMarker: true,
                });
                prevNearestPointData = null;
                populate = false;
            }
        }
        if (get(nearestPoint, 'data')) {
            prevNearestPointData = nearestPoint.data;
        }
    });
    return nearestPoints;
};

export const getDeviceIdFromDriverPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return '';
    }
    if (phoneNumber.length === 14) {
        return '91' + phoneNumber.substring(4);
    }
    if (phoneNumber.length === 10) {
        return '91' + phoneNumber;
    }
};

export const getWhiteLabelLogo = (hostName) => {
    const url = 'https://' + hostName;
    switch (url) {
        case ALL_WHITE_LABEL_URLS.FLEETX_INTERNAL:
            return '/public/img/logos/white_label/white-label.png';
        case ALL_WHITE_LABEL_URLS.ICICI_LOMBARD:
            return '/public/img/logos/white_label/ilogman.png';
        case ALL_WHITE_LABEL_URLS.RIGHT_CHOICE:
            return '/public/img/logos/white_label/right_choice.png';
        case ALL_WHITE_LABEL_URLS.ACCEX:
            return '/public/img/logos/white_label/accex.png';
        case ALL_WHITE_LABEL_URLS.SHELL:
            return '/public/img/logos/white_label/shell_telematics.png';
        // case ALL_WHITE_LABEL_URLS.SHOORA:
        //     return '/public/img/logos/white_label/shoora.png';
    }
};

export const DEVICE_STATUS = [
    {
        value: 'ON_JOB',
        label: 'On Job',
    },
    {
        value: 'AT_PLANT',
        label: 'At Plant',
    },
    {
        value: 'WITH_TRANSPORTER',
        label: 'With Transporter',
    },
    {
        value: 'REVERSE_IN_TRANSIT',
        label: 'Reverse in transit',
    },
    {
        value: 'REACHED_AT_DESTINATION',
        label: 'Reached at destination',
    },
];

export const LOW_BATTERY_POWER_THRESHOLD = 8.0;
export const tableDataForEditFieldModal = [
    {
        'id': 'hide',
        'label': 'Hide',
        'type': 'boolean',
    },
    {
        'id': 'label',
        'label': 'Label',
        'type': 'text',
    },
];

export const ALLOWED_REFERRAL_DATA = {
    BVC: 'bvclogistics.com',
    SALESFORCE: 'salesforce.com',
    BROCABS: 'brocabs.com',
    SHELL: 'shelltelematics.in',
};

export const VEHICLE_DOCUMENT_TYPES = {
    INSURANCE: 'INSURANCE',
    FITNESS: 'FITNESS',
    ROADTAX: 'ROADTAX',
    PERMIT: 'PERMIT',
    OTHERS: 'OTHERS',
    CROSS_BORDER_PERMIT: 'CROSS_BORDER_PERMIT',
    ZAMBIAN_INSURANCE: 'ZAMBIAN_INSURANCE',
    IDENTITY: 'IDENTITY',
    COMESA: 'COMESA',
    MOZAMBIQUE_INSURANCE: 'MOZAMBIQUE_INSURANCE',
    BEIRA_PORT_ENTRY: 'BEIRA_PORT_ENTRY',
    ZIMBABWE_CVG: 'ZIMBABWE_CVG',
    TANZANIA_C29: 'TANZANIA_C29',
    PURE_OTHERS: 'PURE_OTHERS',
};

export const VEHICLE_DOCUMENT_TYPES_OPTIONS = [
    {
        value: VEHICLE_DOCUMENT_TYPES.INSURANCE,
        label: 'Insurance',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.FITNESS,
        label: 'Fitness',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.ROADTAX,
        label: 'Road Tax',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.PERMIT,
        label: 'Permit',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.OTHERS,
        label: 'PUC / Other Goods',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.PURE_OTHERS,
        label: 'Others',
    },
];

export const VEHICLE_DOCUMENT_TYPES__ADDITIONAL_OPTIONS = [
    ...VEHICLE_DOCUMENT_TYPES_OPTIONS,
    {
        value: VEHICLE_DOCUMENT_TYPES.CROSS_BORDER_PERMIT,
        label: 'Cross Border Permit',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.ZAMBIAN_INSURANCE,
        label: 'Zambian Insurance',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.IDENTITY,
        label: 'Identity',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.COMESA,
        label: 'COMESA',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.MOZAMBIQUE_INSURANCE,
        label: 'Mozambique Insurance',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.BEIRA_PORT_ENTRY,
        label: 'Biera Port Entry',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.ZIMBABWE_CVG,
        label: 'Zimbabwe CVG',
    },
    {
        value: VEHICLE_DOCUMENT_TYPES.TANZANIA_C29,
        label: 'Tanzania C29',
    },
];

export const VENDOR_TAG_TYPE = {
    RATECHART: 'RATECHART',
    PO: 'PO',
    CONTRACT: 'CONTRACT',
    VERIFICATION: 'VERIFICATION',
    OTHERS: 'OTHERS',
};

export const BRANCH_TAG_TYPE = {
    SECURITY: 'SECURITY',
    ENVIRONMENT: 'ENVIRONMENT',
    SAFETY: 'SAFETY',
    INSURANCE: 'INSURANCE',
    LEGAL: 'LEGAL',
    OTHERS: 'OTHERS',
};
export const userStatusOptions = [
    { value: 'STOCK TO BE SUBMITTED', label: 'STOCK TO BE SUBMITTED' },
    { value: 'SUBMIT TO PARTY', label: 'SUBMIT TO PARTY' },
    { value: 'ADDRESS TO BE COURIER', label: 'ADDRESS TO BE COURIER' },
];

export const STOPPAGE_TIME_INTERVAL = [
    { value: 10, label: '10 mins and above' },
    { value: 20, label: '20 mins and above' },
    { value: 30, label: '30 mins and above' },
    { value: 40, label: '40 mins and above' },
    { value: 50, label: '50 mins and above' },
    { value: 60, label: '60 mins and above' },
    { value: 70, label: '70 mins and above' },
    { value: 80, label: '80 mins and above' },
    { value: 90, label: '90 mins and above' },
    { value: 100, label: '100 mins and above' },
    { value: 110, label: '110 mins and above' },
    { value: 120, label: '120 mins and above' },
];

export const START_TIME_STOPPAGE_REPORT_OPTIONS = [
    { value: false, label: 'Plant Entry' },
    { value: true, label: 'Plant Exit' },
];

export const END_TIME_STOPPAGE_REPORT_OPTIONS = [
    { value: true, label: 'At Unloading' },
    { value: false, label: 'At Destination' },
];

export const getStoppageFields = [
    {
        placeholder: 'Select Stoppage Time Range',
        options: STOPPAGE_TIME_INTERVAL,
        clearable: true,
        key: 'stoppageTime',
        label: 'Cutoff Time',
    },
    {
        placeholder: 'Select Start Time',
        options: START_TIME_STOPPAGE_REPORT_OPTIONS,
        clearable: true,
        key: 'actualDepartureStartTime',
        label: 'Start Time',
    },
    {
        placeholder: 'Select End Time',
        options: END_TIME_STOPPAGE_REPORT_OPTIONS,
        clearable: true,
        key: 'unloadingPointEndTime',
        label: 'End Time',
    },
];
